import React, { Component } from "react";
import { Link } from "react-router-dom";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { loginUser } from "../../actions/authActions";
import { accountType } from "../../enums/accountType";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.auth.isAuthenticated) {
          if (nextProps.auth.user.type === accountType.USER) {
            nextProps.history.push(`/admin/offices`);
          } else if (nextProps.auth.user.type === accountType.CANDIDATE) {
            nextProps.history.push(`/dashboard`);
          }
        }
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentWillUnmount() {
    this.setState({ mounted: false });
    this.props.clearErrors();
  }
  componentDidMount() {
    this.setState({ mounted: true });
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.type === 1) {
        this.props.history.push(`/admin/offices`);
      } else {
        this.props.history.push(`/dashboard`);
      }
    }
  }
  responseGoogle = (response) => {
    if (this.state.mounted) {
      if (response && response.profileObj) {
        this.props.loginUser(response);
      }
    }
  };

  render() {
    return (
      <div className='grid-container'>
        <div className='main'>
          <div className='landing'>
            <div className='landing-title text-center'>QASandbox</div>
            <div className='landing-description  text-center'>
              Quality Assurance testing playgrounds. Play, explore, test...
            </div>
            <div className='landing-buttons text-center'>
              <Link to='/login' className='landing-buttons--btn btn btn-primary'>
                Login
              </Link>
              <Link to='/admin-login' className='landing-buttons--btn btn btn-primary-outline'>
                Admin
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, clearErrors })(withRouter(Landing));
