import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import Dropdown from "../../../components/common/Dropdown";
import Input from "../../../components/common/Input";
import Confirm from "../../../components/common/Confirm";
import Spinner from "../../../components/common/Spinner";
import Switch from "../../../components/common/SwitchComponent";
// import Switch from "../../../components/common/SwitchComponent";
import {
  getScenario,
  cancelCandidateExam,
  candidateAvailableScenarios,
  setCandidateExamScenario,
  updateCandidateExamScenario,
} from "../../../actions/scenarioActions";
import { getOfficeCandidate } from "../../../actions/candidateActions";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";
import ReactHtmlParser from "react-html-parser";
// import moment from "moment";
// import { Link } from "react-router-dom";

class NewScenario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      candidateActiveScenario:
        this.props.scenarios && this.props.scenarios.candidateActiveScenario && this.props.scenarios.candidateActiveScenario.scenarios,
      scenario: this.props.scenarios && this.props.scenarios.scenario,
      active: false,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR, roles.RECRUITER],
      scenario_id: "",
      deadline_switch: false,
      availableScenariosMapped: [],
      auth: {},
    };
    this.handleDeadlineSwitch = this.handleDeadlineSwitch.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/candidates/${nextProps.match.params.candidateId}`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        var errors = {};
        errors.deadline = "";
        update.errors = errors;
        update.auth = nextProps.auth;
      }
      if (nextProps.scenarios && nextProps.scenarios.scenario !== prevState.scenario) {
        update.scenario_id = nextProps.scenarios.scenario.id;
        update.scenarioMapped = nextProps.scenarios.scenario;
        update.scenarioUpdated = true;
      }

      if (
        nextProps.scenarios &&
        nextProps.scenarios.candidateAvailableScenarios &&
        nextProps.scenarios.candidateAvailableScenarios !== prevState.candidateAvailableScenarios
      ) {
        var availableScenarios = nextProps.scenarios.candidateAvailableScenarios;
        var availableScenariosMapped = [];
        availableScenarios.map((availableScenario) => {
          return availableScenariosMapped.push({
            id: availableScenario.id,
            title: availableScenario.title.length > 45 ? `${availableScenario.title.substring(0, 45)}...` : availableScenario.title,
          });
        });

        update.availableScenariosMapped = availableScenariosMapped;
        update.availableScenariosUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {});
    this.props.candidateAvailableScenarios(this.props.match.params.candidateId, this.props.match.params.officeId);
  }

  confirmActivationChange = (value) => {
    if (!value) {
      this.props.cancelCandidateExam(this.props.match.params.candidateId, (res) => {
        if (res.status === 200) {
          this.props.history.push(`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`);
          successToast("Candidate exam canceled successfully");
        } else {
          this.props.history.push(
            `/admin/${this.props.match.params.officeId}/candidate/${this.props.match.params.candidateId}/current-scenario`
          );
          failToast(`Candidate exam canceling failed`);
        }
      });
    }
  };
  confirmActivationStatusChangeModal = (value) => {
    var confirmMainText = "";
    var confirmText = "";
    var btnText = "";
    if (!value) {
      if (this.state.deadlineValue > 0) {
        confirmText = (
          <span>
            Candidate still has <span className="text-danger"> {this.state.deadlineValue} days left</span> to finish this exam.
            <br /> If canceled user will not be able to log in anymore and status will be set as{" "}
            <span className="text-warning">Canceled(Before Time)</span>!
            <br /> This action is
            <span className="text-danger"> IREVERSIBLE</span>!
          </span>
        );
      } else if (this.state.deadlineValue === 0) {
        confirmText = (
          <span>
            Candidate still has <span className="text-danger"> less than a day left</span> to finish this exam.
            <br /> If canceled user will not be able to log in anymore and status will be set as{" "}
            <span className="text-warning">Canceled(Before Time)</span>!
            <br /> This action is
            <span className="text-danger"> IREVERSIBLE!</span>
          </span>
        );
      } else if (this.state.deadlineValue < 0) {
        confirmText = (
          <span>
            Candidate deadline to finish this exam was <span className="text-success"> {Math.abs(this.state.deadlineValue)} ago </span> .
            <br /> If canceled user will not be able to log in anymore and status will be set as{" "}
            <span className="text-primary">Canceled(Timed Out)</span>!
            <br /> This action is
            <span className="text-danger"> IREVERSIBLE</span>!
          </span>
        );
      } else {
        confirmText = (
          <span>
            If canceled user will not be able to log in anymore and status will be set as{" "}
            <span className="text-primary">Canceled(No Timer)</span>!<br /> This action is
            <span className="text-danger"> IREVERSIBLE</span>!
          </span>
        );
      }

      confirmMainText = "Are you sure you want to cancel candidate exam?";
      btnText = "Deactivate";
      Confirm(confirmMainText, confirmText, "Cancel", btnText, () => this.confirmActivationChange(value));
    } else {
      // Confirm("confirmMainText", "confirmText", "Cancel", "btnText", () => this.confirmActivationChange(value));
    }
  };

  confirmScenarioSetModal = () => {
    var newScenarioId = this.state.newScenarioId && !isNaN(this.state.newScenarioId) ? parseInt(this.state.newScenarioId) : null;
    var newDeadline = this.state.deadlineSet && !isNaN(this.state.deadlineSet) ? parseInt(this.state.deadlineSet) : null;
    var confirmMainText = "Are you sure you want to set this candidate's exam scenario?";
    var confirmText = (
      <span>
        Setting candidate's exam scenario will take effect right away.
        <br /> Candidate will be activated and will be able to log in!
      </span>
    );
    var btnText = "Set";
    Confirm(confirmMainText, confirmText, "Cancel", btnText, () => this.confirmScenarioSet(newScenarioId, newDeadline));
  };
  confirmScenarioSet = (newScenarioId, newDeadline) => {
    if (!isNaN(newScenarioId)) {
      var formData = {};
      if (newScenarioId) {
        formData.scenario_id = newScenarioId;
      }
      if (newDeadline && this.state.deadline_switch) {
        formData.days_to_finish = newDeadline;
      } else if (!this.state.deadline_switch) {
        formData.days_to_finish = null;
      }
    }

    this.props.setCandidateExamScenario(this.props.match.params.candidateId, formData, (res) => {
      if (res.status === 200) {
        this.props.history.push(
          `/admin/${this.props.match.params.officeId}/candidate/${this.props.match.params.candidateId}/current-scenario`
        );

        successToast("Candidate exam updated successfully");
      } else {
        failToast(`Candidate exam update failed`);
      }
    });
  };
  onChangeScenario(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      var newScenarioId = !isNaN(this.state.newScenarioId) ? parseInt(this.state.newScenarioId) : null;
      this.props.getScenario(newScenarioId);
    });
  }
  onChange(e) {
    if (e.target.name === "deadlineSet") {
      this.setState({ deadlineChanged: true });
    }
    var errors = {};
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (isNaN(this.state.deadlineSet) || this.state.deadlineSet.substring(0, 1) === "0" || this.state.deadlineSet === "") {
        errors.deadline = "Invalid deadline";
        this.setState({ errors });
      } else {
        errors = {};
        errors.deadline = " ";
        this.setState({ errors });
      }
    });
  }

  handleDeadlineSwitch(value) {
    this.setState({ deadline_switch: value, deadlineSet: "" });
  }
  render() {
    const { loading } = this.props.scenarios;
    var content;
    var deadlineInput = "";
    if (this.state.deadline_switch) {
      deadlineInput = (
        <Input
          placeholder="Days"
          name={"deadlineSet"}
          validationMsg={this.state.errors.deadline}
          onChange={(e) => this.onChange(e)}
          value={this.state.deadlineSet}
        />
      );
    }

    var confirmSetScenarioBtn = "";
    if (this.state.scenario_id) {
      confirmSetScenarioBtn = (
        <div className="btn btn-primary mb-1" onClick={this.confirmScenarioSetModal}>
          Confirm
        </div>
      );
    }
    if (!this.state.availableScenariosUpdated || loading) {
      content = <Spinner />;
    } else {
      content = (
        <div className="exam">
          <div className="exam-title">New Exam Scenario</div>
          <div className="exam-body">
            {this.state.scenarioMapped ? ReactHtmlParser(this.state.scenarioMapped.scenario) : "Not set"}
            <hr />
            Test Cases to Write:{" "}
            {this.state.scenarioMapped && this.state.scenarioMapped.testcase_count ? this.state.scenarioMapped.testcase_count : "Not set"}
            <br />
            Test Cases to Automate:{" "}
            {this.state.scenarioMapped && this.state.scenarioMapped.automated_count ? this.state.scenarioMapped.automated_count : "Not set"}
            <br />
            Issues to report:{" "}
            {this.state.scenarioMapped && this.state.scenarioMapped.report_count ? this.state.scenarioMapped.report_count : "Not set"}
          </div>

          <hr />
          <div className="exam-bottom">
            <div className="exam-bottom--item">
              <div className="exam-bottom--item-title">Scenarios:</div>
              <div className="exam-bottom--item-info">
                You can change user scenario here and candidate will see change right away. If there is no scenario assigned (candidate
                deactivated) it will assign new scenario and candidate can be activated again for new exam.
              </div>
              <div className="exam-bottom--item-value">
                <div className="exam-buttons-grid">
                  <Dropdown
                    placeholder={"Scenarios"}
                    options={this.state.availableScenariosMapped}
                    label={""}
                    value={this.state.newScenarioId}
                    validationMsg=""
                    name={"newScenarioId"}
                    onChange={(e) => this.onChangeScenario(e)}
                  />
                  <div>
                    <Switch
                      onChange={this.handleDeadlineSwitch}
                      value={this.state.deadline_switch}
                      id={"deadline_switch"}
                      name={"deadline_switch"}
                      label={"Deadline"}
                      className="mt-1"
                    />
                  </div>
                  <div> {deadlineInput}</div>
                  {confirmSetScenarioBtn}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={
                this.props.candidates && this.props.candidates.officeCandidate
                  ? `${this.props.candidates.officeCandidate.first_name} ${this.props.candidates.officeCandidate.last_name} - Scenario`
                  : `Scenario`
              }
              link={`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`}
            />
            <div className="width-container">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

NewScenario.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  scenarios: state.scenarios,
  candidates: state.candidates,
});

export default connect(mapStateToProps, {
  getScenario,
  cancelCandidateExam,
  candidateAvailableScenarios,
  getOfficeCandidate,
  setCandidateExamScenario,
  updateCandidateExamScenario,
  clearErrors,
})(withRouter(NewScenario));
