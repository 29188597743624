import { combineReducers } from "redux";

import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import officeReducer from "./officeReducer";
import difficultyReducer from "./difficultyReducer";
import categoryReducer from "./categoryReducer";
import questionReducer from "./questionReducer";
import examReducer from "./examReducer";
import userReducer from "./userReducer";
import roleReducer from "./roleReducer";
import scenarioReducer from "./scenarioReducer";
import candidateReducer from "./candidateReducer";
import testcaseReducer from "./testcaseReducer";
import reportReducer from "./reportReducer";
import projectReducer from "./projectReducer";
import commentReducer from "./commentReducer";
import careerStatusesReducer from "./careerStatusesReducer";
import technologyReducer from "./technologyReducer";
import seniorityReducer from "./seniorityReducer";
import teamReducer from "./teamReducer";
import personReducer from "./personReducer";
import cityReducer from "./cityReducer";
import statisticsReducer from "./statisticsReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  offices: officeReducer,
  difficulties: difficultyReducer,
  categories: categoryReducer,
  questions: questionReducer,
  exams: examReducer,
  users: userReducer,
  roles: roleReducer,
  scenarios: scenarioReducer,
  candidates: candidateReducer,
  testcases: testcaseReducer,
  reports: reportReducer,
  projects: projectReducer,
  comments: commentReducer,
  careerStatuses: careerStatusesReducer,
  technologies: technologyReducer,
  seniorities: seniorityReducer,
  teams: teamReducer,
  people: personReducer,
  cities: cityReducer,
  statistics: statisticsReducer,
});
