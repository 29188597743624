import axios from "axios";

import { GET_CAREER_STATUSES, CAREER_STATUS_LOADING, GET_ERRORS } from "../types";

// Get All Career Statuses
export const getCareerStatuses = () => (dispatch) => {
  dispatch(careerStatusLoading());

  axios
    .get("/api/candidate/statuses")
    .then((res) =>
      dispatch({
        type: GET_CAREER_STATUSES,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    });
};

// Career Status loading
export const careerStatusLoading = () => {
  return {
    type: CAREER_STATUS_LOADING,
  };
};
