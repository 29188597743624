import React from "react";
import FormElementTitle from "../form/FormElementTitle";
import isEmpty from "../../validation/isEmpty"

function Dropdown({ options, placeholder, value, label, validationMsg, onChange, name, noTitle, required }) {
  var placeholderValue = "";
  if (!required) {
    placeholderValue = (
      <option value='0' className='default'>
        {placeholder}
      </option>
    );
  } else {
    placeholderValue = "";
  }
  var validationId = "";
  if(!isEmpty(validationMsg) && (validationMsg[0] !== undefined || validationMsg[1] !== undefined)){
    validationId = "validation-msg"
  }

  var title = (
    <div className='form-element-itemCol'>
      <FormElementTitle label={label} validationMsg={validationMsg} validationId={validationId} />
    </div>
  );
  if (noTitle) {
    title = "";
  }


  return (
    <div className='form-element'>
      {title}
      <select className='form-element--dropdown' onChange={onChange} name={name} value={value}>
        {placeholderValue}

        {options.map((option, index) => (
          <option key={index} value={option.id}>
            {option.title}
          </option>
        ))}
      </select>
      <span className='form-element--dropdown--icon'>
        <i className='fas fa-chevron-down'></i>
      </span>
    </div>
  );
}
export default Dropdown;
