import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import Spinner from "../../../components/common/Spinner";
import CategoryCard from "../../../components/Cards/CategoryCard";
// import failToast from "../../../components/toast/failToast";
import { roles } from "../../../enums/roles";
import { pageType } from "../../../enums/pageType";
import { getCategories } from "../../../actions/categoryActions";
import CheckPermissions from "../../../validation/CheckPermissions";
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      categories: this.props.categories.categories,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
      auth: {},
      categoriesMapped: [],
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;

      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/difficulties`,
          roles_allowed_to_write: prevState.rolesAllowedToOpen,
          page_type: pageType.WRITE,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.categories.categories !== prevState.categories) {
        update.categories = nextProps.categories.categories;

        var categories = nextProps.categories.categories;

        var mappedcategories = [];
        categories &&
          categories.map((category) => {
            return mappedcategories.push({
              id: category.id,
              category: category.category,
              link: `/admin/${nextProps.match.params.officeId}/edit-category/${category.id}`,
            });
          });

        if (mappedcategories.length > 0) {
          update.categoriesMapped = mappedcategories;
        } else {
          update.categoriesMapped = null;
        }
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/dashboard`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };

    if (CheckPermissions(dataToCheck)) {
      this.props.getCategories(this.props.match.params.officeId, () => {});
    }
  }
  render() {
    const {
      // categories,
      loading,
    } = this.props.categories;
    var content = "";
    if (this.state.categoriesMapped === [] || loading) {
      content = <Spinner />;
    } else {
      content = <CategoryCard categories={this.state.categoriesMapped} />;
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={"Categories"}
              link={`/admin/${this.props.match.params.officeId}/settings`}
              buttons={[
                <Link to={`/admin/${this.props.match.params.officeId}/new-category`} className="btn btn-primary">
                  Add Category
                </Link>,
              ]}
            />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

Categories.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  categories: state.categories,
});

export default connect(mapStateToProps, { getCategories, clearErrors })(withRouter(Categories));
