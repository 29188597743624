import isEmpty from "../validation/isEmpty";
const ParticipantValidation = (data) => {
  var errors = {};

  if (data && data.length > 0) {
    data.forEach((el) => {
      if (isNaN(el.id)) {
        errors.error = "Invalid user id ";
      }
    });
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default ParticipantValidation;
