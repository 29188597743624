import isEmpty from "../validation/isEmpty";
const AddExistingUserValidation = (data) => {
  var errors = {};

  data.office_id = !isEmpty(data.office_id) ? data.office_id : "";
  data.user_id = !isEmpty(data.user_id) ? data.user_id : "";
  data.role_id = !isEmpty(data.role_id) ? data.role_id : "";

  if (isEmpty(data.office_id)) {
    errors.office_id = "Office is required";
  } else {
    if (isNaN(data.office_id)) {
      errors.office_id = `Office is invalid`;
    }
  }

  if (isEmpty(data.user_id)) {
    errors.user_id = "User is required";
  } else {
    if (isNaN(data.user_id)) {
      errors.user_id = `User is invalid`;
    }
  }

  if (isEmpty(data.role_id)) {
    errors.role_id = "Role is required";
  } else {
    if (isNaN(data.role_id)) {
      errors.role_id = `Role is invalid`;
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default AddExistingUserValidation;
