import axios from "axios";

import { GET_CANDIDATE_TESTCASES, TESTCASE_LOADING, CLEAR_TESTCASES, REMOVE_LOADING, GET_ERRORS } from "./types";

// Get All Office Candidate testcases
export const getCandidateTestcases = (candidateId, scenarioId) => (dispatch) => {
  dispatch(testcaseLoading());

  var url = `/api/candidates/candidate/${candidateId}/testcases`;
  if (scenarioId) {
    url = `/api/candidates/candidate/${candidateId}/testcases?scenario_id=${scenarioId}`;
  }

  axios
    .get(url)
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_TESTCASES,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Clear Testcases
export const clearTestcases = () => {
  return {
    type: CLEAR_TESTCASES,
  };
};

// Testcase loading
export const testcaseLoading = () => {
  return {
    type: TESTCASE_LOADING,
  };
};
