import isEmpty from "../validation/isEmpty";
const Validator = require("validator");
const RegisterCandidateValidation = (data) => {
  const minPassLength = 6;
  const maxPassLength = 30;

  let errors = {};

  data.email = !isEmpty(data.email) ? data.email : "";
  data.password = !isEmpty(data.password) ? data.password : "";
  data.password2 = !isEmpty(data.password2) ? data.password2 : "";

  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email field is required";
  }

  if (
    !Validator.isLength(data.password, {
      min: minPassLength,
      max: maxPassLength,
    })
  ) {
    errors.password = `Password must be at least ${minPassLength} characters long`;
  }

  if (Validator.isEmpty(data.password)) {
    errors.password = "Password is required";
  }

  if (!Validator.equals(data.password, data.password2)) {
    errors.password2 = "Confirm password must match password";
  }

  if (Validator.isEmpty(data.password2)) {
    errors.password2 = "Confirm password is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default RegisterCandidateValidation;
