import axios from "axios";

import { GET_OFFICE_ROLES, ROLE_LOADING, REMOVE_LOADING, GET_ERRORS } from "./types";

// Get Non Global Roles
export const getOfficeRoles = (formData, callback) => (dispatch) => {
  dispatch(roleLoading());

  axios
    .post(`/api/roles`)
    .then((res) =>
      dispatch({
        type: GET_OFFICE_ROLES,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err.response);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Role loading
export const roleLoading = () => {
  return {
    type: ROLE_LOADING,
  };
};
