import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import { Link } from "react-router-dom";
import { getCandidateProfile } from "../../actions/candidate/profileActions";
import imgPlaceholder from "../../img/noImg.png";
import Spinner from "../../components/common/Spinner";
import linkify from "../../utility/linkify"


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      officeCandidate: this.props.candidates && this.props.candidates.officeCandidate,
      mounted: false,
      candidateMapped: null,
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.candidates.officeCandidate !== prevState.officeCandidate) {
        update.officeCandidate = nextProps.candidates.officeCandidate;
        update.candidateMapped = nextProps.candidates.officeCandidate;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getCandidateProfile(() => {});
  }
  render() {
    const { loading } = this.props.candidates;
    var content = "";
    if (this.state.candidateMapped === null || loading) {
      content = <Spinner />;
    } else {


    //   let config = [{
    //     regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
    //     fn: (key, result) => <span key={key}  className="hyperlink-class">
    //                              <a target="_blank" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{result[2]}.{result[3]}{result[4]}</a>{result[5]}
    //                          </span>
    // }, {
    //     regex: /(www\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
    //     fn: (key, result) => <span key={key} className="hyperlink-class">
    //                              <a target="_blank" href={`http://${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
    //                          </span>
    // }];

    // let processed = processString(config)(this.state.candidateMapped.biography);

      content = (
        <div>
          <div className='profile'>
            <div className='profile-top'>
              <div className='profile-top-left'>
                <div className='profile-top-left-img'>
                  <div className='fixed-ratio-div'>
                    <img
                      className='card-img-top rounded-circle'
                      src={
                        this.state.candidateMapped && this.state.candidateMapped.img
                          ? this.state.candidateMapped.img
                          : imgPlaceholder
                      }
                      alt=''
                      onError={(e) => {
                        e.preventDefault();
                        e.target.onerror = null;
                        e.target.src = imgPlaceholder;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='profile-top-right'>
                <div className='profile-top-right--item'>
                  <div className='profile-top-right--item-title'>First name:</div>
                  <div className='profile-top-right--item-value'>{this.state.candidateMapped.first_name}</div>
                </div>
                <div className='profile-top-right--item'>
                  <div className='profile-top-right--item-title'>Last name:</div>
                  <div className='profile-top-right--item-value'>{this.state.candidateMapped.last_name}</div>
                </div>
                <div className='profile-top-right--item'>
                  <div className='profile-top-right--item-title'>Email:</div>
                  <div className='profile-top-right--item-value'>{this.state.candidateMapped.email}</div>
                </div>
                <div className='profile-top-right--item'>
                  <div className='profile-top-right--item-title'>Status:</div>
                  <div className='profile-top-right--item-value'>
                    {this.state.candidateMapped.career_status ? this.state.candidateMapped.career_status.title : "/"}
                  </div>
                </div>
                <div className='profile-top-right--item'>
                  <div className='profile-top-right--item-title'>Github:</div>
                  <div className='profile-top-right--item-value'>
                    {this.state.candidateMapped.github_username ? this.state.candidateMapped.github_username : "/"}
                  </div>
                </div>
                <div className='profile-top-right--item'>
                  <div className='profile-top-right--item-title'>City:</div>
                  <div className='profile-top-right--item-value'>
                    {this.state.candidateMapped.city && this.state.candidateMapped.city.value ? this.state.candidateMapped.city.value : "/"}
                  </div>
                </div>
              </div>
            </div>
            <div className='profile-bottom'>
              <div className='profile-bottom--item'>
                <div className='profile-bottom--item-title'>Biography:</div>
                <div className='profile-bottom--item-value'> 
{this.state.candidateMapped && this.state.candidateMapped.biography ? linkify(this.state.candidateMapped.biography) : "/"}

                </div>
              </div>
            </div>
          </div>
          <div className='default-flex'>
            <div className='profile-buttons-left'>
              <Link
                className='btn btn-secondary'
                to={"/change-password"}
                label='Change Password'
                // onClick={(e) => this.onSubmit(e)}
              >
                Change Password
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='grid-menu-container'>
        <div className='menu-grid'>
          <div className='menu'>
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className='main-grid'>
          <div className='main'>
            <div className='width-container'>
              <Navigate
                title={"Profile"}
                link={"/dashboard"}
                buttons={[
                  <Link to={"/edit-profile"} className='btn btn-primary'>
                    Edit Profile
                  </Link>,
                ]}
              />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  candidates: state.candidates,
});

export default connect(mapStateToProps, { getCandidateProfile })(withRouter(Profile));
