import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import QuestionsCard from "../../../components/Cards/QuestionsCard";
import Input from "../../../components/common/Input";
import Spinner from "../../../components/common/Spinner";
import isEmpty from "../../../validation/isEmpty";
import Dropdown from "../../../components/common/Dropdown";
import { roles } from "../../../enums/roles";
import { pageType } from "../../../enums/pageType";
import Btn from "../../../components/common/Btn";
import CheckPermissions from "../../../validation/CheckPermissions";
import { getCategories } from "../../../actions/categoryActions";
import { getDifficulties } from "../../../actions/difficultyActions";
import Switch from "../../../components/common/SwitchComponent";
import { Link } from "react-router-dom";
import { getQuestions } from "../../../actions/questionActions";

import { withRouter } from "react-router-dom";
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;
class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      initialRender: true,
      questions: this.props.questions.questions,
      difficulties: this.props.difficulties.difficulties,
      categories: this.props.categories.categories,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR, roles.GLOBALVIEWER, roles.QA],
      difficultiesMapped: [],
      categoriesMapped: [],
      mounted: false,
      question_search: "",
      reviewedFilter: false,
      globalFilter: false,
      myQuestions: false,
      auth: {},
      errors: {},
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRevewied = this.handleChangeRevewied.bind(this);
    this.handleChangeGlobal = this.handleChangeGlobal.bind(this);
    this.handleChangeMyQuestions = this.handleChangeMyQuestions.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      var dataToCheck = {
        isAuthenticated: nextProps.auth.isAuthenticated,
        user_type: nextProps.auth.user.type,
        root_link: `/admin/offices`,
        user_offices: nextProps.auth.user.offices,
        office_id: nextProps.match.params.officeId,
        previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
        roles_allowed_to_write: prevState.rolesAllowedToOpen,
        page_type: pageType.WRITE,
        history: nextProps.history,
      };
      CheckPermissions(dataToCheck);

      if (nextProps.auth !== prevState.auth) {
        const officeRoleObject =
          nextProps.auth &&
          nextProps.auth.user &&
          nextProps.auth.user.offices &&
          nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
        var userRoleOffice =
          officeRoleObject &&
          officeRoleObject[0] &&
          officeRoleObject[0].user_role_office &&
          officeRoleObject[0].user_role_office.role.value;
        update.userRoleOffice = userRoleOffice;

        if (prevState.initialRender) {
          update.reviewedFilter = false;
          update.globalFilter = false;
          update.initialRender = false;
        }

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.questions.questions !== prevState.questions) {
        update.questions = nextProps.questions.questions;

        var questions = nextProps.questions.questions;

        var mappedquestions = [];
        questions &&
          questions.map((question) => {
            return mappedquestions.push({
              id: question.id,
              question: question.question,
              question_plain: question.question_plain,
              link: `/admin/${nextProps.match.params.officeId}/edit-question/${question.id}`,

              answer: question.answer,
              answer_plain: question.answer_plain,
              reviewed: question.reviewed,
              global: question.global,
              category: question.category ? question.category.category : null,
              difficulty: question.difficulty ? question.difficulty.difficulty : null,
              created_by: `${question.user.first_name} ${question.user.last_name}`,
              user_id: question.user_id,
            });
          });
        update.questionsMapped = mappedquestions;
        update.questionsUpdated = true;
      }
      if (nextProps.difficulties.difficulties !== prevState.difficulties) {
        var { difficulties } = nextProps.difficulties;
        var difficultiesMapped = [];
        difficulties.map((difficulty) => {
          return difficultiesMapped.push({ id: difficulty.id, title: difficulty.difficulty });
        });
        update.difficultiesMapped = difficultiesMapped;
        update.difficultiesUpdated = true;
      }
      if (nextProps.categories.categories !== prevState.categories) {
        var { categories } = nextProps.categories;
        var categoriesMapped = [];
        categories.map((category) => {
          return categoriesMapped.push({ id: category.id, title: category.category });
        });
        update.categoriesMapped = categoriesMapped;
        update.categoriesUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/dashboard`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getQuestions(this.props.match.params.officeId);
      this.props.getCategories(this.props.match.params.officeId, () => {});
      this.props.getDifficulties(this.props.match.params.officeId, () => {});
    }
  }
  handleChangeRevewied(reviewedFilter) {
    this.setState({ reviewedFilter }, () => {
      this.searchQuestions();
    });
  }
  handleChangeMyQuestions(myQuestions) {
    this.setState({ myQuestions }, () => {
      this.searchQuestions();
    });
  }
  handleChangeGlobal(globalFilter) {
    this.setState({ globalFilter }, () => {
      this.searchQuestions();
    });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.searchQuestions();
    });
  }
  timer = null;
  handleChange = (e) => {
    clearTimeout(this.timer);
    this.setState({ question_search: e.target.value }, () => {});
    this.timer = setTimeout((timeout) => {
      this.triggerChange(timeout);
    }, WAIT_INTERVAL);
  };

  handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.triggerChange(e);
    }
  };

  searchQuestions() {
    var filters = {};
    if (!isEmpty(this.state.question_search)) {
      filters.question_search = this.state.question_search;
    }
    if (!isEmpty(this.state.category) && !isNaN(this.state.category) && parseInt(this.state.category) !== 0) {
      filters.category_id = parseInt(this.state.category);
    }

    if (!isEmpty(this.state.difficulty) && !isNaN(this.state.difficulty) && parseInt(this.state.difficulty) !== 0) {
      filters.difficulty_id = parseInt(this.state.difficulty);
    }
    if (this.state.globalFilter) {
      filters.globalFilter = this.state.globalFilter;
    }

    if (this.state.reviewedFilter) {
      filters.reviewedFilter = this.state.reviewedFilter;
    }
    if (this.state.myQuestions) {
      filters.myQuestions = this.state.myQuestions;
    }
    if (Object.keys(filters).length > 0) {
      this.setState({ filters }, () => {});
      this.props.getQuestions(this.props.match.params.officeId, filters);
    } else {
      this.props.getQuestions(this.props.match.params.officeId);
    }
  }
  clearFilters() {
    this.setState(
      {
        question_search: "",
        category: "",
        difficulty: "",
        globalFilter: false,
        reviewedFilter: false,
        myQuestions: false,
      },
      () => {
        this.searchQuestions();
      }
    );
  }

  triggerChange = (e) => {
    this.setState({ question_search: this.state.question_search }, () => {
      this.searchQuestions();
    });
  };

  render() {
    const { loading } = this.props.questions;
    var content;
    if (!this.state.questionsUpdated || loading || !this.state.categoriesUpdated || !this.state.difficultiesUpdated) {
      content = <Spinner />;
    } else {
      content = (
        <QuestionsCard
          questions={this.state.questionsMapped}
          filters={this.state.filters}
          userRoleOffice={this.state.userRoleOffice}
          user={this.props.auth.user}
        />
      );
    }
    var addNewBtn = "";
    if (
      this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
      this.state.userRoleOffice === roles.ADMINISTRATOR ||
      this.state.userRoleOffice === roles.QA
    ) {
      addNewBtn = [
        <Link to={`/admin/${this.props.match.params.officeId}/new-question`} className="btn btn-primary">
          Add Question
        </Link>,
      ];
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Questions"} link={`/admin/${this.props.match.params.officeId}/dashboard`} buttons={addNewBtn} />

            <div className="search-container">
              <div className="search-container-search">
                <Input
                  placeholder="Search question and answers..."
                  name={"question_search"}
                  noTitle={true}
                  onChange={(e) => this.handleChange(e)}
                  onKeyDown={this.handleKeyDown}
                  value={this.state.question_search}
                />
              </div>
              <div className="search-container-filter">
                <Dropdown
                  placeholder="Categories"
                  options={this.state.categoriesMapped}
                  name={"category"}
                  noTitle={true}
                  onChange={(e) => this.onChange(e)}
                  value={this.state.category}
                />
              </div>
              <div className="search-container-filter">
                <Dropdown
                  placeholder="Difficulties"
                  options={this.state.difficultiesMapped}
                  name={"difficulty"}
                  noTitle={true}
                  onChange={(e) => this.onChange(e)}
                  value={this.state.difficulty}
                />
              </div>
            </div>
            <div className="search-container">
              <div className="search-container-filter">
                <Switch
                  onChange={this.handleChangeGlobal}
                  value={this.state.globalFilter}
                  id={"globalFilter"}
                  name={"globalFilter"}
                  label={"Global"}
                />
              </div>
              <div className="search-container-filter">
                <Switch
                  onChange={this.handleChangeRevewied}
                  value={this.state.reviewedFilter}
                  id={"reviewedFilter"}
                  name={"reviewedFilter"}
                  label={"Reviewed"}
                />
              </div>
              <div className="search-container-filter">
                {" "}
                <Switch
                  onChange={this.handleChangeMyQuestions}
                  value={this.state.myQuestions}
                  id={"myQuestions"}
                  name={"myQuestions"}
                  label={"My Questions"}
                />
              </div>
            </div>
            <div className="search-container">
              <div className="search-container-filter mb-1">
                <Btn label="Clear" className="btn btn-secondary" onClick={(e) => this.clearFilters()} />
              </div>
            </div>
            {content}
          </div>
        </div>
      </div>
    );
  }
}

Questions.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  questions: state.questions,
  categories: state.categories,
  difficulties: state.difficulties,
});

export default connect(mapStateToProps, { getQuestions, getDifficulties, getCategories, clearErrors })(withRouter(Questions));
