import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import imgPlaceholder from "../../img/noImg.png";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Confirm from "../common/Confirm";
class Navbar extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  confirmLogout = () => {
    this.props.logoutUser();
    // this.props.removeReport(this.props.match.params.reportId, (res) => {
    //   if (res.status === 200) {
    //     successToast("Report removed successfully");
    //     this.props.history.push(`/reports`);
    //   } else {
    //     failToast("Report remove failed");
    //     this.props.history.push(`/edit-report/${this.props.match.params.reportId}`);
    //   }
    // });
  };
  confirmLogoutModal = () => {
    var mainText = "Logout?";
    var subText = "Are you sure you want to log out?";
    var btnText = "Logout";

    Confirm(mainText, subText, "Cancel", btnText, () => this.confirmLogout());
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    // var authLinks = null;
    // var authLogo = null;
    var links;
    if (isAuthenticated) {
      links = (
        <div className="nav-links">
          <div className="nav-links--link">
            <Link to="#" onClick={(e) => this.confirmLogoutModal(e)}>
              Logout
            </Link>
          </div>
          <div className="nav-links--link">
            <div className="nav-links--link-img">
              {/* <img src={`${user.img}`} /> */}
              <img
                className="card-img-top rounded-circle"
                src={user.img ? user.img : imgPlaceholder}
                alt=""
                onError={(e) => {
                  e.preventDefault();
                  e.target.onerror = null;
                  e.target.src = imgPlaceholder;
                }}
              />
            </div>
          </div>
        </div>
      );
    } else {
      links = (
        <div className="nav-links">
          <div className="nav-links--link">
            <Link to="/login">Login</Link>
          </div>
          <div className="nav-links--link">
            <Link to="/admin-login">Admin</Link>
          </div>
        </div>
      );
    }
    return (
      <div className="nav">
        <div className="nav-logo">
          <div className="nav-logo--value">
            <Link to="/">QASandbox</Link>
          </div>
        </div>
        {links}
      </div>
    );
  }
}
Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(Navbar);
