import React, { Component } from "react";
// import { clearErrors } from "../../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
// import Input from "../../../components/common/Input";
import Dropdown from "../../../components/common/Dropdown";
import Spinner from "../../../components/common/Spinner";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";
import TestcaseCardsAdmin from "../../../components/Cards/TestcaseCardsAdmin";
import { getCandidateScenarios, getOfficeCandidate } from "../../../actions/candidateActions";
import { getCandidateTestcases } from "../../../actions/testcaseActions";

class AdminTestcases extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      user: {},
      candidateScenarios: this.props.candidates && this.props.candidates.candidateScenarios,
      testcases: this.props.testcases && this.props.testcases.testcases,
      auth: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          // update.errors = nextProps.errors;
          var errors = {};
          errors.deadline = "";
          update.errors = errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.testcases && nextProps.testcases.testcases !== prevState.testcases) {
        var testcases = nextProps.testcases.testcases;

        update.testcases = testcases;
        update.testcasesUpdated = true;
      }
      if (nextProps.candidates && nextProps.candidates.candidateScenarios !== prevState.candidateScenarios) {
        var candidateScenarios = nextProps.candidates.candidateScenarios;

        var candidateScenariosMapped = [];
        candidateScenarios.map((scenario) => {
          var newTitle = "";

          if (scenario.title) {
            newTitle = scenario.title.length > 45 ? `${scenario.title.substring(0, 45)}...` : scenario.title;
          } else {
            newTitle = "Current";
          }
          return candidateScenariosMapped.push({
            id: scenario.id,
            title: newTitle,
          });
        });

        update.candidateScenariosMapped = candidateScenariosMapped;
        update.candidateScenariosUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getCandidateScenarios(this.props.match.params.candidateId);
    this.props.getCandidateTestcases(this.props.match.params.candidateId);
    this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {});
  }

  onChangeScenario(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      var scenarioId = this.state.candidate_scenario_id ? parseInt(this.state.candidate_scenario_id) : null;
      this.props.getCandidateTestcases(this.props.match.params.candidateId, scenarioId);
    });
  }

  render() {
    const { loading } = this.props.testcases;

    var content;
    if (!this.state.candidateScenariosMapped || !this.state.testcasesUpdated || loading) {
      content = <Spinner />;
    } else {
      content = (
        <span>
          <div className="search-container">
            <div className="search-container-filter">
              <Dropdown
                placeholder="Exams"
                options={this.state.candidateScenariosMapped}
                noTitle={true}
                label={"Exams"}
                name={"candidate_scenario_id"}
                value={this.state.candidate_scenario_id}
                onChange={(e) => this.onChangeScenario(e)}
              />
            </div>
          </div>

          <TestcaseCardsAdmin testcases={this.state.testcases} />
        </span>
      );
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={
                this.props.candidates && this.props.candidates.officeCandidate
                  ? `${this.props.candidates.officeCandidate.first_name} ${this.props.candidates.officeCandidate.last_name} - Test Cases`
                  : `Test Cases`
              }
              link={`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`}
            />
            {content}
          </div>
        </div>
      </div>
    );
  }
}
AdminTestcases.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  candidates: state.candidates,
  testcases: state.testcases,
});

export default connect(mapStateToProps, {
  getCandidateScenarios,
  getCandidateTestcases,
  getOfficeCandidate,
})(withRouter(AdminTestcases));
