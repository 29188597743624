import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import { Link } from "react-router-dom";
import UserCard from "../../../components/Cards/UserCard";
import Input from "../../../components/common/Input";
import Dropdown from "../../../components/common/Dropdown";
import Spinner from "../../../components/common/Spinner";
import Btn from "../../../components/common/Btn";
import { getOfficeUsers } from "../../../actions/userActions";
import { getOfficeRoles } from "../../../actions/roleActions";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";
import isEmpty from "../../../validation/isEmpty";

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      officeUsers: this.props.users && this.props.users.officeUsers,
      officeRoles: this.props.roles && this.props.roles.officeRoles,
      active: "",
      user_search: "",
      auth: {},
      officeRolesMapped: [],
      errors: {},
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        const officeRoleObject =
          nextProps.auth &&
          nextProps.auth.user &&
          nextProps.auth.user.offices &&
          nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
        var userRoleOffice =
          officeRoleObject &&
          officeRoleObject[0] &&
          officeRoleObject[0].user_role_office &&
          officeRoleObject[0].user_role_office.role.value;
        update.userRoleOffice = userRoleOffice;
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.users.officeUsers !== prevState.officeUsers) {
        update.officeUsers = nextProps.users.officeUsers;

        var officeUsers = nextProps.users.officeUsers;
        var mappedOfficeUsers = [];
        officeUsers &&
          officeUsers.map((user) => {
            return mappedOfficeUsers.push(user);
          });
        update.officeUsersMapped = mappedOfficeUsers;
        update.officeUsersUpdated = true;
      }
      if (nextProps.roles.officeRoles !== prevState.officeRoles) {
        update.officeRoles = nextProps.roles.officeRoles;

        var officeRoles = nextProps.roles.officeRoles;
        var mappedOfficeRoles = [];
        officeRoles &&
          officeRoles.map((role) => {
            return mappedOfficeRoles.push({ id: role.id, title: role.value });
          });
        update.officeRolesMapped = mappedOfficeRoles;
        update.officeRolesUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    this.props.getOfficeUsers(this.props.match.params.officeId);
    this.props.getOfficeRoles();
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.searchUsers();
    });
  }
  searchUsers() {
    var filters = {};
    if (!isEmpty(this.state.user_search)) {
      filters.user_search = this.state.user_search;
    }

    if (!isEmpty(this.state.role) && !isNaN(this.state.role) && parseInt(this.state.role) !== 0) {
      filters.role_id = parseInt(this.state.role);
    }

    if (!isEmpty(this.state.active) && !isNaN(this.state.active) && parseInt(this.state.active) !== 0) {
      if (parseInt(this.state.active) === 2) {
        filters.active = parseInt(this.state.active);
      } else if (parseInt(this.state.active) === 3) {
        filters.active = parseInt(this.state.active);
      }
    }

    if (Object.keys(filters).length > 0) {
      this.setState({ filters }, () => {});
      this.props.getOfficeUsers(this.props.match.params.officeId, filters);
    } else {
      this.props.getOfficeUsers(this.props.match.params.officeId);
    }
  }
  timer = null;
  handleChange = (e) => {
    clearTimeout(this.timer);
    this.setState({ user_search: e.target.value }, () => {});
    this.timer = setTimeout((timeout) => {
      this.triggerChange(timeout);
    }, WAIT_INTERVAL);
  };

  handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.triggerChange(e);
    }
  };

  clearFilters() {
    this.setState(
      {
        user_search: "",
        active: "",
        role: "",
      },
      () => {
        this.searchUsers();
      }
    );
  }
  triggerChange = (e) => {
    this.setState({ user_search: this.state.user_search }, () => {
      this.searchUsers();
    });
  };

  render() {
    const { loading } = this.props.users;

    var content;
    if (!this.state.officeUsersUpdated || !this.state.officeRolesUpdated || loading) {
      content = <Spinner />;
    } else {
      content = <UserCard users={this.state.officeUsersMapped} props={this.props} filters={this.state.filters} />;
    }

    var addNewBtn = "";
    if (this.state.userRoleOffice === roles.SUPERADMINISTRATOR || this.state.userRoleOffice === roles.ADMINISTRATOR) {
      addNewBtn = [
        <Link to={`/admin/${this.props.match.params.officeId}/new-user`} className="btn btn-primary">
          Add User
        </Link>,
      ];
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Users"} link={`/admin/${this.props.match.params.officeId}/dashboard`} buttons={addNewBtn} />
            <div className="search-container">
              <div className="search-container-search">
                <Input
                  placeholder="Search users..."
                  name={"user_search"}
                  noTitle={true}
                  onChange={(e) => this.handleChange(e)}
                  onKeyDown={this.handleKeyDown}
                  value={this.state.user_search}
                />
              </div>
              <div className="search-container-filter">
                <Dropdown
                  placeholder="Role"
                  options={this.state.officeRolesMapped}
                  onChange={(e) => this.onChange(e)}
                  name={"role"}
                  value={this.state.role}
                  noTitle={true}
                />
              </div>
              <div className="search-container-filter">
                <Dropdown
                  placeholder="Active"
                  options={[
                    { id: 1, title: "All" },
                    { id: 2, title: "Activated" },
                    { id: 3, title: "Deactivated" },
                  ]}
                  onChange={(e) => this.onChange(e)}
                  name={"active"}
                  value={this.state.active}
                  noTitle={true}
                />
              </div>
            </div>
            <div className="search-container">
              <div className="search-container-filter mb-1">
                <Btn label="Clear" className="btn btn-secondary" onClick={(e) => this.clearFilters()} />
              </div>
            </div>
            {content}
          </div>
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  users: state.users,
  roles: state.roles,
});

export default connect(mapStateToProps, { getOfficeUsers, getOfficeRoles, clearErrors })(withRouter(Users));
