import { GET_PEOPLE, GET_PERSON, PERSON_LOADING } from "../actions/types";

const initialState = {
  people: null,
  person: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PERSON_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_PEOPLE:
      return {
        ...state,
        people: action.payload,
        loading: false,
      };
    case GET_PERSON:
      return {
        ...state,
        person: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
