import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Navigate from "../../../components/Navigate/Navigate";
import Spinner from "../../../components/common/Spinner";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import ScenariosCardPrevious from "../../../components/Cards/ScenariosCardPrevious";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import { getOfficeCandidate } from "../../../actions/candidateActions";
import CheckPermissions from "../../../validation/CheckPermissions";
import { updateExamParticipantStatus } from "../../../actions/examActions";

class AdminScenariosPrevious extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      user: {},
      mounted: false,
      candidate_scenarios:
        this.props.candidates && this.props.candidates.officeCandidate && this.props.candidates.officeCandidate.candidate_scenarios,
      errors: {},
      auth: {},
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          // update.errors = nextProps.errors;
          var errors = {};
          errors.deadline = "";
          update.errors = errors;
        }
        update.auth = nextProps.auth;
      }
      // var daysLeftToFinish = "";
      // if (
      //   nextProps.scenarios &&
      //   nextProps.scenarios.candidateActiveScenario &&
      //   nextProps.scenarios.candidateActiveScenario.scenarios !== prevState.candidateActiveScenario
      // ) {
      if (
        nextProps.candidates &&
        nextProps.candidates.officeCandidate &&
        nextProps.candidates.officeCandidate.candidate_scenarios !== prevState.candidate_scenarios
      ) {
        var candidateScenarios = nextProps.candidates.officeCandidate.candidate_scenarios;
        var mappedPreviousScenarios = [];

        candidateScenarios.map((scenario) => {
          if (!scenario.active) {
            mappedPreviousScenarios.push(scenario);
          }
          return null;
        });
        update.mappedPreviousScenarios = mappedPreviousScenarios;
        update.previousScenariosUpdated = true;
      }
      // }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.IsMounted = true;
    this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {});
  }

  render() {
    const { loading } = this.props.candidates;

    var content;
    if (!this.state.previousScenariosUpdated || loading) {
      content = <Spinner />;
    } else {
      content = (
        <ScenariosCardPrevious
          scenarios={this.state.mappedPreviousScenarios}
          props={this.props}
          onClickReviewed={this.props.updateExamParticipantStatus}
        />
      );
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={
                this.props.candidates && this.props.candidates.officeCandidate
                  ? `${this.props.candidates.officeCandidate.first_name} ${this.props.candidates.officeCandidate.last_name} - Previous`
                  : `Previous`
              }
              link={`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`}
            />
            {content}
          </div>
        </div>
      </div>
    );
  }
}
AdminScenariosPrevious.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  candidates: state.candidates,
});

export default connect(mapStateToProps, {
  getOfficeCandidate,
  updateExamParticipantStatus,
})(withRouter(AdminScenariosPrevious));
