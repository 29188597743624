export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_USER_OFFICES = "GET_USER_OFFICES";
export const GET_OFFICE = "GET_OFFICE";
export const GET_USER_GLOBAL_ROLE = "GET_USER_GLOBAL_ROLE";
export const GET_GLOBAL_USERS = "GET_GLOBAL_USERS";
export const GET_GLOBAL_USER = "GET_GLOBAL_USER";
export const OFFICE_LOADING = "OFFICE_LOADING";

export const GET_DIFFICULTIES = "GET_DIFFICULTIES";
export const GET_DIFFICULTY = "GET_DIFFICULTY";
export const DIFFICULTY_LOADING = "DIFFICULTY_LOADING";
export const GET_GLOBAL_DIFFICULTIES = "GET_GLOBAL_DIFFICULTIES";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const GET_GLOBAL_CATEGORIES = "GET_GLOBAL_CATEGORIES";

export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTION = "GET_QUESTION";
export const QUESTION_LOADING = "QUESTION_LOADING";

export const REMOVE_LOADING = "REMOVE_LOADING";

export const GET_GLOBAL_QUESTIONS = "GET_GLOBAL_QUESTIONS";
export const GET_GLOBAL_QUESTION = "GET_GLOBAL_QUESTION";

export const GET_EXAM_EMAIL_RECIPIENTS = "GET_EXAM_EMAIL_RECIPIENTS";
export const EXAM_LOADING = "EXAM_LOADING";
export const GET_EXAM_STATUSES = "GET_EXAM_STATUSES";
export const GET_EXAM_PARTICIPANTS = "GET_EXAM_PARTICIPANTS";

export const GET_OFFICE_USERS = "GET_OFFICE_USERS";
export const GET_ALL_OFFICES = "GET_ALL_OFFICES";
export const USER_LOADING = "USER_LOADING";

export const GET_OFFICE_ROLES = "GET_OFFICE_ROLES";
export const ROLE_LOADING = "ROLE_LOADING";

export const GET_SCENARIOS = "GET_SCENARIOS";
export const GET_SCENARIO = "GET_SCENARIO";
export const SCENARIO_LOADING = "SCENARIO_LOADING";
export const GET_OFFICE_USER = "GET_OFFICE_USER";
export const GET_AVAILABLE_USERS = "GET_AVAILABLE_USERS";
export const GET_ACTIVE_SCENARIO = "GET_ACTIVE_SCENARIO";
export const GET_AVAILABLE_SCENARIOS = "GET_AVAILABLE_SCENARIOS";

export const GET_OFFICE_CANDIDATES = "GET_OFFICE_CANDIDATES";
export const GET_OFFICE_CANDIDATE = "GET_OFFICE_CANDIDATE";
export const CANDIDATE_LOADING = "CANDIDATE_LOADING";
export const GET_CANDIDATE_REPOS = "GET_CANDIDATE_REPOS";
export const CLEAR_REPOS = "CLEAR_REPOS";

export const GET_CANDIDATE_SCENARIOS = "GET_CANDIDATE_SCENARIOS";
export const GET_CANDIDATE_DASHBOARD = "GET_CANDIDATE_DASHBOARD";

export const GET_CANDIDATE_TESTCASES = "GET_CANDIDATE_TESTCASES";
export const GET_CANDIDATE_TESTCASE = "GET_CANDIDATE_TESTCASE";
export const TESTCASE_LOADING = "TESTCASE_LOADING";
export const CLEAR_TESTCASES = "CLEAR_TESTCASES";

export const GET_CANDIDATE_REPORTS = "GET_CANDIDATE_REPORTS";
export const GET_CANDIDATE_REPORT = "GET_CANDIDATE_REPORT";
export const REPORT_LOADING = "REPORT_LOADING";
export const CLEAR_REPORTS = "CLEAR_REPORTS";

export const GET_CANDIDATE_PROJECTS = "GET_CANDIDATE_PROJECTS";
export const GET_CANDIDATE_PROJECT = "GET_CANDIDATE_PROJECT";
export const CLEAR_PROJECTS = "CLEAR_PROJECTS";
export const PROJECT_LOADING = "PROJECT_LOADING";

export const GET_CANDIDATE_COMMENTS = "GET_CANDIDATE_COMMENTS";
export const COMMENT_LOADING = "COMMENT_LOADING";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";

export const INVITE_CANDIDATE = "INVITE_CANDIDATE";
export const INVITED_CANDIDATE = "INVITED_CANDIDATE";
export const INVITE_LOADING = "INVITE_LOADING";

export const GET_CAREER_STATUSES = "GET_CAREER_STATUSES";
export const CAREER_STATUS_LOADING = "CAREER_STATUS_LOADING";

export const GET_TECHNOLOGIES = "GET_TECHNOLOGIES";
export const GET_TECHNOLOGY = "GET_TECHNOLOGY";
export const TECHNOLOGY_LOADING = "TECHNOLOGY_LOADING";

export const GET_SENIORITIES = "GET_SENIORITIES";
export const GET_SENIORITY = "GET_SENIORITY";
export const SENIORITY_LOADING = "SENIORITY_LOADING";

export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAM = "GET_TEAM";
export const TEAM_LOADING = "TEAM_LOADING";

export const GET_PEOPLE = "GET_PEOPLE";
export const GET_PERSON = "GET_PERSON";
export const PERSON_LOADING = "PERSON_LOADING";

export const GET_CITIES = "GET_CITIES";

export const GET_GLOBAL_CANDIDATES = "GET_GLOBAL_CANDIDATES";
export const GET_GLOBAL_CANDIDATE = "GET_GLOBAL_CANDIDATE";

export const GET_OFFICE_STATISTICS = "GET_OFFICE_STATISTICS";
export const STATISTIC_LOADING = "STATISTIC_LOADING";
export const GET_GLOBAL_STATISTICS = "GET_GLOBAL_STATISTICS";
