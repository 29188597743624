import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Input from "../../components/common/Input";
import Btn from "../../components/common/Btn";
import {
  getTechnologies,
  createTechnology,
  updateTechnology,
  removeTechnology,
} from "../../actions/candidate/technologyActions";
import { getCandidateProject } from "../../actions/candidate/projectActions";
import { getPeople } from "../../actions/candidate/peopleActions";
import Spinner from "../../components/common/Spinner";
import SettingsItemList from "../../components/Project/SettingsItemList";
import isEmpty from "../../validation/isEmpty";
import successToast from "../toast/successToast";
import failToast from "../toast/failToast";
import Confirm from "../common/Confirm";
import TechnologyValidation from "../../validation/TechnologyValidation";

class Technology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      project: this.props.projects && this.props.project,
      technologies: this.props.technologies && this.props.technologies.technologies,
      projectMapped: null,
      technologiesMapped: null,
      requestLoading:false,
      technology: "",
      updateTechnology: false,
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.technologies.technologies !== prevState.technologies) {
        update.technologies = nextProps.technologies.technologies;
        update.technologiesMapped = nextProps.technologies.technologies;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getTechnologies();
  }
  onChangeEdit(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidationEdit();
    });
  }
  onChangeCreate(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidationCreate();
    });
  }

  checkValidationCreate() {
    this.props.clearErrors();
    this.setState({ errors: {} });
    var formFields = {};
    formFields.title = this.state.technology && this.state.technology.length > 0 ? this.state.technology.trim() : "";
    var { errors, isValid } = TechnologyValidation(formFields);
    if (!isValid) {
      if (errors.technology) {
        errors.technologyCreate = errors.technology;
      }
      this.setState({ errors });
    }
  }

  checkValidationEdit() {
    this.props.clearErrors();
    this.setState({ errors: {} });
    var formFields = {};
    formFields.title = this.state.technology_name && this.state.technology_name.length > 0 ? this.state.technology_name.trim() : ""
    var { errors, isValid } = TechnologyValidation(formFields);
    if (!isValid) {
      if (errors.technology) {
        errors.technologyEdit = errors.technology;
      }
      this.setState({ errors });
    }
  }
  onSubmitTechnology() {
    this.props.clearErrors();
    var formFields = {};
    formFields.title = this.state.technology && this.state.technology.length > 0 ? this.state.technology.trim() : "";
    var { errors, isValid } = TechnologyValidation(formFields);
    if (isValid) {
      this.setState({requestLoading: true})
      this.props.createTechnology(formFields, (res) => {
        if (res.status === 200) {
          successToast("Technology created successfully");
          this.setState({ technology_name: "",technology: "", requestLoading: false });
        } else {
          this.setState({requestLoading: false})
          failToast("Technology creating failed");
        }
       
      });
    } else {
      if (errors.technology) {
        errors.technologyCreate = errors.technology;
      }
      this.setState({ errors });
    }
  }

  editTechnology(technology) {
    this.props.clearErrors();
    this.setState({ technologyToEdit: technology.id, technology_name: technology.title }, () => {
      this.checkValidationEdit();
    });
  }

  confirmEditTechnology(technology) {
    this.props.clearErrors();
    var technologyData = {};
    technologyData.title = this.state.technology_name && this.state.technology_name.length > 0 ? this.state.technology_name.trim() : ""
    const { errors, isValid } = TechnologyValidation(technologyData);

    if (isValid) {
    
      this.props.updateTechnology(technology.id, technologyData, (res) => {
        if (res.status === 200) {
          successToast("Technology updated successfully");
          this.props.getCandidateProject(this.props.match.params.projectId, () => {});
          this.props.getPeople();
          this.setState({ technology_name: "", technologyToEdit: null });
        } else {
          failToast("Technology update failed");
        }
      });
    } else {
      if (errors.technology) {
        errors.technologyEdit = errors.technology;
      }
      this.setState({ errors });
    }
  }

  confirmTechnologyRemove = (technology) => {
    if (!isEmpty(technology.id)) {
      this.props.removeTechnology(technology.id, (res) => {
        if (res.status === 200) {
          this.props.getCandidateProject(this.props.match.params.projectId, () => {});
          this.props.getPeople();
          successToast("Technology removed successfully");
        } else {
          failToast(`Removing ${technology.name} technology failed`);
        }
      });
    }
  };
  confirmTechnologyRemoveModal = (technology) => {
    var reject = "No";
    var title = "Remove this technology?";
    var msg = ``;
    var confirm = "Remove";

    Confirm(title, msg, reject, confirm, (e) => this.confirmTechnologyRemove(technology));
  };

  render() {
    const { loading } = this.props.technologies;
    var technologyContent = "";
    if (this.state.technologiesMapped === null || loading) {
      technologyContent = <Spinner />;
    } else if (this.state.technologiesMapped.length > 0) {
      technologyContent = this.state.technologies.map((technology, index) => (
        <SettingsItemList
          link={`/Employee/${technology.id}`}
          index={index}
          key={index}
          label={"Edit Technology"}
          id={technology.id}
          value1={technology.title}
          name={"technology_name"}
          value={this.state.technology_name}
          onClickRemove={(e) => this.confirmTechnologyRemoveModal(technology)}
          onClickEdit={(e) => {
            this.editTechnology(technology);
          }}
          cancelEdit={(e) => this.setState({ technologyToEdit: null })}
          onClickConfirmEdit={(e) => this.confirmEditTechnology(technology)}
          onChange={(e) => this.onChangeEdit(e, this.state.technologyToEdit)}
          validationMsg={[this.props.errors.technologyEdit, this.state.errors.technologyEdit]}
          idToEdit={this.state.technologyToEdit}
        />
      ));
    } else {
      technologyContent = <div className='no-content'>No technologies created</div>;
    }
    return (
      <div className='project-bottom-technologies'>
        Here you can create technologies (Java, SQL, .NET, React...).
        <Input
          placeholder={"Technology"}
          type='text'
          onChange={(e) => this.onChangeCreate(e)}
          name={"technology"}
          label='Technologies'
          value={this.state.technology}
          validationMsg={[this.state.errors.technologyCreate, this.props.errors.technologyCreate]}
        />
        <div className='submit-button'>
          <Btn className='btn btn-primary ' label='Create' onClick={(e) => this.onSubmitTechnology(e)} loading={this.state.requestLoading} />
        </div>
        {technologyContent}
      </div>
    );
  }
}

Technology.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  technologies: state.technologies,
});

export default connect(mapStateToProps, {
  createTechnology,
  getTechnologies,
  updateTechnology,
  removeTechnology,
  getCandidateProject,
  getPeople,
  clearErrors,
})(withRouter(Technology));
