import { GET_TECHNOLOGIES, GET_TECHNOLOGY, TECHNOLOGY_LOADING } from "../actions/types";

const initialState = {
  technologies: null,
  technology: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TECHNOLOGY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TECHNOLOGIES:
      return {
        ...state,
        technologies: action.payload,
        loading: false,
      };
    case GET_TECHNOLOGY:
      return {
        ...state,
        technology: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
