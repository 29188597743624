import React from "react";
import  processString from 'react-process-string';
     
const linkify = text => {
     
     let config = [{
        regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
        fn: (key, result) => <span key={key}  className="hyperlink-class">
                                 <a target="_blank" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`} rel="noopener noreferrer">{result[2]}.{result[3]}{result[4]}</a>{result[5]}
                             </span>
    }, {
        regex: /(www\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
        fn: (key, result) => <span key={key} className="hyperlink-class">
                                 <a target="_blank" href={`http://${result[1]}.${result[2]}${result[3]}`} rel="noopener noreferrer">{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                             </span>
    }];
    let processed = processString(config)(text);
    return processed;
}

export default linkify;