import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { roles } from "../../enums/roles";

export default function ScenarioCard({ scenarios, userRoleOffice }) {
  var content = "Loading...";

  var roleCanOpen = false;
  var clickableClass = "preview-card-not-clickable";
  if (userRoleOffice === roles.SUPERADMINISTRATOR || userRoleOffice === roles.ADMINISTRATOR) {
    roleCanOpen = true;
    clickableClass = "";
  }

  if (scenarios && scenarios.length > 0) {
    content = (
      <div className="portrait-grid">
        {scenarios &&
          scenarios.map((scenario, index) => (
            <span key={index}>
              <Link className={`preview-card`} key={index} to={roleCanOpen ? scenario.link : "#"}>
                <div className={`preview-card ${clickableClass}`}>
                  <div className="preview-card-title">
                    <div className="preview-card-title-value">{scenario.title}</div>
                  </div>
                  <div className="preview-card-body">
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-new-line">
                        <div className="preview-card-body--items-new-line-title">Scenario:</div>
                        <div className="preview-card-body--items-new-line-value">{scenario.scenario_plain}</div>
                      </div>
                    </div>
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-single">
                        <div className="preview-card-body--items-single-title">Created by:</div>
                        <div className="preview-card-body--items-single-value">{scenario.created_by}</div>
                      </div>
                    </div>
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-single">
                        <div className="preview-card-body--items-single-title">Test Cases:</div>
                        <div className="preview-card-body--items-single-value">
                          {scenario.testcase_count ? scenario.testcase_count : "Not set"}
                        </div>
                      </div>
                    </div>
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-single">
                        <div className="preview-card-body--items-single-title">Automate Count:</div>
                        <div className="preview-card-body--items-single-value">
                          {scenario.automated_count ? scenario.automated_count : "Not set"}
                        </div>
                      </div>
                    </div>
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-single">
                        <div className="preview-card-body--items-single-short">
                          <div className="preview-card-body--items-single-short-title">Reports:</div>
                          <div className="preview-card-body--items-single-short-value">
                            {scenario.report_count ? scenario.report_count : "Not set"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="preview-card-body--items"
                      onClick={(e) => e.preventDefault()}
                      data-toggle="modal"
                      data-target={`#myModal-${index}`}
                    >
                      <div className="preview-card-body--items-single-preview">
                        <div className="preview-card-body--items-single-preview-title">Preview</div>
                        <div className="preview-card-body--items-single-preview-value">
                          <i className="fas fa-eye"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <div id={`myModal-${index}`} className="modal fade" role="dialog">
                <div className="modal-dialog">
                  {/* <!-- Modal content--> */}
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-titles">Scenario Preview</h4>
                      <button type="button" className="close" data-dismiss="modal">
                        &times;
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="preview-card-modal">
                        <div className="preview-card-modal-title">
                          <div className="preview-card-modal-title-value">{scenario.title}</div>
                        </div>
                        <hr />
                        <div className="preview-card-modal-body">
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Test Cases:</div>
                              <div className="preview-card-modal-body--items-single-value">{scenario.testcase_count}</div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Automate Count:</div>
                              <div className="preview-card-modal-body--items-single-value">{scenario.automated_count}</div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Reports:</div>
                              <div className="preview-card-modal-body--items-single-value">{scenario.report_count}</div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-new-line">
                              <div className="preview-card-modal-body--items-new-line-title">Scenario:</div>
                              <div className="preview-card-modal-body--items-new-line-value">{ReactHtmlParser(scenario.scenario)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          ))}
      </div>
    );
  } else {
    content = <div className="no-content">There are no previous scenarios assigned.</div>;
  }
  return <div>{content}</div>;
}
