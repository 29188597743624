import {
  GET_QUESTIONS,
  GET_QUESTION,
  QUESTION_LOADING,
  GET_GLOBAL_QUESTIONS,
  GET_GLOBAL_QUESTION,
  REMOVE_LOADING,
} from "../actions/types";

const initialState = {
  questions: null,
  question: null,
  globalQuestions: null,
  globalQuestion: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case QUESTION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
        loading: false,
      };
    case GET_QUESTION:
      return {
        ...state,
        question: action.payload,
        loading: false,
      };
    case REMOVE_LOADING:
      return {
        ...state,

        loading: false,
      };
    case GET_GLOBAL_QUESTIONS:
      return {
        ...state,
        globalQuestions: action.payload,
        loading: false,
      };
    case GET_GLOBAL_QUESTION:
      return {
        ...state,
        question: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
