import { accountType } from "../enums/accountType";
import { pageType } from "../enums/pageType";
// import failToast from "../components/toast/failToast";

const CheckPermissions = (data) => {
  // var initialRender = true;
  var valid = true;
  if (!data.isAuthenticated || data.user_type !== accountType.USER) {
    // Not Authorized so redirecting to offices page which will redirect to landing
    data.history.push(data.root_link);
    valid = false;
    // if (initialRender) {
    //   initialRender = false;
    //   failToast("Not authenticated - try relog");
    // }
    // failToast("No permission - try relog");
  } else {
    // Authorized and checking permission

    const officeRole = data.user_offices.filter((office) => office.id === parseInt(data.office_id));
    // ROLE ZA TAJ OFFICE
    if (officeRole.length === 0) {
      // No permission so returning to previous page
      // if (initialRender) {
      //   initialRender = false;
      //   failToast("No offices assigned - try relog");
      // }
      data.history.push(data.previous_link);
      valid = false;
    } else {
      var userRoleForOffice = officeRole[0].user_role_office.role.value;

      if (!data.roles_allowed_to_write.includes(userRoleForOffice) && data.page_type === pageType.WRITE) {
        // This is write page and user has no permission to write so returning to previous page

        // if (initialRender) {
        //   initialRender = false;
        //   failToast("No write permissions - try relog");
        // }
        valid = false;
        data.history.push(data.previous_link);
      }
    }
  }
  return valid;
};

export default CheckPermissions;
