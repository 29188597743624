import React from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { setCurrentUser, logoutUser } from "./actions/authActions";
import setAuthToken from "./actions/setAuthToken";
import store from "./store";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "../src/sass/main.scss";
// -------------------GLOBAL-----------------------
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Landing from "./pages/Auth/Landing";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import AdminLogin from "./pages/Auth/AdminLogin";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import NotFound from "./pages/NotFound";

import Dashboard from "./pages/Candidate/Dashboard";
import Profile from "./pages/Candidate/Profile";
import EditProfile from "./pages/Candidate/EditProfile";
import Testcases from "./pages/Candidate/Testcases";
import Projects from "./pages/Candidate/Projects";
import Reports from "./pages/Candidate/Reports";
import NewTestcase from "./pages/Candidate/NewTestcase";
import EditTestcase from "./pages/Candidate/EditTestcase";
import ChangePassword from "./pages/Candidate/ChangePassword";
import NewProject from "./pages/Candidate/NewProject";
import EditProject from "./pages/Candidate/EditProject";
import ResetPassword from "./pages/Candidate/ResetPassword";

import ForgotPasswordAdmin from "./pages/Auth/ForgotPasswordAdmin";
import AdminSettings from "./pages/Admin/Settings/Settings";
import AdminOfficeStatistics from "./pages/Admin/Statistics/OfficeStatistics";
import AdminGlobalStatistics from "./pages/Admin/Statistics/GlobalStatistics";
import AdminSettingsUsers from "./pages/Admin/Settings/Users";
import AdminSettingsOffices from "./pages/Admin/Settings/Offices";
import AdminSettingsOffice from "./pages/Admin/Settings/Office";
import AdminSettingsCreateOffice from "./pages/Admin/Settings/CreateOffice";
import AdminSettingsUser from "./pages/Admin/Settings/User";
import AdminSettingsCandidates from "./pages/Admin/Settings/Candidates";
import AdminSettingsCandidate from "./pages/Admin/Settings/Candidate";
import AdminOffices from "./pages/Admin/Offices";
import AdminDashboard from "./pages/Admin/Dashboard";
import AdminCandidates from "./pages/Admin/Candidate/Candidates";
import AdminNewCandidate from "./pages/Admin/Candidate/AddCandidate";
import AdminEditCandidate from "./pages/Admin/Candidate/EditCandidate";
import AdminUsers from "./pages/Admin/Users/Users";
import AdminUser from "./pages/Admin/Users/User";
import AdminNewUser from "./pages/Admin/Users/AddUser";
import AdminQuestions from "./pages/Admin/Questions/Questions";
import AdminEditQuestion from "./pages/Admin/Questions/EditQuestion";
import AdminCopyQuestion from "./pages/Admin/Questions/CopyQuestion";
import AdminNewQuestion from "./pages/Admin/Questions/NewQuestion";
import AdminGlobalQuestions from "./pages/Admin/Questions/GlobalQuestions";
import AdminScenarios from "./pages/Admin/Scenarios/Scenarios";
import AdminRecipients from "./pages/Admin/Exam/Recipients";
import AdminRecipientsEdit from "./pages/Admin/Exam/EditRecipients";

import AdminDifficulties from "./pages/Admin/Difficulties/Difficulties";
import AdminNewDifficulty from "./pages/Admin/Difficulties/NewDifficulty";
import AdminEditDifficulty from "./pages/Admin/Difficulties/EditDifficulty";

import AdminCategories from "./pages/Admin/Categories/Categories";
import AdminNewCategory from "./pages/Admin/Categories/NewCategory";
import AdminEditCategory from "./pages/Admin/Categories/EditCategory";

import AdminCandidate from "./pages/Admin/Candidate/Candidate";
import AdminTestcases from "./pages/Admin/Candidate/Testcases";
import AdminProjects from "./pages/Admin/Candidate/Projects";
import AdminReports from "./pages/Admin/Candidate/Reports";
import AdminRepositories from "./pages/Admin/Candidate/Repositories";
import AdminScenarioCurrent from "./pages/Admin/Candidate/ScenarioCurrent";
import AdminScenariosPrevious from "./pages/Admin/Candidate/ScenariosPrevious";
import AdminNewScenario from "./pages/Admin/Candidate/NewScenario";
import AdminScenarioEdit from "./pages/Admin/Scenarios/EditScenario";
import AdminScenarioNew from "./pages/Admin/Scenarios/NewScenario";
import AdminOfficeSettings from "./pages/Admin/Settings";
import ResetPasswordAdmin from "./pages/Admin/ResetPasswordAdmin";

import NewReport from "./pages/Candidate/NewReport";
import EditReport from "./pages/Candidate/EditReport";
import Exam from "./pages/Candidate/Exam";
import Intro from "./pages/Candidate/Intro";
import openSocket from "socket.io-client";
import webSocket from "./configSocket/keys";
// import PrivateRoute from "./components/common/PrivateRoute";
var socket = openSocket(webSocket.webSocket);
socket.on("refreshUserToken", function (data) {
  if (localStorage.jwtSandboxToken) {
    const decoded = jwt_decode(localStorage.jwtSandboxToken);
    if (decoded.id === parseInt(data)) {
      var refreshTokenCrypted = localStorage.getItem("jwtSandboxRefreshToken");
      let refreshTokenObj = {
        refreshToken: refreshTokenCrypted,
      };
      axios
        .post("/api/token", refreshTokenObj)
        .then((res) => {
          // Save to localStorage
          const { token, refreshToken } = res.data;
          // Set token to ls
          localStorage.setItem("jwtSandboxToken", token);
          localStorage.setItem("jwtSandboxRefreshToken", refreshToken);
          // Set token to Auth header
          setAuthToken(token);
          // Decode token to get user data
          const decoded = jwt_decode(token);
          // Set current user
          store.dispatch(setCurrentUser(decoded));
        })
        .catch((err) => {
          store.dispatch(logoutUser());
        });
    }
  }
});

// Check for token
if (localStorage.jwtSandboxToken) {
  const currentTime = Date.now() / 1000;
  // Set auth token header auth
  setAuthToken(localStorage.jwtSandboxToken);
  var refreshTokenCrypted = localStorage.getItem("jwtSandboxRefreshToken");
  let refreshTokenObj = {
    refreshToken: refreshTokenCrypted,
  };
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtSandboxToken);
  if (decoded.exp < currentTime - 10) {
    axios
      .post("/api/token", refreshTokenObj)
      .then((res) => {
        // Save to localStorage
        const { token, refreshToken } = res.data;
        // Set token to ls
        localStorage.setItem("jwtSandboxToken", token);
        localStorage.setItem("jwtSandboxRefreshToken", refreshToken);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        // Set current user
        store.dispatch(setCurrentUser(decoded));
      })
      .catch((err) => {
        store.dispatch(logoutUser());
      });
  } else {
    store.dispatch(setCurrentUser(decoded));
  }
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Navbar />
          <div>
            <ToastContainer transition={Flip} position="bottom-right" />
          </div>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/admin-login" component={AdminLogin} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/edit-profile" component={EditProfile} />
            <Route exact path="/testcases" component={Testcases} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/new-testcase" component={NewTestcase} />
            <Route exact path="/edit-testcase/:testcaseId" component={EditTestcase} />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/new-report" component={NewReport} />
            <Route exact path="/new-project" component={NewProject} />
            <Route exact path="/edit-report/:reportId" component={EditReport} />
            <Route exact path="/edit-project/:projectId" component={EditProject} />
            <Route exact path="/exam" component={Exam} />
            <Route exact path="/intro" component={Intro} />

            <Route exact path="/admin/offices" component={AdminOffices} />
            <Route exact path="/reset-password-admin" component={ResetPasswordAdmin} />
            <Route exact path="/forgot-password-admin" component={ForgotPasswordAdmin} />
            <Route exact path="/admin/:officeId/dashboard" component={AdminDashboard} />
            <Route exact path="/admin/:officeId/candidates" component={AdminCandidates} />
            <Route exact path="/admin/:officeId/new-candidate" component={AdminNewCandidate} />
            <Route exact path="/admin/:officeId/users" component={AdminUsers} />
            <Route exact path="/admin/:officeId/new-user" component={AdminNewUser} />
            <Route exact path="/admin/:officeId/users/:userId" component={AdminUser} />
            <Route exact path="/admin/:officeId/questions" component={AdminQuestions} />
            <Route exact path="/admin/:officeId/edit-question/:questionId" component={AdminEditQuestion} />
            <Route exact path="/admin/:officeId/copy-question/:questionId" component={AdminCopyQuestion} />
            <Route exact path="/admin/:officeId/new-question" component={AdminNewQuestion} />
            <Route exact path="/admin/:officeId/global-questions" component={AdminGlobalQuestions} />
            <Route exact path="/admin/:officeId/scenarios" component={AdminScenarios} />
            <Route exact path="/admin/:officeId/recipients" component={AdminRecipients} />
            <Route exact path="/admin/:officeId/edit-recipients" component={AdminRecipientsEdit} />
            <Route exact path="/admin/:officeId/statistics" component={AdminOfficeStatistics} />
            <Route exact path="/admin/global-statistics" component={AdminGlobalStatistics} />

            <Route exact path="/admin/:officeId/difficulties" component={AdminDifficulties} />
            <Route exact path="/admin/:officeId/new-difficulty" component={AdminNewDifficulty} />
            <Route exact path="/admin/:officeId/edit-difficulty/:difficultyId" component={AdminEditDifficulty} />

            <Route exact path="/admin/:officeId/categories" component={AdminCategories} />
            <Route exact path="/admin/:officeId/new-category" component={AdminNewCategory} />
            <Route exact path="/admin/:officeId/edit-category/:categoryId" component={AdminEditCategory} />

            <Route exact path="/admin/:officeId/candidates/:candidateId" component={AdminCandidate} />
            <Route exact path="/admin/:officeId/candidates/:candidateId/edit" component={AdminEditCandidate} />
            <Route exact path="/admin/:officeId/candidate/testcases/:candidateId" component={AdminTestcases} />
            <Route exact path="/admin/:officeId/candidate/projects/:candidateId" component={AdminProjects} />
            <Route exact path="/admin/:officeId/candidate/reports/:candidateId" component={AdminReports} />
            <Route exact path="/admin/:officeId/candidate/repositories/:candidateId" component={AdminRepositories} />
            <Route exact path="/admin/:officeId/candidate/:candidateId/current-scenario" component={AdminScenarioCurrent} />
            <Route exact path="/admin/:officeId/candidate/:candidateId/previous-scenarios" component={AdminScenariosPrevious} />
            <Route exact path="/admin/:officeId/candidate/:candidateId/new-scenario" component={AdminNewScenario} />
            <Route exact path="/admin/:officeId/edit-scenario/:scenarioId" component={AdminScenarioEdit} />
            <Route exact path="/admin/:officeId/new-scenario" component={AdminScenarioNew} />
            <Route exact path="/admin/:officeId/settings" component={AdminOfficeSettings} />
            <Route exact path="/admin/settings" component={AdminSettings} />
            <Route exact path="/admin/users/global-settings" component={AdminSettingsUsers} />
            <Route exact path="/admin/users/user/:userId/settings" component={AdminSettingsUser} />
            <Route exact path="/admin/offices/global-settings" component={AdminSettingsOffices} />
            <Route exact path="/admin/offices/settings/new-office" component={AdminSettingsCreateOffice} />
            <Route exact path="/admin/offices/office/:officeId/settings" component={AdminSettingsOffice} />
            <Route exact path="/admin/candidates/global-settings" component={AdminSettingsCandidates} />
            <Route exact path="/admin/candidates/candidate/:candidateId/settings" component={AdminSettingsCandidate} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
