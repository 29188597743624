import axios from "axios";

import { GET_USER_OFFICES, GET_OFFICE, GET_ALL_OFFICES, OFFICE_LOADING, GET_ERRORS } from "./types";

// Get All User Offices With Roles
export const getUserOffices = (user_id) => (dispatch) => {
  dispatch(officeLoading());

  axios
    .get(`/api/user/${user_id}/offices`)
    .then((res) =>
      dispatch({
        type: GET_USER_OFFICES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_USER_OFFICES,
        payload: {},
      })
    );
};

export const getOffice = (office_id) => (dispatch) => {
  dispatch(officeLoading());

  axios
    .get(`/api/offices/office/${office_id}`)
    .then((res) =>
      dispatch({
        type: GET_OFFICE,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_OFFICE,
        payload: {},
      })
    );
};

// Get All Offices
export const getAllOffices = (filters) => (dispatch) => {
  dispatch(officeLoading());

  axios
    .post(`/api/offices`, filters)
    .then((res) =>
      dispatch({
        type: GET_ALL_OFFICES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_USER_OFFICES,
        payload: {},
      })
    );
};

export const uploadOfficePhoto = (officeId, file, config, callback) => (dispatch) => {
  axios
    .post(`/api/offices/${officeId}/photo`, file, config)

    .then((res) => {
      callback(res);
    })
    .catch((res) => callback(res));
};

export const createOffice = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/offices/office`, formData)

    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

export const updateOffice = (officeId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/offices/office/${officeId}`, formData)

    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// User Offices Loading
export const officeLoading = () => {
  return {
    type: OFFICE_LOADING,
  };
};
