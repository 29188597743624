import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
// import Input from "../../../components/common/Input";
import SearchDropdown from "../../../components/common/SearchDropdown";
import TextAreaEditor from "../../../components/common/TextAreaEditor";
import QuestionValidation from "../../../validation/QuestionValidation";
import Btn from "../../../components/common/Btn";
import Switch from "../../../components/common/SwitchComponent";
import Spinner from "../../../components/common/Spinner";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import CheckPermissions from "../../../validation/CheckPermissions";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import { createQuestion } from "../../../actions/questionActions";
import { getCategories } from "../../../actions/categoryActions";
import { getDifficulties } from "../../../actions/difficultyActions";
import scrollToError from "../../../utility/scrollToError";
import {
  EditorState,
  convertToRaw,
  // ContentState
} from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";

import { withRouter } from "react-router-dom";

class NewQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      submitPressed: false,
      editorStateQuestion: EditorState.createEmpty(),
      editorStateAnswer: EditorState.createEmpty(),
      categories: this.props.categories.categories,
      difficulties: this.props.difficulties.difficulties,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR, roles.QA],
      difficultiesMapped: [],
      requestLoading: false,
      global: false,
      reviewed: false,
      categoriesMapped: [],
      errors: {},
    };

    this.onEditorStateChangeQuestion = this.onEditorStateChangeQuestion.bind(this);
    this.onEditorStateChangeAnswer = this.onEditorStateChangeAnswer.bind(this);
    this.selectDifficulty = this.selectDifficulty.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.handleChangeRevewied = this.handleChangeRevewied.bind(this);
    this.handleChangeGlobal = this.handleChangeGlobal.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (prevState.initialRender) {
        if (nextProps.auth !== prevState.auth) {
          const officeRoleObject =
            nextProps.auth &&
            nextProps.auth.user &&
            nextProps.auth.user.offices &&
            nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
          var userRoleOffice =
            officeRoleObject &&
            officeRoleObject[0] &&
            officeRoleObject[0].user_role_office &&
            officeRoleObject[0].user_role_office.role.value;
          update.userRoleOffice = userRoleOffice;
          var dataToCheck = {
            isAuthenticated: nextProps.auth.isAuthenticated,
            user_type: nextProps.auth.user.type,
            root_link: `/admin/offices`,
            user_offices: nextProps.auth.user.offices,
            office_id: nextProps.match.params.officeId,
            previous_link: `/admin/${nextProps.match.params.officeId}/questions`,
            roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR, roles.QA],
            page_type: pageType.WRITE,
            history: nextProps.history,
          };

          CheckPermissions(dataToCheck);

          if (nextProps.errors) {
            update.errors = nextProps.errors;
          }
          update.auth = nextProps.auth;
        }
      }
      if (nextProps.difficulties.difficulties !== prevState.difficulties) {
        var { difficulties } = nextProps.difficulties;
        var difficultiesMapped = [];
        difficulties.map((difficulty) => {
          return difficultiesMapped.push({ id: difficulty.id, title: difficulty.difficulty });
        });
        update.difficultiesMapped = difficultiesMapped;
      }
      if (nextProps.categories.categories !== prevState.categories) {
        var { categories } = nextProps.categories;
        var categoriesMapped = [];
        categories.map((category) => {
          return categoriesMapped.push({ id: category.id, title: category.category });
        });
        update.categoriesMapped = categoriesMapped;
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/questions`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getCategories(this.props.match.params.officeId, () => {});
      this.props.getDifficulties(this.props.match.params.officeId, () => {});
    }
  }
  componentWillUnmount() {
    this.props.clearErrors();
  }

  checkValidation() {
    this.props.clearErrors();
    var formData = {};
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }
    formData.question = draftToHtml(convertToRaw(this.state.editorStateQuestion.getCurrentContent()));
    formData.question_plain = this.state.editorStateQuestion.getCurrentContent().getPlainText().trim();
    formData.answer = draftToHtml(convertToRaw(this.state.editorStateAnswer.getCurrentContent()));
    formData.answer_plain = this.state.editorStateAnswer.getCurrentContent().getPlainText().trim();
    formData.difficulty_id = this.state.difficulty && this.state.difficulty.id ? this.state.difficulty.id : null;
    formData.category_id = this.state.category && this.state.category.id ? this.state.category.id : null;
    formData.global = this.state.global ? this.state.global : false;
    formData.reviewed = this.state.reviewed ? this.state.reviewed : false;
    formData.user_id = this.props.auth.user.id;

    const { errors } = QuestionValidation(formData);
    this.setState({ errors });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({ submitPressed: true });
    var formData = {};
    formData.question = draftToHtml(convertToRaw(this.state.editorStateQuestion.getCurrentContent()));
    formData.question_plain = this.state.editorStateQuestion.getCurrentContent().getPlainText().trim();
    formData.answer = draftToHtml(convertToRaw(this.state.editorStateAnswer.getCurrentContent()));
    formData.answer_plain = this.state.editorStateAnswer.getCurrentContent().getPlainText().trim();
    formData.difficulty_id = this.state.difficulty && this.state.difficulty.id ? this.state.difficulty.id : null;
    formData.category_id = this.state.category && this.state.category.id ? this.state.category.id : null;
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }
    formData.user_id = this.props.auth.user.id;
    formData.global = this.state.global ? this.state.global : false;
    formData.reviewed = this.state.reviewed ? this.state.reviewed : false;
    const { errors, isValid } = QuestionValidation(formData);
    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.createQuestion(formData, (res) => {
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Question created successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/questions`);
        } else {
          this.setState({ requestLoading: false });
          scrollToError();
          failToast("Question creating failed");
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ requestLoading: false });
        scrollToError();
      });
    }
  }
  handleChangeRevewied(reviewed) {
    if (!reviewed) {
      this.setState({ reviewed, global: false });
    } else {
      this.setState({ reviewed });
    }
  }
  handleChangeGlobal(global) {
    this.setState({ global });
  }
  onEditorStateChangeQuestion = (editorStateQuestion) => {
    this.setState(
      {
        editorStateQuestion,
      },
      () => {
        if (this.state.submitPressed) {
          this.checkValidation();
        }
      }
    );
  };
  onEditorStateChangeAnswer = (editorStateAnswer) => {
    this.setState(
      {
        editorStateAnswer,
      },
      () => {
        if (this.state.submitPressed) {
          this.checkValidation();
        }
      }
    );
  };
  selectDifficulty(value) {
    if (value.id === (this.state.difficulty && this.state.difficulty.id)) {
      value = [];
    }
    this.setState({ difficulty: value }, () => {
      this.checkValidation();
    });
  }
  selectCategory(value) {
    if (value.id === (this.state.category && this.state.category.id)) {
      value = [];
    }
    this.setState({ category: value }, () => {
      this.checkValidation();
    });
  }
  render() {
    const { editorStateQuestion, editorStateAnswer } = this.state;
    const { categories, loading } = this.props.categories;

    var content;
    if (categories === null || loading) {
      content = <Spinner />;
    } else {
      content = (
        <div className="question-container">
          <TextAreaEditor
            label={"Question*"}
            editorState={editorStateQuestion}
            placeholder={"Question"}
            onChange={this.onEditorStateChangeQuestion}
            validationMsg={[this.state.errors.question, this.props.errors.error]}
          />
          <TextAreaEditor
            label={"Answer*"}
            title={"Question"}
            placeholder={"Answer"}
            editorState={editorStateAnswer}
            onChange={this.onEditorStateChangeAnswer}
            validationMsg={[this.state.errors.answer]}
          />

          <SearchDropdown
            label={"Categories"}
            placeholder={"Category"}
            value={this.state.category}
            name={"category_id"}
            onChange={this.selectCategory}
            multiple={false}
            options={this.state.categoriesMapped}
            isRequired={false}
            validationMsg={[this.state.errors.category_id, this.props.errors.category_id]}
          />
          <SearchDropdown
            label={"Difficulties"}
            placeholder={"Difficulty"}
            value={this.state.difficulty}
            name={"difficulty_id"}
            onChange={this.selectDifficulty}
            multiple={false}
            options={this.state.difficultiesMapped}
            validationMsg={[this.state.errors.difficulty_id, this.props.errors.difficulty_id]}
          />
          {this.state.userRoleOffice === roles.SUPERADMINISTRATOR || this.state.userRoleOffice === roles.ADMINISTRATOR ? (
            <div className="default-flex">
              <Switch
                onChange={this.handleChangeRevewied}
                value={this.state.reviewed}
                id={"reviewed"}
                name={"reviewed"}
                label={"Reviewed"}
              />
              <Switch
                onChange={this.handleChangeGlobal}
                value={this.state.global}
                id={"global"}
                name={"global"}
                label={"Global"}
                disabled={!this.state.reviewed}
              />
            </div>
          ) : (
            ""
          )}
          {/* <div className={"text-danger"}>
            {Object.keys(this.state.errors).length + Object.keys(this.props.errors).length > 0
              ? `Validation errors count: ${Object.keys(this.state.errors).length + Object.keys(this.props.errors).length}`
              : ""}
          </div> */}
          <div className="submit-button">
            <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
          </div>
        </div>
      );
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate title={"New Question"} link={`/admin/${this.props.match.params.officeId}/questions`} />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewQuestion.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  difficulties: state.difficulties,
  categories: state.categories,
});

export default connect(mapStateToProps, {
  createQuestion,
  getCategories,
  getDifficulties,
  clearErrors,
})(withRouter(NewQuestion));
