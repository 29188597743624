import React, { Component } from "react";
// import { clearErrors } from "../../actions/errorsActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import Textarea from "../../components/common/Textarea";
import Spinner from "../../components/common/Spinner";
import { getCandidateScenario, finishExam } from "../../actions/candidate/scenarioActions";
import { examStatusChangeEmail } from "../../actions/candidateActions";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { statuses } from "../../enums/statuses";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import { logoutUser } from "../../actions/authActions";
import Confirm from "../../components/common/Confirm";
import { getCandidateProfile } from "../../actions/candidate/profileActions";

class Exam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      scenario: this.props.scenarios && this.props.scenarios.scenario,
      mounted: false,
      show: "exam",
      scenarioMapped: null,
      profileUpdated: false,
      officeCandidate: this.props.candidates.officeCandidate,
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.scenarios.scenario !== prevState.scenario) {
        update.scenario = nextProps.scenarios.scenario;

        update.scenario = nextProps.scenarios.scenario;
        update.scenarioMapped = nextProps.scenarios.scenario;
      }
      if (nextProps.candidates.officeCandidate !== prevState.officeCandidate) {
        var candidate = nextProps.candidates.officeCandidate;
        if (candidate.img !== null) {
          update.profileUpdated = true;
        }
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getCandidateProfile(() => {});
    this.props.getCandidateScenario();
  }

  onChangeFinish(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidationFinish();
    });
  }

  onChangeAbandon(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidationQuit();
    });
  }

  checkValidationQuit() {
    var errors = {};
    if (this.state.abandonComment && this.state.abandonComment.length > 1000) {
      errors.abandonComment = `Comment can not have more than 1000 characters (${this.state.abandonComment.length})`;
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
    }
  }

  checkValidationFinish() {
    var errors = {};
    if (this.state.finishComment && this.state.finishComment.length > 1000) {
      errors.finishComment = `Comment can not have more than 1000 characters (${this.state.finishComment.length})`;
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
    }
  }

  confirmAbandon(e) {
    var statusObj = {};
    statusObj.status = statuses.QUIT;
    statusObj.comment = this.state.abandonComment;
    statusObj.full_name = `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`;
    statusObj.id = this.props.auth.user.id;
    if (Object.keys(this.state.errors).length === 0) {
      this.props.examStatusChangeEmail(statusObj);
      this.props.finishExam(statusObj, (res) => {
        if (res.status === 200) {
          this.setState({ deactivated: true });
          successToast("Exam status changed successfully");
          setTimeout(() => {
            this.props.logoutUser();
          }, 5000);
        } else {
          failToast("Exam status changing failed");
        }
      });
    }
  }

  confirmAbandonModal = () => {
    var mainText = "Are you sure you want to quit?";
    var subText = "If you quit your exam will not be reviewed and you will be logged out and deactivated!";
    var btnText = "Quit";

    Confirm(mainText, subText, "Cancel", btnText, () => this.confirmAbandon());
  };

  confirmFinish(e) {
    this.setState({ deactivated: true });
    var statusObj = {};
    statusObj.status = statuses.FINISHED;
    statusObj.comment = this.state.finishComment;
    statusObj.full_name = `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`;
    statusObj.id = this.props.auth.user.id;

    if (Object.keys(this.state.errors).length === 0) {
      this.props.examStatusChangeEmail(statusObj);
      this.props.finishExam(statusObj, (res) => {
        if (res.status === 200) {
          this.setState({ deactivated: true });
          successToast("Exam status changed successfully");
          setTimeout(() => {
            this.props.logoutUser();
          }, 5000);
        } else {
          failToast("Exam status changing failed");
        }
      });
    }
  }

  confirmFinishModal = () => {
    var mainText = "Are you sure you want to submit exam?";
    var subText = "Exam will be submitted for review and you will be logged out and deactivated!";
    var btnText = "Submit";

    Confirm(mainText, subText, "Cancel", btnText, () => this.confirmFinish());
  };

  render() {
    const { loading } = this.props.scenarios;
    var content = "";
    if (this.state.scenarioMapped === null || loading) {
      content = <Spinner />;
    } else {
      if (!this.state.profileUpdated && (this.state.giveUp || this.state.allDone)) {
        content = (
          <div className="exam">
            <div className="exam-title">Exam</div>
            <div className="exam-body">Please update your profile and upload your photo before changing your exam status</div>
            <div className="exam-buttons">
              <div className="exam-buttons--btn"></div>
              <div className="exam-buttons--btn">
                <Link to={"#"} className="btn btn-secondary" onClick={(e) => this.setState({ allDone: false, giveUp: false })}>
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        );
      } else if (!this.state.giveUp && !this.state.allDone && !this.state.deactivated) {
        content = (
          <div className="exam">
            <div className="exam-title">Exam</div>
            <div className="exam-body">
              {this.state.scenarioMapped && this.state.scenarioMapped.scenario
                ? ReactHtmlParser(this.state.scenarioMapped.scenario)
                : "Something went wrong. Contact someone from HTEC to resolve the issue."}
            </div>
            <div className="exam-buttons">
              <div className="exam-buttons--btn">
                <Link to={"#"} className="btn btn-danger" onClick={(e) => this.setState({ giveUp: true })}>
                  I Give Up
                </Link>
              </div>
              <div className="exam-buttons--btn">
                <Link to={"#"} className="btn btn-primary" onClick={(e) => this.setState({ allDone: true })}>
                  All Done
                </Link>
              </div>
            </div>
          </div>
        );
      } else if (this.state.giveUp && !this.state.deactivated) {
        content = (
          <div className="exam">
            <div className="exam-title">Quit Exam</div>
            <div className="exam-body">
              Are you sure you want to quit exam without your work being reviewed? <br />
              <b>Warning: </b> This is not a test! Clicking <span className="text-danger">Quit!</span> will deactive your account and log
              you out of the application without us reviewing your work!
              <br /> <br />
              <Textarea
                label="Comment"
                placeholder="Comment"
                name={"abandonComment"}
                onChange={(e) => this.onChangeAbandon(e)}
                validationMsg={this.state.errors.abandonComment}
              />
              <small className="form-text">You can add comment letting us know the reason why you are not going to finish</small>
              <br />
              <div className="exam-buttons">
                <div className="exam-buttons--btn">
                  <Link
                    to={"#"}
                    className="btn btn-secondary"
                    onClick={(e) => this.setState({ giveUp: false, errors: {}, abandonComment: "", finishComment: "" })}
                  >
                    Cancel
                  </Link>
                </div>

                <div className={`exam-buttons--btn`}>
                  <Link
                    to={"#"}
                    className={`btn btn-danger ${Object.keys(this.state.errors).length > 0 ? "disabled" : ""}`}
                    onClick={(e) => this.confirmAbandonModal()}
                  >
                    Quit!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.allDone && !this.state.deactivated) {
        content = (
          <div className="exam">
            <div className="exam-title">Finish Exam</div>
            <div className="exam-body">
              Are you sure you are all done and you want to submit your exam? <br />
              <b>Warning: </b> This is not a test! Clicking <span className="text-primary">Submit</span> will send us notification and log
              you out of the application!
              <br /> <br />
              <Textarea
                label="Comment"
                placeholder="Comment"
                name={"finishComment"}
                onChange={(e) => this.onChangeFinish(e)}
                validationMsg={this.state.errors.finishComment}
              />
              <small className="form-text">You can send comment on submit along with notification</small>
              <br />
              <div className="exam-buttons">
                <div className="exam-buttons--btn">
                  <Link
                    to={"#"}
                    className="btn btn-secondary"
                    onClick={(e) => this.setState({ allDone: false, errors: {}, abandonComment: "", finishComment: "" })}
                  >
                    Cancel
                  </Link>
                </div>
                <div className="exam-buttons--btn">
                  <Link
                    to={"#"}
                    className={`btn btn-primary ${Object.keys(this.state.errors).length > 0 ? "disabled" : ""}`}
                    onClick={(e) => this.confirmFinishModal()}
                  >
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.giveUp && this.state.deactivated) {
        content = (
          <div className="exam">
            <div className="exam-title">Deactivating user</div>
            <div className="exam-body">
              Thank you for your time!
              <br />
              You will be <span className="text-dark">logged out</span> of the application in <span className="text-dark">5 sec</span>
            </div>
          </div>
        );
      } else if (this.state.allDone && this.state.deactivated) {
        content = (
          <div className="exam">
            <div className="exam-title">Submiting exam</div>
            <div className="exam-body">
              Thank you for your time! We will contact you soon!
              <br />
              You will be <span className="text-dark">logged out</span> of the application in <span className="text-dark">5 sec</span>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Exam"} link={"/dashboard"} />
            <div className="width-container">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

Exam.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  scenarios: state.scenarios,
  candidates: state.candidates,
});

export default connect(mapStateToProps, { getCandidateScenario, finishExam, examStatusChangeEmail, logoutUser, getCandidateProfile })(
  withRouter(Exam)
);
