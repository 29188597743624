import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import Input from "../../components/common/Input";
import Btn from "../../components/common/Btn";
import { createProject } from "../../actions/candidate/projectActions";
import ProjectValidation from "../../validation/ProjectValidation";

class NewProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      auth: {},
      requestLoading: false,
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }
  checkValidation() {
    this.setState({ errors: {} });
    this.props.clearErrors();
    var formData = {};
    formData.title = this.state.title && this.state.title.length > 0 ? this.state.title.trim() : "";

    const { errors, isValid } = ProjectValidation(formData);
    if (!isValid) {
      this.setState({ errors });
    }
  }
  onSubmit() {
    this.setState({ errors: {} });
    this.props.clearErrors();
    var formData = {};
    formData.title = this.state.title && this.state.title.length > 0 ? this.state.title.trim() : "";

    const { errors, isValid } = ProjectValidation(formData);
    if (isValid) {
      this.setState({requestLoading: true})
      this.props.createProject(formData, (res) => {
        if (res.status === 200) {
          this.props.history.push(`/edit-project/${res.data.id}`);
          successToast("Project created successfully");
          this.setState({requestLoading: false})
        } else {
          failToast("Project creating failed");
          this.setState({requestLoading: false})
        }
      });
    } else {
      this.setState({ errors });
    }
  }
  render() {
    return (
      <div className='grid-menu-container'>
        <div className='menu-grid'>
          <div className='menu'>
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className='main-grid'>
          <div className='main'>
            <Navigate title={"New Project"} link={"/projects"} />
            <div className='width-container default-container default-padding'>
              <Input
                placeholder={"Title"}
                type='text'
                onChange={(e) => this.onChange(e)}
                name={"title"}
                label='Title*'
                validationMsg={[this.state.errors.title, this.props.errors.title]}
              />

              <div className='submit-button'>
                <Btn className='btn btn-primary ' label='Submit' onClick={(e) => this.onSubmit(e)} loading={this.state.requestLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewProject.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { createProject, clearErrors })(withRouter(NewProject));
