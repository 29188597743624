import axios from "axios";

import { GET_CITIES, REMOVE_LOADING, GET_ERRORS } from "../types";

// Get All Cities
export const getCities = () => (dispatch) => {
  axios
    .get(`/api/candidate/cities`)
    .then((res) => {
      dispatch({
        type: GET_CITIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};
