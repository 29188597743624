import { GET_OFFICE_ROLES, ROLE_LOADING, REMOVE_LOADING } from "../actions/types";

const initialState = {
  officeRoles: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_OFFICE_ROLES:
      return {
        ...state,
        officeRoles: action.payload,
        loading: false,
      };
    case REMOVE_LOADING:
      return {
        ...state,

        loading: false,
      };

    default:
      return state;
  }
}
