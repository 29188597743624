import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import Input from "../../../components/common/Input";
import Btn from "../../../components/common/Btn";
import Spinner from "../../../components/common/Spinner";
import CategoryValidation from "../../../validation/CategoryValidation";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import { updateCategory, getCategory, removeCategory } from "../../../actions/categoryActions";
import CheckPermissions from "../../../validation/CheckPermissions";
import Confirm from "../../../components/common/Confirm";

import { withRouter } from "react-router-dom";
import isEmpty from "../../../validation/isEmpty";

class EditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      user: {},
      categoryDb: this.props.categories.category,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
      mounted: false,
      requestLoading: false,
      auth: {},
      category: "",
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/categories`,
          roles_allowed_to_write: prevState.rolesAllowedToOpen,
          page_type: pageType.WRITE,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.categories && nextProps.categories.category) {
        if (prevState.initialRender) {
          if (nextProps.categories.category !== prevState.categoryDb) {
            update.initialRender = false;
            var { category } = nextProps.categories;
            update.category = category.category;
            update.initialCategory = category.category;
          }
        }
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/categories`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getCategory(this.props.match.params.categoryId);
    }
  }
  componentWillUnmount() {
    this.props.clearErrors();
  }

  checkValidation() {
    this.props.clearErrors();
    var formData = {};
    formData.category = this.state.category;
    formData.office_id = this.props.match.params.officeId;

    const { errors } = CategoryValidation(formData);

    this.setState({ errors });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }

  submitForm(e) {
    e.preventDefault();
    var formData = {};
    formData.category = this.state.category;
    formData.office_id = this.props.match.params.officeId;

    const { errors, isValid } = CategoryValidation(formData);

    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.updateCategory(this.props.match.params.categoryId, formData, (res) => {
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Category updated successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/categories`);
        } else {
          this.setState({ requestLoading: false });
          failToast("Category edit failed");
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ requestLoading: false });
      });
    }
  }

  confirmRemove = () => {
    this.props.removeCategory(this.props.match.params.categoryId, (res) => {
      if (res.status === 200) {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/categories`);
        successToast("Category removed successfully");
      } else {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/categories`);
        failToast(`Category removing failed`);
      }
    });
  };
  confirmModal = () => {
    Confirm(
      "Remove this category?",
      "Questions assigned with this category will lose this property",
      "Cancel",
      "Remove",
      this.confirmRemove
    );
  };

  render() {
    const { loading } = this.props.categories;
    var content = "";
    var navDelBtn;
    if (loading) {
      content = <Spinner />;
    } else {
      if (isEmpty(this.state.initialCategory)) {
        content = <div className="no-content">No category with this ID</div>;
      } else {
        navDelBtn = [<Btn className="btn btn-danger" label={<i className="far fa-trash-alt"></i>} onClick={(e) => this.confirmModal(e)} />];
        content = (
          <div>
            <Input
              placeholder={"Category"}
              type="text"
              value={this.state.category}
              onChange={(e) => this.onChange(e)}
              name={"category"}
              label="Category"
              validationMsg={[this.state.errors.category, this.props.errors.error]}
            />
            <div className="submit-button">
              <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
            </div>
          </div>
        );
      }
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate title={"Edit Category"} link={`/admin/${this.props.match.params.officeId}/categories`} buttons={navDelBtn} />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditCategory.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  categories: state.categories,
});

export default connect(mapStateToProps, { updateCategory, getCategory, removeCategory, clearErrors })(withRouter(EditCategory));
