import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { registerCandidate } from "../../actions/authActions";
import Input from "../../components/common/Input";
import { getInvitedUser } from "../../actions/inviteActions";
import { toast } from "react-toastify";
import RegisterCandidateValidation from "../../validation/RegisterCandidateValidation";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      full_name: "",
      email: "",
      invitedCandidate: this.props && this.props.candidates && this.props.candidates.invitedCandidate,
      invited_email: "",
      invited_full_name: "",
      password: "",
      password2: "",
      hash: "",
      errors: {},
      validationErrors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.errors) {
      update.errors = nextProps.errors;
    }
    if (nextProps.candidates.invitedCandidate) {
      if (nextProps.candidates.invitedCandidate !== prevState.invitedCandidate) {
        update.invitedCandidate = nextProps.candidates.invitedCandidate;
        update.full_name = `${nextProps.candidates.invitedCandidate.first_name} ${nextProps.candidates.invitedCandidate.last_name}`;
        update.email = nextProps.candidates.invitedCandidate.email;
      }
    }

    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (this.props.location.hash) {
      var tempHash = this.props.location.hash;
      var trimedHash = tempHash.substr(1);
      this.setState({
        hash: trimedHash,
      });
    }

    this.props.getInvitedUser(trimedHash);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const newCandidate = {
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      hash: this.state.hash,
    };

    const { errors, isValid } = RegisterCandidateValidation(newCandidate);
    if (isValid) {
      this.props.registerCandidate(newCandidate, this.props.history, (res) => {
        if (res && res.status === 200) {
          toast.info("Registration successfull. You can now login", {});
          this.props.history.push("/login");
        } else {
          toast.error("Registration failed. Something went wrong", {});
        }
      });
    } else {
      this.setState({ validationErrors: errors });
    }
  }

  render() {
    var disabledEmail = true;
    if (this.state.invited_user && this.state.invited_user.email === "") {
      disabledEmail = false;
    }

    var disabledFullName = true;
    if (this.state.invited_user && this.state.invited_user.first_name === "") {
      disabledFullName = false;
    }
    const { errors } = this.state;
    return (
      <div className="grid-container">
        <div className="main ">
          <div className="landing">
            <div className="landing-title text-center">Sign Up</div>
            <p className="landing-description text-center">Create your QA Sandbox account</p>
            <form onSubmit={this.onSubmit}>
              <Input
                placeholder="Full name"
                name="full_name"
                value={this.state.full_name}
                onChange={this.onChange}
                validationMsg={[errors.full_name, this.state.validationErrors.full_name]}
                disabled={disabledFullName}
              />
              <Input
                placeholder="Email Address"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.onChange}
                validationMsg={[errors.email, this.state.validationErrors.email]}
                disabled={disabledEmail}
              />
              <Input
                placeholder="Password"
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.onChange}
                validationMsg={[errors.password, this.state.validationErrors.password]}
              />
              <Input
                placeholder="Confirm Password"
                name="password2"
                type="password"
                value={this.state.password2}
                onChange={this.onChange}
                validationMsg={[errors.password2, this.state.validationErrors.password2]}
              />
              <Input
                placeholder="Hash"
                name="hash"
                type="hidden"
                value={this.state.hash}
                onChange={this.onChange}
                validationMsg={errors.error}
              />
              <button data-testid="submit_btn" type="submit" className="btn btn-primary btn-block mt-4">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  candidates: state.candidates,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerCandidate, getInvitedUser })(withRouter(Register));
