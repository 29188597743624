import { GET_SENIORITIES, GET_SENIORITY, SENIORITY_LOADING } from "../actions/types";

const initialState = {
  seniorities: null,
  seniority: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SENIORITY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SENIORITIES:
      return {
        ...state,
        seniorities: action.payload,
        loading: false,
      };
    case GET_SENIORITY:
      return {
        ...state,
        seniority: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
