import isEmpty from "../validation/isEmpty";
const DifficultyValidation = (data) => {
  var errors = {};

  var titleLimit = 30;

  data.difficulty = !isEmpty(data.difficulty) ? data.difficulty : "";

  if (isEmpty(data.difficulty)) {
    errors.difficulty = "Title is required";
  } else {
    if (data.difficulty.length > titleLimit) {
      errors.difficulty = `Title can not have more than ${titleLimit} character (${data.difficulty.length})`;
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default DifficultyValidation;
