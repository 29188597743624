import isEmpty from "../validation/isEmpty";
const ReportValidation = (data) => {
  var errors = {};
  var summaryLimit = 255;
  var descriptionLimit = 1000;
  var maxTestStepLength = 300;
  var maxExpectedLength = 300;

  data.summary = !isEmpty(data.summary) ? data.summary : "";
  data.description = !isEmpty(data.description) ? data.description : "";
  data.expected_result = !isEmpty(data.expected_result) ? data.expected_result : "";

  var reproduction_steps = data.reproduction_steps;
  var filtered_reproduction_steps = reproduction_steps.filter((el) => el.value.trim() !== "");

  if (isEmpty(data.summary)) {
    errors.summary = "Summary is required";
  } else {
    if (data.summary.length > summaryLimit) {
      errors.summary = `Summary can not have more than ${summaryLimit} character (${data.summary.length})`;
    }
  }

  if (isEmpty(data.type)) {
    errors.type = "Issue type is required";
  }
  if (isEmpty(data.severity)) {
    errors.severity = "Severity is required";
  }
  if (isEmpty(data.priority)) {
    errors.priority = "Priority is required";
  }

  if (!isEmpty(data.expected_result)) {
    if (data.expected_result.length > maxExpectedLength) {
      errors.expected_result = `Expected result can not have more than ${maxExpectedLength} characters (${data.expected_result.length})`;
    }
  }

  if (!isEmpty(data.description)) {
    if (data.description.length > descriptionLimit) {
      errors.description = `Description can not have more than ${descriptionLimit} characters (${data.description.length})`;
    }
  }

  if (filtered_reproduction_steps.length > 0) {
    var errorSteps = [];

    filtered_reproduction_steps.map((step) => {
      if (step.value.length > maxTestStepLength) {
        errorSteps.push({
          id: step.id,
          error: `Reproduction step can not have more than ${maxTestStepLength} characters (${step.value.length})`,
        });
        errors.stepErrors = errorSteps;
      }

      return null;
    });
  } else {
    errors.reproduction_steps = "There must be at least one reproduction step";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default ReportValidation;
