import React from "react";
import { Link } from "react-router-dom";
import linkify from "../../utility/linkify"

export default function TestcaseCard({ testcases }) {
  var content = "Loading...";
  if (testcases && testcases.length > 0) {
    content = (
      <div className='portrait-grid'>
        {testcases &&
          testcases.map((testcase, index) => (
            <span key={index}>
              <Link className='preview-card' to={`/edit-testcase/${testcase.id}`}>
                <div className='preview-card'>
                  <div className='preview-card-title'>
                    <div className='preview-card-title-value'>{testcase.title}</div>
                  </div>
                  <div className='preview-card-body'>
                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-title'>Description:</div>
                        <div className='preview-card-body--items-single-value'>{testcase.description}</div>
                      </div>
                      <div className='preview-card-body--items'>
                        <div className='preview-card-body--items-single'>
                          <div className='preview-card-body--items-single-title'>Expected:</div>
                          <div className='preview-card-body--items-single-value'>{testcase.expected_result}</div>
                        </div>
                      </div>
                      <div className='preview-card-body--items'>
                        <div className='preview-card-body--items-single'>
                          <div className='preview-card-body--items-single-title'>Automated:</div>
                          <div className='preview-card-body--items-single-value'>
                            {testcase.automated ? "Yes" : "No"}
                          </div>
                        </div>
                      </div>
                      <div className='preview-card-body--items'>
                        <div className='preview-card-body--items-single'>
                          <div className='preview-card-body--items-single-short'>
                            <div className='preview-card-body--items-single-short-title'>Steps:</div>
                            <div className='preview-card-body--items-single-short-value'>
                              {testcase.test_steps ? testcase.test_steps.length : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='preview-card-body--items'
                        onClick={(e) => e.preventDefault()}
                        data-toggle='modal'
                        data-target={`#myModal-${index}`}
                      >
                        <div className='preview-card-body--items-single-preview'>
                          <div className='preview-card-body--items-single-preview-title'>Preview</div>
                          <div className='preview-card-body--items-single-preview-value'>
                            <i className='fas fa-eye'></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <div id={`myModal-${index}`} className='modal fade' role='dialog'>
                <div className='modal-dialog'>
                  {/* <!-- Modal content--> */}
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h4 className='modal-titles'>Test Case Preview</h4>
                      <button type='button' className='close' data-dismiss='modal'>
                        &times;
                      </button>
                    </div>
                    <div className='modal-body'>
                      <div className='preview-card-modal'>
                        <div className='preview-card-modal-title'>
                          <div className='preview-card-modal-title-value'>{testcase.title}</div>
                        </div>
                        <hr />
                        <div className='preview-card-modal-body'>
                          <div className='preview-card-modal-body--items'>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-title'>Description:</div>
                              <div className='preview-card-modal-body--items-single-value'>{linkify(testcase.description)}</div>
                            </div>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-title'>Expected:</div>
                              <div className='preview-card-modal-body--items-single-value'>
                                {linkify(testcase.expected_result)}
                              </div>
                            </div>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-title'>Automated:</div>
                              <div className='preview-card-modal-body--items-single-value'>
                                {testcase.automated ? "Yes" : "No"}
                              </div>
                            </div>

                            <div className='preview-card-modal-body--items'>
                              <div className='preview-card-modal-body--items-single-short'>
                                <div className='preview-card-modal-body--items-single-short-title'>Steps:</div>
                                <div className='preview-card-modal-body--items-single-short-value'>
                                  {testcase.test_steps &&
                                    testcase.test_steps.map((step, index) => (
                                      <div className='preview-card-modal-body--items-single-short-value' key={index}>
                                        {index + 1}. {linkify(step.value)}
                                        <br />
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-default' data-dismiss='modal'>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          ))}
      </div>
    );
  } else {
    content = (
      <div className='no-content'>
        There are no test cases created. Click New Test Case in top right corner to get started.
      </div>
    );
  }
  return <div>{content}</div>;
}
