import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import SearchDropdown from "../../../components/common/SearchDropdown";
import Btn from "../../../components/common/Btn";
import Spinner from "../../../components/common/Spinner";
import CheckPermissions from "../../../validation/CheckPermissions";
import { getExamEmailRecipients, updateExamEmailRecipients } from "../../../actions/examActions";
import RecipientsValidation from "../../../validation/RecipientsValidation";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import { getOfficeUsers } from "../../../actions/userActions";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";

import { withRouter } from "react-router-dom";

class EditRecipients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      user: {},
      mounted: false,
      emailRecipients: this.props.exams && this.props.exams.emailRecipients,
      officeUsers: this.props.users && this.props.users.officeUsers,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
      requestLoading: false,
      auth: {},
      errors: {},
    };
    this.selectRecipient = this.selectRecipient.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: prevState.rolesAllowedToOpen,
          page_type: pageType.WRITE,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps) {
        if (nextProps.exams.emailRecipients !== prevState.emailRecipients) {
          update.emailRecipients = nextProps.exams.emailRecipients;

          var emailRecipients = nextProps.exams.emailRecipients;
          var mappedRecipients = [];
          emailRecipients &&
            emailRecipients.map((recipient) => {
              return mappedRecipients.push({
                id: recipient.user_id,
                title: recipient.user.email,
              });
            });
          update.emailRecipientsMapped = mappedRecipients;
          update.emailRecipientsUpdated = true;
          update.initialRender = false;
        }
      }
      if (nextProps.users.officeUsers !== prevState.officeUsers) {
        update.officeUsers = nextProps.users.officeUsers;

        var officeUsers = nextProps.users.officeUsers;
        var mappedOfficeUsers = [];
        officeUsers &&
          officeUsers.map((user) => {
            return mappedOfficeUsers.push({
              id: user.user_id,
              title: user.user.email,
            });
          });
        update.officeUsersMapped = mappedOfficeUsers;
        update.officeUsersUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/recipients`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getExamEmailRecipients(this.props.match.params.officeId);
      this.props.getOfficeUsers(this.props.match.params.officeId);
    }
  }

  selectRecipient(value) {
    this.setState({ emailRecipientsMapped: value });
  }

  submitForm(e) {
    e.preventDefault();
    var formData = [];

    formData = this.state.emailRecipientsMapped;
    const { errors, isValid } = RecipientsValidation(formData);
    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.updateExamEmailRecipients(this.props.match.params.officeId, formData, (res) => {
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Recipient list updated successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/recipients`);
        } else {
          this.setState({ requestLoading: false });
          failToast("Recipient list update failed");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/recipients`);
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ requestLoading: false });
      });
    }
  }

  render() {
    const { loading } = this.props.exams;

    var content;
    if (!this.state.emailRecipientsUpdated || loading) {
      content = <Spinner />;
    } else {
      content = (
        <div>
          <SearchDropdown
            label={"Recipients"}
            placeholder={"Recipients"}
            options={this.state.officeUsersMapped}
            value={this.state.emailRecipientsMapped}
            validationMsg={[this.state.errors.error]}
            onChange={this.selectRecipient}
            multiple={true}
            numberDisplayed={1}
          />
          <div className="submit-button">
            <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
          </div>
        </div>
      );
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Edit Recipients"} link={`/admin/${this.props.match.params.officeId}/recipients`} />
            <div className="width-container">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

EditRecipients.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  exams: state.exams,
  users: state.users,
});

export default connect(mapStateToProps, {
  getOfficeUsers,
  getExamEmailRecipients,
  updateExamEmailRecipients,
  clearErrors,
})(withRouter(EditRecipients));
