const scrollToError = ()=>{
            const element = document.getElementById("validation-msg");
            const y =
              element && element.getBoundingClientRect().top + window.scrollY;
            if (element) {
              window.scroll({
                top: y,
                behavior: "smooth",
              });
            }
        }
        export default scrollToError;
  