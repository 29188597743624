import axios from "axios";

import { GET_CANDIDATE_PROJECTS, GET_CANDIDATE_PROJECT, PROJECT_LOADING, CLEAR_PROJECTS, REMOVE_LOADING, GET_ERRORS } from "../types";

// Get All Office Candidate projects
export const getCandidateProjects = () => (dispatch) => {
  dispatch(projectLoading());

  axios
    .get("/api/candidate/projects")
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_PROJECTS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get All Office Candidate projects
export const getCandidateProject = (projectId, callback) => (dispatch) => {
  // dispatch(projectLoading());

  axios
    .get(`/api/candidate/project/${projectId}`)
    .then((res) => {
      dispatch({
        type: GET_CANDIDATE_PROJECT,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Update Project
export const updateProject = (projectId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidate/projects/${projectId}`, formData)
    .then((res) => {
      dispatch({
        type: GET_CANDIDATE_PROJECT,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Create Project
export const createProject = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/projects`, formData)
    .then((res) => {
      dispatch({
        type: GET_CANDIDATE_PROJECT,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

export const uploadProjectPhoto = (projectId, file, config, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/project/photo/${projectId}`, file, config)

    .then((res) => {
      callback(res);
    })
    .catch((res) => callback(res));
};

// Remove Project
export const removeProject = (projectId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/projects/${projectId}`)
    .then((res) => {
      dispatch({
        type: GET_CANDIDATE_PROJECTS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Clear Projects
export const clearProjects = () => {
  return {
    type: CLEAR_PROJECTS,
  };
};

// Project loading
export const projectLoading = () => {
  return {
    type: PROJECT_LOADING,
  };
};
