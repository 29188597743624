import axios from "axios";

import {
  GET_OFFICE_USERS,
  GET_AVAILABLE_USERS,
  GET_OFFICE_USER,
  USER_LOADING,
  GET_USER_GLOBAL_ROLE,
  GET_GLOBAL_USERS,
  GET_GLOBAL_USER,
  REMOVE_LOADING,
  GET_ERRORS,
} from "./types";

// Get All Office Users
export const getOfficeUsers = (office_id, filters) => (dispatch) => {
  dispatch(userLoading());

  axios
    .post(`/api/users/office/${office_id}`, filters)
    .then((res) =>
      dispatch({
        type: GET_OFFICE_USERS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get All Office Users
export const getUserGlobalRole = (userId) => (dispatch) => {
  dispatch(userLoading());

  axios
    .get(`/api/users/user/${userId}/global-roles`)
    .then((res) =>
      dispatch({
        type: GET_USER_GLOBAL_ROLE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get All Office Users
export const setUserGlobalRole = (userId, role, callback) => (dispatch) => {
  dispatch(userLoading());

  axios
    .put(`/api/users/user/${userId}/global-role`, role)
    .then((res) => {
      dispatch({
        type: GET_USER_GLOBAL_ROLE,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Office User
export const getOfficeUser = (user_id, office_id, callback) => (dispatch) => {
  dispatch(userLoading());

  axios
    .get(`/api/users/user/${user_id}/office/${office_id}`)
    .then((res) => {
      dispatch({
        type: GET_OFFICE_USER,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Update Office User Role
export const updateOfficeUserRole = (user_id, office_id, formData, callback) => (dispatch) => {
  axios
    .put(`/api/users/user/${user_id}/office/${office_id}`, formData)
    .then((res) => {
      dispatch({
        type: GET_OFFICE_USER,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Remove Office User
export const removeOfficeUser = (user_id, office_id, callback) => (dispatch) => {
  axios
    .delete(`/api/users/user/${user_id}/office/${office_id}`)
    .then((res) => {
      dispatch({
        type: GET_OFFICE_USERS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get All Users
export const getAvailableUsers = (officeId) => (dispatch) => {
  dispatch(userLoading());

  axios
    .get(`/api/available-users/office/${officeId}`)
    .then((res) =>
      dispatch({
        type: GET_AVAILABLE_USERS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Existing User to Office
export const addExistingUserToOffice = (officeId, formData, callback) => (dispatch) => {
  axios
    .post(`/api/add-existing-user/office/${officeId}`, formData)
    .then((res) => {
      dispatch({
        type: GET_AVAILABLE_USERS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Add New User to Office
export const addNewUserToOffice = (officeId, formData, callback) => (dispatch) => {
  axios
    .post(`/api/add-new-user/office/${officeId}`, formData)
    .then((res) => {
      dispatch({
        type: GET_AVAILABLE_USERS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

export const uploadProfilePhoto = (user_id, file, config, callback) => (dispatch) => {
  axios
    .post(`/api/users/${user_id}/profile-photo`, file, config)

    .then((res) => {
      callback(res);
    })
    .catch((res) => callback(res));
};

export const uploadUserCV = (user_id, cv, config, callback) => (dispatch) => {
  axios
    .post(`/api/cv/${user_id}`, cv, config)

    .then((res) => {
      callback(res);
    })
    .catch((err) => callback(err));
};

export const removeUserCV = (user_id, callback) => (dispatch) => {
  axios
    .delete(`/api/cv/${user_id}`)

    .then((res) => {
      callback(res);
    })
    .catch((err) => callback(err));
};

export const getUserCV = (user_id, callback) => (dispatch) => {
  axios
    .get(`/api/cv/${user_id}`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    })
    .then((res) => {
      if (!res.headers.etag) {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        callback(fileURL);
      }
    })

    .catch((error) => {
      callback(400);
    });
};

// Get Global Users
export const getGlobalUsers = (filters) => (dispatch) => {
  dispatch(userLoading());

  axios
    .post(`/api/users`, filters)
    .then((res) =>
      dispatch({
        type: GET_GLOBAL_USERS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Global User
export const getGlobalUser = (userId, callback) => (dispatch) => {
  dispatch(userLoading());

  axios
    .get(`/api/users/${userId}`)
    .then((res) => {
      dispatch({
        type: GET_GLOBAL_USER,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Set User Global Active Flag
export const setUserGlobalActivation = (userId, activationFlag, callback) => (dispatch) => {
  dispatch(userLoading());

  axios
    .put(`/api/users/${userId}/activation`, activationFlag)
    .then((res) => {
      dispatch({
        type: GET_GLOBAL_USER,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Reset Password Email Sending
export const forgotPasswordEmailAdmin = (email, callback) => (dispatch) => {
  axios
    .post("/api/users/forgot-password-admin", email)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
};

export const resetUserPassword = (data, callback) => (dispatch) => {
  axios
    .put("/api/users/reset-password", data)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// User loading
export const userLoading = () => {
  return {
    type: USER_LOADING,
  };
};
