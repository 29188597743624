import React from "react";
import { Link } from "react-router-dom";
import placeholderImg from "../../img/QASandboxLogo.png";
import { roles } from "../../enums/roles";

export default function SideMenuOffices({ props }) {
  var items = [];
  var tokenUserOffices = props.auth.user.offices;
  var singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
  var officeImage;
  var shortText;
  props &&
    props.auth &&
    props.auth.user &&
    props.auth.user.offices &&
    props.auth.user.offices.map((office) => {
      if (props.match.params.officeId && !isNaN(props.match.params.officeId) && office.id === parseInt(props.match.params.officeId)) {
        officeImage = office.image;
        shortText = office.short_text;
      }
      return null;
    });

  if (!officeImage) {
    officeImage = placeholderImg;
  }

  if (!singleOffice) {
    items.push({
      title: "Offices",
      link: "/admin/offices",
      icon: <i className="fas fa-mail-bulk"></i>,
      active: props.match.path === "/admin/offices" ? true : false,
    });
  }

  if (
    props.auth.user.offices &&
    props.auth.user.offices[0] &&
    props.auth.user.offices[0].user_role_office.role.value === roles.SUPERADMINISTRATOR
  ) {
    items.push({
      title: "Settings",
      link: `/admin/settings`,
      icon: <i className="fas fa-cogs"></i>,
      active:
        props.match.path === "/admin/settings" ||
        props.match.path === "/admin/users/global-settings" ||
        props.match.path === "/admin/users/user/:userId/settings" ||
        props.match.path === "/admin/offices/global-settings" ||
        props.match.path === "/admin/offices/settings/new-office" ||
        props.match.path === "/admin/offices/office/:officeId/settings" ||
        props.match.path === "/admin/candidates/global-settings" ||
        props.match.path === "/admin/candidates/candidate/:candidateId/settings",
    });
  }

  if (
    (props.auth.user.offices &&
      props.auth.user.offices[0] &&
      props.auth.user.offices[0].user_role_office.role.value === roles.SUPERADMINISTRATOR) ||
    (props.auth.user.offices && props.auth.user.offices[0] && props.auth.user.offices[0].user_role_office.role.value === roles.GLOBALVIEWER)
  ) {
    items.push({
      title: "Global Stats",
      link: `/admin/global-statistics`,
      icon: <i className="fas fa-chart-area"></i>,
      active: props.match.path === "/admin/global-statistics" ? true : false,
    });
  }

  return (
    <div className="menu-items">
      <span>
        <div className={`menu-items--item-image`}>
          <div className="fixed-ratio-div">
            <img className="card-img-top rounded-circle border-primary" src={officeImage} alt="" title={""} />
            <div className="fixed-ratio-div-project-short">{shortText}</div>
          </div>
        </div>
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <span key={index}>
              <Link to={item.link} className={`menu-items--item${item.active ? "-active" : ""}  ${item.disabled ? "disabled" : ""}`}>
                <div className={`menu-items--item${item.active ? "-active" : ""}-value`}> {item.title}</div>
                <div className={`menu-items--item${item.active ? "-active" : ""}-icon`}>
                  <i className="fas fa-arrow-right"></i>
                </div>

                <div className={`menu-items--item${item.active ? "-active" : ""}-icon2`}>{item.icon}</div>
              </Link>
              <div className="menu-items--item-sub">
                {item &&
                  item.submenu &&
                  item.submenu.map((sub, index) => (
                    <div className="menu-items--item-sub--items" key={index}>
                      <div className={`menu-items--item-sub--items-item`}>
                        <div className={`menu-items--item-sub--items-item${sub.active ? "-active" : ""}-value`}>{sub.title}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </span>
          ))}
      </span>
    </div>
  );
}
