import { GET_CITIES } from "../actions/types";

const initialState = {
  cities: null,
  //   seniority: null,
  //   loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // case SENIORITY_LOADING:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    case GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    // case GET_SENIORITY:
    //   return {
    //     ...state,
    //     seniority: action.payload,
    //     loading: false,
    //   };

    default:
      return state;
  }
}
