import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import SideMenuOffices from "../../components/layout/SideMenuOffices";
import SideMenu from "../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import AdminCards from "../../components/Cards/AdminCards";
import { getOffice } from "../../actions/officeActions";
import { pageType } from "../../enums/pageType";
import { accountType } from "../../enums/accountType";
import CheckPermissions from "../../validation/CheckPermissions";

import { withRouter } from "react-router-dom";
import { roles } from "../../enums/roles";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
      mounted: false,
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        var tokenUserOffices = nextProps.auth.user.offices;
        update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
        var usersRoleInOffice =
          nextProps.auth &&
          nextProps.auth.user.offices &&
          nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
        update.usersRoleInOffice =
          usersRoleInOffice &&
          usersRoleInOffice[0] &&
          usersRoleInOffice[0].user_role_office &&
          usersRoleInOffice[0].user_role_office.role.value;
        if (!nextProps.auth.isAuthenticated || nextProps.auth.user.type !== accountType.USER) {
          // Not Authorized so redirecting to offices page which will redirect to landing
          nextProps.history.push(`/admin/offices`);
        } else {
          // Authorized and checking permission
          const result = nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
          if (result.length === 0) {
            // No permission so returning to previous page
            nextProps.history.push(`/admin/offices`);
          }
        }
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: prevState.rolesAllowedToOpen,
          page_type: pageType.WRITE,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  render() {
    var cards = [];
    if (roles.SUPERADMINISTRATOR === this.state.usersRoleInOffice || roles.ADMINISTRATOR === this.state.usersRoleInOffice) {
      cards = [
        {
          link: `/admin/${this.props.match.params.officeId}/difficulties`,
          title: "Difficulties",
          subtitle: "List of all difficulties",
        },
        {
          link: `/admin/${this.props.match.params.officeId}/categories`,
          title: "Categories",
          subtitle: "List of all categories",
        },
        {
          link: `/admin/${this.props.match.params.officeId}/recipients`,
          title: "Recipients",
          subtitle: "List of all exam status change recipients",
        },
      ];
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Settings"} />
            <AdminCards cards={cards} />
          </div>
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  offices: state.offices,
});

export default connect(mapStateToProps, { getOffice, clearErrors })(withRouter(Settings));
