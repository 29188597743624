import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Navigate from "../../../components/Navigate/Navigate";

import Spinner from "../../../components/common/Spinner";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import RepositoryCards from "../../../components/Cards/RepositoryCards";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";
import {
  // getCandidateRepositories,
  getOfficeCandidate,
} from "../../../actions/candidateActions";

class RepositoriesAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      count: 5,
      sort: "created: asc",
      user: {},
      repos: [],
      mounted: false,
      officeCandidate: this.props.candidates && this.props.candidates.officeCandidate,
      github_username: "",
      errors: {},
      auth: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          var errors = {};
          errors.deadline = "";
          update.errors = errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.candidates.officeCandidate !== prevState.officeCandidate) {
        update.officeCandidate = nextProps.candidates.officeCandidate;

        var officeCandidate = nextProps.candidates.officeCandidate;
        update.github_username = officeCandidate.github_username;

        // if (officeCandidate && officeCandidate.github_username) {
        //   nextProps.getCandidateRepositories(officeCandidate && officeCandidate.github_username);
        // }
      }

      if (nextProps.candidates.candidateRepos !== prevState.candidateRepos) {
        var candidateRepos = nextProps.candidates.candidateRepos;
        update.candidateRepos = candidateRepos;
        update.candidateReposUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.IsMounted = true;
    this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {});
  }

  render() {
    const { candidateRepos } = this.state;
    var repoItems;
    if (!this.state.candidateReposUpdated || candidateRepos === null) {
      repoItems = <Spinner />;
    } else {
      var repoArray = [];
      candidateRepos &&
        candidateRepos.map((repo) => {
          return repoArray.push({
            title: repo.name,
            created: repo.created_at,
            updated: repo.updated_at,
            link: repo.html_url,
          });
        });

      repoItems = <RepositoryCards repositories={repoArray} />;
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={"Aleksandar Pavlovic - Repositories"}
              link={`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`}
            />

            {repoItems}
          </div>
        </div>
      </div>
    );
  }
}

RepositoriesAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  candidates: state.candidates,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  // getCandidateRepositories,

  getOfficeCandidate,
  clearErrors,
})(withRouter(RepositoriesAdmin));
