import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
// import { Link } from "react-router-dom";
// import UserCard from "../../../components/Cards/UserCard";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import SearchDropdown from "../../../components/common/SearchDropdown";
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Btn from "../../../components/common/Btn";
import { getAvailableUsers, addExistingUserToOffice, addNewUserToOffice } from "../../../actions/userActions";
import AddExistingUserValidation from "../../../validation/AddExistingUserValidation";
import AddNewUserValidation from "../../../validation/AddNewUserValidation";
import { getOfficeRoles } from "../../../actions/roleActions";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";
import openSocket from "socket.io-client";
import webSocket from "../../../configSocket/keys";
var socket = openSocket(webSocket.webSocket);
class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      submitPressed: false,
      submitNewPressed: false,
      availableUsers: this.props.users && this.props.users.getAvailableUsers,
      officeRoles: this.props.roles && this.props.roles.officeRoles,
      addExistingLoading: false,
      addNewLoading: false,
      mounted: false,
      first_name: "",
      last_name: "",
      email: "",
      auth: {},
      active: false,
      errors: {},
    };
    this.selectRole = this.selectRole.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.selectRoleNew = this.selectRoleNew.bind(this);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.users && nextProps.users.availableUsers) {
        if (nextProps.users.availableUsers !== prevState.availableUsers) {
          var { availableUsers } = nextProps.users;

          var mappedAvailableUsers = [];
          availableUsers.map((user) => {
            return mappedAvailableUsers.push({
              id: user.id,
              title: `${user.first_name} ${user.last_name} - ${user.email}`,
            });
          });
          update.officeUsersMapped = mappedAvailableUsers;
          update.officeUsersUpdated = true;
        }
      }
      if (nextProps.roles.officeRoles !== prevState.officeRoles) {
        update.officeRoles = nextProps.roles.officeRoles;

        var officeRoles = nextProps.roles.officeRoles;
        var mappedOfficeRoles = [];
        officeRoles &&
          officeRoles.map((role) => {
            return mappedOfficeRoles.push({ id: role.id, title: role.value });
          });
        update.officeRolesMapped = mappedOfficeRoles;
        update.officeRolesUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getAvailableUsers(this.props.match.params.officeId);
    this.props.getOfficeRoles();
  }
  componentWillUnmount() {
    this.props.clearErrors();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.submitNewPressed) {
        this.checkValidationNew();
      }
    });
  }

  checkValidation() {
    this.setState({ errors: {} });
    this.props.clearErrors();
    var formData = {};
    formData.user_id = this.state.user && this.state.user.id ? this.state.user.id : null;
    formData.role_id = this.state.role && this.state.role.id ? this.state.role.id : null;
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }

    const { errors } = AddExistingUserValidation(formData);
    this.setState({ errors });
  }

  submitForm(e) {
    this.setState({ errors: {}, submitPressed: true });
    e.preventDefault();
    var formData = {};

    formData.user_id = this.state.user && this.state.user.id ? this.state.user.id : null;
    formData.role_id = this.state.role && this.state.role.id ? this.state.role.id : null;
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }

    const { errors, isValid } = AddExistingUserValidation(formData);
    if (isValid) {
      this.setState({ addExistingLoading: true });
      this.props.addExistingUserToOffice(formData.office_id, formData, (res) => {
        if (res.status === 200) {
          this.setState({ addExistingLoading: false });
          socket.emit("refreshUserToken", formData.user_id);
          successToast("User added successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/users`);
        } else {
          this.setState({ addExistingLoading: false });
          failToast("User adding failed");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/new-user`);
        }
      });
    } else {
      this.setState({ errors }, () => {});
    }
  }

  checkValidationNew() {
    this.setState({ errors: {} });
    this.props.clearErrors();
    var formData = {};
    formData.first_name = this.state.first_name;
    formData.last_name = this.state.last_name;
    formData.email = this.state.email;
    formData.role_id = this.state.roleNew && this.state.roleNew.id ? this.state.roleNew.id : null;
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }

    const { errors } = AddNewUserValidation(formData);
    this.setState({ errors });
  }

  submitFormNew(e) {
    this.setState({ errors: {}, submitNewPressed: true });
    e.preventDefault();
    var formData = {};
    formData.first_name = this.state.first_name;
    formData.last_name = this.state.last_name;
    formData.email = this.state.email;
    formData.role_id = this.state.roleNew && this.state.roleNew.id ? this.state.roleNew.id : null;
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }

    const { errors, isValid } = AddNewUserValidation(formData);
    if (isValid) {
      this.setState({ addNewLoading: true });
      this.props.addNewUserToOffice(formData.office_id, formData, (res) => {
        if (res.status === 200) {
          this.setState({ addNewLoading: false });
          successToast("User added successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/users`);
        } else {
          this.setState({ addNewLoading: false });
          failToast("User adding failed");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/new-user`);
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ addNewLoading: false });
      });
    }
  }

  selectUser(value) {
    this.setState({ user: value }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }

  selectRole(value) {
    this.setState({ role: value }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }
  selectRoleNew(value) {
    this.setState({ roleNew: value }, () => {
      if (this.state.submitNewPressed) {
        this.checkValidationNew();
      }
    });
  }
  render() {
    const { loading } = this.props.users;

    var content;
    if (!this.state.officeUsersUpdated || !this.state.officeRolesUpdated || loading) {
      content = <Spinner />;
    } else {
      content = (
        <div className="width-cointainer default-container default-padding">
          <div>
            <h2>Add existing user to office</h2>
            <SearchDropdown
              label={"Users"}
              placeholder={"User"}
              value={this.state.user}
              name={"user_id"}
              validationMsg={this.state.errors.user_id}
              onChange={this.selectUser}
              multiple={false}
              options={this.state.officeUsersMapped}
              isRequired={true}
            />
            <SearchDropdown
              label={"Roles"}
              placeholder={"Role"}
              value={this.state.role}
              name={"role_id"}
              validationMsg={this.state.errors.role_id}
              onChange={this.selectRole}
              multiple={false}
              options={this.state.officeRolesMapped}
              isRequired={true}
            />
            <div className="submit-button">
              <Btn
                className="btn btn-primary "
                label="Submit"
                onClick={(e) => this.submitForm(e)}
                loading={this.state.addExistingLoading}
              />
            </div>
          </div>
          <div>
            <hr />
            <h2>Add new user to QASandbox</h2>
            <Input
              placeholder="First name"
              name={"first_name"}
              label={"First name"}
              onChange={(e) => this.onChange(e)}
              //   onKeyDown={this.handleKeyDown}
              value={this.state.new_first_name}
              validationMsg={[this.props.errors.first_name, this.state.errors.first_name]}
            />
            <Input
              placeholder="Last name"
              name={"last_name"}
              label={"Last name"}
              onChange={(e) => this.onChange(e)}
              //   onKeyDown={this.handleKeyDown}
              value={this.state.last_name}
              validationMsg={[this.props.errors.last_name, this.state.errors.last_name]}
            />
            <Input
              placeholder="Email"
              name={"email"}
              label={"Email"}
              onChange={(e) => this.onChange(e)}
              //   onKeyDown={this.handleKeyDown}
              value={this.state.email}
              validationMsg={[this.props.errors.email, this.state.errors.email]}
            />
            <SearchDropdown
              label={"Roles"}
              placeholder={"Role"}
              value={this.state.roleNew}
              name={"new_role_id"}
              validationMsg={this.state.errors.new_role_id}
              onChange={this.selectRoleNew}
              multiple={false}
              options={this.state.officeRolesMapped}
              isRequired={true}
            />
            <div className="submit-button">
              <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitFormNew(e)} loading={this.state.addNewLoading} />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate title={"Add User"} link={`/admin/${this.props.match.params.officeId}/users`} />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddUser.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  users: state.users,
  roles: state.roles,
});

export default connect(mapStateToProps, {
  getAvailableUsers,
  getOfficeRoles,
  addExistingUserToOffice,
  addNewUserToOffice,
  clearErrors,
})(withRouter(AddUser));
