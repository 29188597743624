import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Navigate from "../../../components/Navigate/Navigate";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import { Link } from "react-router-dom";

import {
  getGlobalCandidate,
  getCandidateCV,
  uploadCandidateCV,
  // getCandidateRepositories,
  removeGlobalCandidate,
  clearRepos,
  removeCandidateCV,
} from "../../../actions/candidateActions";
import { getCandidateTestcases, clearTestcases } from "../../../actions/testcaseActions";
import { getCandidateComments } from "../../../actions/commentActions";
import Confirm from "../../../components/common/Confirm";
import { getCandidateReports, clearReports } from "../../../actions/reportActions";
import { getCandidateProjects, clearProjects } from "../../../actions/projectActions";
import { accountType } from "../../../enums/accountType";
import { roles } from "../../../enums/roles";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import isEmpty from "../../../validation/isEmpty";
import Spinner from "../../../components/common/Spinner";
import CandidateTopContainer from "../../../components/Cards/CanddiateTopContainer";

class Candidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      initialRender: true,
      mounted: false,
      auth: {},
      globalCandidate: this.props.candidates && this.props.candidates.globalCandidate,
      candidateRepos: this.props.candidates && this.props.candidates.candidateRepos,
      testcases: this.props.testcases && this.props.testcases.testcases,
      comments: this.props.comments && this.props.comments.comments,
      projects: this.props.projects && this.props.projects.projects,
      reports: this.props.reports && this.props.reports.reports,
      latestScenario: {},
      github_username: "",
      repos: [],
      daysLeftToFinish: "",
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        if (!nextProps.auth.isAuthenticated || !accountType.USER) {
          nextProps.history.push("/");
        }
        if (
          (nextProps.auth.user.offices &&
            nextProps.auth.user.offices[0] &&
            nextProps.auth.user.offices[0].user_role_office.role.value !== roles.SUPERADMINISTRATOR) ||
          isEmpty(nextProps.auth.user.offices)
        ) {
          nextProps.history.push("/");
        }

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.testcases.testcases !== prevState.testcases) {
        update.testcases = nextProps.testcases.testcases;

        var testcases = nextProps.testcases.testcases;
        update.testcases = testcases;
      }
      if (nextProps.comments.comments !== prevState.comments) {
        update.comments = nextProps.comments.comments;

        var comments = nextProps.comments.comments;
        update.comments = comments;
      }
      if (nextProps.projects.projects !== prevState.projects) {
        update.projects = nextProps.projects.projects;

        var projects = nextProps.projects.projects;
        update.projects = projects;
      }
      if (nextProps.reports.reports !== prevState.reports) {
        update.reports = nextProps.reports.reports;

        var reports = nextProps.reports.reports;
        update.reports = reports;
      }
      if (nextProps.candidates.globalCandidate !== prevState.globalCandidate) {
        update.globalCandidate = nextProps.candidates.globalCandidate;

        var globalCandidate = nextProps.candidates.globalCandidate;
        update.github_username = globalCandidate.github_username;
        var activeScenarios = 0;
        globalCandidate.candidate_scenarios.map((scenario) => {
          if (scenario.active) {
            activeScenarios++;
          }
          return null;
        });

        var previousScenarios = 0;
        globalCandidate.candidate_scenarios.map((scenario) => {
          if (!scenario.active) {
            previousScenarios++;
          }
          return null;
        });

        var latestScenario = globalCandidate.candidate_scenarios.sort(
          (a, b) => new Date(b.assigned_at).getTime() - new Date(a.assigned_at).getTime()
        )[0];
        update.cv = nextProps.candidates.globalCandidate.cv;
        update.latestScenario = latestScenario;
        update.globalCandidate = globalCandidate;
        update.globalCandidatesUpdated = true;
        update.activeScenariosCount = activeScenarios;
        update.previousScenariosCount = previousScenarios;
      }
      // if (globalCandidate && globalCandidate.github_username) {
      //   nextProps.getCandidateRepositories(globalCandidate.github_username);
      // }
      if (nextProps.candidates.candidateRepos !== prevState.candidateRepos) {
        var candidateRepos = nextProps.candidates.candidateRepos;
        update.candidateRepos = candidateRepos;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.IsMounted = true;
    this.props.getGlobalCandidate(this.props.match.params.candidateId, () => {});
    this.props.getCandidateCV(this.props.match.params.candidateId, this.props.match.params.officeId, (res) => {
      if (res) {
        if (this.IsMounted) {
          this.setState({
            cvRoute: res,
          });
        }
      }
    });
  }

  confirmRemoveCandidate = () => {
    var candidateId =
      this.props.match.params.candidateId && !isNaN(this.props.match.params.candidateId)
        ? parseInt(this.props.match.params.candidateId)
        : null;
    this.props.removeGlobalCandidate(candidateId, (res) => {
      if (res.status) {
        successToast("Candidate removed successfully");
        this.props.history.push("/admin/candidates/global-settings");
      } else {
        failToast("Candidate removing failed");
      }
    });
  };
  confirmRemoveCandidateModal = () => {
    Confirm(
      "Remove this candidate?",
      "This will permanently remove all that candidate created in QASandbox",
      "Cancel",
      "Remove",
      this.confirmRemoveCandidate
    );
  };

  render() {
    var candidate = this.state.globalCandidate;
    var latestScenario = this.state.latestScenario;
    const { loading } = this.props.candidates;
    var content;
    if (!this.state.globalCandidatesUpdated || loading) {
      content = <Spinner />;
    } else {
      content = (
        <div className="candidate width-container">
          <CandidateTopContainer candidate={candidate} latestScenario={latestScenario} cv={this.state.cv} cvRoute={this.state.cvRoute} />
        </div>
      );
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate
                title={
                  this.state.globalCandidatesUpdated && this.state.globalCandidate && this.state.globalCandidate.first_name
                    ? `${this.state.globalCandidate.first_name} ${this.state.globalCandidate.last_name}`
                    : ""
                }
                link={`/admin/candidates/global-settings`}
                buttons={[
                  <Link to={"#"} className="btn btn-danger" onClick={(e) => this.confirmRemoveCandidateModal(e)}>
                    Remove
                  </Link>,
                ]}
              />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Candidate.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  testcases: state.testcases,
  reports: state.reports,
  candidates: state.candidates,
  projects: state.projects,
  comments: state.comments,
  exams: state.exams,
});

export default connect(mapStateToProps, {
  getGlobalCandidate,
  getCandidateCV,
  uploadCandidateCV,
  clearRepos,
  getCandidateTestcases,
  removeCandidateCV,
  clearTestcases,
  // getCandidateRepositories,
  getCandidateReports,
  getCandidateComments,
  clearReports,
  getCandidateProjects,
  removeGlobalCandidate,
  clearProjects,
  clearErrors,
})(withRouter(Candidate));
