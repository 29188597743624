import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import Input from "../../components/common/Input";
import Dropdown from "../../components/common/Dropdown";
import Textarea from "../../components/common/Textarea";
import Spinner from "../../components/common/Spinner";
import SearchDropdown from "../../components/common/SearchDropdown";
import Btn from "../../components/common/Btn";
import {
  getCandidateProfile,
  updateCandidateProfile,
  searchCandidateGithubUsername,
  uploadCandidateProfilePhoto,
} from "../../actions/candidate/profileActions";
import { getCareerStatuses } from "../../actions/candidate/statusActions";
import { getCities } from "../../actions/candidate/cityActions";
import { refreshToken } from "../../actions/authActions";
import imgPlaceholder from "../../img/noImg.png";
import ProfileValidation from "../../validation/ProfileValidation";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import loadingImg from "../../img/imgLoader.gif";
import isEmpty from "../../validation/isEmpty";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      src: null,
      crop: {
        unit: "%",
        width: 50,
        aspect: 10 / 10,
      },
      user: {},
      officeCandidate: this.props.candidates && this.props.candidates.officeCandidate,
      careerStatuses: this.props.careerStatuses && this.props.careerStatuses.careerStatuses,
      cities: this.props.cities && this.props.cities.cities,
      mounted: false,
      uploading: false,
      requestLoading: false,
      candidateMapped: null,
      citiesMapped: null,
      auth: {},
      errors: {},
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangePhoto = this.onChangePhoto.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectCity = this.selectCity.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.careerStatuses.careerStatuses !== prevState.careerStatuses) {
        update.careerStatuses = nextProps.careerStatuses.careerStatuses;
      }

      if (nextProps.cities.cities !== prevState.cities) {
        update.cities = nextProps.cities.cities;
        var mappedCities = [];

        nextProps.cities.cities.map((city) => {
          mappedCities.push({ id: city.id, title: city.value });
          return null;
        });
        update.citiesMapped = mappedCities;
      }
      if (nextProps.candidates.officeCandidate !== prevState.officeCandidate) {
        update.officeCandidate = nextProps.candidates.officeCandidate;

        if (prevState.initialRender) {
          update.first_name = nextProps.candidates.officeCandidate.first_name ? nextProps.candidates.officeCandidate.first_name : "";
          update.last_name = nextProps.candidates.officeCandidate.last_name ? nextProps.candidates.officeCandidate.last_name : "";
          update.email = nextProps.candidates.officeCandidate.email ? nextProps.candidates.officeCandidate.email : "";
          update.career_status_id = nextProps.candidates.officeCandidate.career_status_id
            ? nextProps.candidates.officeCandidate.career_status_id
            : 0;
          var cityMapped = null;
          if (nextProps.candidates.officeCandidate.city) {
            cityMapped = {};
            cityMapped.id = nextProps.candidates.officeCandidate.city.id;
            cityMapped.title = nextProps.candidates.officeCandidate.city.value;
          }

          update.city = cityMapped;
          update.biography = nextProps.candidates.officeCandidate.biography ? nextProps.candidates.officeCandidate.biography : "";

          update.github_username = nextProps.candidates.officeCandidate.github_username
            ? nextProps.candidates.officeCandidate.github_username
            : "";
          update.initialRender = false;
        }

        update.img = nextProps.candidates.officeCandidate.img ? nextProps.candidates.officeCandidate.img : "";
        update.candidateMapped = nextProps.candidates.officeCandidate;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  selectCity(value) {
    if (value.id === (this.state.city && this.state.city.id)) {
      value = [];
    }
    this.setState({ city: value }, () => {
      this.checkValidation();
    });
  }

  componentDidMount() {
    this.IsMounted = true;
    this.props.getCandidateProfile(() => {});
    this.props.getCities();
    this.props.getCareerStatuses();
  }

  componentWillUnmount() {
    this.IsMounted = false;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }

  checkValidation() {
    this.props.clearErrors();
    var formData = {};
    formData.biography = this.state.biography && this.state.biography.length > 0 ? this.state.biography.trim() : null;
    if (this.state.career_status_id && !isNaN(this.state.career_status_id)) {
      formData.career_status_id = parseInt(this.state.career_status_id);
    } else {
      formData.career_status_id = null;
    }
    formData.city_id = !isEmpty(this.state.city) && !isEmpty(this.state.city.id) ? this.state.city.id : null;
    formData.github_username = this.state.github_username;

    var { errors } = ProfileValidation(formData);

    this.setState({ errors });
  }

  submitForm(e) {
    e.preventDefault();
    var formData = {};
    formData.biography = this.state.biography && this.state.biography.length > 0 ? this.state.biography.trim() : null;
    if (this.state.career_status_id && !isNaN(this.state.career_status_id) && this.state.career_status_id > 0) {
      formData.career_status_id = parseInt(this.state.career_status_id);
    } else {
      formData.career_status_id = null;
    }
    formData.city_id = !isEmpty(this.state.city) && !isEmpty(this.state.city.id) ? this.state.city.id : null;

    formData.github_username = this.state.github_username;
    const { errors, isValid } = ProfileValidation(formData);

    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.updateCandidateProfile(formData, (res) => {
        if (res.status === 200) {
          successToast("Profile updated successfully");
          this.props.history.push(`/profile`);
        } else {
          this.setState({ requestLoading: false });
          failToast("Profile edit failed");
          this.props.history.push(`/edit-profile`);
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  onChangePhoto(e) {
    this.setState({ file: e.target.files[0] }, () => {
      this.onFormSubmit(e);
    });
  }

  onFormSubmit(e) {
    this.setState({ uploading: true });
    const formData = new FormData();
    formData.append("file", this.state.file);
    const config = {
      headers: {
        "content-type": "image/xyz",
      },
    };

    this.props.uploadCandidateProfilePhoto(formData, config, (res) => {
      if (res.status === 200) {
        this.props.refreshToken();
        successToast("Photo successfully uploaded");
        this.props.getCandidateProfile(() => {
          if (this.IsMounted) {
            this.setState({
              uploading: false,
              src: null,
              croppedImageUrl: null,
              crop: {
                unit: "%",
                width: 100,
                aspect: 10 / 10,
              },
            });
          }
        });
      } else if (res.status === undefined) {
        failToast(`Photo upload failed! Try different format or smaller image`);
        if (this.IsMounted) {
          this.setState({ uploading: false });
        }
      }
      if (this.IsMounted) {
        this.setState({ uploading: false });
      }
    });
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
          crop: {
            unit: "%",
            width: 100,
            aspect: 10 / 10,
          },
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.setState({ croppedImageUrl }, () => {});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);

        function blobToFile(theBlob, fileName) {
          theBlob.lastModifiedDate = new Date();
          theBlob.name = fileName;
          return theBlob;
        }
        var newFile = blobToFile(blob, "name.png");

        var newfile = new File([newFile], "name.png", { type: "image/png" });
        this.setState({ file: newfile });
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const { loading } = this.props.candidates;

    const { crop, src } = this.state;

    var content = "";
    var userImage = "";
    if (this.state.img === null) {
      userImage = imgPlaceholder;
    } else {
      userImage = this.state.img;
    }

    if (this.state.candidateMapped === null || this.state.careerStatuses === null || this.state.citiesMapped === null || loading) {
      content = <Spinner />;
    } else {
      var imgContent = "";
      if (this.state.uploading) {
        imgContent = (
          <div>
            <img src={loadingImg} style={{ width: "250px", margin: "auto", display: "block" }} alt="Loading..." />
          </div>
        );
      } else {
        imgContent = (
          <img
            className="card-img-top rounded-circle img-upload"
            src={this.state.croppedImageUrl ? this.state.croppedImageUrl : userImage}
            alt=""
            onError={(e) => {
              e.preventDefault();
              e.target.onerror = null;
              e.target.src = imgPlaceholder;
            }}
          />
        );
      }

      var rightContent;
      if (this.state.src === null) {
        rightContent = (
          <div className="profile-top-right">
            <div className="profile-top-right--item">
              <div className="profile-top-right--item-title">First name:</div>
              <div className="profile-top-right--item-value">{this.state.first_name}</div>
            </div>
            <div className="profile-top-right--item">
              <div className="profile-top-right--item-title">Last name:</div>
              <div className="profile-top-right--item-value">{this.state.last_name}</div>
            </div>
            <div className="profile-top-right--item">
              <div className="profile-top-right--item-title">Email:</div>
              <div className="profile-top-right--item-value">{this.state.email}</div>
            </div>
            <div className="profile-top-right--item">
              <Dropdown
                placeholder={"Career Status"}
                label={"Status"}
                name={"career_status_id"}
                options={this.state.careerStatuses}
                onChange={(e) => this.onChange(e)}
                value={this.state.career_status_id}
              />
            </div>
            <div className="profile-top-right--item">
              <Input
                placeholder={"Github Username"}
                type="text"
                onChange={(e) => this.onChange(e)}
                onKeyDown={this.handleKeyDown}
                name={"github_username"}
                label="Github Username"
                value={this.state.github_username}
                validationMsg={this.state.errors.github_username}
              />
            </div>
            <div className="profile-top-right--item">
              <SearchDropdown
                label={"City"}
                placeholder={"City"}
                value={this.state.city}
                name={"city_id"}
                validationMsg={this.state.errors.city_id}
                onChange={this.selectCity}
                multiple={false}
                options={this.state.citiesMapped}
                isRequired={false}
              />
            </div>
          </div>
        );
        var bottomContent = (
          <span className="profile-bottom">
            <div className="profile-bottom">
              <div className="profile-bottom--item">
                <Textarea
                  placeholder={"Biography"}
                  label={"Biography"}
                  name={"biography"}
                  value={this.state.biography}
                  onChange={(e) => this.onChange(e)}
                  validationMsg={this.state.errors.biography}
                />
              </div>
            </div>
            <div className="profile-buttons">
              <div className="profile-buttons-left"></div>
              <div className="profile-buttons-right">
                <Btn className="btn btn-primary" label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
              </div>
            </div>
          </span>
        );
      } else {
        rightContent = (
          <div className="candidate-top-right">
            <div className="upload-image-container">
              <div className="upload-image-container-img">
                <ReactCrop
                  src={src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              </div>
              <div className="upload-image-container-btns">
                <Btn
                  label={"Cancel"}
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({
                      src: null,
                      croppedImageUrl: null,
                      crop: {
                        unit: "%",
                        width: 100,
                        aspect: 10 / 10,
                      },
                    })
                  }
                />
                <Btn label={"Upload"} className="btn btn-primary" onClick={this.onFormSubmit} />
              </div>
            </div>
          </div>
        );
      }
      content = (
        <div className="profile width-container">
          <div className="profile-top">
            <div className="profile-top-left">
              <div className="profile-top-left-img">
                <form onSubmit={this.onFormSubmit}>
                  <div className="fixed-ratio-div">
                    <label className="rounded-circle">
                      <input
                        className="centered-image rounded-circle"
                        type="file"
                        name="image"
                        accept=".gif,.jpg,.jpeg,.png,.tiff,.webp"
                        onChange={this.onSelectFile}
                        value={""}
                      />
                      {imgContent}
                    </label>
                  </div>
                </form>
              </div>
            </div>
            {rightContent}
          </div>
          {bottomContent}
        </div>
      );
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Edit Profile"} link={"/profile"} />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

EditProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  candidates: state.candidates,
  careerStatuses: state.careerStatuses,
  cities: state.cities,
});

export default connect(mapStateToProps, {
  getCandidateProfile,
  updateCandidateProfile,
  getCareerStatuses,
  searchCandidateGithubUsername,
  uploadCandidateProfilePhoto,
  getCities,
  refreshToken,
  clearErrors,
})(withRouter(EditProfile));
