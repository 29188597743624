import isEmpty from "../validation/isEmpty";
const SeniorityValidation = (data) => {
  var errors = {};

  var titleLimit = 50;

  data.title = !isEmpty(data.title) ? data.title : "";
  if (isEmpty(data.title)) {
    errors.seniority = "Title is required";
  } else {
    if (data.title.length > titleLimit) {
      errors.seniority = `Title can not have more than ${titleLimit} character (${data.title.length})`;
    }
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default SeniorityValidation;
