import React, { Component } from "react";
// import { clearErrors } from "../../actions/errorsActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }
  render() {
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Introduction"} link={"/dashboard"} />
            <div className="intro">
              <div className="intro-welcome">
                <div className="intro-welcome-title">Welcome to QASandbox</div>
                <div className="intro-welcome-body">
                  <p>First of all thank you for applying for QA interview process!</p>
                  <p>
                    Purpose of this introduction page is to explain why we require some info from you and to explain how the app is working.
                  </p>
                  <p>
                    You can always skip and come back on this page by clicking on <span className="text-primary">Introduction</span> in side
                    menu bar.
                  </p>
                </div>
              </div>

              <div className="intro-profile">
                <div className="intro-profile-title">Profile</div>
                <div className="intro-profile-body">
                  <p>On profile page Profesional Status and Github username are required.</p>

                  <p>
                    <b>Professional Status</b> is used to compare with your Exam result when it is finished. If you are not sure what your
                    professional status is you can simply use your best estimation.
                    <span role="img" aria-label="smiley">
                      &#128521;
                    </span>
                  </p>
                  <p>
                    <b>Github username</b> is required because QASandbox will automatically connect to that repository and make the review
                    process much easier.
                  </p>
                </div>
              </div>

              <div className="intro-testcases">
                <div className="intro-testcases-title">Test Cases</div>
                <div className="intro-testcases-body">
                  <p>Test cases are there simply to show the way you are testing / automating this application.</p>
                  <p>
                    Make sure that for every automated test case, pushed to your public github repository, there is <b>Automated?</b> switch
                    set to <b>YES</b>. Automated test cases are shown on dashboard on Playground card.
                  </p>
                  <p>
                    <i>
                      <b>Tip:</b>
                    </i>{" "}
                    Most important thing about test cases are having clear logic of their coverage and detailed steps explanations.
                  </p>
                </div>
              </div>

              <div className="intro-playground">
                <div className="intro-playground-title">Playground</div>
                <div className="intro-playground-body">
                  <p>
                    Playground is there only to add depth and complexity to logic for automated tests with nested objects and array of
                    objects.
                  </p>
                  Designed as:
                  <ul>
                    <li>
                      <b>Project</b> - <i>object</i>
                      <ul>
                        <li>
                          <b>Teams</b> - <i>array of objects</i>
                          <ul>
                            <li>
                              <b>People</b> - <i>array of objects</i>
                              <ul>
                                <li>
                                  <b>Seniority</b> - <i>object</i>
                                </li>
                                <li>
                                  <b>Technology</b> - <i>array of objects</i>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="intro-reports">
                <div className="intro-reports-title">Reports</div>
                <div className="intro-reports-body">
                  There are multiple reasons for Reports:
                  <ul>
                    <li>Most importantly we want to see on what kind of issues/bugs you are focusing on.</li>

                    <li>We want to see how detailed and clear explanations (Reports) are.</li>
                    <li>We want to see if you can find hidden "bugs" left on purpose.</li>
                    <li>
                      We want to fix bugs that we did not leave on purpose.{" "}
                      <span role="img" aria-label="smiley">
                        &#128517;
                      </span>
                    </li>
                  </ul>
                  <p>
                    Reports are required because even if you do not find any issues we want to see what kind of things is bothering you the
                    most.
                  </p>
                  <p>
                    <i>
                      <b>Note:</b>
                    </i>{" "}
                    QASandbox Reports are the simplest form of JIRA which is used on all HTEC projects so it is important for us to see how
                    you would use it.
                  </p>
                </div>
              </div>
              <div className="intro-dashboard">
                <div className="intro-dashboard-title">Dashboard</div>
                <div className="intro-dashboard-body">
                  <p>
                    On dashboard you have 4 cards: Profile, Test cases, Playground and Reports. All of these are explained in previous
                    steps.
                  </p>
                  <p>
                    Test cases, Playground and Reports are <b>disabled</b> when you start, until you <b>update</b> your <b>Profile</b> with
                    your <b>photo</b>.
                  </p>
                  <p>
                    You have set number of Test cases, Automated Test cases and Reports that you should aim for. Reaching the set number for
                    any of these entities will update your progress bar.
                  </p>
                  <p>
                    <i>
                      <b>Note:</b>
                    </i>{" "}
                    It is recommended to submit your Exam as finished only with full progress bar.
                  </p>
                </div>
              </div>
              <div className="intro-scenario">
                <div className="intro-scenario-title">Exam</div>
                <div className="intro-scenario-body">
                  <p>And finally, we have prepared scenario for you that we would like you to do / automate.</p>
                  <p>
                    You can open your scenario any time you want from <b>Exam</b> link in side menu bar.
                  </p>
                  <p>
                    <span className="text-danger">I Give Up!</span> button will <b>deactivate</b> your <b>account</b> and terminate process
                    without us going through your exam work!
                  </p>
                  <p>
                    <span className="text-primary">All Done!</span> button will <b>deactivate</b> your <b>account</b> and it will notify us
                    that your work on exam is done and we can review it. After our review, which can take up to one week, we will contact
                    you with our decision to continue interviewing process.
                  </p>
                  <p>
                    <i>
                      <b>Note: </b>
                    </i>{" "}
                    You do not have to do only what is written is scenario. Feel free to do whatever you like besides the things set in
                    scenario. Same goes for set number of entities (Test cases, Automated, Reports)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Intro.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(Intro));
