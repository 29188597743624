import React from "react";
import imageProject from "../../img/project.jpg";

export default function ProjectCardAdmin({ projects }) {
  var content;
  if (projects && projects.length > 0) {
    content = (
      <div className="portrait-grid">
        {projects &&
          projects.map((project, index) => (
            <span key={index}>
              <div className="preview-card preview-card-not-clickable">
                <div className="preview-card-title">
                  <div className="preview-card-title-value">{project.title}</div>
                </div>
                <div className="preview-card-body">
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-title">Teams:</div>
                      <div className="preview-card-body--items-single-value">{project.teams ? project.teams.length : 0}</div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-title">People:</div>
                      <div className="preview-card-body--items-single-value">{project.people_count}</div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-title">Seniorities:</div>
                      <div className="preview-card-body--items-single-value">{project.seniority_count}</div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-short">
                        <div className="preview-card-body--items-single-short-title">Technologies:</div>
                        <div className="preview-card-body--items-single-short-value">{project.technology_count}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="preview-card-body--items"
                    onClick={(e) => e.preventDefault()}
                    data-toggle="modal"
                    data-target={`#myModal-${index}`}
                  >
                    <div className="preview-card-body--items-single-preview">
                      <div className="preview-card-body--items-single-preview-title">Preview</div>
                      <div className="preview-card-body--items-single-preview-value">
                        <i className="fas fa-eye"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id={`myModal-${index}`} className="modal fade" role="dialog">
                <div className="modal-dialog">
                  {/* <!-- Modal content--> */}
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-titles">Project Preview</h4>
                      <button type="button" className="close" data-dismiss="modal">
                        &times;
                      </button>
                    </div>
                    <div className="modal-body">
                      <React.Fragment key={1}>
                        {/* <div className='project-container'> */}
                        <div className="project-container-top">
                          <div className="project-container-top-image">
                            <div className="fixed-ratio-div">
                              <img
                                className="card-img-top rounded-circle"
                                src={project.image ? project.image : imageProject}
                                alt=""
                                title={"title"}
                              />
                            </div>
                          </div>
                          <div className="project-container-top-title">{project && project.title}</div>
                        </div>

                        <div className="project-container-bottom">
                          {project &&
                            project.teams &&
                            project.teams.map((team, teamIndex) => (
                              <div className="project-container-bottom--teams" key={teamIndex}>
                                <React.Fragment key={teamIndex}>
                                  <div className="project-container-bottom--teams-title">{team.title}</div>
                                  {team.people &&
                                    team.people.map((person, personIndex) => (
                                      <React.Fragment key={personIndex}>
                                        <div className="project-container-bottom--teams-people">
                                          <div className="project-container-bottom--teams-people--person">
                                            <div className="project-container-bottom--teams-people--person-name">
                                              {person && person.name ? person.name : ""}
                                            </div>
                                            <div className="project-container-bottom--teams-people--person-seniority">
                                              {`Seniority: ${person.seniority && person.seniority.title}`}
                                            </div>
                                            <div className="project-container-bottom--teams-people--person-technologies">
                                              {person.technologies && person.technologies.length > 0 ? `Technologies: ` : ""}
                                              {person.technologies &&
                                                person.technologies.map((technology, technologyIndex) => (
                                                  <React.Fragment key={technologyIndex}>
                                                    {technology.title}
                                                    {person.technologies.length - 1 > technologyIndex ? `, ` : ``}
                                                  </React.Fragment>
                                                ))}
                                            </div>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    ))}
                                </React.Fragment>
                              </div>
                            ))}
                        </div>
                        {/* </div> */}
                      </React.Fragment>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-default" data-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          ))}
      </div>
    );
  } else {
    content = <div className="no-content">There are no projects created.</div>;
  }
  return <div>{content}</div>;
}
