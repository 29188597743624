import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";

import Navigate from "../../../components/Navigate/Navigate";
import { Link } from "react-router-dom";
import CandidateCard from "../../../components/Cards/CandidateCard";
import Input from "../../../components/common/Input";
import Dropdown from "../../../components/common/Dropdown";
import Btn from "../../../components/common/Btn";
import isEmpty from "../../../validation/isEmpty";
// import img from "../../../img/noImg.png";
// import profile from "../../../img/profile.jpg";
import Spinner from "../../../components/common/Spinner";
import moment from "moment";

import { getOfficeCandidates } from "../../../actions/candidateActions";
import { getExamStatuses } from "../../../actions/examActions";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import { examType } from "../../../enums/examType";
import CheckPermissions from "../../../validation/CheckPermissions";

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;
class Candidates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      officeCandidates: this.props.candidates && this.props.candidates.officeCandidates,
      examStatuses: this.props.exams && this.props.exams.examStatuses,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR, roles.RECRUITER, roles.QA, roles.GLOBALVIEWER],
      auth: {},
      examStatusesMapped: [],
      candidates_search: "",
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        const officeRoleObject =
          nextProps.auth &&
          nextProps.auth.user &&
          nextProps.auth.user.offices &&
          nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
        var userRoleOffice =
          officeRoleObject &&
          officeRoleObject[0] &&
          officeRoleObject[0].user_role_office &&
          officeRoleObject[0].user_role_office.role.value;
        update.userRoleOffice = userRoleOffice;
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.exams.examStatuses !== prevState.examStatuses) {
        update.examStatuses = nextProps.exams.examStatuses;
        var mappedExamStatuses = [];
        var examStatuses = nextProps.exams.examStatuses;
        examStatuses &&
          examStatuses.map((status) => {
            return mappedExamStatuses.push({ id: status.id, title: status.value });
          });
        mappedExamStatuses.push({ id: -1, title: "No Exam" });
        update.examStatusesMapped = mappedExamStatuses;
        update.examStatusesUpdated = true;
      }

      if (nextProps.candidates.officeCandidates !== prevState.officeCandidates) {
        update.officeCandidates = nextProps.candidates.officeCandidates;

        var officeCandidates = nextProps.candidates.officeCandidates;
        var mappedofficeCandidates = [];
        officeCandidates &&
          officeCandidates.map((candidate) => {
            var latestScenario = candidate.candidate_scenarios.sort(
              (a, b) => new Date(b.assigned_at).getTime() - new Date(a.assigned_at).getTime()
            )[0];
            var daysLeftToFinish = "";
            if (latestScenario && latestScenario.days_to_finish && latestScenario.assigned_at && latestScenario.active) {
              var dateToFinish = moment(moment(latestScenario.assigned_at).add(latestScenario.days_to_finish, "days"));
              var date_to_finish = moment(dateToFinish);
              var current_date = moment();
              var daysLeft = date_to_finish.diff(current_date, "days");
              if (daysLeft > 0) {
                daysLeftToFinish = ` (${daysLeft} days left)`;
              } else if (daysLeft === 0) {
                if (current_date > date_to_finish) {
                  daysLeftToFinish = ` (expired today)`;
                } else {
                  daysLeftToFinish = ` (last day)`;
                }
              } else {
                if (Math.abs(daysLeft) === 1) {
                  daysLeftToFinish = ` (${Math.abs(daysLeft)} day over)`;
                } else {
                  daysLeftToFinish = ` (${Math.abs(daysLeft)} days over)`;
                }
              }
            }

            return mappedofficeCandidates.push({
              id: candidate.id,
              email: candidate.email,
              first_name: candidate.first_name,
              last_name: candidate.last_name,
              img: candidate.img,
              office: candidate.offices.value,
              active: candidate.active,
              last_login: candidate.last_login,
              registered: candidate.registered,
              exams: candidate.candidate_scenarios && candidate.candidate_scenarios.length,
              status: latestScenario && latestScenario.status.value ? `${latestScenario.status.value} ${daysLeftToFinish}` : null,
              exam_participants: candidate.candidate_scenarios,
            });
          });
        update.officeCandidatesMapped = mappedofficeCandidates;
        update.officeCandidatesUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/dashboard`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getOfficeCandidates(this.props.match.params.officeId);
      this.props.getExamStatuses();
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.candidatesSearch();
    });
  }
  candidatesSearch() {
    var filters = {};
    if (!isEmpty(this.state.candidates_search)) {
      filters.candidates_search = this.state.candidates_search;
    }

    if (!isEmpty(this.state.exam_status) && !isNaN(this.state.exam_status) && parseInt(this.state.exam_status) !== 0) {
      filters.exam_status = parseInt(this.state.exam_status);
    }

    if (!isEmpty(this.state.active) && !isNaN(this.state.active) && parseInt(this.state.active) !== 0) {
      if (parseInt(this.state.active) === 2) {
        filters.active = parseInt(this.state.active);
      } else if (parseInt(this.state.active) === 3) {
        filters.active = parseInt(this.state.active);
      }
    }

    if (!isEmpty(this.state.exam_type) && !isNaN(this.state.exam_type) && parseInt(this.state.exam_type) !== 0) {
      if (parseInt(this.state.exam_type) === 1 || parseInt(this.state.exam_type) === 2) {
        filters.exam_type = parseInt(this.state.exam_type);
      }
    }

    if (Object.keys(filters).length > 0) {
      this.setState({ filters }, () => {});
      this.props.getOfficeCandidates(this.props.match.params.officeId, filters);
    } else {
      this.props.getOfficeCandidates(this.props.match.params.officeId);
    }
  }

  timer = null;
  handleChange = (e) => {
    clearTimeout(this.timer);
    this.setState({ candidates_search: e.target.value }, () => {});
    this.timer = setTimeout((timeout) => {
      this.triggerChange(timeout);
    }, WAIT_INTERVAL);
  };

  handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.triggerChange(e);
    }
  };

  clearFilters() {
    this.setState(
      {
        candidates_search: "",
        active: "",
        exam_status: "",
        exam_type: "",
      },
      () => {
        this.candidatesSearch();
      }
    );
  }
  triggerChange = (e) => {
    this.setState({ candidates_search: this.state.candidates_search }, () => {
      this.candidatesSearch();
    });
  };

  render() {
    const { loading } = this.props.candidates;
    var content;
    if (!this.state.officeCandidatesUpdated || !this.state.examStatusesUpdated || loading) {
      content = <Spinner />;
    } else {
      content = <CandidateCard candidates={this.state.officeCandidatesMapped} props={this.props} filters={this.state.filters} />;
    }

    var addNewBtn = "";
    if (
      this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
      this.state.userRoleOffice === roles.ADMINISTRATOR ||
      this.state.userRoleOffice === roles.RECRUITER
    ) {
      addNewBtn = [
        <Link to={`/admin/${this.props.match.params.officeId}/new-candidate`} className="btn btn-primary">
          Add Candidate
        </Link>,
      ];
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Candidates"} link={`/admin/${this.props.match.params.officeId}/dashboard`} buttons={addNewBtn} />
            <div className="search-container">
              <div className="search-container-search">
                <Input
                  placeholder="Search candidates..."
                  noTitle={true}
                  onChange={(e) => this.handleChange(e)}
                  name={"candidates_search"}
                  value={this.state.candidates_search}
                />
              </div>
              <div className="search-container-filter">
                <Dropdown
                  placeholder="Active"
                  options={[
                    { id: 1, title: "All" },
                    { id: 2, title: "Activated" },
                    { id: 3, title: "Deactivated" },
                  ]}
                  onChange={(e) => this.onChange(e)}
                  name={"active"}
                  value={this.state.active}
                  noTitle={true}
                />
              </div>
              <div className="search-container-filter">
                <Dropdown
                  placeholder="Latest Exam Status"
                  options={this.state.examStatusesMapped}
                  onChange={(e) => this.onChange(e)}
                  name={"exam_status"}
                  value={this.state.exam_status}
                  noTitle={true}
                />
              </div>
              <div className="search-container-filter">
                <Dropdown
                  placeholder="Latest Exam Type"
                  options={[
                    { id: 0, title: "All" },
                    { id: examType.MANUAL, title: "Manual" },
                    { id: examType.AUTOMATION, title: "Automation" },
                  ]}
                  onChange={(e) => this.onChange(e)}
                  name={"exam_type"}
                  value={this.state.exam_type}
                  noTitle={true}
                />
              </div>
            </div>
            <div className="search-container">
              <div className="search-container-clear mb-1">
                <Btn label="Clear" className="btn btn-secondary" onClick={(e) => this.clearFilters()} />
              </div>
            </div>
            {content}
          </div>
        </div>
      </div>
    );
  }
}

Candidates.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  offices: state.offices,
  candidates: state.candidates,
  exams: state.exams,
});

export default connect(mapStateToProps, { getOfficeCandidates, getExamStatuses, clearErrors })(withRouter(Candidates));
