import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import Input from "../../components/common/Input";
import Textarea from "../../components/common/Textarea";
import Btn from "../../components/common/Btn";
import InputGroup from "../../components/common/InputGroup";
import Switch from "../../components/common/SwitchComponent";
import FullBtn from "../../components/common/FullBtn";
import TestCaseValidation from "../../validation/TestcaseValidation";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import { createTestcase } from "../../actions/candidate/testcaseActions";
import { clearErrors } from "../../actions/errorsActions";
import scrollToError from "../../utility/scrollToError";

class NewTestcase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      submitPressed: false,
      mounted: false,
      auth: {},
      title: "",
      description: "",
      expected_result: "",
      automated: false,
      testcaseMapped: null,
      test_steps: [{ id: 1, value: "" }],
      maxSteps: 50,
      requestLoading: false,
      errors: {},
    };
    this.handleChangeAutomated = this.handleChangeAutomated.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  onChange(e) {
    this.props.clearErrors();
    if (e.target.id.substring(0, 4) === "step") {
      var enteredTestSteps = this.state.test_steps;
      enteredTestSteps[e.target.name.substring(5)].value = e.target.value;
      this.setState({ test_steps: enteredTestSteps }, () => {
        if (this.state.submitPressed) {
          this.checkValidation();
        }
      });
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {
        if (this.state.submitPressed) {
          this.checkValidation();
        }
      });
    }
  }
  handleChangeAutomated(automated) {
    this.setState({ automated });
  }

  addColumnStep(e) {
    var test_steps = this.state.test_steps;
    const max = test_steps.length > 0 && test_steps.reduce((prev, current) => (prev.id > current.id ? prev : current));

    test_steps.push({ id: max.id ? max.id + 1 : 1, value: "" });
    this.setState({ test_steps }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }

  checkValidation() {
    var formData = {};
    formData.title = this.state.title.trim();
    formData.description = this.state.description.trim();
    formData.expected_result = this.state.expected_result.trim();
    formData.test_steps = this.state.test_steps;
    formData.automated = this.state.automated;

    var { errors } = TestCaseValidation(formData);
    this.setState({ errors });
  }

  onSubmit() {
    this.checkValidation();
    this.setState({ submitPressed: true });
    var formData = {};
    formData.title = this.state.title.trim();
    formData.description = this.state.description.trim();
    formData.expected_result = this.state.expected_result.trim();
    var test_steps = this.state.test_steps;
    test_steps = test_steps.filter((el) => el.value.trim() !== "");
    var trimedSteps = [];
    test_steps.map((step) => {
      trimedSteps.push({ id: step.id, value: step.value.trim() });
      return null;
    });
    formData.test_steps = trimedSteps;
    formData.automated = this.state.automated;
    formData.candidate_scenario_id = this.state.candidate_scenario_id;

    const { errors, isValid } = TestCaseValidation(formData);

    if (isValid) {
      this.setState({requestLoading: true})
      this.props.createTestcase(formData, (res) => {
        if (res.status === 200) {
          successToast("Test case created successfully");
          this.props.history.push(`/testcases`);
        } else {
          this.setState({requestLoading: false})
          failToast("Test case creating failed");
          this.props.history.push(`/new-testcase`);
          scrollToError();
        }
      });
    } else {
      this.setState({ errors },()=>{
        scrollToError();
      });
    }
  }

  removeColumnStep(e) {
    var indexToRemove = e.target.id.substring(5);
    var stepErrors = this.state.errors.stepErrors;
    var errors = this.state.errors;
    stepErrors =
      stepErrors &&
      stepErrors.filter(function (obj) {
        return obj.id !== indexToRemove;
      });

    errors.stepErrors = stepErrors;
    var test_steps = this.state.test_steps;

    test_steps.splice(indexToRemove, 1);
    this.setState({ test_steps, errors }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }

  orderUp(e) {
    if (e.target.id > 0) {
      var changingIndex = parseInt(e.target.id);
      var test_steps = this.state.test_steps;
      var first = test_steps[changingIndex].id;
      var second = test_steps[changingIndex - 1].id;

      test_steps[changingIndex].id = second;
      test_steps[changingIndex - 1].id = first;

      var sortedTestSteps = test_steps.sort(function (a, b) {
        return a.id - b.id;
      });
      this.setState({ sortedTestSteps }, () => {
        if (this.state.submitPressed) {
          this.checkValidation();
        }
      });
    }
  }

  orderDown(e) {
    if (e.target.id < this.state.test_steps.length - 1) {
      var changingIndex = parseInt(e.target.id);
      var test_steps = this.state.test_steps;
      var first = test_steps[changingIndex].id;
      var second = test_steps[changingIndex + 1].id;

      test_steps[changingIndex].id = second;
      test_steps[changingIndex + 1].id = first;

      var sortedTestSteps = test_steps.sort(function (a, b) {
        return a.id - b.id;
      });
      this.setState({ sortedTestSteps }, () => {
        if (this.state.submitPressed) {
          this.checkValidation();
        }
      });
    }
  }

  render() {
    return (
      <div className='grid-menu-container'>
        <div className='menu-grid'>
          <div className='menu'>
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className='main-grid'>
          <div className='main'>
            {/* <div className='padding-container'> */}
            <Navigate title={"New Test Case"} link={"/testcases"} buttons={[]} />
            <div className='width-container default-container default-padding'>
              <Input
                placeholder={"Title"}
                type='text'
                value={this.state.title}
                onChange={(e) => this.onChange(e)}
                name={"title"}
                label='Title*'
                validationMsg={[this.state.errors.title, this.props.errors.title]}
              />
              <Textarea
                placeholder={"Description"}
                type='text'
                value={this.state.description}
                onChange={(e) => this.onChange(e)}
                name={"description"}
                label='Description'
                validationMsg={this.state.errors.description}
              />
              <Input
                placeholder={"Expected Result"}
                type='text'
                value={this.state.expected_result}
                onChange={(e) => this.onChange(e)}
                name={"expected_result"}
                label='Expected Result*'
                validationMsg={this.state.errors.expected_result}
              />
              <InputGroup
                type='text'
                placeholder={"Test step"}
                label='Test steps*'
                validationMsg={this.state.errors.test_steps}
                validationMsg2={this.state.errors.stepErrors}
                values={this.state.test_steps}
                keys={"value"}
                onChange={(e) => this.onChange(e)}
                id={"step"}
                addColumn={
                  <FullBtn
                    placeholder={
                      this.state.test_steps && this.state.test_steps.length >= this.state.maxSteps
                        ? `Max steps (${this.state.maxSteps})`
                        : "Add Test Step"
                    }
                    disabled={
                      this.state.test_steps && this.state.test_steps.length >= this.state.maxSteps ? true : false
                    }
                    onClick={(e) => this.addColumnStep(e)}
                  />
                }
                removeColumn={(e) => this.removeColumnStep(e)}
                required={true}
                onKeyDown={this.submitFormOnEnterKey}
                orderUp={(e) => this.orderUp(e)}
                orderDown={(e) => this.orderDown(e)}
              />
              <Switch
                onChange={this.handleChangeAutomated}
                value={this.state.automated}
                id={"automated"}
                name={"automated"}
                label={"Automated"}
              />
              <div className='submit-button'>
                <Btn className='btn btn-primary float-right' label='Submit' onClick={(e) => this.onSubmit(e)} loading={this.state.requestLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  }
}

NewTestcase.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { createTestcase, clearErrors })(withRouter(NewTestcase));
