import axios from "axios";

import { GET_CATEGORIES, GET_CATEGORY, CATEGORY_LOADING, REMOVE_LOADING, GET_ERRORS, GET_GLOBAL_CATEGORIES } from "./types";

// Get All Categories
export const getCategories = (office_id, callback) => (dispatch) => {
  dispatch(categoryLoading());

  axios
    .get(`/api/categories/office/${office_id}`)
    .then((res) => {
      dispatch({
        type: GET_CATEGORIES,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Category
export const getCategory = (categoryId) => (dispatch) => {
  dispatch(categoryLoading());

  axios
    .get(`/api/categories/category/${categoryId}`)
    .then((res) =>
      dispatch({
        type: GET_CATEGORY,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Create Category
export const createCategory = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/categories/category`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Update Category
export const updateCategory = (categoryId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/categories/category/${categoryId}`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Remove Category
export const removeCategory = (categoryId, callback) => (dispatch) => {
  axios
    .delete(`/api/categories/category/${categoryId}`)
    .then((res) => callback(res))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get All Global Categories
export const getGlobalCategories = () => (dispatch) => {
  dispatch(categoryLoading());

  axios
    .get(`/api/offices/categories`)
    .then((res) =>
      dispatch({
        type: GET_GLOBAL_CATEGORIES,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      // callback(err.response);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Category loading
export const categoryLoading = () => {
  return {
    type: CATEGORY_LOADING,
  };
};
