import React from "react";
import poweredByHTEC from "../../img/powered-by-htec.png";

export default function Footer() {
  return (
    <footer className=' text-center footer noselect'>
      <div className='push' />
      {/* Copyright &copy; {new Date().getFullYear()} QASandbox
      <br /> */}
      <a href='http://htecgroup.com' target='_blank' rel='noopener noreferrer'>
        <img src={poweredByHTEC} alt='powered by HTEC' className='footer-logo' />
      </a>
    </footer>
  );
}
