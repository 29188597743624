import { GET_OFFICE_STATISTICS, STATISTIC_LOADING, GET_GLOBAL_STATISTICS } from "../actions/types";

const initialState = {
  officeStatistics: null,
  globalStatistics: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STATISTIC_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_OFFICE_STATISTICS:
      return {
        ...state,
        officeStatistics: action.payload,
        loading: false,
      };
    case GET_GLOBAL_STATISTICS:
      return {
        ...state,
        globalStatistics: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
