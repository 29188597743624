import React from "react";
import isEmpty from "../../validation/isEmpty"

function FormElementTitle({ label, validationMsg }) {
  var validationMessage = "";
  var validationId = "";
  if ((validationMsg && !isEmpty(validationMsg[0])) || (validationMsg && !isEmpty(validationMsg[1]))) {
     validationId = "validation-msg"
    validationMessage = (
      // <span className='float-right'>
      <label className='form-element--validation' id={validationId}>{validationMsg}</label>
      // </span>
    );
  }else{
    validationMessage = ""
  }
  return (
    <div>
      <label className='form-element--label'>
        {label}
        {validationMessage}
      </label>
    </div>
  );
}

export default FormElementTitle;
