import isEmpty from "../validation/isEmpty";
const TestcaseValidation = (data) => {
  var errors = {};
  var titleLimit = 255;
  var expectedLimit = 255;
  var maxTestStepLength = 300;
  var descriptionLimit = 1000;

  data.title = !isEmpty(data.title) ? data.title : "";
  data.description = !isEmpty(data.description) ? data.description : "";
  data.expected_result = !isEmpty(data.expected_result) ? data.expected_result : "";

  var test_steps = data.test_steps;
  var filtered_test_steps = test_steps.filter((el) => el.value.trim() !== "");

  if (isEmpty(data.title)) {
    errors.title = "Title is required";
  } else {
    if (data.title.length > titleLimit) {
      errors.title = `Title can not have more than ${titleLimit} character (${data.title.length})`;
    }
  }

  if (!isEmpty(data.description)) {
    if (data.description.length > descriptionLimit) {
      errors.description = `Description can not have more than ${descriptionLimit} character (${data.description.length})`;
    }
  }

  if (isEmpty(data.expected_result)) {
    errors.expected_result = "Expected result is required";
  } else {
    if (data.expected_result.length > expectedLimit) {
      errors.expected_result = `Expected result can not have more than ${expectedLimit} characters (${data.expected_result.length})`;
    }
  }

  if (filtered_test_steps.length > 0) {
    var errorSteps = [];

    filtered_test_steps.map((step) => {
      var trimmedStep = step && step.value && step.value.length > 0 ? step.value.trim() : "";
      if (trimmedStep.length > maxTestStepLength) {
        errorSteps.push({
          id: step.id,
          error: `Test step can not have more than ${maxTestStepLength} characters (${trimmedStep.length})`,
        });
        errors.stepErrors = errorSteps;
      }

      return null;
    });
  } else {
    errors.test_steps = "There must be at least one test step";
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default TestcaseValidation;
