import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import SideMenuOffices from "../../components/layout/SideMenuOffices";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import Spinner from "../../components/common/Spinner";
import AdminCards from "../../components/Cards/AdminCards";
import isEmpty from "../../validation/isEmpty";
import { getUserOffices } from "../../actions/officeActions";
import { accountType } from "../../enums/accountType";
// import { pageType } from "../../enums/pageType";
// import { roles } from "../../enums/roles";
// import CheckPermissions from "../../validation/CheckPermissions";
// import qasandboxLogo from "../../img/QASandboxLogo.png";

import { withRouter } from "react-router-dom";
// import openSocket from "socket.io-client";
// import webSocket from "../../configSocket/keys";
// var socket = openSocket(webSocket.webSocket);

class Offices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      // userOffices: this.props.offices && this.props.offices.userOffices,
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (!nextProps.auth.isAuthenticated || accountType.USER !== nextProps.auth.user.type) {
          nextProps.history.push("/");
        }
        var tokenUserOffices = nextProps.auth.user.offices;
        update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        var userOffices = nextProps.auth.user.offices;
        if (tokenUserOffices && tokenUserOffices.length === 1) {
          nextProps.history.push(`/admin/${tokenUserOffices[0].id}/dashboard`);
        }
        var mappedOffices = [];
        if (!isEmpty(userOffices)) {
          userOffices &&
            userOffices.map((office) => {
              return mappedOffices.push({
                link: `/admin/${office.id}/dashboard`,
                title: office.value,
                subtitle: office.user_role_office && office.user_role_office.role.value,
              });
            });

          update.userOfficesMapped = mappedOffices;
          update.userOfficesUpdated = true;
        } else {
          update.userOfficesUpdated = true;
          update.userOfficesMapped = [];
        }
        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  render() {
    const { loading } = this.props.offices;

    var content = "";
    if (!this.state.userOfficesUpdated || loading) {
      content = <Spinner />;
    } else {
      content = <AdminCards cards={this.state.userOfficesMapped} />;
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Offices"} />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

Offices.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  offices: state.offices,
});

export default connect(mapStateToProps, { getUserOffices, clearErrors })(withRouter(Offices));
