import axios from "axios";

import { GET_PEOPLE, GET_PERSON, PERSON_LOADING, REMOVE_LOADING, GET_ERRORS } from "../types";

// Get All People
export const getPeople = () => (dispatch) => {
  dispatch(peopleLoading());

  axios
    .get(`/api/candidate/people`)
    .then((res) => {
      dispatch({
        type: GET_PEOPLE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Person
export const getPerson = (personId) => (dispatch) => {
  dispatch(peopleLoading());

  axios
    .get(`/api/candidate/people/${personId}`)
    .then((res) =>
      dispatch({
        type: GET_PERSON,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    });
};

// Create Person
export const createPerson = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/people`, formData)
    .then((res) => {
      dispatch({
        type: GET_PEOPLE,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Update Person
export const updatePerson = (personId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidate/people/${personId}`, formData)
    .then((res) => {
      dispatch({
        type: GET_PEOPLE,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Remove Person
export const removePerson = (personId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/people/${personId}`)
    .then((res) => {
      dispatch({
        type: GET_PEOPLE,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// People loading
export const peopleLoading = () => {
  return {
    type: PERSON_LOADING,
  };
};
