import isEmpty from "../validation/isEmpty";
const QuestionValidation = (data) => {
  var errors = {};

  data.question = !isEmpty(data.question) ? data.question : "";
  data.question_plain = !isEmpty(data.question_plain) ? data.question_plain : "";
  data.answer = !isEmpty(data.answer) ? data.answer : "";
  data.answer_plain = !isEmpty(data.answer_plain) ? data.answer_plain : "";
  data.created_on = !isEmpty(data.created_on) ? data.created_on : "";

  if (isEmpty(data.question) || isEmpty(data.question_plain)) {
    errors.question = "Title is required";
  }
  if (isEmpty(data.answer) || isEmpty(data.answer_plain)) {
    errors.answer = "Answer is required";
  }
  if (isEmpty(data.office_id)) {
    errors.office_id = "Office is required";
  } else {
    if (isNaN(data.office_id)) {
      errors.office_id = "Office id is not a valid number";
    }
  }

  // if (isEmpty(data.user_id)) {
  //   errors.user_id = "User id is required";
  // } else {
  //   if (isNaN(data.user_id)) {
  //     errors.user_id = "User id is not a valid number";
  //   }
  // }

  if (isNaN(data.category_id)) {
    errors.category_id = "Category id is not a valid number";
  }

  if (isNaN(data.difficulty_id)) {
    errors.difficulty_id = "Difficulty id is not a valid number";
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default QuestionValidation;
