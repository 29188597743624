import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "../../components/common/Input";
import Btn from "../../components/common/Btn";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import isEmpty from "../../validation/isEmpty";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import ChangePasswordValidation from "../../validation/ChangePasswordValidation";
import { changeCandidatePassword } from "../../actions/candidate/profileActions";

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      requestLoading: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var update = {};
    if (!nextProps.auth.isAuthenticated) {
      nextProps.history.push("/login");
    }
    if (!isEmpty(nextProps.errors)) {
      update.errors = nextProps.errors;
    }
    return Object.keys(update).length ? update : null;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }

  checkValidation() {
    this.props.clearErrors();
    const passwordData = {};
    passwordData.current_password = this.state.current_password;
    passwordData.new_password = this.state.new_password;
    passwordData.confirm_password = this.state.confirm_password;
    const { errors } = ChangePasswordValidation(passwordData);

    this.setState({ errors });
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.clearErrors();
    const passwordData = {};
    passwordData.current_password = this.state.current_password;
    passwordData.new_password = this.state.new_password;
    passwordData.confirm_password = this.state.confirm_password;

    const { errors, isValid } = ChangePasswordValidation(passwordData);
    if (isValid) {
      this.setState({requestLoading: true})
      this.props.changeCandidatePassword(passwordData, (res) => {
        if (res.status === 200) {
          successToast("Password changed successfully");
          this.props.history.push(`/profile`);
        } else {
          this.setState({requestLoading: false})
          failToast("Password changing failed");
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  render() {
    return (
      <div className='grid-menu-container'>
        <div className='menu-grid'>
          <div className='menu'>
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className='main-grid'>
          <div className='main'>
            <Navigate title={"Change Password"} link={"/profile"} />
            <div className='width-container default-container default-padding'>
              {/* <div className='landing-title text-center'>Change Password</div> */}
              <p className='landing-description text-center'></p>
              <Input
                placeholder={"Current Password"}
                onChange={(e) => this.onChange(e)}
                name={"current_password"}
                type='password'
                label='Current Password*'
                validationMsg={this.state.errors.current_password}
              />
              <Input
                placeholder={"New Password"}
                onChange={(e) => this.onChange(e)}
                name={"new_password"}
                type='password'
                label='New Password*'
                validationMsg={this.state.errors.new_password}
              />
              <Input
                placeholder={"Confirm Password"}
                onChange={(e) => this.onChange(e)}
                name={"confirm_password"}
                type='password'
                label='Confirm Password*'
                validationMsg={this.state.errors.confirm_password}
              />
              {/* <span className='text-danger text-center'>{this.state.errors.error}</span> */}
              <br />
              <Btn className='full-width-btn' label='Change' onClick={(e) => this.onSubmit(e)} loading={this.state.requestLoading} />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { changeCandidatePassword, clearErrors })(withRouter(ChangePassword));
