import isEmpty from "../validation/isEmpty";
const ResetPasswordValidation = (data) => {
  const minPassLength = 6;
  const maxPassLength = 30;

  let errors = {};

  data.password = !isEmpty(data.password) ? data.password : "";
  data.confirm_password = !isEmpty(data.confirm_password) ? data.confirm_password : "";

  if (data.password.length < minPassLength) {
    errors.password = `Password must be at least ${minPassLength} characters long`;
  }else if(data.password.length > maxPassLength){
    errors.password = `Password can have maximum of ${maxPassLength} characters `;
  }

  if (isEmpty(data.password)) {
    errors.password = "New password is required";
  }

  if (data.password !== data.confirm_password) {
    errors.confirm_password = "Confirm password must match password";
  }

  if (isEmpty(data.confirm_password)) {
    errors.confirm_password = "Confirm password is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default ResetPasswordValidation;
