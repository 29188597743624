import React from "react";
// import { Link } from "react-router-dom";

export default function RecipientCards({ recipients }) {
  var content = "Loading...";
  if (recipients && recipients.length > 0) {
    content = (
      <div className='portrait-grid'>
        {recipients &&
          recipients.map((recipient, index) => (
            <div className='preview-card preview-card-not-clickable' key={index}>
              <div className='preview-card-body'>
                <div className='preview-card-body--items'>
                  <div className='preview-card-body--items-single'>
                    <div className='preview-card-body--items-single-title'>{recipient.title}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  } else {
    content = (
      <div className='no-content'>
        There are no recipients added. Click Edit Recipients in top right corner to add first.
      </div>
    );
  }
  return <div>{content}</div>;
}
