import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import Input from "../../../components/common/Input";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import TextAreaEditor from "../../../components/common/TextAreaEditor";
import Btn from "../../../components/common/Btn";
import Dropdown from "../../../components/common/Dropdown";
import CheckPermissions from "../../../validation/CheckPermissions";
import Switch from "../../../components/common/SwitchComponent";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import { examType } from "../../../enums/examType";
import { createScenario } from "../../../actions/scenarioActions";
import { EditorState, convertToRaw } from "draft-js";
import ScenarioValidation from "../../../validation/ScenarioValidation";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withRouter } from "react-router-dom";
import scrollToError from "../../../utility/scrollToError";

class NewScenario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      editorStateScenario: EditorState.createEmpty(),
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
      title: "",
      show_testcase_count: true,
      requestLoading: false,
      show_automated_count: true,
      show_report_count: true,
      exam_type: "",
      testcase_count: "",
      automated_count: "",
      report_count: "",
      submitPressed: false,
      errors: {},
    };
    this.handleTestCaseCountSwitch = this.handleTestCaseCountSwitch.bind(this);
    this.handleAutomatedCountSwitch = this.handleAutomatedCountSwitch.bind(this);
    this.handleReportCountSwitch = this.handleReportCountSwitch.bind(this);
    this.onEditorStateChangeScenario = this.onEditorStateChangeScenario.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/scenarios`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.WRITE,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/scenarios`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    CheckPermissions(dataToCheck);
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }
  checkValidation() {
    this.props.clearErrors();
    var formData = {};
    if (!isNaN(this.props.match.params.scenarioId)) {
      formData.scenarioId = parseInt(this.props.match.params.scenarioId);
    } else {
      formData.scenarioId = null;
    }
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }
    formData.scenario = draftToHtml(convertToRaw(this.state.editorStateScenario.getCurrentContent()));
    formData.scenario_plain = this.state.editorStateScenario.getCurrentContent().getPlainText();
    formData.user_id = this.state.auth.user && this.state.auth.user.id ? this.state.auth.user.id : null;
    formData.show_testcase_count = this.state.show_testcase_count;
    formData.show_automated_count = this.state.show_automated_count;
    formData.show_report_count = this.state.show_report_count;
    formData.testcase_count =
      this.state.show_testcase_count && this.state.testcase_count && !isNaN(this.state.testcase_count)
        ? parseInt(this.state.testcase_count)
        : this.state.testcase_count;
    formData.automated_count =
      this.state.show_automated_count && this.state.automated_count && !isNaN(this.state.automated_count)
        ? parseInt(this.state.automated_count)
        : this.state.automated_count;
    formData.report_count =
      this.state.show_report_count && this.state.report_count && !isNaN(this.state.report_count)
        ? parseInt(this.state.report_count)
        : this.state.report_count;
    formData.title = this.state.title;

    formData.exam_type = this.state.exam_type && !isNaN(this.state.exam_type) ? parseInt(this.state.exam_type) : this.state.exam_type;

    const { errors } = ScenarioValidation(formData);
    this.setState({ errors });
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({ submitPressed: true });
    var formData = {};
    if (!isNaN(this.props.match.params.scenarioId)) {
      formData.scenarioId = parseInt(this.props.match.params.scenarioId);
    } else {
      formData.scenarioId = null;
    }
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }
    formData.scenario = draftToHtml(convertToRaw(this.state.editorStateScenario.getCurrentContent()));
    formData.scenario_plain = this.state.editorStateScenario.getCurrentContent().getPlainText();
    formData.user_id = this.state.auth.user && this.state.auth.user.id ? this.state.auth.user.id : null;
    formData.show_testcase_count = this.state.show_testcase_count;
    formData.show_automated_count = this.state.show_automated_count;
    formData.show_report_count = this.state.show_report_count;
    formData.testcase_count =
      this.state.show_testcase_count && this.state.testcase_count && !isNaN(this.state.testcase_count)
        ? parseInt(this.state.testcase_count)
        : this.state.testcase_count;
    formData.automated_count =
      this.state.show_automated_count && this.state.automated_count && !isNaN(this.state.automated_count)
        ? parseInt(this.state.automated_count)
        : this.state.automated_count;
    formData.report_count =
      this.state.show_report_count && this.state.report_count && !isNaN(this.state.report_count)
        ? parseInt(this.state.report_count)
        : this.state.report_count;
    formData.title = this.state.title;
    formData.exam_type = this.state.exam_type && !isNaN(this.state.exam_type) ? parseInt(this.state.exam_type) : this.state.exam_type;

    const { errors, isValid } = ScenarioValidation(formData);
    if (isValid) {
      this.setState({ requestLoading: true });
      formData.testcase_count = this.state.show_testcase_count && formData.testcase_count ? formData.testcase_count : null;
      formData.automated_count = this.state.show_automated_count && formData.automated_count ? formData.automated_count : null;
      formData.report_count = this.state.show_report_count && formData.report_count ? formData.report_count : null;
      this.props.createScenario(formData, (res) => {
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Scenario updated successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/scenarios`);
        } else {
          this.setState({ requestLoading: false });
          failToast("Scenario edit failed");
          scrollToError();
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ requestLoading: false });
        scrollToError();
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }

  onEditorStateChangeScenario = (editorStateScenario) => {
    this.setState(
      {
        editorStateScenario,
      },
      () => {
        if (this.state.submitPressed) {
          this.checkValidation();
        }
      }
    );
  };
  handleTestCaseCountSwitch(show_testcase_count) {
    this.setState({ show_testcase_count }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }
  handleAutomatedCountSwitch(show_automated_count) {
    this.setState({ show_automated_count }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }
  handleReportCountSwitch(show_report_count) {
    this.setState({ show_report_count }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }

  render() {
    const { editorStateScenario } = this.state;

    var content = (
      <div className="question-container width-container">
        <Input
          placeholder={"Scenario Title"}
          type="text"
          onChange={(e) => this.onChange(e)}
          name={"title"}
          label="Scenario Title*"
          value={this.state.title}
          validationMsg={[this.state.errors.title, this.props.errors.title]}
        />
        <Dropdown
          placeholder="Exam Type"
          label={"Exam Type"}
          required={false}
          options={[
            { id: examType.MANUAL, title: "Manual" },
            { id: examType.AUTOMATION, title: "Automation" },
          ]}
          onChange={(e) => this.onChange(e)}
          name={"exam_type"}
          value={this.state.exam_type}
          validationMsg={[this.state.errors.exam_type, this.props.errors.exam_type]}
        />
        <div className="default-flex">
          <Input
            placeholder={"Test Case Count"}
            type="text"
            onChange={(e) => this.onChange(e)}
            name={"testcase_count"}
            label="Test Case Count"
            disabled={!this.state.show_testcase_count}
            value={this.state.testcase_count}
            validationMsg={this.state.errors.testcase_count}
          />
          <Switch
            onChange={this.handleTestCaseCountSwitch}
            value={this.state.show_testcase_count}
            id={"show_testcase_count"}
            name={"show_testcase_count"}
            label={""}
          />
        </div>
        <div className="default-flex">
          <Input
            placeholder={"Count To Automate"}
            type="text"
            onChange={(e) => this.onChange(e)}
            name={"automated_count"}
            label="Count To Automate"
            disabled={!this.state.show_automated_count}
            value={this.state.automated_count}
            validationMsg={this.state.errors.automated_count}
          />
          <Switch
            onChange={this.handleAutomatedCountSwitch}
            value={this.state.show_automated_count}
            id={"show_automated_count"}
            name={"show_automated_count"}
            label={""}
          />
        </div>
        <div className="default-flex">
          <Input
            placeholder={"Report Count"}
            type="text"
            onChange={(e) => this.onChange(e)}
            name={"report_count"}
            label="Report Count"
            disabled={!this.state.show_report_count}
            value={this.state.report_count}
            validationMsg={this.state.errors.report_count}
          />
          <Switch
            onChange={this.handleReportCountSwitch}
            value={this.state.show_report_count}
            id={"show_report_count"}
            name={"show_report_count"}
            label={""}
          />
        </div>
        <TextAreaEditor
          label={"Scenario*"}
          editorState={editorStateScenario}
          placeholder={"Scenario"}
          name={"scenario"}
          onChange={this.onEditorStateChangeScenario}
          validationMsg={this.state.errors.scenario}
        />
        {/* <div className={"text-danger"}>
          {Object.keys(this.state.errors).length + Object.keys(this.props.errors).length > 0
            ? `Validation errors count: ${Object.keys(this.state.errors).length + Object.keys(this.props.errors).length}`
            : ""}
        </div> */}
        <div className="submit-button">
          <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
        </div>
      </div>
    );

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"New Scenario"} link={`/admin/${this.props.match.params.officeId}/scenarios`} />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

NewScenario.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { createScenario, clearErrors })(withRouter(NewScenario));
