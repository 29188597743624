import isEmpty from "../validation/isEmpty";
const PersonValidation = (data) => {
  var errors = {};

  var nameLimit = 50;

  data.name = !isEmpty(data.name) ? data.name : "";

  if (isEmpty(data.name)) {
    errors.person = "Name is required";
  } else {
    if (data.name.length > nameLimit) {
      errors.person = `Name can not have more than ${nameLimit} character (${data.name.length})`;
    }
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default PersonValidation;
