import isEmpty from "../validation/isEmpty";
const addCandidateValidation = (data) => {
  var errors = {};
  var firstNameMaxLength = 20;
  var lastNameMaxLength = 50;

  data.office_id = !isEmpty(data.office_id) ? data.office_id : "";
  data.first_name = !isEmpty(data.first_name) ? data.first_name : "";
  data.last_name = !isEmpty(data.last_name) ? data.last_name : "";
  data.email = !isEmpty(data.email) ? data.email : "";

  // function validateEmail(email) {
  //   const re = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  //   return re.test(String(email).toLowerCase());
  // }

  if (isEmpty(data.first_name)) {
    errors.first_name = "First name is required";
  } else {
    if (data.first_name.length > firstNameMaxLength) {
      errors.first_name = `First name can not be greater than ${firstNameMaxLength} characters (${data.first_name.length})`;
    }
  }
  if (isEmpty(data.last_name)) {
    errors.last_name = "Last name is required";
  } else {
    if (data.last_name.length > lastNameMaxLength) {
      errors.last_name = `Last name can not be greater than ${lastNameMaxLength} characters (${data.last_name.length})`;
    }
  }

  if (isEmpty(data.email)) {
    errors.email = "Email is required";
  } else {
    // Commented for testing purposes
    // if (!validateEmail(data.email)) {
    //   errors.email = "Email address is not valid";
    // }
  }

  if (isEmpty(data.office_id)) {
    errors.office_id = "Office is required";
  } else {
    if (isNaN(data.office_id)) {
      errors.office_id = `Office is invalid`;
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default addCandidateValidation;
