import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Input from "../../components/common/Input";
import Btn from "../../components/common/Btn";
import { getTeams, createTeam, updateTeam, removeTeam } from "../../actions/candidate/teamActions";
import { getCandidateProject } from "../../actions/candidate/projectActions";
import { getPeople } from "../../actions/candidate/peopleActions";
import Spinner from "../../components/common/Spinner";
import SettingsItemList from "../../components/Project/SettingsItemList";
import isEmpty from "../../validation/isEmpty";
import successToast from "../toast/successToast";
import failToast from "../toast/failToast";
import Confirm from "../common/Confirm";
import TeamValidation from "../../validation/TeamValidation";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      project: this.props.projects && this.props.project,
      teams: this.props.teams && this.props.teams.teams,
      projectMapped: null,
      teamsMapped: null,
      requestLoading: false,
      team: "",
      updateTeam: false,
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.teams.teams !== prevState.teams) {
        update.teams = nextProps.teams.teams;
        update.teamsMapped = nextProps.teams.teams;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getTeams();
  }
  onChangeEdit(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidationEdit();
    });
  }
  onChangeCreate(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidationCreate();
    });
  }

  checkValidationCreate() {
    this.props.clearErrors();
    this.setState({ errors: {} });
    var formFields = {};
    formFields.title = this.state.team && this.state.team.length > 0 ? this.state.team.trim() : "";
    var { errors, isValid } = TeamValidation(formFields);
    if (!isValid) {
      if (errors.team) {
        errors.teamCreate = errors.team;
      }
      this.setState({ errors });
    }
  }

  checkValidationEdit() {
    this.props.clearErrors();
    this.setState({ errors: {} });
    var formFields = {};
    formFields.title = this.state.team_name && this.state.team_name.length > 0 ? this.state.team_name.trim() : "";
    var { errors, isValid } = TeamValidation(formFields);
    if (!isValid) {
      if (errors.team) {
        errors.teamEdit = errors.team;
      }
      this.setState({ errors });
    }
  }
  onSubmitTeam() {
    this.props.clearErrors();
    var formFields = {};
    formFields.title = this.state.team && this.state.team.length > 0 ? this.state.team.trim() : "";
    var { errors, isValid } = TeamValidation(formFields);
    if (isValid) {
      this.setState({requestLoading: true})
      this.props.createTeam(formFields, (res) => {
        if (res.status === 200) {
          successToast("Team created successfully");
          this.setState({ team_name: "",team: "",requestLoading: false });
        } else {
          this.setState({requestLoading: false})
          failToast("Team creating successfully");
        }
      });
    } else {
      if (errors.team) {
        errors.teamCreate = errors.team;
      }
      this.setState({ errors });
    }
  }

  editTeam(team) {
    this.props.clearErrors();
    this.setState({ teamToEdit: team.id, team_name: team.title }, () => {
      this.checkValidationEdit();
    });
  }

  confirmEditTeam(team) {
    this.props.clearErrors();
    var teamData = {};
    teamData.title = this.state.team_name && this.state.team_name.length > 0 ? this.state.team_name.trim() : "";
    const { errors, isValid } = TeamValidation(teamData);

    if (isValid) {
      this.props.updateTeam(team.id, teamData, (res) => {
        if (res.status === 200) {
          this.props.getCandidateProject(this.props.match.params.projectId, () => {});
          this.props.getPeople();
          successToast("Team updated successfully");
          this.setState({ team_name: "", teamToEdit: null });
        } else {
          failToast("Team update failed");
        }
      });
    } else {
      if (errors.team) {
        errors.teamEdit = errors.team;
      }
      this.setState({ errors });
    }
  }

  confirmTeamRemove = (team) => {
    if (!isEmpty(team.id)) {
      this.props.removeTeam(team.id, (res) => {
        if (res.status === 200) {
          this.props.getCandidateProject(this.props.match.params.projectId, () => {});
          this.props.getPeople();
          successToast("Team removed successfully");
        } else {
          failToast(`Removing ${team.name} team failed`);
        }
      });
    }
  };
  confirmTeamRemoveModal = (team) => {
    var reject = "No";
    var title = "Remove this team?";
    var msg = ``;
    var confirm = "Remove";

    Confirm(title, msg, reject, confirm, (e) => this.confirmTeamRemove(team));
  };

  render() {
    const { loading } = this.props.teams;
    var teamContent = "";
    if (this.state.teamsMapped === null || loading) {
      teamContent = <Spinner />;
    } else if (this.state.teamsMapped.length > 0) {
      teamContent = this.state.teams.map((team, index) => (
        <SettingsItemList
          link={`/Employee/${team.id}`}
          index={index}
          key={index}
          label={"Edit Team"}
          id={team.id}
          value1={team.title}
          name={"team_name"}
          value={this.state.team_name}
          onClickRemove={(e) => this.confirmTeamRemoveModal(team)}
          onClickEdit={(e) => {
            this.editTeam(team);
          }}
          cancelEdit={(e) => this.setState({ teamToEdit: null })}
          onClickConfirmEdit={(e) => this.confirmEditTeam(team)}
          onChange={(e) => this.onChangeEdit(e, this.state.teamToEdit)}
          validationMsg={[this.props.errors.teamEdit, this.state.errors.teamEdit]}
          idToEdit={this.state.teamToEdit}
        />
      ));
    } else {
      teamContent = <div className='no-content'>No teams created</div>;
    }
    return (
      <div className='project-bottom-technologies'>
        Here you can create teams (QA, BE, FE, PM...).
        <Input
          placeholder={"Team"}
          type='text'
          onChange={(e) => this.onChangeCreate(e)}
          name={"team"}
          label='Teams'
          value={this.state.team}
          validationMsg={[this.state.errors.teamCreate, this.props.errors.teamCreate]}
        />
        <div className='submit-button'>
          <Btn className='btn btn-primary ' label='Create' onClick={(e) => this.onSubmitTeam(e)} loading={this.state.requestLoading} />
        </div>
        {teamContent}
      </div>
    );
  }
}

Team.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  teams: state.teams,
});

export default connect(mapStateToProps, {
  createTeam,
  getTeams,
  updateTeam,
  removeTeam,
  getCandidateProject,
  getPeople,
  clearErrors,
})(withRouter(Team));
