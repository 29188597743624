import { GET_CAREER_STATUSES, CAREER_STATUS_LOADING } from "../actions/types";

const initialState = {
  careerStatus: null,
  careerStatuses: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CAREER_STATUS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CAREER_STATUSES:
      return {
        ...state,
        careerStatuses: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
