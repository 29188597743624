import React from "react";
import imgPlaceholder from "../../img/noImg.png";
import SmallBtnExternal from "../../components/common/SmallBtnExternal";
import moment from "moment";
import linkify from "../../utility/linkify";

export default function CanddiateTopContainer({ candidate, latestScenario, cv, cvRoute }) {
  var daysLeftToFinish = "Not set";
  if (latestScenario && latestScenario.days_to_finish && latestScenario.assigned_at && latestScenario.active) {
    var dateToFinish = moment(moment(latestScenario.assigned_at).add(latestScenario.days_to_finish, "days"));
    var date_to_finish = moment(dateToFinish);
    var current_date = moment();
    var daysLeft = date_to_finish.diff(current_date, "days");
    if (daysLeft > 0) {
      daysLeftToFinish = ` ${daysLeft} days left`;
    } else if (daysLeft === 0) {
      if (current_date > date_to_finish) {
        daysLeftToFinish = ` Expired today`;
      } else {
        daysLeftToFinish = ` Last day`;
      }
    } else {
      if (Math.abs(daysLeft) === 1) {
        daysLeftToFinish = ` ${Math.abs(daysLeft)} day over`;
      } else {
        daysLeftToFinish = ` ${Math.abs(daysLeft)} days over`;
      }
    }
  }

  return (
    <div>
      <div className="candidate-top">
        <div className="candidate-top-left">
          <div className="candidate-top-left-img">
            <div className="fixed-ratio-div">
              <img
                className="card-img-top rounded-circle"
                src={candidate.img ? candidate.img : imgPlaceholder}
                alt=""
                onError={(e) => {
                  e.preventDefault();
                  e.target.onerror = null;
                  e.target.src = imgPlaceholder;
                }}
              />
            </div>
          </div>
        </div>
        <div className="candidate-top-right">
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">First name:</div>
            <div className="candidate-top-right--item-value">{candidate.first_name}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Last name:</div>
            <div className="candidate-top-right--item-value">{candidate.last_name}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Email:</div>
            <div className="candidate-top-right--item-value">{candidate.email}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Career Status:</div>
            <div className="candidate-top-right--item-value">
              {candidate.career_status && candidate.career_status ? candidate.career_status.title : "Not specified"}
            </div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Latest exam:</div>
            <div className="candidate-top-right--item-value">{latestScenario ? latestScenario.status.value : "No Exams"}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Office:</div>
            <div className="candidate-top-right--item-value">{candidate.offices.value}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Github:</div>
            <div className="candidate-top-right--item-value">{candidate.github_username ? candidate.github_username : "Not specified"}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">City:</div>
            <div className="candidate-top-right--item-value">{candidate.city ? candidate.city.value : "Not specified"}</div>
          </div>
          {/* <div className='candidate-top-right--item'>
            <div className='candidate-top-right--item-title'>Exam:</div>
            <div className='candidate-top-right--item-value'>/</div>
          </div> */}
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Last login:</div>
            <div className="candidate-top-right--item-value">
              {candidate.last_login ? moment.utc(candidate.last_login).local().format("DD.MM.YYYY HH:mm:ss") : "Never"}
            </div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Registered:</div>
            <div className="candidate-top-right--item-value">
              {candidate.registered ? moment.utc(candidate.registered).local().format("DD.MM.YYYY HH:mm:ss") : "Never"}
            </div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Active:</div>
            <div className="candidate-top-right--item-value">{candidate.active ? "Yes" : "No"}</div>
          </div>

          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Days to finish:</div>
            <div className="candidate-top-right--item-value">{daysLeftToFinish}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">CV:</div>
            <div className="candidate-top-right--item-value">
              {cv ? (
                <SmallBtnExternal label={<i className="fas fa-external-link-alt"></i>} link={cvRoute} className={"btn-primary"} />
              ) : (
                "Not uploaded"
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="candidate-bottom">
        <div className="candidate-bottom--item">
          <div className="candidate-bottom--item-title">Biography:</div>
          <div className="candidate-bottom--item-value">{linkify(candidate.biography)}</div>
        </div>
        <hr />
      </div>
    </div>
  );
}
