import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Input from "../../components/common/Input";
import Btn from "../../components/common/Btn";
import {
  getSeniorites,
  createSeniority,
  updateSeniority,
  removeSeniority,
} from "../../actions/candidate/seniorityActions";
import { getCandidateProject } from "../../actions/candidate/projectActions";
import { getPeople } from "../../actions/candidate/peopleActions";
import Spinner from "../../components/common/Spinner";
import SettingsItemList from "../../components/Project/SettingsItemList";
import isEmpty from "../../validation/isEmpty";
import successToast from "../toast/successToast";
import failToast from "../toast/failToast";
import Confirm from "../common/Confirm";
import SeniorityValidation from "../../validation/SeniorityValidation";

class Seniority extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      project: this.props.projects && this.props.project,
      seniorities: this.props.seniorities && this.props.seniorities.seniorities,
      projectMapped: null,
      requestLoading: false,
      senioritiesMapped: null,
      seniority: "",
      updateSeniority: false,
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.seniorities.seniorities !== prevState.seniorities) {
        update.seniorities = nextProps.seniorities.seniorities;
        update.senioritiesMapped = nextProps.seniorities.seniorities;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getSeniorites();
  }
  onChangeEdit(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidationEdit();
    });
  }

  onChangeCreate(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidationCreate();
    });
  }

  checkValidationCreate() {
    this.props.clearErrors();
    this.setState({ errors: {} });
    var formFields = {};
    formFields.title = this.state.seniority && this.state.seniority.length > 0 ? this.state.seniority.trim() : "";

    var { errors, isValid } = SeniorityValidation(formFields);
    if (!isValid) {
      if (errors.seniority) {
        errors.seniorityCreate = errors.seniority;
      }
      this.setState({ errors });
    }
  }

  checkValidationEdit() {
    this.props.clearErrors();
    this.setState({ errors: {} });
    var formFields = {};
    formFields.title = this.state.seniority_name && this.state.seniority_name.length > 0 ? this.state.seniority_name.trim() : ""
    var { errors, isValid } = SeniorityValidation(formFields);
    if (!isValid) {
      if (errors.seniority) {
        errors.seniorityEdit = errors.seniority;
      }
      this.setState({ errors });
    }
  }
  onSubmitSeniority() {
    this.props.clearErrors();
    var formFields = {};
    formFields.title = this.state.seniority && this.state.seniority.length > 0 ? this.state.seniority.trim() : "";
    var { errors, isValid } = SeniorityValidation(formFields);
    if (isValid) {
      this.setState({requestLoading: true})
      this.props.createSeniority(formFields, (res) => {
        if (res.status === 200) {
          successToast("Seniority created successfully");
          this.setState({ seniority_name: "",seniority: "", requestLoading: false });
        } else {
          this.setState({requestLoading: false})
          failToast("Seniority creating successfully");
        }
      });
    } else {
      if (errors.seniority) {
        errors.seniorityCreate = errors.seniority;
      }
      this.setState({ errors });
    }
  }

  editSeniority(seniority) {
    this.props.clearErrors();
    this.setState({ seniorityToEdit: seniority.id, seniority_name: seniority.title }, () => {
      this.checkValidationEdit();
    });
  }

  confirmEditSeniority(seniority) {
    this.props.clearErrors();
    var seniorityData = {};
    seniorityData.title = this.state.seniority_name && this.state.seniority_name.length > 0 ? this.state.seniority_name.trim() : ""
    const { errors, isValid } = SeniorityValidation(seniorityData);

    if (isValid) {
      this.props.updateSeniority(seniority.id, seniorityData, (res) => {
        if (res.status === 200) {
          this.props.getCandidateProject(this.props.match.params.projectId, () => {});
          this.props.getPeople();
          successToast("Seniority updated successfully");
          this.setState({ seniority_name: "", seniorityToEdit: null });
        } else {
          failToast("Seniority update failed");
        }
      });
    } else {
      if (errors.seniority) {
        errors.seniorityEdit = errors.seniority;
      }
      this.setState({ errors });
    }
  }

  confirmSeniorityRemove = (seniority) => {
    if (!isEmpty(seniority.id)) {
      this.props.removeSeniority(seniority.id, (res) => {
        if (res.status === 200) {
          this.props.getCandidateProject(this.props.match.params.projectId, () => {});
          this.props.getPeople();
          successToast("Seniority removed successfully");
        } else {
          failToast(`Removing ${seniority.name} seniority failed`);
        }
      });
    }
  };
  confirmSeniorityRemoveModal = (seniority) => {
    var reject = "No";
    var title = "Remove this seniority?";
    var msg = ``;
    var confirm = "Remove";

    Confirm(title, msg, reject, confirm, (e) => this.confirmSeniorityRemove(seniority));
  };

  render() {
    const { loading } = this.props.seniorities;
    var seniorityContent = "";
    if (this.state.senioritiesMapped === null || loading) {
      seniorityContent = <Spinner />;
    } else if (this.state.senioritiesMapped.length > 0) {
      seniorityContent = this.state.seniorities.map((seniority, index) => (
        <SettingsItemList
          link={`/Employee/${seniority.id}`}
          index={index}
          key={index}
          label={"Edit Seniority"}
          id={seniority.id}
          value1={seniority.title}
          name={"seniority_name"}
          value={this.state.seniority_name}
          onClickRemove={(e) => this.confirmSeniorityRemoveModal(seniority)}
          onClickEdit={(e) => {
            this.editSeniority(seniority);
          }}
          cancelEdit={(e) => this.setState({ seniorityToEdit: null })}
          onClickConfirmEdit={(e) => this.confirmEditSeniority(seniority)}
          onChange={(e) => this.onChangeEdit(e, this.state.seniorityToEdit)}
          validationMsg={[this.props.errors.seniorityEdit, this.state.errors.seniorityEdit]}
          idToEdit={this.state.seniorityToEdit}
        />
      ));
    } else {
      seniorityContent = <div className='no-content'>No seniorities created</div>;
    }
    return (
      <div className='project-bottom-technologies'>
        Here you can create seniorities (Intern, Medior, Senior...).
        <Input
          placeholder={"Seniority"}
          type='text'
          onChange={(e) => this.onChangeCreate(e)}
          name={"seniority"}
          label='Seniorities'
          value={this.state.seniority}
          validationMsg={[this.state.errors.seniorityCreate, this.props.errors.seniorityCreate]}
        />
        <div className='submit-button'>
          <Btn className='btn btn-primary ' label='Create' onClick={(e) => this.onSubmitSeniority(e)} loading={this.state.requestLoading} />
        </div>
        {seniorityContent}
      </div>
    );
  }
}

Seniority.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  seniorities: state.seniorities,
});

export default connect(mapStateToProps, {
  createSeniority,
  getSeniorites,
  updateSeniority,
  removeSeniority,
  getCandidateProject,
  getPeople,
  clearErrors,
})(withRouter(Seniority));
