import axios from "axios";

import { INVITE_CANDIDATE, INVITED_CANDIDATE, GET_ERRORS } from "./types";

export const sendUserInvite = (inviteUserData, callback, sendCallback) => (dispatch) => {
  axios
    .post(`/api/admin/users/user/invite`, inviteUserData)
    .then((res) =>
      dispatch({
        type: INVITE_CANDIDATE,
        payload: res.data,
      })
    )
    .then(() =>
      axios
        .post(`/api/mailer/invite-user`, inviteUserData)
        .then((res) => {
          callback(res);
        })
        .catch((err) => callback(err))
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Resend Invitation Email
export const resendInvitationEmail = (inviteUserData, callback) => (dispatch) => {
  axios
    .post(`/api/mailer/invite-user`, inviteUserData)

    .then((res) =>
      dispatch({
        type: INVITE_CANDIDATE,
        payload: res.data,
      })
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => callback(err));
};

// Get Invited User
export const getInvitedUser = (hash) => (dispatch) => {
  axios
    .get(`/api/candidates/hash/${hash}`, {
      headers: { typeinvite: true },
    })
    .then((res) =>
      dispatch({
        type: INVITED_CANDIDATE,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Removing invited user that is not registered
export const removeUserInvite = (user_id, history, callback) => (dispatch) => {
  axios
    .delete(`/api/admin/users/user/${user_id}`)
    .then((res) => {
      callback(res);
    })
    .then((res) => history.push("/admin/users"))
    .catch((err) => {
      callback(err);
    });
};

// Removing invited candidate that is not registered
export const removeCandidateInvite = (user_id, history, callback) => (dispatch) => {
  axios
    .delete(`/api/admin/users/user/${user_id}`)
    .then((res) => {
      callback(res);
    })
    .then((res) => history.push("/admin/candidates"))
    .catch((err) => {
      callback(err);
    });
};

export const getScenarios = (callback) => (dispatch) => {
  axios
    .get(`/api/admin/scenarios/all`)

    .then((res) => {
      callback(res);
    })
    .catch((err) => callback(err));
};
export const getScenario = (callback) => (dispatch) => {
  axios
    .get(`/api/scenario`)

    .then((res) => {
      callback(res);
    })
    .catch((err) => callback(err));
};
// export const getDifficulty = callback => dispatch => {
//   axios
//     .get(`/api/difficulty`)

//     .then(res => {
//       callback(res);
//     })
//     .catch(err => callback(err));
// };
