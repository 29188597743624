import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "../../components/common/Input";
import Btn from "../../components/common/Btn";
// import isEmpty from "../../validation/isEmpty";
import { Link } from "react-router-dom";
import { loginUser } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorsActions";
import Switch from "../../components/common/SwitchComponent";
var crypto = require("crypto"),
  algorithm = "aes-256-ctr",
  passwordcrypto = "d6F3Efeq";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      remember_me: false,
      requestLoading: false,
      validationErrors: {},
    };
    this.handleRememberMeSwitch = this.handleRememberMeSwitch.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  encrypt = (text) => {
    var cipher = crypto.createCipher(algorithm, passwordcrypto);
    var crypted = cipher.update(text, "utf8", "hex");
    crypted += cipher.final("hex");
    return crypted;
  };
  decrypt = (text) => {
    var decipher = crypto.createDecipher(algorithm, passwordcrypto);
    var dec = decipher.update(text, "hex", "utf8");
    dec += decipher.final("utf8");
    return dec;
  };

  componentDidMount() {
    if (localStorage.adminRemember_me && localStorage.adminEmail !== "") {
      this.setState({
        remember_me: true,
        email: localStorage.adminEmail,
        password: this.decrypt(localStorage.adminPassword),
      });
    }
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var update = {};
    if (nextProps.auth.isAuthenticated) {
      nextProps.history.push("/admin/offices");
    }
    update.errors = nextProps.errors;
    return Object.keys(update).length ? update : null;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.clearErrors();
    const userData = {
      email: this.state.email ? this.state.email.toLowerCase() : null,
      password: this.state.password,
    };
    var validationErrors = {};
    if (!this.state.email) {
      validationErrors.email = "Email is required";
    }
    if (!this.state.password) {
      validationErrors.password = "Password is required";
    }
    this.setState({ validationErrors });
    if (Object.keys(validationErrors).length === 0) {
      const { email, password, remember_me } = this.state;

      if (remember_me && email !== "") {
        localStorage.adminEmail = email ? email.toLowerCase() : null;
        localStorage.adminPassword = this.encrypt(password);
        localStorage.adminRemember_me = remember_me;
      } else {
        localStorage.removeItem("adminEmail");
        localStorage.removeItem("adminPassword");
        localStorage.removeItem("adminRemember_me");
      }

      this.setState({ requestLoading: true });
      this.props.loginUser(userData, (res) => {
        if (res.status !== 200) {
          this.setState({ requestLoading: false });
        }
      });
    }
  }
  handleRememberMeSwitch(remember_me) {
    this.setState({ remember_me });
  }

  render() {
    return (
      <div className="grid-container">
        <div className="main ">
          <div className="landing">
            <div className="landing-title text-center">Administration Log In</div>
            <p className="landing-description text-center">Log in as administrator if you are authorized</p>
            <Input
              placeholder={"Email"}
              onChange={(e) => this.onChange(e)}
              value={this.state.email}
              name={"email"}
              label="Email"
              validationMsg={this.state.validationErrors.email}
            />
            <Input
              placeholder={"Password"}
              type="password"
              value={this.state.password}
              onChange={(e) => this.onChange(e)}
              name={"password"}
              label="Password"
              validationMsg={this.state.validationErrors.password}
            />
            <Input
              placeholder="Hash"
              name="hash"
              type="hidden"
              value={this.state.hash}
              onChange={this.onChange}
              validationMsg={this.state.errors.email}
            />
            <Switch
              onChange={this.handleRememberMeSwitch}
              value={this.state.remember_me}
              id={"remember_me"}
              name={"remember_me"}
              label={"Remember me"}
            />
            <span className="text-danger text-center">{this.state.errors.error}</span>
            <br />
            <Btn className="full-width-btn" label="Login" onClick={(e) => this.onSubmit(e)} loading={this.state.requestLoading} />
            <br />
            <Link to="/forgot-password-admin" className="text-center">
              Forgot Password
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginUser,
  clearErrors,
})(Login);
