import {
  GET_CATEGORIES,
  GET_CATEGORY,
  CATEGORY_LOADING,
  GET_GLOBAL_CATEGORIES,
  REMOVE_LOADING,
} from "../actions/types";

const initialState = {
  globalCategories: null,
  categories: null,
  category: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: action.payload,
        loading: false,
      };
    case REMOVE_LOADING:
      return {
        ...state,

        loading: false,
      };
    case GET_GLOBAL_CATEGORIES:
      return {
        ...state,
        globalCategories: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
