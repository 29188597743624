import isEmpty from "../validation/isEmpty";
const NewCommentValidation = (data) => {
  var errors = {};

  data.comment = !isEmpty(data.comment) ? data.comment : "";
  data.candidate_id = !isEmpty(data.candidate_id) ? data.candidate_id : "";
  data.user_id = !isEmpty(data.user_id) ? data.user_id : "";

  if (isEmpty(data.comment)) {
    errors.new_comment = "Comment can not be empty";
  }

  if (isEmpty(data.user_id)) {
    errors.user_id = "User id is required";
  } else {
    if (isNaN(data.user_id)) {
      errors.user_id = `User id is invalid`;
    }
  }

  if (isEmpty(data.candidate_id)) {
    errors.candidate_id = "Candidate id is required";
  } else {
    if (isNaN(data.candidate_id)) {
      errors.candidate_id = `Candidate id is invalid`;
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default NewCommentValidation;
