import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import Dropdown from "../../../components/common/Dropdown";
import Confirm from "../../../components/common/Confirm";
import Spinner from "../../../components/common/Spinner";
import SearchDropdown from "../../../components/common/SearchDropdown";
import Input from "../../../components/common/Input";
import Switch from "../../../components/common/SwitchComponent";
import ParticipantValidation from "../../../validation/ParticipantValidation";
import {
  getCandidateActiveScenario,
  cancelCandidateExam,
  candidateAvailableScenarios,
  updateCandidateExamScenario,
} from "../../../actions/scenarioActions";
import SmallButtonExternal from "../../../components/common/SmallBtnExternal";
import { getExamParticipants, updateExamParticipants } from "../../../actions/examActions";
import { inviteCandidate, getOfficeCandidate, getCandidateRepository, updateCandidateRepository } from "../../../actions/candidateActions";
import { getOfficeUsers } from "../../../actions/userActions";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";
import SweetAlert from "react-bootstrap-sweetalert";
import Btn from "../../../components/common/Btn";

import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import openSocket from "socket.io-client";
import webSocket from "../../../configSocket/keys";
import isEmpty from "../../../validation/isEmpty";
var socket = openSocket(webSocket.webSocket);

class ScenarioCurrent extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      user: {},
      mounted: false,
      candidateActiveScenario:
        this.props.scenarios && this.props.scenarios.candidateActiveScenario && this.props.scenarios.candidateActiveScenario.scenarios,
      candidateAvailableScenarios: this.props.scenarios && this.props.scenarios.candidateAvailableScenarios,
      scenario: this.props.scenarios && this.props.scenarios.scenario,
      activeScenario: this.props.scenarios && this.props.scenarios.candidateActiveScenario,
      active: false,
      deadlineSet: "",
      newScenarioId: null,
      deadline_switch: false,
      requestLoading: false,
      participantRequestLoading: false,
      errors: {},
      repositoryCurrent: "",
      repositoryOriginal: "",
      repositoryGithub: {},
      notifyLoading: false,
      officeUsers: this.props.users && this.props.users.officeUsers,
      officeCandidate: this.props.candidates && this.props.candidates.officeCandidate,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR, roles.QA, roles.RECRUITER, roles.GLOBALVIEWER],
      confirmation: null,
      resendUserCallback: null,
      confirmationResend: null,
      scenario_notifications: [],
      availableScenariosMapped: [],
      examParticipantsMapped: [],
      auth: {},
    };
    this.handleDeadlineSwitch = this.handleDeadlineSwitch.bind(this);
    this.selectParticipant = this.selectParticipant.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        const officeRoleObject =
          nextProps.auth &&
          nextProps.auth.user &&
          nextProps.auth.user.offices &&
          nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
        var userRoleOffice = officeRoleObject && officeRoleObject[0].user_role_office.role.value;
        update.userRoleOffice = userRoleOffice;
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          // update.errors = nextProps.errors;
          var errors = {};
          errors.deadline = "";
          update.errors = errors;
        }
        update.auth = nextProps.auth;
      }
      var daysLeftToFinish = "";
      if (
        nextProps.scenarios &&
        nextProps.scenarios.candidateActiveScenario &&
        nextProps.scenarios.candidateActiveScenario !== prevState.activeScenario
      ) {
        if (isEmpty(nextProps.scenarios.candidateActiveScenario)) {
          nextProps.history.push(`/admin/${nextProps.match.params.officeId}/candidates/${nextProps.match.params.candidateId}`);
        }
        update.repositoryOriginal = nextProps.scenarios.candidateActiveScenario.repository
          ? nextProps.scenarios.candidateActiveScenario.repository
          : "";
        update.activeScenario = nextProps.scenarios.candidateActiveScenario;
      }
      if (
        nextProps.scenarios &&
        nextProps.scenarios.candidateActiveScenario &&
        nextProps.scenarios.candidateActiveScenario.scenarios !== prevState.candidateActiveScenario
      ) {
        if (
          nextProps.scenarios.candidateActiveScenario &&
          nextProps.scenarios.candidateActiveScenario.days_to_finish &&
          nextProps.scenarios.candidateActiveScenario.assigned_at &&
          nextProps.scenarios.candidateActiveScenario.active
        ) {
          var dateToFinish = moment(
            moment(nextProps.scenarios.candidateActiveScenario.assigned_at).add(
              nextProps.scenarios.candidateActiveScenario.days_to_finish,
              "days"
            )
          );
          var date_to_finish = moment(dateToFinish);
          var current_date = moment();
          var daysLeft = date_to_finish.diff(current_date, "days");
          if (!isNaN(daysLeft)) {
            daysLeftToFinish = daysLeft;
          }
        }
        if (prevState.initialRender) {
          update.initialRender = false;
          update.deadlineSet = nextProps.scenarios.candidateActiveScenario.days_to_finish
            ? nextProps.scenarios.candidateActiveScenario.days_to_finish
            : "";
          update.deadline_switch = nextProps.scenarios.candidateActiveScenario.days_to_finish ? true : false;
          if (nextProps.scenarios.candidateActiveScenario.repository !== prevState.repositoryCurrent) {
            if (!isEmpty(nextProps.scenarios.candidateActiveScenario.repository)) {
              nextProps.getCandidateRepository(nextProps.scenarios.candidateActiveScenario.repository);
              update.repositoryCurrent = nextProps.scenarios.candidateActiveScenario.repository
                ? nextProps.scenarios.candidateActiveScenario.repository
                : "";
            }
          }
        }

        if (nextProps.candidates.candidateRepos !== prevState.repositoryGithub) {
          update.repositoryGithub = nextProps.candidates.candidateRepos;
        }
        update.active = nextProps.scenarios.candidateActiveScenario.active ? nextProps.scenarios.candidateActiveScenario.active : false;

        update.candidateActiveScenario = nextProps.scenarios.candidateActiveScenario;
        update.lastDay = !(current_date > date_to_finish);
        update.deadlineValue = daysLeftToFinish;
        update.candidateActiveScenariosUpdated = true;
        update.scenario_notifications = nextProps.scenarios.candidateActiveScenario.scenario_notifications
          ? nextProps.scenarios.candidateActiveScenario.scenario_notifications
          : [];
      }
      if (nextProps.candidates.officeCandidate !== prevState.officeCandidate) {
        update.officeCandidate = nextProps.candidates.officeCandidate;

        var officeCandidate = nextProps.candidates.officeCandidate;
        update.officeCandidate = officeCandidate;
      }
      if (nextProps.scenarios && nextProps.scenarios.scenario !== prevState.scenario) {
        update.scenario_id = nextProps.scenarios.scenario.id;
        update.scenarioMapped = nextProps.scenarios.scenario;
        update.scenarioUpdated = true;
      }
      if (
        nextProps.scenarios &&
        nextProps.scenarios.candidateAvailableScenarios &&
        nextProps.scenarios.candidateAvailableScenarios !== prevState.candidateAvailableScenarios
      ) {
        var availableScenarios = nextProps.scenarios.candidateAvailableScenarios;
        var availableScenariosMapped = [];
        availableScenarios.map((availableScenario) => {
          return availableScenariosMapped.push({
            id: availableScenario.id,
            title: availableScenario.title.length > 45 ? `${availableScenario.title.substring(0, 45)}...` : availableScenario.title,
          });
        });

        update.availableScenariosMapped = availableScenariosMapped;
        update.availableScenariosUpdated = true;
      }
    }
    if (nextProps.users.officeUsers !== prevState.officeUsers) {
      update.officeUsers = nextProps.users.officeUsers;

      var officeUsers = nextProps.users.officeUsers;
      var mappedOfficeUsers = [];
      officeUsers &&
        officeUsers.map((user) => {
          return mappedOfficeUsers.push({
            id: user.user_id,
            title: user.user.email,
          });
        });
      update.officeUsersMapped = mappedOfficeUsers;
      update.officeUsersUpdated = true;
    }

    if (nextProps) {
      if (nextProps.exams.examParticipants !== prevState.examParticipants) {
        update.examParticipants = nextProps.exams.examParticipants;

        var examParticipants = nextProps.exams.examParticipants;
        var mappedRecipients = [];
        examParticipants &&
          examParticipants.map((recipient) => {
            return mappedRecipients.push({
              id: recipient.user_id,
              title: recipient.user.email,
            });
          });
        update.examParticipantsMapped = mappedRecipients;
        update.examParticipantsUpdated = true;
      }
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this._isMounted = true;

    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {});
      this.props.getCandidateActiveScenario(this.props.match.params.candidateId);
      this.props.candidateAvailableScenarios(this.props.match.params.candidateId, this.props.match.params.officeId);
      this.props.getExamParticipants(this.props.match.params.candidateId);
      this.props.getOfficeUsers(this.props.match.params.officeId);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  selectParticipant(value) {
    this.setState({ examParticipantsMapped: value });
  }

  confirmActivationChange = (value) => {
    if (!value) {
      this.props.cancelCandidateExam(this.props.match.params.candidateId, (res) => {
        if (res.status === 200) {
          socket.emit("refreshUserToken", this.props.match.params.candidateId);
          this.props.history.push(`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`);
          successToast("Candidate exam canceled successfully");
        } else {
          this.props.history.push(
            `/admin/${this.props.match.params.officeId}/candidate/${this.props.match.params.candidateId}/current-scenario`
          );
          failToast(`Candidate exam canceling failed`);
        }
      });
    }
  };
  confirmActivationStatusChangeModal = (value) => {
    var confirmMainText = "";
    var confirmText = "";
    var btnText = "";
    if (!value) {
      if (this.state.deadlineValue > 0) {
        confirmText = (
          <span>
            Candidate still has <span className="text-danger"> {this.state.deadlineValue} days left</span> to finish this exam.
            <br /> If canceled user will not be able to log in anymore and status will be set as{" "}
            <span className="text-warning">Canceled(Before Time)</span>!
            <br /> This action is
            <span className="text-danger"> IREVERSIBLE</span>!
          </span>
        );
      } else if (this.state.deadlineValue === 0) {
        if (this.state.lastDay) {
          confirmText = (
            <span>
              Candidate still has <span className="text-danger"> less than a day left</span> to finish this exam.
              <br /> If canceled user will not be able to log in anymore and status will be set as{" "}
              <span className="text-warning">Canceled(Before Time)</span>!
              <br /> This action is
              <span className="text-danger"> IREVERSIBLE!</span>
            </span>
          );
        } else {
          confirmText = (
            <span>
              Candidate exam <span className="text-success">expired today</span>.
              <br /> If canceled user will not be able to log in anymore and status will be set as{" "}
              <span className="text-primary">Canceled(Timed Out)</span>!
              <br /> This action is
              <span className="text-danger"> IREVERSIBLE!</span>
            </span>
          );
        }
      } else if (this.state.deadlineValue < 0) {
        confirmText = (
          <span>
            Candidate deadline to finish this exam was <span className="text-success"> {Math.abs(this.state.deadlineValue)} ago </span> .
            <br /> If canceled user will not be able to log in anymore and status will be set as{" "}
            <span className="text-primary">Canceled(Timed Out)</span>!
            <br /> This action is
            <span className="text-danger"> IREVERSIBLE</span>!
          </span>
        );
      } else {
        confirmText = (
          <span>
            If canceled user will not be able to log in anymore and status will be set as{" "}
            <span className="text-primary">Canceled(No Timer)</span>!<br /> This action is
            <span className="text-danger"> IREVERSIBLE</span>!
          </span>
        );
      }

      confirmMainText = "Are you sure you want to cancel candidate exam?";
      btnText = "Deactivate";
      Confirm(confirmMainText, confirmText, "Cancel", btnText, () => this.confirmActivationChange(value));
    }
  };
  handleDeadlineSwitch(value) {
    this.setState({ deadline_switch: value, deadlineSet: "" });
  }
  confirmScenarioChangeModal = (value) => {
    var newScenarioId;
    if (!isNaN(value.target.value)) {
      newScenarioId = parseInt(value.target.value);
    }
    var confirmMainText = "Are you sure you want to change candidate's exam scenario?";
    var confirmText = (
      <span>
        Changing candidate's active exam scenario will take effect right away.
        <br /> Change only in agreement with candidate!
      </span>
    );
    var btnText = "Change";

    Confirm(confirmMainText, confirmText, "Cancel", btnText, () => this.confirmScenarioChange(newScenarioId));
  };

  changeRepo(e) {
    e.preventDefault();
    var formData = {};
    formData.repository = this.state.repositoryCurrent;
    this.props.updateCandidateRepository(this.props.match.params.candidateId, formData, () => {
      this.props.getCandidateRepository(this.state.repositoryCurrent);
      // this.props.getCandidateActiveScenario(this.props.match.params.candidateId);
    });
  }
  confirmScenarioChangeModal = () => {
    var newScenarioId = this.state.newScenarioId && !isNaN(this.state.newScenarioId) ? parseInt(this.state.newScenarioId) : null;
    var newDeadline = this.state.deadlineSet && !isNaN(this.state.deadlineSet) ? parseInt(this.state.deadlineSet) : null;

    var confirmMainText = "Are you sure you want to set this candidate's exam scenario?";
    var confirmText = (
      <span>
        Setting candidate's exam scenario will take effect right away.
        <br /> Candidate will be activated and will be able to log in!
      </span>
    );
    var btnText = "Set";
    Confirm(confirmMainText, confirmText, "Cancel", btnText, () => this.confirmScenarioChange(newScenarioId, newDeadline));
  };
  confirmScenarioChange = (newScenarioId, newDeadline) => {
    if (!isNaN(newScenarioId)) {
      var formData = {};
      if (newScenarioId) {
        formData.scenario_id = newScenarioId ? newScenarioId : this.state.activeScenario.id;
      }
      formData.repository = !isEmpty(this.state.repository) ? this.state.repository : null;
      if (newDeadline && this.state.deadline_switch) {
        formData.days_to_finish = newDeadline;
      } else if (!this.state.deadline_switch) {
        formData.days_to_finish = null;
      }
      this.setState({ requestLoading: true });
      this.props.updateCandidateExamScenario(this.props.match.params.candidateId, formData, (res) => {
        if (res.status === 200) {
          this.props.candidateAvailableScenarios(this.props.match.params.candidateId, this.props.match.params.officeId);
          this.props.getCandidateActiveScenario(this.props.match.params.candidateId);
          this.setState({ requestLoading: false });
          successToast("Candidate exam updated successfully");
        } else {
          this.setState({ requestLoading: false });
          failToast(`Candidate exam update failed`);
        }
      });
    }
  };
  onChange(e) {
    if (e.target.name === "deadlineSet") {
      this.setState({ deadlineChanged: true });
    }
    var errors = {};
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (isNaN(this.state.deadlineSet) || parseInt(this.state.deadlineSet) === 0 || this.state.deadlineSet === "") {
        errors.deadline = "Invalid deadline";
        this.setState({ errors });
      } else {
        errors = {};
        errors.deadline = " ";
        this.setState({ errors });
      }
    });
  }

  submitForm(e) {
    e.preventDefault();
    var formData = [];

    formData = this.state.examParticipantsMapped;
    const { errors, isValid } = ParticipantValidation(formData);
    if (isValid) {
      this.setState({ participantRequestLoading: true });

      var data = {};
      data.participants = formData;
      data.office_id = this.props.match.params.officeId;
      data.candidate_id = this.props.match.params.candidateId;
      this.props.updateExamParticipants(data, (res) => {
        if (res.status === 200) {
          this.setState({ participantRequestLoading: false });
          successToast("Participant list updated successfully");
          this.props.history.push(
            `/admin/${this.props.match.params.officeId}/candidate/${this.props.match.params.candidateId}/current-scenario`
          );
        } else {
          this.setState({ requestLoading: false });
          failToast("Participant list update failed");
          this.props.history.push(
            `/admin/${this.props.match.params.officeId}/candidate/${this.props.match.params.candidateId}/current-scenario`
          );
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ participantRequestLoading: false });
      });
    }
  }

  onClickNotify() {
    var formData = {};
    formData.id = this.state.officeCandidate.id;
    formData.email = this.state.officeCandidate.email;
    formData.first_name = this.state.officeCandidate.first_name;
    formData.last_name = this.state.officeCandidate.last_name;
    formData.registered = this.state.officeCandidate.registered;
    this.setState({ notifyLoading: true });
    this.props.inviteCandidate(formData, (res) => {
      if (this._isMounted === true) {
        if (res && res.status === 200) {
          this.setState({ notifyLoading: false });
          this.props.clearErrors();
          var smallText = (
            <span>
              Invitation successfully sent to <span style={{ color: "#28a745" }}>{formData.email}</span>
            </span>
          );
          var title = <span>Success!</span>;

          const getAlert = () => (
            <SweetAlert success title={title} confirmBtnBsStyle="success" onConfirm={() => this.onConfirm()}>
              {smallText}
            </SweetAlert>
          );
          this.setState({
            confirmationResend: getAlert(),
          });
        } else {
          this.setState({ notifyLoading: false });
          var smallTextFail = (
            <span>
              Info stored in database and mail can be resent from users list. <br /> Email:{" "}
              <span style={{ color: "#ffbf00" }}> {formData.email}</span>
            </span>
          );
          var titleFail = <span>Sending failed!</span>;

          const getAlert = () => (
            <SweetAlert warning title={titleFail} confirmBtnBsStyle="warning" onConfirm={() => this.onConfirm()}>
              {smallTextFail}
            </SweetAlert>
          );
          this.setState({
            confirmationResend: getAlert(),
          });
        }
      } else {
        if (res && res.status === 200) {
          successToast(`Invitation email successfully sent!`, {});
        } else {
          failToast("Sending invitation email failed!", {});
        }
      }
    });
  }
  onConfirm(e) {
    this.props.getCandidateActiveScenario(this.props.match.params.candidateId);
    this.setState({
      confirmationResend: null,
    });
  }

  confirmNotifyModal = () => {
    var confirmMainText = (
      <span>
        Send email to <span className="text-success">{this.state.officeCandidate.email}</span>?
      </span>
    );
    var confirmText = <span>Depending if candidate already registered or not different email will be sent!</span>;
    var btnText = "Send";
    Confirm(confirmMainText, confirmText, "Cancel", btnText, () => this.onClickNotify());
  };

  render() {
    const scenario = this.state.candidateActiveScenario;
    const { loading } = this.props.scenarios;
    var content;

    if (!this.state.availableScenariosUpdated || !this.state.candidateActiveScenariosUpdated || loading) {
      content = <Spinner />;
    } else {
      var repositoryChangeBtn = "";
      if (this.state.repositoryGithub && this.state.repositoryGithub.name) {
      }
      if (
        this.state.repositoryCurrent !== this.state.repositoryOriginal ||
        (this.state.repositoryGithub && this.state.repositoryOriginal !== this.state.repositoryGithub.name)
      ) {
        repositoryChangeBtn = (
          <div className="form-element">
            <div className="submit-button">
              <Btn
                className="btn btn-primary "
                label={this.state.repositoryGithub && this.state.repositoryGithub.name ? "Change" : "Add"}
                onClick={(e) => this.changeRepo(e)}
                loading={this.state.participantRequestLoading}
              />
            </div>
          </div>
        );
      }
      var deadlineInput = "";
      if (this.state.deadline_switch) {
        deadlineInput = (
          <Input
            placeholder="Days"
            name={"deadlineSet"}
            validationMsg={this.state.errors.deadline}
            onChange={(e) => this.onChange(e)}
            value={this.state.deadlineSet}
          />
        );
      }
      var confirmSetScenarioBtn = "";
      if (
        parseInt(this.state.deadlineSet) !== scenario.days_to_finish ||
        (this.state.newScenarioId &&
          parseInt(this.state.newScenarioId) !== 0 &&
          parseInt(this.state.newScenarioId) !== scenario.scenarios.id)
      ) {
        confirmSetScenarioBtn = (
          <Btn className="btn btn-primary" label="Confirm" onClick={this.confirmScenarioChangeModal} loading={this.state.requestLoading} />
        );
      }
      content = (
        <div className="exam">
          <div className="exam-title">
            Current Exam Scenario {scenario && scenario.scenarios && scenario.scenarios.title ? ` - (${scenario.scenarios.title})` : ""}
          </div>
          <div className="exam-body">
            {scenario && scenario.scenarios && scenario.scenarios.scenario
              ? ReactHtmlParser(scenario && scenario.scenarios && scenario.scenarios.scenario)
              : "Not set"}
            <hr />
            Test Cases to Write:{" "}
            {scenario && scenario.scenarios && scenario.scenarios.testcase_count ? scenario.scenarios.testcase_count : "Not set"}
            <br />
            Test Cases to Automate:{" "}
            {scenario && scenario.scenarios && scenario.scenarios.automated_count ? scenario.scenarios.automated_count : "Not set"}
            <br />
            Issues to Report:{" "}
            {scenario && scenario.scenarios && scenario.scenarios.report_count ? scenario.scenarios.report_count : "Not set"}
            <br />
            Assigned: {moment(scenario.assigned_at).format("DD.MM.YYYY HH:mm:ss")}
            <br />
            {/* Deadline: {scenario.days_to_finish ? `${this.state.formattedDateToFinish}  (${scenario.days_to_finish} days)` : "Not set"} */}
            Deadline:{" "}
            {scenario.days_to_finish
              ? `${moment(scenario.assigned_at).add(scenario.days_to_finish, "days").format("DD.MM.YYYY HH:mm:ss")}  (${
                  scenario.days_to_finish
                } days)`
              : "Not set"}
          </div>

          <div className="exam-bottom">
            {this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
            this.state.userRoleOffice === roles.ADMINISTRATOR ||
            this.state.userRoleOffice === roles.RECRUITER ? (
              <div className="exam-bottom--item">
                <hr />
                <div className="exam-bottom--item-title">Scenarios:</div>
                <div className="exam-bottom--item-info">
                  You can change user scenario here and candidate will see change right away. Deadline 0 and empty fields will be ignored.
                </div>
                <div className="exam-bottom--item-value">
                  <div className="exam-buttons-grid">
                    <Dropdown
                      placeholder={"Scenarios"}
                      options={this.state.availableScenariosMapped}
                      label={""}
                      // noTitle={true}
                      validationMsg=""
                      name={"newScenarioId"}
                      onChange={(e) => this.onChange(e)}
                    />
                    <div>
                      <Switch
                        onChange={this.handleDeadlineSwitch}
                        value={this.state.deadline_switch}
                        id={"deadline_switch"}
                        name={"deadline_switch"}
                        label={"Deadline"}
                        className="mt-1"
                      />
                    </div>
                    <div> {deadlineInput}</div>
                    {confirmSetScenarioBtn}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
            this.state.userRoleOffice === roles.ADMINISTRATOR ||
            this.state.userRoleOffice === roles.RECRUITER ? (
              <div className="exam-bottom--item">
                <hr />
                <div className="exam-bottom--item-title">Repository:</div>
                <div className="exam-bottom--item-info">Repository to witch candidates will push their code for automation exam.</div>
                <div className="exam-bottom--item-value">
                  <div className="repository-flex">
                    <Input
                      placeholder="Repository"
                      name={"repositoryCurrent"}
                      validationMsg={this.state.errors.repository}
                      onChange={(e) => this.onChange(e)}
                      value={this.state.repositoryCurrent}
                    />
                    {repositoryChangeBtn}
                  </div>
                  <div>
                    {this.state.repositoryGithub && this.state.repositoryGithub.name ? (
                      <div className="repository">
                        <div className="repository-name">
                          <div className="repository-name-title">Name: </div>
                          <div className="repository-name-value">
                            {this.state.repositoryGithub && this.state.repositoryGithub.full_name
                              ? this.state.repositoryGithub.full_name
                              : ""}
                          </div>
                        </div>
                        <div className="repository-created">
                          <div className="repository-created-title">Created at: </div>
                          <div className="repository-created-value">
                            {this.state.repositoryGithub && this.state.repositoryGithub.created_at
                              ? this.state.repositoryGithub.created_at
                              : ""}
                          </div>
                        </div>
                        <div className="repository-updated">
                          <div className="repository-updated-title">Updated at: </div>
                          <div className="repository-updated-value">
                            {this.state.repositoryGithub && this.state.repositoryGithub.updated_at
                              ? this.state.repositoryGithub.updated_at
                              : ""}
                          </div>
                        </div>
                        <div className="repository-link">
                          {this.state.repositoryGithub && this.state.repositoryGithub.name ? (
                            <SmallButtonExternal
                              label={<i className="fas fa-external-link-alt"></i>}
                              link={`https://github.com/HTEC-interview/${this.state.repositoryGithub.name}`}
                              className={"btn-primary"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      "No or invalid repository set"
                    )}

                    <div></div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
            this.state.userRoleOffice === roles.ADMINISTRATOR ||
            this.state.userRoleOffice === roles.RECRUITER ? (
              <div className="exam-bottom--item">
                <hr />
                <div className="exam-bottom--item-title">Notification:</div>
                <div className="exam-bottom--item-info">
                  You can send email to candidate to notify him that he is able to login to QASandbox. In case that user never registered on
                  QASandbox "register" email will be sent and if candidate is already registered email will only notify user that he is able
                  to log in again. Send <b>ONLY ONCE</b> per exam unless candidate says he/she did not receive email the first time.
                </div>
                <div className="exam-bottom--item-value">
                  <div className="exam-buttons-grid-left">
                    <Btn
                      className="btn btn-light"
                      label="Notify"
                      onClick={() => this.confirmNotifyModal()}
                      loading={this.state.notifyLoading}
                    />
                  </div>
                </div>

                <div className="exam-bottom--item-notifications">
                  <br />
                  {this.state.scenario_notifications &&
                    this.state.scenario_notifications.map((notification, index) => (
                      <React.Fragment key={index}>
                        <div className="exam-bottom--item-notifications-row">
                          <div className="exam-bottom--item-notifications-row-item">
                            <div className="exam-bottom--item-notifications-row-item-title"> Sender:</div>
                            <div className="exam-bottom--item-notifications-row-item-value">
                              {notification.sender.first_name + " " + notification.sender.last_name} - {notification.sender.email}
                            </div>
                          </div>
                          <div className="exam-bottom--item-notifications-row-item">
                            <div className="exam-bottom--item-notifications-row-item-title"> Date:</div>
                            <div className="exam-bottom--item-notifications-row-item-value">
                              {moment(notification.date).format("DD.MM.YYYY HH:mm:ss")}
                            </div>
                          </div>
                          <div className="exam-bottom--item-notifications-row-item">
                            <div className="exam-bottom--item-notifications-row-item-title"> Notification:</div>
                            <div className="exam-bottom--item-notifications-row-item-value">
                              {notification.delivered ? (
                                <React.Fragment>
                                  Successfully sent <i className="fas fa-check-circle text-primary"></i>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  Notification failed <i className="fas fa-exclamation-circle text-danger"></i>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                          <div className="exam-bottom--item-notifications-row-item">
                            <div className="exam-bottom--item-notifications-row-item-title"> For scenario:</div>
                            <div className="exam-bottom--item-notifications-row-item-value"> {notification.scenario_title}</div>
                          </div>
                          <hr />
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
            this.state.userRoleOffice === roles.ADMINISTRATOR ||
            this.state.userRoleOffice === roles.RECRUITER ? (
              <div className="exam-bottom--item">
                <hr />
                <div className="exam-bottom--item-title">Participants:</div>
                <div className="exam-bottom--item-info">Set users that will participate in exam, reviewing or interviewing process.</div>
                <div className="exam-bottom--item-value">
                  <div>
                    <SearchDropdown
                      label={"Participants"}
                      placeholder={"Participants"}
                      options={this.state.officeUsersMapped}
                      value={this.state.examParticipantsMapped}
                      validationMsg={[this.state.errors.error]}
                      onChange={this.selectParticipant}
                      multiple={true}
                      numberDisplayed={1}
                    />
                    <div className="submit-button">
                      <Btn
                        className="btn btn-primary "
                        label="Submit"
                        onClick={(e) => this.submitForm(e)}
                        loading={this.state.participantRequestLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="exam-bottom--item">
                <div className="exam-bottom--item-title">Participants:</div>
                <div className="exam-bottom--item-info">Users that are set to participate in exam, reviewing or interviewing process.</div>
                <div className="exam-bottom--item-value">
                  {this.state.examParticipantsMapped.map((participant, index) => (
                    <div key={index}>{participant.title}</div>
                  ))}
                </div>
              </div>
            )}

            {this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
            this.state.userRoleOffice === roles.ADMINISTRATOR ||
            this.state.userRoleOffice === roles.RECRUITER ? (
              <div className="exam-bottom--item">
                <hr />
                <div className="exam-bottom--item-title">Deactivate:</div>
                <div className="exam-bottom--item-info">
                  Candidate can be deactivated here. Deactivated candidate can no longer log in to QASandbox. Deactivated candidate will
                  have exam status "Canceled(Before Time)" in case there is time left on exam, "Canceled(Timed Out)" if there is no time
                  left or Canceled(No Timer) if no deadline is set.
                </div>
                <div className="exam-bottom--item-value">
                  <div className="exam-buttons-grid-left">
                    {/* <Switch
                      onChange={this.confirmActivationStatusChangeModal}
                      value={this.state.active}
                      id={"active"}
                      name={"active"}
                      label={"Active"}
                      className="mt-1"
                    /> */}
                    <Btn
                      className="btn btn-danger"
                      label="Deactivate"
                      onClick={(e) => this.confirmActivationStatusChangeModal()}
                      loading={this.state.participantRequestLoading}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={
                this.props.candidates && this.props.candidates.officeCandidate
                  ? `${this.props.candidates.officeCandidate.first_name} ${this.props.candidates.officeCandidate.last_name} - Scenario`
                  : `Scenario`
              }
              link={`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`}
            />
            <div className="width-container">{content}</div>
            {this.state.confirmation}
            {this.state.resendUserCallback}
            {this.state.confirmationResend}
          </div>
        </div>
      </div>
    );
  }
}

ScenarioCurrent.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  candidates: state.candidates,
  exams: state.exams,
  users: state.users,
  scenarios: state.scenarios,
});

export default connect(mapStateToProps, {
  getCandidateActiveScenario,
  cancelCandidateExam,
  candidateAvailableScenarios,
  getOfficeCandidate,
  updateCandidateExamScenario,
  inviteCandidate,
  updateExamParticipants,
  getCandidateRepository,
  getExamParticipants,
  getOfficeUsers,
  updateCandidateRepository,
  clearErrors,
})(withRouter(ScenarioCurrent));
