import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import { Link } from "react-router-dom";
import SettingsAdminCard from "../../../components/Cards/SettingsAdminCard";
import Input from "../../../components/common/Input";
import Spinner from "../../../components/common/Spinner";
import Btn from "../../../components/common/Btn";
import { getAllOffices } from "../../../actions/officeActions";
import { accountType } from "../../../enums/accountType";

import { roles } from "../../../enums/roles";
import isEmpty from "../../../validation/isEmpty";

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;
class AdminOffices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      allOffices: this.props.offices && this.props.offices.allOffices,
      active: "",
      office_search: "",
      auth: {},
      errors: {},
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        if (!nextProps.auth.isAuthenticated || !accountType.USER) {
          nextProps.history.push("/");
        }
        if (
          (nextProps.auth.user.offices &&
            nextProps.auth.user.offices[0] &&
            nextProps.auth.user.offices[0].user_role_office.role.value !== roles.SUPERADMINISTRATOR) ||
          isEmpty(nextProps.auth.user.offices)
        ) {
          nextProps.history.push("/");
        }

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.offices.allOffices !== prevState.allOffices) {
        update.allOffices = nextProps.offices.allOffices;

        var allOffices = nextProps.offices.allOffices;
        var mappedallOffices = [];
        allOffices &&
          allOffices.map((office) => {
            return mappedallOffices.push(office);
          });
        update.allOfficesMapped = mappedallOffices;
        update.allOfficesUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    this.props.getAllOffices();
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.searchOffices();
    });
  }
  searchOffices() {
    var filters = {};
    if (!isEmpty(this.state.office_search)) {
      filters.office_search = this.state.office_search;
    }

    if (!isEmpty(this.state.role)) {
      filters.role = this.state.role;
    }

    if (!isEmpty(this.state.active) && !isNaN(this.state.active) && parseInt(this.state.active) !== 0) {
      if (parseInt(this.state.active) === 2) {
        filters.active = parseInt(this.state.active);
      } else if (parseInt(this.state.active) === 3) {
        filters.active = parseInt(this.state.active);
      }
    }
    if (Object.keys(filters).length > 0) {
      this.setState({ filters }, () => {});

      this.props.getAllOffices(filters);
    } else {
      this.props.getAllOffices();
    }
  }
  timer = null;
  handleChange = (e) => {
    clearTimeout(this.timer);
    this.setState({ office_search: e.target.value }, () => {});
    this.timer = setTimeout((timeout) => {
      this.triggerChange(timeout);
    }, WAIT_INTERVAL);
  };

  handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.triggerChange(e);
    }
  };

  clearFilters() {
    this.setState(
      {
        office_search: "",
        active: "",
        role: "",
      },
      () => {
        this.searchOffices();
      }
    );
  }
  triggerChange = (e) => {
    this.setState({ office_search: this.state.office_search }, () => {
      this.searchOffices();
    });
  };

  render() {
    const { loading } = this.props.offices;

    var content;
    if (!this.state.allOfficesUpdated || loading) {
      content = <Spinner />;
    } else {
      content = <SettingsAdminCard offices={this.state.allOfficesMapped} props={this.props} filters={this.state.filters} />;
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={"Offices"}
              link={`/admin/settings`}
              buttons={[
                <Link to={"/admin/offices/settings/new-office"} className="btn btn-primary">
                  Add Office
                </Link>,
              ]}
            />
            <div className="search-container">
              <div className="search-container-search">
                <Input
                  placeholder="Search offices..."
                  name={"office_search"}
                  noTitle={true}
                  onChange={(e) => this.handleChange(e)}
                  onKeyDown={this.handleKeyDown}
                  value={this.state.office_search}
                />
              </div>
              <div className="search-container-clear mb-1">
                <Btn label="Clear" className="btn btn-secondary" onClick={(e) => this.clearFilters()} />
              </div>
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}

AdminOffices.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  offices: state.offices,
});

export default connect(mapStateToProps, {
  getAllOffices,
  clearErrors,
})(withRouter(AdminOffices));
