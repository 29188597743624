import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "../../validation/isEmpty";

export default function Navigate({ title, buttons, link }) {
  var content;
  if (!isEmpty(link)) {
    content = (
      <div className='navigate'>
        <Link to={link} className='navigate-left'>
          <div className='navigate-left-btn btn'>
            <i className='fas fa-arrow-left'></i>
          </div>
          <div className='navigate-left-title'>{title}</div>
        </Link>
        <div className='navigate-right'>
          <div className='navigate-buttons'>
            {buttons && buttons.map((button, index) => <span key={index}>{button}</span>)}
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div className='navigate'>
        <div className='navigate-left'>
          <div className='navigate-left-title'>{title}</div>
        </div>
        <div className='navigate-right'>
          <div className='navigate-buttons'>
            {buttons && buttons.map((button, index) => <span key={index}>{button}</span>)}
          </div>
        </div>
      </div>
    );
  }
  return <div>{content}</div>;
}
