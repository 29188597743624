import React from "react";
import FormElementTitle from "../form/FormElementTitle";
// import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";
export default function TextAreaEditor({
  className,
  type,
  placeholder,
  label,
  validationMsg,
  value,
  onChange,
  name,
  onKeyDown,
  id,
  noMargin,
  editorState,
  noTitle,
}) {
  var marginValue = "form-element";

  if (noMargin) {
    marginValue = "full-width";
  }
  var validationId = "";
  if (validationMsg) {
    validationId = "validation-msg";
  }
  var title = (
    <div className="form-element-itemCol">
      <FormElementTitle label={label} validationMsg={validationMsg} validationId={validationId} />
    </div>
  );
  if (noTitle) {
    title = "";
  }
  return (
    <div className={marginValue}>
      {/* <div className="form-element-itemCol">
        <FormElementTitle label={label} validationMsg={validationMsg} />
      </div> */}
      {title}
      <div className="form-element-item">
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          toolbarClassName="demo-toolbar-custom"
          onEditorStateChange={onChange}
          placeholder={placeholder}
          toolbar={{
            options: ["inline", "blockType", "fontSize", "list", "textAlign", "fontFamily", "colorPicker", "emoji", "remove", "history"],
            fontFamily: {
              options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana", "IBM Plex Sans"],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            colorPicker: {
              // icon: color,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              colors: [
                "rgb(97,189,109)",
                "rgb(26,188,156)",
                "rgb(84,172,210)",
                "rgb(44,130,201)",
                "rgb(147,101,184)",
                "rgb(71,85,119)",
                "rgb(204,204,204)",
                "rgb(65,168,95)",
                "rgb(0,168,133)",
                "rgb(61,142,185)",
                "rgb(41,105,176)",
                "rgb(85,57,130)",
                "rgb(40,50,78)",
                "rgb(0,0,0)",
                "rgb(247,218,100)",
                "rgb(251,160,38)",
                "rgb(235,107,86)",
                "rgb(226,80,65)",
                "rgb(163,143,132)",
                "rgb(239,239,239)",
                "rgb(255,255,255)",
                "rgb(250,197,28)",
                "rgb(243,121,52)",
                "rgb(209,72,65)",
                "rgb(184,49,47)",
                "rgb(124,112,107)",
                "rgb(209,213,216)",
                "rgb(209,213,216,0)",
              ],
            },
          }}
        />
      </div>
    </div>
  );
}
