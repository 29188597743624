import React from "react";
import FormElementTitle from "../form/FormElementTitle";

function Textarea({ className, placeholder, label, validationMsg, value, onChange, name, onKeyDown, noTitle, noMargin }) {
  var marginValue = "form-element";

  if (noMargin) {
    marginValue = "full-width";
  }
  var validationId = "";
  if (validationMsg) {
    validationId = "validation-msg";
  }
  var title = (
    <div className="form-element-itemCol">
      <FormElementTitle label={label} validationMsg={validationMsg} validationId={validationId} />
    </div>
  );
  if (noTitle) {
    title = "";
  }
  return (
    <div className={marginValue}>
      {/* <div className="form-element-itemCol">
        <FormElementTitle label={label} validationMsg={validationMsg} />
      </div> */}
      {title}
      <div className="form-element-item">
        <textarea
          placeholder={placeholder}
          value={value}
          className="form-element--textarea"
          onChange={onChange}
          name={name}
          onKeyDown={onKeyDown}
        />
      </div>
      {/* <div className="form-element-item">
        <label className="form-element--validation">{validationMsg}</label>
      </div> */}
    </div>
  );
}

export default Textarea;
