import axios from "axios";

import { GET_CANDIDATE_PROJECTS, PROJECT_LOADING, CLEAR_PROJECTS, REMOVE_LOADING, GET_ERRORS } from "./types";

// Get All Office Candidate projects
export const getCandidateProjects = (candidateId, scenarioId) => (dispatch) => {
  dispatch(projectLoading());

  var url = `/api/candidates/candidate/${candidateId}/projects`;
  if (scenarioId) {
    url = `/api/candidates/candidate/${candidateId}/projects?scenario_id=${scenarioId}`;
  }

  axios
    .get(url)
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_PROJECTS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Clear Projects
export const clearProjects = () => {
  return {
    type: CLEAR_PROJECTS,
  };
};

// Project loading
export const projectLoading = () => {
  return {
    type: PROJECT_LOADING,
  };
};
