import axios from "axios";

import { GET_CANDIDATE_TESTCASES, GET_CANDIDATE_TESTCASE, TESTCASE_LOADING, CLEAR_TESTCASES, REMOVE_LOADING, GET_ERRORS } from "../types";

// Get All Office Candidate testcases
export const getCandidateTestcases = () => (dispatch) => {
  axios
    .get("/api/candidate/testcases")
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_TESTCASES,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Office Candidate testcase
export const getCandidateTestcase = (testcaseId) => (dispatch) => {
  axios
    .get(`/api/candidate/testcases/${testcaseId}`)
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_TESTCASE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Update Test case
export const updateTestcase = (testcaseId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidate/testcases/${testcaseId}`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Create Test case
export const createTestcase = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/testcases`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Remove Test case
export const removeTestcase = (testcaseId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/testcases/${testcaseId}`)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Clear Testcases
export const clearTestcases = () => {
  return {
    type: CLEAR_TESTCASES,
  };
};

// Testcase loading
export const testcaseLoading = () => {
  return {
    type: TESTCASE_LOADING,
  };
};
