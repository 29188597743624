import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import SideMenu from "../../../components/layout/SideMenu";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import Input from "../../../components/common/Input";
import Spinner from "../../../components/common/Spinner";
import SearchDropdown from "../../../components/common/SearchDropdown";
import Btn from "../../../components/common/Btn";
import { getOfficeCandidate, updateCandidate, uploadCandidateProfilePhoto } from "../../../actions/candidateActions";
import { getAllOffices } from "../../../actions/officeActions";
import imgPlaceholder from "../../../img/noImg.png";
import AddCandidateValidation from "../../../validation/AddCandidateValidation";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import ReactCrop from "react-image-crop";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";
import "react-image-crop/dist/ReactCrop.css";
import loadingImg from "../../../img/imgLoader.gif";
import isEmpty from "../../../validation/isEmpty";
import Datepicker from "../../../components/common/Datepicker";
import moment from "moment";

class EditCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      src: null,
      hired: false,
      crop: {
        unit: "%",
        width: 50,
        aspect: 10 / 10,
      },
      user: {},
      officeCandidate: this.props.candidates && this.props.candidates.officeCandidate,
      careerStatuses: this.props.careerStatuses && this.props.careerStatuses.careerStatuses,
      allOffices: this.props.offices && this.props.offices.allOffices,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
      mounted: false,
      uploading: false,
      requestLoading: false,
      candidateMapped: null,
      showDatepicker: false,
      activeDate: "",
      citiesMapped: null,
      auth: {},
      errors: {},
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangePhoto = this.onChangePhoto.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectOffice = this.selectOffice.bind(this);
    this.handleHiredSwitch = this.handleHiredSwitch.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        const officeRoleObject =
          nextProps.auth &&
          nextProps.auth.user &&
          nextProps.auth.user.offices &&
          nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));

        var userRoleOffice =
          officeRoleObject &&
          officeRoleObject[0] &&
          officeRoleObject[0].user_role_office &&
          officeRoleObject[0].user_role_office.role.value;

        update.userRoleOffice = userRoleOffice;
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/candidates/${nextProps.match.params.candidateId}`,
          roles_allowed_to_write: prevState.rolesAllowedToOpen,
          page_type: pageType.WRITE,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      var mappedOffices = [];
      if (nextProps.offices.allOffices !== prevState.allOffices) {
        update.allOffices = nextProps.offices.allOffices;

        nextProps.offices.allOffices.map((office) => {
          mappedOffices.push({ id: office.id, title: office.value });
          return null;
        });
        update.officesMapped = mappedOffices;
        update.mappedOffices = true;
      }

      if (nextProps.candidates.officeCandidate !== prevState.officeCandidate) {
        update.officeCandidate = nextProps.candidates.officeCandidate;

        if (prevState.initialRender) {
          update.officeCandidate = nextProps.candidates.officeCandidate;
          update.first_name = nextProps.candidates.officeCandidate.first_name ? nextProps.candidates.officeCandidate.first_name : "";
          update.last_name = nextProps.candidates.officeCandidate.last_name ? nextProps.candidates.officeCandidate.last_name : "";
          update.email = nextProps.candidates.officeCandidate.email ? nextProps.candidates.officeCandidate.email : "";
          update.selectedDate = nextProps.candidates.officeCandidate.hired ? nextProps.candidates.officeCandidate.hired : false;
          update.formattedDate = nextProps.candidates.officeCandidate.hired
            ? moment(nextProps.candidates.officeCandidate.hired).format("YYYY-MM-DD HH:mm:ss")
            : null;
          update.career_status_id = nextProps.candidates.officeCandidate.career_status_id
            ? nextProps.candidates.officeCandidate.career_status_id
            : 0;
          var selectedOfficeMapped = null;
          if (nextProps.candidates.officeCandidate.offices) {
            selectedOfficeMapped = {};
            selectedOfficeMapped.id = nextProps.candidates.officeCandidate.offices.id;
            selectedOfficeMapped.title = nextProps.candidates.officeCandidate.offices.value;
          }
          update.initialOffice = selectedOfficeMapped;
          update.office = selectedOfficeMapped;
          update.biography = nextProps.candidates.officeCandidate.biography ? nextProps.candidates.officeCandidate.biography : "";

          update.github_username = nextProps.candidates.officeCandidate.github_username
            ? nextProps.candidates.officeCandidate.github_username
            : "";
          update.initialRender = false;
        }

        update.img = nextProps.candidates.officeCandidate.img ? nextProps.candidates.officeCandidate.img : "";
        update.candidateMapped = nextProps.candidates.officeCandidate;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  selectOffice(value) {
    var officeChanged = false;
    if (value.id !== this.state.initialOffice.id) {
      officeChanged = true;
    }
    this.setState({ office: value, officeChanged }, () => {
      this.checkValidation();
    });
  }

  componentDidMount() {
    this.IsMounted = true;
    this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {});
    this.props.getAllOffices();
  }

  componentWillUnmount() {
    this.IsMounted = false;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }

  checkValidation() {
    this.props.clearErrors();
    var formData = {};
    formData.first_name = this.state.first_name && this.state.first_name.length > 0 ? this.state.first_name.trim() : null;
    formData.last_name = this.state.last_name && this.state.last_name.length > 0 ? this.state.last_name.trim() : null;
    formData.email = this.state.email && this.state.email.length > 0 ? this.state.email.trim() : null;
    if (this.state.office.id && !isNaN(this.state.office.id) && this.state.office.id > 0) {
      formData.office_id = parseInt(this.state.office.id);
    } else {
      formData.office_id = null;
    }

    var { errors } = AddCandidateValidation(formData);

    this.setState({ errors });
  }

  submitForm(e) {
    e.preventDefault();
    var formData = {};
    formData.first_name = this.state.first_name && this.state.first_name.length > 0 ? this.state.first_name.trim() : null;
    formData.last_name = this.state.last_name && this.state.last_name.length > 0 ? this.state.last_name.trim() : null;
    formData.email = this.state.email && this.state.email.length > 0 ? this.state.email.trim() : null;
    formData.hired = this.state.formattedDate ? this.state.formattedDate : null;
    if (this.state.office.id && !isNaN(this.state.office.id) && this.state.office.id > 0) {
      formData.office_id = parseInt(this.state.office.id);
    } else {
      formData.office_id = null;
    }

    if (this.props.match.params.candidateId && !isNaN(this.props.match.params.candidateId) && this.props.match.params.candidateId > 0) {
      formData.candidate_id = parseInt(this.props.match.params.candidateId);
    } else {
      formData.candidate_id = null;
    }

    if (this.props.match.params.officeId && !isNaN(this.props.match.params.officeId) && this.props.match.params.officeId > 0) {
      formData.previous_office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.previous_office_id = null;
    }

    const { errors, isValid } = AddCandidateValidation(formData);

    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.updateCandidate(formData, (res) => {
        if (res.status === 200) {
          successToast("Candidate updated successfully");
          if (this.state.officeChanged) {
            this.props.history.push(`/admin/${this.props.match.params.officeId}/candidates`);
          } else {
            this.props.history.push(`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`);
          }
        } else {
          this.setState({ requestLoading: false });
          failToast("Candidate edit failed");
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  handleHiredSwitch(value) {
    this.setState({ hired: value });
  }

  onChangePhoto(e) {
    this.setState({ file: e.target.files[0] }, () => {
      this.onFormSubmit(e);
    });
  }
  setFromDate(day) {
    var date = moment(new Date(day)).add(14, "hours").format("YYYY-MM-DD");

    var dateToCompare = moment(new Date(day)).format("YYYY-MM-DD");
    if (moment(new Date(this.state.selectedDate)).format("YYYY-MM-DD") === dateToCompare) {
      this.setState({ selectedDate: null, formattedDate: null, activeDate: null }, () => {});
    } else {
      var formattedDate = moment(day).add(14, "hours").format("YYYY-MM-DD HH:mm:ss");
      this.setState({ selectedDate: date, formattedDate, activeDate: date }, () => {});
    }
  }
  onFormSubmit(e) {
    this.setState({ uploading: true });
    const formData = new FormData();
    formData.append("file", this.state.file);
    const config = {
      headers: {
        "content-type": "image/xyz",
      },
    };

    this.props.uploadCandidateProfilePhoto(
      this.props.match.params.candidateId,
      this.props.match.params.officeId,
      formData,
      config,
      (res) => {
        if (res.status === 200) {
          successToast("Photo successfully uploaded");
          this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {
            if (this.IsMounted) {
              this.setState({
                uploading: false,
                src: null,
                croppedImageUrl: null,
                crop: {
                  unit: "%",
                  width: 100,
                  aspect: 10 / 10,
                },
              });
            }
          });
        } else if (res.status === undefined) {
          failToast(`Photo upload failed! Try different format or smaller image`);
          if (this.IsMounted) {
            this.setState({ uploading: false });
          }
        }
        if (this.IsMounted) {
          this.setState({ uploading: false });
        }
      }
    );
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].type === "image/jpeg") {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
          crop: {
            unit: "%",
            width: 100,
            aspect: 10 / 10,
          },
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.setState({ croppedImageUrl }, () => {});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);

        function blobToFile(theBlob, fileName) {
          theBlob.lastModifiedDate = new Date();
          theBlob.name = fileName;
          return theBlob;
        }
        var newFile = blobToFile(blob, "name.png");

        var newfile = new File([newFile], "name.png", { type: "image/png" });
        this.setState({ file: newfile });
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const { loading } = this.props.candidates;
    const { crop, src } = this.state;

    var content = "";
    var userImage = "";
    if (this.state.img === null) {
      userImage = imgPlaceholder;
    } else {
      userImage = this.state.img;
    }

    if (this.state.candidateMapped === null || !this.state.mappedOffices || loading) {
      content = <Spinner />;
    } else {
      if (isEmpty(this.state.officeCandidate)) {
        content = <div>There is no candidate with this ID</div>;
      } else {
        var imgContent = "";
        if (this.state.uploading) {
          imgContent = (
            <div>
              <img src={loadingImg} style={{ width: "150px", height: "150px", margin: "auto", display: "block" }} alt="Loading..." />
            </div>
          );
        } else {
          imgContent = (
            <img
              className="card-img-top rounded-circle img-upload"
              src={this.state.croppedImageUrl ? this.state.croppedImageUrl : userImage}
              alt=""
              onError={(e) => {
                e.preventDefault();
                e.target.onerror = null;
                e.target.src = imgPlaceholder;
              }}
            />
          );
        }

        var rightContent;
        if (this.state.src === null) {
          rightContent = (
            <div className="profile-top-right">
              <div className="profile-top-right--item">
                <Input
                  placeholder={"First Name*"}
                  type="text"
                  onChange={(e) => this.onChange(e)}
                  name={"first_name"}
                  label="First Name*"
                  value={this.state.first_name}
                  validationMsg={this.state.errors.first_name}
                />
              </div>
              <div className="profile-top-right--item">
                <Input
                  placeholder={"Last Name*"}
                  type="text"
                  onChange={(e) => this.onChange(e)}
                  name={"last_name"}
                  label="Last Name*"
                  value={this.state.last_name}
                  validationMsg={this.state.errors.last_name}
                />
              </div>

              <div className="profile-top-right--item">
                <Input
                  placeholder={"Email*"}
                  type="text"
                  onChange={(e) => this.onChange(e)}
                  name={"email"}
                  label="Email*"
                  value={this.state.email}
                  validationMsg={[this.state.errors.email, this.props.errors.email]}
                />
              </div>

              <div className="profile-top-right--item">
                <SearchDropdown
                  label={"Office*"}
                  placeholder={"Office"}
                  value={this.state.office}
                  name={"office_id"}
                  validationMsg={this.state.errors.office_id}
                  onChange={this.selectOffice}
                  multiple={false}
                  options={this.state.officesMapped}
                  isRequired={true}
                />
              </div>
              <div className="profile-top-right--item">
                <Datepicker
                  forwardRef={(node) => (this.node = node)}
                  showdatepicker={this.state.showDatepicker}
                  placeholder={"Select Date"}
                  label={"Hired"}
                  selectedDate={this.state.selectedDate ? moment(new Date(this.state.selectedDate)).format("DD-MMMM-YYYY") : ""}
                  onClick={(e) => this.setState({ showDatepicker: !this.state.showDatepicker })}
                  onChange={(e) => this.setState({ showDatepicker: !this.state.showDatepicker })}
                  active={this.state.activeDate ? this.state.activeDate !== null : ""}
                  timestamp={this.state.selectedDate ? moment(new Date(this.state.selectedDate))._d : null}
                  onDayClick={(day) => {
                    this.setFromDate(day);
                    this.setState({ showDatepicker: false });
                  }}
                />
              </div>
            </div>
          );
          var bottomContent = (
            <div className="profile-buttons">
              <div className="profile-buttons-left"></div>
              <div className="profile-buttons-right">
                <Btn className="btn btn-primary" label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
              </div>
            </div>
          );
        } else {
          rightContent = (
            <div className="candidate-top-right">
              <div className="upload-image-container">
                <div className="upload-image-container-img">
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </div>
                <div className="upload-image-container-btns">
                  <Btn
                    label={"Cancel"}
                    className="btn btn-secondary"
                    onClick={() =>
                      this.setState({
                        src: null,
                        croppedImageUrl: null,
                        crop: {
                          unit: "%",
                          width: 100,
                          aspect: 10 / 10,
                        },
                      })
                    }
                  />
                  <Btn label={"Upload"} className="btn btn-primary" onClick={this.onFormSubmit} />
                </div>
              </div>
            </div>
          );
        }
        content = (
          <div className="profile width-container">
            <div className="profile-top">
              <div className="profile-top-left">
                <div className="profile-top-left-img">
                  <form onSubmit={this.onFormSubmit}>
                    <div className="fixed-ratio-div">
                      <label className="rounded-circle">
                        <input
                          className="centered-image rounded-circle"
                          type="file"
                          name="image"
                          accept=".gif,.jpg,.jpeg,.png,.tiff,.webp"
                          onChange={this.onSelectFile}
                          value={""}
                        />
                        {imgContent}
                      </label>
                    </div>
                  </form>
                </div>
              </div>
              {rightContent}
            </div>
            {bottomContent}
          </div>
        );
      }
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={"Edit Candidate"}
              link={`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}`}
            />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

EditCandidate.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  candidates: state.candidates,
  offices: state.offices,
});

export default connect(mapStateToProps, {
  getOfficeCandidate,
  getAllOffices,
  updateCandidate,
  uploadCandidateProfilePhoto,
  clearErrors,
})(withRouter(EditCandidate));
