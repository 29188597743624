import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import Input from "../../components/common/Input";
import Textarea from "../../components/common/Textarea";
import Btn from "../../components/common/Btn";
import Spinner from "../../components/common/Spinner";
import InputGroup from "../../components/common/InputGroup";
import Switch from "../../components/common/SwitchComponent";
import FullBtn from "../../components/common/FullBtn";
import TestCaseValidation from "../../validation/TestcaseValidation";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import Confirm from "../../components/common/Confirm";
import scrollToError from "../../utility/scrollToError";
import { getCandidateTestcase, updateTestcase, removeTestcase } from "../../actions/candidate/testcaseActions";
import { clearErrors } from "../../actions/errorsActions";

class EditTestcase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      auth: {},
      testcase: this.props.testcases && this.props.testcases.testcase,
      title: "",
      description: "",
      expected_result: "",
      automated: false,
      testcaseMapped: null,
      test_steps: [{ id: 1, value: "" }],
      maxSteps: 50,
      requestLoading: false,
      errors: {},
    };
    this.handleChangeAutomated = this.handleChangeAutomated.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.testcases.testcase !== prevState.testcase) {
        var { testcase } = nextProps.testcases;
        update.testcase = testcase;
        update.testcaseMapped = testcase;
        update.title = testcase.title ? testcase.title : "";
        update.description = testcase.description ? testcase.description : "";
        update.expected_result = testcase.expected_result ? testcase.expected_result : "";
        update.test_steps = testcase.test_steps ? testcase.test_steps : [];
        update.automated = testcase.automated ? testcase.automated : false;
        update.candidate_scenario_id = testcase.candidate_scenario_id ? testcase.candidate_scenario_id : "";
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getCandidateTestcase(this.props.match.params.testcaseId);
  }
  componentWillUnmount() {
    this.props.clearErrors();
  }

  onChange(e) {
    this.props.clearErrors();
    if (e.target.id.substring(0, 4) === "step") {
      var enteredTestSteps = this.state.test_steps;
      enteredTestSteps[e.target.name.substring(5)].value = e.target.value;
      this.setState({ test_steps: enteredTestSteps }, () => {
        this.checkValidation();
      });
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {
        this.checkValidation();
      });
    }
  }
  handleChangeAutomated(automated) {
    this.setState({ automated });
  }

  addColumnStep(e) {
    var test_steps = this.state.test_steps;
    const max = test_steps.length > 0 && test_steps.reduce((prev, current) => (prev.id > current.id ? prev : current));

    test_steps.push({ id: max.id ? max.id + 1 : 1, value: "" });
    this.setState({ test_steps }, () => {
      this.checkValidation();
    });
  }

  checkValidation() {
    var formData = {};
    formData.title = this.state.title && this.state.title.length > 0 ? this.state.title.trim(): null;
    formData.description = this.state.description && this.state.description.length > 0 ? this.state.description.trim(): null;
    formData.expected_result = this.state.expected_result && this.state.expected_result.length > 0 ? this.state.expected_result.trim(): null;
    formData.test_steps = this.state.test_steps;
    formData.automated = this.state.automated;

    var { errors } = TestCaseValidation(formData);
    this.setState({ errors });
  }

  onSubmit() {
    this.checkValidation();

    var formData = {};
    formData.title = this.state.title.trim();
    formData.description = this.state.description.trim();
    formData.expected_result = this.state.expected_result.trim();
    var test_steps = this.state.test_steps;
    test_steps = test_steps.filter((el) => el.value.trim() !== "");
    var trimedSteps = [];
    test_steps.map((step) => {
      trimedSteps.push({ id: step.id, value: step.value.trim() });
      return null;
    });
    formData.test_steps = trimedSteps;
    formData.automated = this.state.automated;
    formData.candidate_scenario_id = this.state.candidate_scenario_id;
    formData.testcaseId =
      this.props.match.params.testcaseId && !isNaN(this.props.match.params.testcaseId)
        ? parseInt(this.props.match.params.testcaseId)
        : null;
    const { errors, isValid } = TestCaseValidation(formData);

    if (isValid) {
      this.setState({requestLoading: true})
      this.props.updateTestcase(this.props.match.params.testcaseId, formData, (res) => {
       
        if (res.status === 200) {
          successToast("Test case updated successfully");
          this.props.history.push(`/testcases`);
        } else {
          this.setState({requestLoading: false})
          scrollToError();
          failToast("Test case edit failed");
          this.props.history.push(`/edit-testcase/${this.props.match.params.testcaseId}`);
        }
      });
    } else {
      this.setState({ errors },()=>{
        scrollToError();
      });
    }
  }

  removeColumnStep(e) {
    var indexToRemove = e.target.id.substring(5);
    var stepErrors = this.state.errors.stepErrors;
    var errors = this.state.errors;
    stepErrors =
      stepErrors &&
      stepErrors.filter(function (obj) {
        return obj.id !== indexToRemove;
      });

    errors.stepErrors = stepErrors;
    var test_steps = this.state.test_steps;

    test_steps.splice(indexToRemove, 1);
    this.setState({ test_steps, errors }, () => {
      this.checkValidation();
    });
  }

  orderUp(e) {
    if (e.target.id > 0) {
      var changingIndex = parseInt(e.target.id);
      var test_steps = this.state.test_steps;
      var first = test_steps[changingIndex].id;
      var second = test_steps[changingIndex - 1].id;

      test_steps[changingIndex].id = second;
      test_steps[changingIndex - 1].id = first;

      var sortedTestSteps = test_steps.sort(function (a, b) {
        return a.id - b.id;
      });
      this.setState({ sortedTestSteps }, () => {
        this.checkValidation();
      });
    }
  }

  orderDown(e) {
    if (e.target.id < this.state.test_steps.length - 1) {
      var changingIndex = parseInt(e.target.id);
      var test_steps = this.state.test_steps;
      var first = test_steps[changingIndex].id;
      var second = test_steps[changingIndex + 1].id;

      test_steps[changingIndex].id = second;
      test_steps[changingIndex + 1].id = first;

      var sortedTestSteps = test_steps.sort(function (a, b) {
        return a.id - b.id;
      });
      this.setState({ sortedTestSteps }, () => {
        this.checkValidation();
      });
    }
  }

  confirmRemoveTestcase = () => {
    this.props.removeTestcase(this.props.match.params.testcaseId, (res) => {
      if (res.status === 200) {
        successToast("Test case removed successfully");
        this.props.history.push(`/testcases`);
      } else {
        failToast("Test case remove failed");
        this.props.history.push(`/edit-testcase/${this.props.match.params.testcaseId}`);
      }
    });
  };
  confirmRemoveTestcaseModal = () => {
    var mainText = "Remove this test case?";
    var subText = "";
    var btnText = "Remove";

    Confirm(mainText, subText, "Cancel", btnText, () => this.confirmRemoveTestcase());
  };

  render() {
    const { loading } = this.props.testcases;
    var content = "";
    if (this.state.testcaseMapped === null || loading) {
      content = <Spinner />;
    } else {
      content = (
        <div className='width-container default-container default-padding'>
          <Input
            placeholder={"Title"}
            type='text'
            value={this.state.title}
            onChange={(e) => this.onChange(e)}
            name={"title"}
            label='Title*'
            validationMsg={[this.state.errors.title, this.props.errors.title]}
          />
          <Textarea
            placeholder={"Description"}
            type='text'
            value={this.state.description}
            onChange={(e) => this.onChange(e)}
            name={"description"}
            label='Description'
            validationMsg={this.state.errors.description}
          />
          <Input
            placeholder={"Expected Result"}
            type='text'
            value={this.state.expected_result}
            onChange={(e) => this.onChange(e)}
            name={"expected_result"}
            label='Expected Result*'
            validationMsg={this.state.errors.expected_result}
          />
          <InputGroup
            type='text'
            placeholder={"Test step"}
            label='Test steps*'
            validationMsg={this.state.errors.test_steps}
            validationMsg2={this.state.errors.stepErrors}
            values={this.state.test_steps}
            keys={"value"}
            onChange={(e) => this.onChange(e)}
            id={"step"}
            addColumn={
              <FullBtn
                placeholder={
                  this.state.test_steps && this.state.test_steps.length >= this.state.maxSteps
                    ? `Max steps (${this.state.maxSteps})`
                    : "Add Test Step"
                }
                disabled={this.state.test_steps && this.state.test_steps.length >= this.state.maxSteps ? true : false}
                onClick={(e) => this.addColumnStep(e)}
              />
            }
            removeColumn={(e) => this.removeColumnStep(e)}
            required={true}
            onKeyDown={this.submitFormOnEnterKey}
            orderUp={(e) => this.orderUp(e)}
            orderDown={(e) => this.orderDown(e)}
          />
          <Switch
            onChange={this.handleChangeAutomated}
            value={this.state.automated}
            id={"automated"}
            name={"automated"}
            label={"Automated"}
          />
          <div className='submit-button'>
            <Btn className='btn btn-primary float-right' label='Submit' onClick={(e) => this.onSubmit(e)}  loading={this.state.requestLoading} />
          </div>
        </div>
      );
    }

    return (
      <div className='grid-menu-container'>
        <div className='menu-grid'>
          <div className='menu'>
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className='main-grid'>
          <div className='main'>
            <div className='width-container'>
              <Navigate
                title={"Edit Test Case"}
                link={"/testcases"}
                buttons={[
                  <Btn
                    className='btn btn-danger'
                    label={<i className='far fa-trash-alt'></i>}
                    onClick={(e) => this.confirmRemoveTestcaseModal(e)}
                  />,
                ]}
              />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditTestcase.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  testcases: state.testcases,
});

export default connect(mapStateToProps, { getCandidateTestcase, updateTestcase, removeTestcase, clearErrors })(
  withRouter(EditTestcase)
);
