import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { pageType } from "../../enums/pageType";
// import { roles } from "../../enums/roles";
// import CheckPermissions from "../../validation/CheckPermissions";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import SmallButton from "../common/SmallButton";
import Confirm from "../common/Confirm";
import Input from "../common/Input";
import Textarea from "../common/Textarea";
import Btn from "../common/Btn";
import CommentValidation from "../../validation/CommentValidation";
import NewCommentValidation from "../../validation/NewCommentValidation";
import {
  getCandidateComments,
  updateCandidateComment,
  createCandidateComment,
  removeCandidateComment,
  clearComments,
} from "../../actions/commentActions";
import moment from "moment";
import linkify from "../../utility/linkify";

class CandidateCommentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      initialRender: true,
      requestLoading: false,
      mounted: false,
      auth: {},
      comments: this.props.comments && this.props.comments.comments,
      comment: null,
      editCommentId: null,
      commentChanged: false,
      new_comment: "",
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.comments.comments !== prevState.comments) {
        update.comments = nextProps.comments.comments;

        var comments = nextProps.comments.comments;
        update.comments = comments;
        update.initialRender = false;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.IsMounted = true;
    this.props.getCandidateComments(this.props.match.params.candidateId);
  }
  handleClick = (e) => {
    this.inputElement.click();
  };
  componentWillUnmount() {
    this.IsMounted = false;
    this.props.clearComments();
  }
  onEditClick(e) {
    this.setState({ editCommentId: e });
  }

  onCancel() {
    this.setState({ confirmationResend: null });
  }

  checkValidation(comment, commentId) {
    this.props.clearErrors();

    var formData = {};
    if (this.state.comment === null) {
      formData.comment = comment;
    } else {
      formData.comment = this.state.comment;
    }
    formData.comment_id = commentId;
    formData.user_id = this.props.auth.user.id;
    formData.candidate_id = this.props.match.params.candidateId;

    const { errors } = CommentValidation(formData);

    this.setState({ errors });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState({ commentChanged: true });
    });
  }

  confirmEditComment(e, commentId, comment) {
    this.handleClick();
    e.preventDefault();
    var formData = {};
    if (this.state.comment === null) {
      formData.comment = comment;
    } else {
      formData.comment = this.state.comment;
    }
    formData.comment_id = commentId;
    formData.user_id = this.props.auth.user.id;
    formData.candidate_id =
      this.props.match.params.candidateId && !isNaN(this.props.match.params.candidateId)
        ? parseInt(this.props.match.params.candidateId)
        : null;

    const { errors, isValid } = CommentValidation(formData);
    if (isValid) {
      this.props.updateCandidateComment(commentId, formData, (res) => {
        if (res.status === 200) {
          successToast("Comment updated successfully");
          this.props.getCandidateComments(this.props.match.params.candidateId);
        } else {
          failToast("Comment edit failed");
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  createComment(e) {
    e.preventDefault();
    var formData = {};

    formData.comment = this.state.new_comment;
    formData.user_id = this.props.auth.user.id;
    formData.candidate_id =
      this.props.match.params.candidateId && !isNaN(this.props.match.params.candidateId)
        ? parseInt(this.props.match.params.candidateId)
        : null;

    const { errors, isValid } = NewCommentValidation(formData);
    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.createCandidateComment(formData, (res) => {
        this.setState({ new_comment: "" });
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Comment added successfully");
          this.props.getCandidateComments(this.props.match.params.candidateId);
        } else {
          this.setState({ requestLoading: false });
          failToast("Comment adding failed");
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  confirmCommentRemoveModal = (commentId) => {
    var confirmMainText = "Remove this comment?";
    var confirmText = "";
    var btnText = "Remove";
    Confirm(confirmMainText, confirmText, "Cancel", btnText, () => this.confirmCommentRemove(commentId));
  };
  confirmCommentRemove = (commentId) => {
    this.props.removeCandidateComment(commentId, (res) => {
      if (res.status === 200) {
        this.setState({ new_comment: "" });
        this.props.getCandidateComments(this.props.match.params.candidateId);
        successToast("Comment removed successfully");
      } else {
        failToast(`Comment removing failed`);
      }
    });
  };

  render() {
    var { comments } = this.state;
    var author = this.props.auth.user;
    var content = "";
    var editDisabled = "";
    if (!this.state.commentChanged) {
      editDisabled = "disabled";
    }
    if (comments && comments.length > 0) {
      content = comments.map((comment, index) => (
        <span key={index}>
          <div className="candidate-bottom--item-value-comments--item">
            <div className="candidate-bottom--item-value-comments--item-title">
              {`${comment.user.first_name} ${comment.user.last_name}`}:{" "}
            </div>
            <div className="candidate-bottom--item-value-comments--item-value">{linkify(comment.comment)}</div>

            <div id={`myModal-${index}`} className="modal fade" role="dialog" aria-hidden="true" aria-modal="false">
              <div className="modal-dialog">
                {/* <!-- Modal content--> */}
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-titles">Comment</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="preview-card-modal">
                      <div className="preview-card-modal-title">
                        <div className="preview-card-modal-title-value">{comment.comment}</div>
                      </div>

                      <div className="preview-card-modal-body">
                        <div className="preview-card-modal-body--items">
                          <div className="preview-card-modal-body--items-single-title">New comment value:</div>
                          <div>
                            <Textarea
                              placeholder={"Comment"}
                              noTitle={true}
                              validationMsg={this.state.errors.comment}
                              name="comment"
                              onChange={(e) => this.onChange(e, comment.id, comment.comment)}
                              value={this.state.comment !== null ? this.state.comment : comment.comment}
                            />
                          </div>
                        </div>
                        <div className="default-flex">
                          <div></div>
                          <Btn
                            label={"Edit"}
                            className={`btn btn-primary ${editDisabled}`}
                            onClick={(e) => this.confirmEditComment(e, comment.id, comment.comment)}
                            // ref={(input) => (this.inputElement = input)}
                            // onClick={this.handleClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal" ref={(input) => (this.inputElement = input)}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="candidate-bottom--item-value-comments--item">
            <div className="candidate-bottom--item-value-comments--item-title">Created: </div>
            <div className="candidate-bottom--item-value-comments--item-value">
              {moment(comment.created_at).format("DD.MM.YYYY HH:mm:ss")}{" "}
            </div>
          </div>
          <div className="candidate-bottom--item-value-comments--item">
            <div className="candidate-bottom--item-value-comments--item-title">Updated: </div>
            <div className="candidate-bottom--item-value-comments--item-value">
              {comment.updated_at ? moment(comment.updated_at).format("DD.MM.YYYY HH:mm:ss") : "/"}{" "}
            </div>
          </div>
          {!this.state.editCommentId && comment.user && author && comment.user.id === author.id ? (
            <div className="default-flex">
              <div></div>
              <div className="candidate-bottom--item-value-comments--item-buttons">
                <div
                  className="candidate-bottom--item-value-comments--item-value"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ comment: null, errors: {}, commentChanged: false });
                  }}
                  data-toggle="modal"
                  data-target={`#myModal-${index}`}
                >
                  <SmallButton label={<i className="fas fa-pencil-alt"></i>} link={"#"} className={"btn-primary"} />
                </div>
                <div>
                  <SmallButton
                    label={<i className="fas fa-trash-alt"></i>}
                    link={"#"}
                    className={"btn-danger"}
                    onClick={() => this.confirmCommentRemoveModal(comment.id)}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <hr
            style={{
              color: "#eee",
              width: "100%",
              textAlign: "center",
            }}
          />
        </span>
      ));
    } else {
      content = <div className="no-content">No comments added for this candidate</div>;
    }
    return (
      <div className="candidate-bottom--item">
        <div className="candidate-bottom--item-title">Comments:</div>
        <div className="candidate-bottom--item-info">
          Comments about this perticular candidate from interview, meeting or just general reminders.
        </div>
        {/* <hr /> */}
        <div className="candidate-bottom--item-value">
          <div className="candidate-bottom--item-value-comments">
            {content}
            <div className="candidate-bottom--item-value-comments--item">
              <Input
                placeholder={"Comment"}
                // noTitle={true}
                name={"new_comment"}
                value={this.state.new_comment}
                validationMsg={this.state.errors.new_comment}
                onChange={(e) => this.onChange(e)}
              />
              <div className="submit-button">
                <Btn
                  label={"Submit"}
                  className="btn btn-primary"
                  onClick={(e) => this.createComment(e)}
                  loading={this.state.requestLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CandidateCommentContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  comments: state.comments,
});

export default connect(mapStateToProps, {
  getCandidateComments,
  clearComments,
  updateCandidateComment,
  createCandidateComment,
  removeCandidateComment,
  clearErrors,
})(withRouter(CandidateCommentContainer));
