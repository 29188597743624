import axios from "axios";

import { GET_OFFICE_CANDIDATE, CANDIDATE_LOADING, GET_CANDIDATE_DASHBOARD, REMOVE_LOADING, GET_ERRORS } from "../types";

export const getCandidateProfile = (callback) => (dispatch) => {
  axios
    .get("/api/candidate/profile")
    .then((res) => {
      dispatch({
        type: GET_OFFICE_CANDIDATE,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

export const getCandidateDashboard = (callback) => (dispatch) => {
  axios
    .get("/api/candidate/dashboard")
    .then((res) => {
      dispatch({
        type: GET_CANDIDATE_DASHBOARD,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Update Profile
export const updateCandidateProfile = (formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidate/profile`, formData)
    .then((res) => {
      dispatch({
        type: GET_OFFICE_CANDIDATE,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      })
    );
};
var clientId = "81023a698e3b0c4c69cc";
var clientSecret = "5215f281b5821187ac758e180c43f32ec531975d";
export const searchCandidateGithubUsername = (github_username, callback) => (dispatch) => {
  let headers = new Headers();
  headers.set("Authorization", "Basic " + Buffer.from(clientId + ":" + clientSecret).toString("base64"));
  fetch(`https://api.github.com/users/${github_username}`, {
    method: "GET",
    headers,
  }).then((res) => {
    callback(res);
  });
};

export const uploadCandidateProfilePhoto = (file, config, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/profile-photo`, file, config)

    .then((res) => {
      callback(res);
    })
    .catch((res) => callback(res));
};

// Reset Password Email Sending
export const forgotPasswordEmail = (email, callback) => (dispatch) => {
  axios
    .post("/api/candidate/forgot-password", email)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
};

export const resetCandidatePassword = (data, callback) => (dispatch) => {
  axios
    .put("/api/candidate/reset-password", data)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

export const changeCandidatePassword = (data, callback) => (dispatch) => {
  axios
    .put("/api/candidate/change-password", data)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Profile loading
export const profileLoading = () => {
  return {
    type: CANDIDATE_LOADING,
  };
};
