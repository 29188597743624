import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import Input from "../../components/common/Input";
import Textarea from "../../components/common/Textarea";
import UploadImage from "../../components/common/UploadImage";
import Dropdown from "../../components/common/Dropdown";
import Btn from "../../components/common/Btn";
import InputGroup from "../../components/common/InputGroup";
import {
  getCandidateReport,
  updateReport,
  removeReport,
  uploadReportPhoto,
  removeReportPhoto,
} from "../../actions/candidate/reportActions";
import ReportValidation from "../../validation/ReportValidation";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import Spinner from "../../components/common/Spinner";
import Confirm from "../../components/common/Confirm";
import FullBtn from "../../components/common/FullBtn";
import scrollToError from "../../utility/scrollToError";

class EditReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      user: {},
      mounted: false,
      auth: {},
      report: this.props.reports && this.props.reports.report,
      images: this.props.reports && this.props.reports.images,
      uploadDisabled: false,
      summary: "",
      description: "",
      expected_result: "",
      automated: false,
      reportMapped: null,
      reproduction_steps: [{ id: 1, value: "" }],
      maxSteps: 50,
      maxImages: 10,
      requestLoading: false,
      errors: {},
    };
    this.onChangePhoto = this.onChangePhoto.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.reports && nextProps.reports.report !== prevState.report) {
        var { report } = nextProps.reports;

        update.candidate_scenario_id = report.candidate_scenario_id ? report.candidate_scenario_id : "";
        update.report = report;
        update.reportMapped = report;

        if (prevState.initialRender) {
          update.summary = report.summary ? report.summary : "";
          update.type = report.type ? report.type : "";
          update.severity = report.severity ? report.severity : "";
          update.priority = report.priority ? report.priority : "";
          update.description = report.description ? report.description : "";
          update.expected_result = report.expected_result ? report.expected_result : "";
          update.reproduction_steps = report.reproduction_steps ? report.reproduction_steps : [];
          update.automated = report.automated ? report.automated : false;
          update.initialRender = false;
        }

        if (report) {
          var reportImages = [];
          report.report_images.map((image) => {
            reportImages.push({ id: image.id, image: image.image });
            return null;
          });
          update.images = reportImages;
          update.uploadDisabled = reportImages.length >= prevState.maxImages ? true : false;
        }
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.getCandidateReport(this.props.match.params.reportId);
  }
  componentWillUnmount() {
    this._isMounted = false;

    this.props.clearErrors();
  }

  onChange(e) {
    this.props.clearErrors();
    if (e.target.id.substring(0, 4) === "step") {
      var enteredTestSteps = this.state.reproduction_steps;
      enteredTestSteps[e.target.name.substring(5)].value = e.target.value;
      this.setState({ reproduction_steps: enteredTestSteps }, () => {
        this.checkValidation();
      });
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {
        this.checkValidation();
      });
    }
  }

  addColumnStep(e) {
    var reproduction_steps = this.state.reproduction_steps;
    const max = reproduction_steps.length > 0 && reproduction_steps.reduce((prev, current) => (prev.id > current.id ? prev : current));

    reproduction_steps.push({ id: max.id ? max.id + 1 : 1, value: "" });
    this.setState({ reproduction_steps }, () => {
      this.checkValidation();
    });
  }

  checkValidation() {
    var formData = {};
    formData.summary = this.state.summary.trim();
    formData.type = this.state.type && this.state.type !== "0" ? this.state.type : null;
    formData.severity = this.state.severity && this.state.severity !== "0" ? this.state.severity : null;
    formData.priority = this.state.priority && this.state.priority !== "0" ? this.state.priority : null;

    formData.description = this.state.description && this.state.description.length > 0 ? this.state.description.trim() : "";
    formData.expected_result = this.state.expected_result && this.state.expected_result.length > 0 ? this.state.expected_result.trim() : "";

    var reproduction_steps = this.state.reproduction_steps;
    reproduction_steps = reproduction_steps.filter((el) => el.value.trim() !== "");
    var trimedSteps = [];
    reproduction_steps.map((step) => {
      trimedSteps.push({ id: step.id, value: step.value.trim() });
      return null;
    });
    formData.reproduction_steps = trimedSteps;

    var { errors } = ReportValidation(formData);
    this.setState({ errors });
  }

  onSubmit() {
    this.props.clearErrors();
    this.setState({ errors: {} });
    var formData = {};
    formData.summary = this.state.summary && this.state.summary.length > 0 ? this.state.summary.trim() : "";
    formData.type = this.state.type && this.state.type !== "0" ? this.state.type : null;
    formData.severity = this.state.severity && this.state.severity !== "0" ? this.state.severity : null;
    formData.priority = this.state.priority && this.state.priority !== "0" ? this.state.priority : null;
    formData.description = this.state.description && this.state.description.length > 0 ? this.state.description.trim() : "";
    formData.expected_result = this.state.expected_result && this.state.expected_result.length > 0 ? this.state.expected_result.trim() : "";
    var reproduction_steps = this.state.reproduction_steps;
    reproduction_steps = reproduction_steps.filter((el) => el.value.trim() !== "");
    var trimedSteps = [];
    reproduction_steps.map((step) => {
      trimedSteps.push({ id: step.id, value: step.value.trim() });
      return null;
    });
    formData.reproduction_steps = trimedSteps;
    formData.candidate_scenario_id = this.state.candidate_scenario_id;
    formData.reportId =
      this.props.match.params.reportId && !isNaN(this.props.match.params.reportId) ? parseInt(this.props.match.params.reportId) : null;

    const { errors, isValid } = ReportValidation(formData);

    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.updateReport(this.props.match.params.reportId, formData, (res) => {
        if (res.status === 200) {
          successToast("Report updated successfully");
          this.props.history.push(`/reports`);
        } else {
          scrollToError();
          failToast("Report edit failed");
          this.setState({ requestLoading: false });
          this.props.history.push(`/edit-report/${this.props.match.params.reportId}`);
        }
      });
    } else {
      this.setState({ errors }, () => {
        scrollToError();
      });
    }
  }

  removeColumnStep(e) {
    var indexToRemove = e.target.id.substring(5);
    var stepErrors = this.state.errors.stepErrors;
    var errors = this.state.errors;
    stepErrors =
      stepErrors &&
      stepErrors.filter(function (obj) {
        return obj.id !== indexToRemove;
      });

    errors.stepErrors = stepErrors;
    var reproduction_steps = this.state.reproduction_steps;

    reproduction_steps.splice(indexToRemove, 1);
    this.setState({ reproduction_steps, errors }, () => {
      this.checkValidation();
    });
  }

  orderUp(e) {
    if (e.target.id > 0) {
      var changingIndex = parseInt(e.target.id);
      var reproduction_steps = this.state.reproduction_steps;
      var first = reproduction_steps[changingIndex].id;
      var second = reproduction_steps[changingIndex - 1].id;

      reproduction_steps[changingIndex].id = second;
      reproduction_steps[changingIndex - 1].id = first;

      var sortedTestSteps = reproduction_steps.sort(function (a, b) {
        return a.id - b.id;
      });
      this.setState({ sortedTestSteps }, () => {
        this.checkValidation();
      });
    }
  }

  orderDown(e) {
    if (e.target.id < this.state.reproduction_steps.length - 1) {
      var changingIndex = parseInt(e.target.id);
      var reproduction_steps = this.state.reproduction_steps;
      var first = reproduction_steps[changingIndex].id;
      var second = reproduction_steps[changingIndex + 1].id;

      reproduction_steps[changingIndex].id = second;
      reproduction_steps[changingIndex + 1].id = first;

      var sortedTestSteps = reproduction_steps.sort(function (a, b) {
        return a.id - b.id;
      });
      this.setState({ sortedTestSteps }, () => {
        this.checkValidation();
      });
    }
  }

  confirmRemoveReport = () => {
    this.props.removeReport(this.props.match.params.reportId, (res) => {
      if (res.status === 200) {
        successToast("Report removed successfully");
        this.props.history.push(`/reports`);
      } else {
        failToast("Report remove failed");
        this.props.history.push(`/edit-report/${this.props.match.params.reportId}`);
      }
    });
  };
  confirmRemoveReportModal = () => {
    var mainText = "Remove this report?";
    var subText = "";
    var btnText = "Remove";

    Confirm(mainText, subText, "Cancel", btnText, () => this.confirmRemoveReport());
  };

  onChangePhoto(e) {
    this.setState({ file: e.target.files[0] }, () => {
      this.onFormSubmit(e);
    });
  }
  onFormSubmit(e) {
    this.setState({ uploading: true });
    this.props.clearErrors();
    const formData = new FormData();
    formData.append("file", this.state.file);
    const config = {
      headers: {
        "content-type": "image/xyz",
      },
    };
    this.props.uploadReportPhoto(this.props.match.params.reportId, formData, config, (res) => {
      if (res.status === 200) {
        successToast("Photo successfully uploaded", {});
        if (this._isMounted) {
          this.setState({ uploading: false });
        }
      } else if (res.status === undefined) {
        failToast(`Photo upload failed! Try different format or smaller image`, {});
        if (this._isMounted) {
          this.setState({ uploading: false });
        }
      }
      if (this._isMounted) {
        this.setState({
          file: null,
        });
      }
    });
  }

  removePhoto(e) {
    var imageId = e.target.id && !isNaN(e.target.id) ? parseInt(e.target.id) : null;
    this.props.removeReportPhoto(imageId, (res) => {
      if (res.status === 200) {
        successToast("Photo successfully removed", {});
      } else if (res.status === undefined) {
        failToast(`Removing photo failed!`, {});
      }
    });
  }
  render() {
    const { loading } = this.props.reports;
    var uploadDisabledClass = "";
    var uploadDisabledMsg = "";
    if (this.state.uploadDisabled) {
      uploadDisabledClass = "disabled";
      uploadDisabledMsg = `Max images(${this.state.maxImages})`;
    } else {
      uploadDisabledClass = "";
      uploadDisabledMsg = "Upload Image";
    }
    var content = "";
    if (this.state.reportMapped === null || loading) {
      content = <Spinner />;
    } else {
      content = (
        <div className="width-container default-container default-padding">
          <Input
            placeholder={"Summary"}
            type="text"
            onChange={(e) => this.onChange(e)}
            name={"summary"}
            value={this.state.summary}
            label="Summary*"
            validationMsg={[this.state.errors.summary, this.props.errors.summary]}
          />
          <Dropdown
            placeholder={"Issue Type"}
            label={"Issue Type*"}
            onChange={(e) => this.onChange(e)}
            value={this.state.type}
            name={"type"}
            options={[
              { label: "Task", title: "Task" },
              { label: "Improvement", title: "Improvement" },
              { label: "Bug", title: "Bug" },
            ]}
            validationMsg={[this.state.errors.type, this.props.errors.type]}
          />
          <Dropdown
            placeholder={"Severity"}
            label={"Severity*"}
            onChange={(e) => this.onChange(e)}
            value={this.state.severity}
            name={"severity"}
            options={[
              { label: "Critical", title: "Critical" },
              { label: "High", title: "High" },
              { label: "Medium", title: "Medium" },
              { label: "Low", title: "Low" },
            ]}
            validationMsg={[this.state.errors.severity, this.props.errors.severity]}
          />
          <Dropdown
            placeholder={"Priority"}
            label={"Priority*"}
            name={"priority"}
            onChange={(e) => this.onChange(e)}
            value={this.state.priority}
            options={[
              { label: "Blocker", title: "Blocker" },
              { label: "Critical", title: "Critical" },
              { label: "Major", title: "Major" },
              { label: "Minor", title: "Minor" },
              { label: "Trivial", title: "Trivial" },
            ]}
            validationMsg={[this.state.errors.priority, this.props.errors.priority]}
          />
          <Textarea
            placeholder={"Description"}
            label={"Description"}
            name={"description"}
            value={this.state.description}
            onChange={(e) => this.onChange(e)}
            validationMsg={[this.state.errors.description, this.props.errors.description]}
          />
          <InputGroup
            type="text"
            placeholder={"Reproduction step"}
            label="Reproduction steps*"
            validationMsg={this.state.errors.reproduction_steps}
            validationMsg2={this.state.errors.stepErrors}
            values={this.state.reproduction_steps}
            keys={"value"}
            onChange={(e) => this.onChange(e)}
            id={"step"}
            addColumn={
              <FullBtn
                placeholder={
                  this.state.reproduction_steps && this.state.reproduction_steps.length >= this.state.maxSteps
                    ? `Max steps (${this.state.maxSteps})`
                    : "Add Test Step"
                }
                disabled={this.state.reproduction_steps && this.state.reproduction_steps.length >= this.state.maxSteps ? true : false}
                onClick={(e) => this.addColumnStep(e)}
              />
            }
            removeColumn={(e) => this.removeColumnStep(e)}
            required={true}
            onKeyDown={this.submitFormOnEnterKey}
            orderUp={(e) => this.orderUp(e)}
            orderDown={(e) => this.orderDown(e)}
          />
          <Input
            placeholder={"Expected result"}
            type="text"
            onChange={(e) => this.onChange(e)}
            name={"expected_result"}
            value={this.state.expected_result}
            label="Expected result"
            validationMsg={[this.state.errors.expected_result, this.props.errors.expected_result]}
          />
          <UploadImage
            btn={
              <label className={`custom-image-upload btn btn-secondary ${uploadDisabledClass}`}>
                <input type="file" name="image" accept=".gif,.jpg,.jpeg,.png,.tiff,.webp" onChange={this.onChangePhoto} />
                <i className="fas fa-plus text-primary"> </i> {uploadDisabledMsg}
              </label>
            }
            validationMsg={this.props.errors.photo}
            removePhoto={(e) => this.removePhoto(e)}
            imgs={this.state.images}
          />
          <div className="submit-button">
            <Btn className="btn btn-primary " label="Submit" loading={this.state.requestLoading} onClick={(e) => this.onSubmit(e)} />
          </div>
        </div>
      );
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate
                title={"Edit Report"}
                link={"/reports"}
                buttons={[<Btn className="btn btn-danger" label="Remove" onClick={(e) => this.confirmRemoveReportModal()} />]}
              />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditReport.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  reports: state.reports,
});

export default connect(mapStateToProps, {
  getCandidateReport,
  updateReport,
  removeReport,
  uploadReportPhoto,
  removeReportPhoto,
  clearErrors,
})(withRouter(EditReport));
