import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "../../components/common/Input";
import Btn from "../../components/common/Btn";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import isEmpty from "../../validation/isEmpty";
import ResetPasswordValidation from "../../validation/ResetPasswordValidation";
import { resetUserPassword } from "../../actions/userActions";

class ChangePasswordAdmin extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      confirm_password: "",
      hash: "",
      requestLoading: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var update = {};
    if (!isEmpty(nextProps.errors)) {
      update.errors = nextProps.errors;
    }
    return Object.keys(update).length ? update : null;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }

  checkValidation() {
    this.setState({ errors: {} });
    this.props.clearErrors();
    var passwordData = {};
    passwordData.password = this.state.password;
    passwordData.confirm_password = this.state.confirm_password;
    passwordData.hash = this.props.location.hash;

    const { errors } = ResetPasswordValidation(passwordData);
    this.setState({ errors });
  }

  onSubmit(e) {
    e.preventDefault();

    var passwordData = {};
    passwordData.password = this.state.password;
    passwordData.confirm_password = this.state.confirm_password;
    passwordData.hash = this.props.location.hash;

    const { errors, isValid } = ResetPasswordValidation(passwordData);
    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.resetUserPassword(passwordData, (res) => {
        if (res.status === 200) {
          successToast("Password changed successfully");
          this.props.history.push(`/admin-login`);
        } else {
          failToast("Password changing failed");
        }
        this.setState({ requestLoading: false });
      });
    } else {
      this.setState({ requestLoading: false });
      this.setState({ errors });
    }
  }

  render() {
    return (
      <div className="grid-container">
        <div className="main">
          <div className="landing">
            {/* <div className='width-container default-container default-padding'> */}
            <div className="landing-title text-center">Reset Password1</div>
            <p className="landing-description text-center">Type in new password bellow</p>
            <Input
              placeholder={"New Password"}
              onChange={(e) => this.onChange(e)}
              name={"password"}
              type="password"
              label="New Password*"
              validationMsg={this.state.errors.password}
            />
            <Input
              placeholder={"Confirm Password"}
              onChange={(e) => this.onChange(e)}
              name={"confirm_password"}
              type="password"
              label="Confirm Password*"
              validationMsg={this.state.errors.confirm_password}
            />
            {/* <span className='text-danger text-center'>{this.state.errors.error}</span> */}
            <br />
            <Btn className="full-width-btn" label="Change" onClick={(e) => this.onSubmit(e)} loading={this.state.requestLoading} />
            <br />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

ChangePasswordAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { resetUserPassword, clearErrors })(withRouter(ChangePasswordAdmin));
