import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import AdminCards from "../../../components/Cards/AdminCards";
// import { getOffice } from "../../../actions/officeActions";
import { accountType } from "../../../enums/accountType";
import { roles } from "../../../enums/roles";
import isEmpty from "../../../validation/isEmpty";

import { withRouter } from "react-router-dom";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (!nextProps.auth.isAuthenticated || !accountType.USER) {
          nextProps.history.push("/");
        }
        if (
          (nextProps.auth.user.offices &&
            nextProps.auth.user.offices[0] &&
            nextProps.auth.user.offices[0].user_role_office.role.value !== roles.SUPERADMINISTRATOR) ||
          isEmpty(nextProps.auth.user.offices)
        ) {
          nextProps.history.push("/");
        }

        var tokenUserOffices = nextProps.auth.user.offices;
        update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }

        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  render() {
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            {/* <SideMenu props={this.props} /> */}
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Settings"} />
            <AdminCards
              cards={[
                {
                  link: `/admin/candidates/global-settings`,
                  title: "Candidates",
                  subtitle: "List of all candidates",
                },
                {
                  link: `/admin/users/global-settings`,
                  title: "Users",
                  subtitle: "List of all users",
                },
                {
                  link: `/admin/offices/global-settings`,
                  title: "Offices",
                  subtitle: "List of all offices",
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  //   offices: state.offices,
});

export default connect(mapStateToProps, { clearErrors })(withRouter(Settings));
