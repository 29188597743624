import isEmpty from "../validation/isEmpty";
const EmailValidation = (data) => {
  var errors = {};

  function validateEmail(email) {
    // const re = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const re = /.+@.+\..+/; // basic validation
    return re.test(String(email).toLowerCase());
  }
  if (!validateEmail(data.email)) {
    errors.email = `Email address '${data.email}' is not valid`;
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default EmailValidation;
