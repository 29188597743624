import isEmpty from "../validation/isEmpty";
const OfficeValidation = (data) => {
  var errors = {};

  var titleLimit = 20;
  var shortTitleLimit = 2;

  data.value = !isEmpty(data.value) ? data.value : "";
  data.short_text = !isEmpty(data.short_text) ? data.short_text : "";

  if (isEmpty(data.value)) {
    errors.value = "Title is required";
  } else {
    if (data.value.length > titleLimit) {
      errors.value = `Title can not have more than ${titleLimit} character (${data.value.length})`;
    }
  }

  if (isEmpty(data.short_text)) {
    errors.short_text = "Short title is required";
  } else {
    if (data.short_text.length > shortTitleLimit) {
      errors.short_text = `Short title can not have more than ${shortTitleLimit} character (${data.short_text.length})`;
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default OfficeValidation;
