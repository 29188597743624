import React from "react";
import ModalImage from "react-modal-image";
import FormElementTitle from "../form/FormElementTitle";


export default function UploadImage({ btn, imgs, removePhoto,validationMsg }) {
  var images = "Loading...";
  if (imgs && imgs.length > 0) {
    images =
      imgs &&
      imgs.map((img, index) => (
        <div className='uploaded-images-wrapper' key={index}>
          <div className={"uploaded-images-wrapper--img"}>
            <ModalImage small={img.image} large={img.image} key={index} />
          </div>
          <span className='uploaded-images-wrapper--close' id={img.id} onClick={removePhoto}>
            x
          </span>
        </div>
      ));
  } else {
    images = <div className='no-content'>No images uploaded</div>;
  }
  var validationId = "";
  if(validationMsg){
    validationId = "validation-msg"
  }
  return (
    <div className='form-element'>
      <div className='form-element-itemCol'>
      <div className='form-element-itemCol'>
      <FormElementTitle label={"Uploaded Images"} validationMsg={validationMsg} validationId={validationId}/>
    </div>
        <div className='uploaded'>
          <div className='uploaded-images'>{images}</div>
          <div className='uploaded-button'>{btn}</div>
        </div>
      </div>
    </div>
  );
}
