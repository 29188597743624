import axios from "axios";

import { GET_TEAMS, GET_TEAM, TEAM_LOADING, REMOVE_LOADING, GET_ERRORS } from "../types";

// Get All Teams
export const getTeams = () => (dispatch) => {
  dispatch(teamsLoading());

  axios
    .get(`/api/candidate/teams`)
    .then((res) => {
      dispatch({
        type: GET_TEAMS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Team
export const getTeam = (teamId) => (dispatch) => {
  dispatch(teamsLoading());

  axios
    .get(`/api/candidate/teams/${teamId}`)
    .then((res) =>
      dispatch({
        type: GET_TEAM,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    });
};

// Create Team
export const createTeam = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/teams`, formData)
    .then((res) => {
      dispatch({
        type: GET_TEAMS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Update Team
export const updateTeam = (teamId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidate/teams/${teamId}`, formData)
    .then((res) => {
      dispatch({
        type: GET_TEAMS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Remove Team
export const removeTeam = (teamId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/teams/${teamId}`)
    .then((res) => {
      dispatch({
        type: GET_TEAMS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Team loading
export const teamsLoading = () => {
  return {
    type: TEAM_LOADING,
  };
};
