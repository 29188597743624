import React from "react";
import { Link } from "react-router-dom";

export default function CandidateContentContainer({ props, repoCount, testcaseCount, reportCount, projectCount }) {
  // var repoButtonClass = "";
  // var repoCountValue = "";
  // if (isNaN(repoCount) || repoCount <= 0) {
  //   repoButtonClass = "disabled";
  //   repoCountValue = "";
  // } else {
  //   repoCountValue = `(${repoCount})`;
  // }

  var testcaseButtonClass = "";
  var testcaseCountValue = "";
  if (isNaN(testcaseCount) || testcaseCount <= 0) {
    testcaseButtonClass = "disabled";
    testcaseCountValue = "";
  } else {
    testcaseCountValue = `(${testcaseCount})`;
  }

  var reportButtonClass = "";
  var reportCountValue = "";
  if (isNaN(reportCount) || reportCount <= 0) {
    reportButtonClass = "disabled";
    reportCountValue = "";
  } else {
    reportCountValue = `(${reportCount})`;
  }

  var projectButtonClass = "";
  var projectCountValue = "";
  if (isNaN(projectCount) || projectCount <= 0) {
    projectButtonClass = "disabled";
    projectCountValue = "";
  } else {
    projectCountValue = `(${projectCount})`;
  }

  return (
    <div>
      <div className="candidate-bottom--item">
        <div className="candidate-bottom--item-title">Content:</div>
        <div className="candidate-bottom--item-info">Content created by candidate in QASandbox and public repositories on Github.</div>
        <div className="candidate-bottom--item-value">
          <div className="profile-buttons-grid">
            <Link
              to={`/admin/${props.match.params.officeId}/candidate/testcases/${props.match.params.candidateId}`}
              className={`btn btn-light ${testcaseButtonClass}`}
            >
              Test Cases {testcaseCountValue}
            </Link>
            <Link
              to={`/admin/${props.match.params.officeId}/candidate/projects/${props.match.params.candidateId}`}
              className={`btn btn-light ${projectButtonClass}`}
            >
              Projects {projectCountValue}
            </Link>
            <Link
              to={`/admin/${props.match.params.officeId}/candidate/reports/${props.match.params.candidateId}`}
              className={`btn btn-light ${reportButtonClass}`}
            >
              Reports {reportCountValue}
            </Link>
            {/* <Link
              to={`/admin/${props.match.params.officeId}/candidate/repositories/${props.match.params.candidateId}`}
              className={`btn btn-light ${repoButtonClass}`}
            >
              Repositories {repoCountValue}
            </Link> */}
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}
