import isEmpty from "../validation/isEmpty";
const RecipientsValidation = (data) => {
  var errors = {};

  if (data && data.length <= 0) {
    errors.error = "There must be at least one recipient";
  } else {
    data.forEach((el) => {
      if (isNaN(el.id)) {
        errors.error = "Invalid user id ";
      }
    });
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default RecipientsValidation;
