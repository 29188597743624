import isEmpty from "../validation/isEmpty";
const ProjectValidation = (data) => {
  var errors = {};

  var titleLimit = 20;

  data.title = !isEmpty(data.title) ? data.title : "";
  if (isEmpty(data.title)) {
    errors.title = "Title is required";
  } else {
    if (data.title.length > titleLimit) {
      errors.title = `Title can not have more than ${titleLimit} character (${data.title.length})`;
    }
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default ProjectValidation;
