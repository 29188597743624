import axios from "axios";

import { GET_DIFFICULTIES, GET_DIFFICULTY, DIFFICULTY_LOADING, REMOVE_LOADING, GET_GLOBAL_DIFFICULTIES, GET_ERRORS } from "./types";

// Get All Difficulties
export const getDifficulties = (office_id, callback) => (dispatch) => {
  dispatch(difficultyLoading());

  axios
    .get(`/api/difficulties/${office_id}`)
    .then((res) => {
      dispatch({
        type: GET_DIFFICULTIES,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Difficulty
export const getDifficulty = (difficultyId) => (dispatch) => {
  dispatch(difficultyLoading());

  axios
    .get(`/api/difficulties/difficulty/${difficultyId}`)
    .then((res) =>
      dispatch({
        type: GET_DIFFICULTY,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Create Difficulty
export const createDifficulty = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/difficulties/difficulty`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Update Difficulty
export const updateDifficulty = (difficultyId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/difficulties/difficulty/${difficultyId}`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Remove Difficulty
export const removeDifficulty = (difficultyId, callback) => (dispatch) => {
  axios
    .delete(`/api/difficulties/difficulty/${difficultyId}`)
    .then((res) => callback(res))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get All Global Difficulties
export const getGlobalDifficulties = () => (dispatch) => {
  dispatch(difficultyLoading());

  axios
    .get(`/api/offices/difficulties`)
    .then((res) =>
      dispatch({
        type: GET_GLOBAL_DIFFICULTIES,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      // callback(err.response);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Difficulty loading
export const difficultyLoading = () => {
  return {
    type: DIFFICULTY_LOADING,
  };
};
