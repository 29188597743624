import {
  GET_DIFFICULTIES,
  GET_DIFFICULTY,
  DIFFICULTY_LOADING,
  GET_GLOBAL_DIFFICULTIES,
  REMOVE_LOADING,
} from "../actions/types";

const initialState = {
  globalDifficulties: null,
  difficulties: null,
  difficulty: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DIFFICULTY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_DIFFICULTIES:
      return {
        ...state,
        difficulties: action.payload,
        loading: false,
      };
    case GET_DIFFICULTY:
      return {
        ...state,
        difficulty: action.payload,
        loading: false,
      };
    case REMOVE_LOADING:
      return {
        ...state,

        loading: false,
      };
    case GET_GLOBAL_DIFFICULTIES:
      return {
        ...state,
        globalDifficulties: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
