import React, { Component } from "react";
import Card from "../../components/Cards/Card";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import SideMenu from "../../components/layout/SideMenu";
import imagePlaceholder from "../../img/noImg.png";
import automatedTesting from "../../img/automatedTesting.png";
import manualTesting from "../../img/manualTesting.png";
import usecases from "../../img/usecases.png";
import Navigate from "../../components/Navigate/Navigate";
import Spinner from "../../components/common/Spinner";
import { getCandidateDashboard } from "../../actions/candidate/profileActions";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      dashboard: this.props.candidates && this.props.candidates.dashboard,
      profile: null,
      scenario: null,
      finished: null,
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (!nextProps.auth.isAuthenticated) {
          nextProps.history.push("/login");
        }
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.candidates && nextProps.candidates.dashboard !== prevState.dashboard) {
        update.profile = nextProps.candidates.dashboard.profile;
        update.finished = nextProps.candidates.dashboard.finished;
        update.scenario = nextProps.candidates.dashboard.scenario;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getCandidateDashboard(() => {});
  }

  render() {
    const { loading } = this.props.candidates;
    const profile = this.state.profile;
    const finished = this.state.finished;
    const scenario = this.state.scenario;

    var content = "";

    if (profile === null || finished === null || scenario === null || loading) {
      content = <Spinner />;
    } else {
      var profileMsg = "";
      var profileStatus = "";

      var testcaseMsg = "";
      var testcaseStatus = "fail";

      var projectMsg = "";
      var projectStatus = "fail";

      var reportMsg = "";
      var reportStatus = "fail";
      var objectivesDone = [];
      if (profile && profile.img) {
        profileMsg = "Profile updated!";
        profileStatus = "success";
        objectivesDone.push("profile");
      } else if (profile && !profile.img) {
        profileMsg = "Update your profile and profile image first!";
        profileStatus = "danger";
      } else if (profile) {
        profileMsg = "Update your profile first!";
        profileStatus = "danger";
      } else if (profile && !profile.img) {
        profileMsg = "Upload your profile photo first!";
        profileStatus = "danger";
      }

      if (!finished.testcases) {
        if (!scenario.testcase_count) {
          testcaseMsg = `No use cases written down!`;
          testcaseStatus = "success";
          objectivesDone.push("testcase");
        } else {
          testcaseMsg = "No test cases written yet!";
          testcaseStatus = "danger";
        }
      } else if (finished.testcases > 0 && scenario && scenario.testcase_count > finished.testcases) {
        testcaseMsg = `${finished.testcases} written down so far (${scenario && scenario.testcase_count - finished.testcases} to go)!`;
        testcaseStatus = "warning";
      } else if (finished.testcases > 0 && scenario.testcase_count <= finished.testcases) {
        testcaseMsg = `${finished.testcases} use cases written down so far!`;
        testcaseStatus = "success";
        objectivesDone.push("testcase");
      }

      if (!finished.projects) {
        if (!scenario.automated_count) {
          projectMsg = `No use cases automated so far!`;
          projectStatus = "success";
          objectivesDone.push("automated");
        } else {
          projectMsg = "No test cases automated yet!";
          projectStatus = "danger";
        }
      } else if (finished.projects > 0 && scenario && scenario.automated_count > finished.projects) {
        projectMsg = `${finished.projects} automated so far (${scenario && scenario.automated_count - finished.projects} to go)!`;
        projectStatus = "warning";
      } else if (finished.projects > 0 && scenario.automated_count <= finished.projects) {
        projectMsg = `${finished.projects} use cases automated so far!`;
        projectStatus = "success";
        objectivesDone.push("automated");
      }

      if (!finished.reports) {
        if (!scenario.report_count) {
          reportMsg = `No issues reported so far!`;
          reportStatus = "success";
          objectivesDone.push("report");
        } else {
          reportMsg = "No issues reported yet!";
          reportStatus = "danger";
        }
      } else if (finished.reports > 0 && scenario && scenario.report_count > finished.reports) {
        reportMsg = `${finished.reports} issues reported so far (${scenario && scenario.report_count - finished.reports} to go)!`;
        reportStatus = "warning";
      } else if (finished.reports > 0 && scenario.report_count <= finished.reports) {
        reportMsg = `${finished.reports} issues reported so far!`;
        reportStatus = "success";
        objectivesDone.push("report");
      }
      var widthBar = "0%";
      if (objectivesDone.length === 0) {
        widthBar = "0%";
      } else if (objectivesDone.length === 1) {
        widthBar = "25%";
      } else if (objectivesDone.length === 2) {
        widthBar = "50%";
      } else if (objectivesDone.length === 3) {
        widthBar = "75%";
      } else if (objectivesDone.length === 4) {
        widthBar = "100%";
      }

      content = (
        <div>
          <div className="progress mb-4">
            <div
              className="progress-bar"
              style={{ width: widthBar }}
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
          <div className="card-grid">
            <Card
              img={this.state.profile && this.state.profile.img ? this.state.profile.img : imagePlaceholder}
              imgTitle={"Profile"}
              title={
                this.state.profile && this.state.profile.first_name
                  ? `${this.state.profile.first_name} ${this.state.profile.last_name}`
                  : ""
              }
              subtitle={"You can update your profile here"}
              statusColor={profileStatus}
              statusMsg={profileMsg}
              link={"/profile"}
            />
            <Card
              img={usecases}
              imgTitle={"Test Cases"}
              title={"Test Cases"}
              subtitle={"Write down test cases here"}
              statusColor={testcaseStatus}
              statusMsg={testcaseMsg}
              link={"/testcases"}
            />
            <Card
              img={automatedTesting}
              imgTitle={"Playground"}
              title={"Playground"}
              subtitle={"Sandbox for coding automated tests"}
              statusColor={projectStatus}
              statusMsg={projectMsg}
              link={"/projects"}
            />
            <Card
              img={manualTesting}
              imgTitle={"Reports"}
              title={"Reports"}
              subtitle={"Report bugs left on purpose"}
              statusColor={reportStatus}
              statusMsg={reportMsg}
              link={"/reports"}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="padding-container">
              <Navigate title={"Dashboard"} />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  candidates: state.candidates,
});

export default connect(mapStateToProps, { getCandidateDashboard, clearErrors })(withRouter(Dashboard));
