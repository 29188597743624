import {
  GET_CANDIDATE_PROJECTS,
  GET_CANDIDATE_PROJECT,
  PROJECT_LOADING,
  CLEAR_PROJECTS,
  REMOVE_LOADING,
} from "../actions/types";

const initialState = {
  projects: null,
  project: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROJECT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CANDIDATE_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false,
      };
    case GET_CANDIDATE_PROJECT:
      return {
        ...state,
        project: action.payload,
        loading: false,
      };
    case CLEAR_PROJECTS:
      return {
        ...state,
        project: null,
        loading: false,
      };
    case REMOVE_LOADING:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
