import axios from "axios";

import {
  GET_SCENARIOS,
  GET_SCENARIO,
  GET_ACTIVE_SCENARIO,
  SCENARIO_LOADING,
  GET_AVAILABLE_SCENARIOS,
  REMOVE_LOADING,
  GET_ERRORS,
} from "./types";

// Get All Scenarios
export const getScenarios = (office_id, callback) => (dispatch) => {
  dispatch(scenarioLoading());

  axios
    .get(`/api/scenarios/${office_id}`)
    .then((res) => {
      dispatch({
        type: GET_SCENARIOS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err.response);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Scenario
export const getScenario = (scenarioId) => (dispatch) => {
  dispatch(scenarioLoading());

  axios
    .get(`/api/scenarios/scenario/${scenarioId}`)
    .then((res) =>
      dispatch({
        type: GET_SCENARIO,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get Scenario
export const getCandidateActiveScenario = (candidateId) => (dispatch) => {
  dispatch(scenarioLoading());

  axios
    .get(`/api/candidates/candidate/${candidateId}/active-scenario`)
    .then((res) =>
      dispatch({
        type: GET_ACTIVE_SCENARIO,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Create Scenario
export const createScenario = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/scenarios/scenario`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Update Scenario
export const updateScenario = (scenarioId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/scenarios/scenario/${scenarioId}`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Remove Scenario
export const removeScenario = (scenarioId, callback) => (dispatch) => {
  axios
    .delete(`/api/scenarios/scenario/${scenarioId}`)
    .then((res) => callback(res))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Cancel Candidate Exam
export const cancelCandidateExam = (candidateId, callback) => (dispatch) => {
  axios
    .put(`/api/candidates/candidate/${candidateId}/active-scenario/cancel`)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Candidate Available Scenarios
export const candidateAvailableScenarios = (candidateId, officeId) => (dispatch) => {
  axios
    .get(`/api/candidates/candidate/${candidateId}/office/${officeId}/available-scenarios`)
    .then((res) =>
      dispatch({
        type: GET_AVAILABLE_SCENARIOS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Update Candidate Active Scenario
export const updateCandidateExamScenario = (candidateId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidates/candidate/${candidateId}/scenario`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Set New Candidate Scenario
export const setCandidateExamScenario = (candidateId, formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidates/candidate/${candidateId}/scenario`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Scenario loading
export const scenarioLoading = () => {
  return {
    type: SCENARIO_LOADING,
  };
};
