import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import Input from "../../../components/common/Input";
import Btn from "../../../components/common/Btn";
import CategoryValidation from "../../../validation/CategoryValidation";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import { createCategory } from "../../../actions/categoryActions";
import CheckPermissions from "../../../validation/CheckPermissions";

import { withRouter } from "react-router-dom";

class NewCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
      mounted: false,
      auth: {},
      requestLoading: false,
      category: "",
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/categories`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.WRITE,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/categories`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    CheckPermissions(dataToCheck);
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  checkValidation() {
    this.props.clearErrors();
    var formData = {};
    formData.category = this.state.category;
    formData.office_id = this.props.match.params.officeId;

    const { errors } = CategoryValidation(formData);

    this.setState({ errors });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }

  submitForm(e) {
    e.preventDefault();
    var formData = {};
    formData.category = this.state.category;
    formData.office_id = this.props.match.params.officeId;

    const { errors, isValid } = CategoryValidation(formData);
    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.createCategory(formData, (res) => {
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Category created successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/categories`);
        } else {
          this.setState({ requestLoading: false });
          failToast("Category creation failed");
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ requestLoading: false });
      });
    }
  }
  render() {
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate title={"New Category"} link={`/admin/${this.props.match.params.officeId}/categories`} />
              <Input
                placeholder={"Category"}
                type="text"
                onChange={(e) => this.onChange(e)}
                name={"category"}
                label="Category"
                validationMsg={[this.state.errors.category, this.props.errors.error]}
              />
              <div className="submit-button">
                <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewCategory.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { createCategory, clearErrors })(withRouter(NewCategory));
