import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { roles } from "../../enums/roles";

export default function QuestionsCardGlobal({ questions, props, userRoleOffice }) {
  var roleCanOpen = false;
  if (userRoleOffice === roles.SUPERADMINISTRATOR || userRoleOffice === roles.ADMINISTRATOR) {
    roleCanOpen = true;
  }
  var content = "Loading...";
  if (questions && questions.length > 0) {
    content = (
      <div className="portrait-grid">
        {questions &&
          questions.map((question, index) => (
            <span key={index}>
              <Link className="preview-card preview-card-not-clickable" key={index} to={"#"}>
                <div className="preview-card preview-card-not-clickable">
                  <div className="preview-card-title">
                    <div className="preview-card-title-value">{question.question_plain}</div>
                  </div>
                  <div className="preview-card-body">
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-new-line">
                        <div className="preview-card-body--items-new-line-title">Answer:</div>
                        <div className="preview-card-body--items-new-line-value">{question.answer_plain}</div>
                      </div>
                    </div>
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-single">
                        <div className="preview-card-body--items-single-title">Created by:</div>
                        <div className="preview-card-body--items-single-value">{question.created_by}</div>
                      </div>
                    </div>
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-single">
                        <div className="preview-card-body--items-single-title">Category:</div>
                        <div className="preview-card-body--items-single-value">{question.category}</div>
                      </div>
                    </div>
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-single">
                        <div className="preview-card-body--items-single-title">Difficulty:</div>
                        <div className="preview-card-body--items-single-value">{question.difficulty}</div>
                      </div>
                    </div>
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-single">
                        <div className="preview-card-body--items-single-title">Global:</div>
                        <div className="preview-card-body--items-single-value">{question.global ? "Yes" : "No"}</div>
                      </div>
                    </div>
                    <div className="preview-card-body--items">
                      <div className="preview-card-body--items-double">
                        <div className="preview-card-body--items-double--items">
                          {roleCanOpen ? (
                            <div
                              className="preview-card-body--items-double--items-item"
                              onClick={(e) => props.history.push(`/admin/${props.match.params.officeId}/copy-question/${question.id}`)}
                            >
                              <div className="preview-card-body--items-double--items-item-title">
                                {question.copied ? "Copied to this office" : "Copy"}
                              </div>
                              <div className="preview-card-body--items-double--items-item-value">
                                {question.copied ? <i className="fas fa-check"></i> : <i className="fas fa-copy"></i>}
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="preview-card-body--items"
                      onClick={(e) => e.preventDefault()}
                      data-toggle="modal"
                      data-target={`#myModal-${index}`}
                    >
                      <div className="preview-card-body--items-single-preview">
                        <div className="preview-card-body--items-single-preview-title">Preview</div>
                        <div className="preview-card-body--items-single-preview-value">
                          <i className="fas fa-eye"></i>
                        </div>
                      </div>
                    </div>
                    {/* <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-short'>
                          <div className='preview-card-body--items-single-short-title'>Copy:</div>
                          <div className='preview-card-body--items-single-short-value'>
                            <i className='fas fa-copy'></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='preview-card-body--items'
                      onClick={(e) => e.preventDefault()}
                      data-toggle='modal'
                      data-target={`#myModal-${index}`}
                    >
                      <div className='preview-card-body--items-single-preview'>
                        <div className='preview-card-body--items-single-preview-title'>Preview</div>
                        <div className='preview-card-body--items-single-preview-value'>
                          <i className='fas fa-eye'></i>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Link>
              <div id={`myModal-${index}`} className="modal fade" role="dialog">
                <div className="modal-dialog">
                  {/* <!-- Modal content--> */}
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-titles">Question Preview</h4>
                      <button type="button" className="close" data-dismiss="modal">
                        &times;
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="preview-card-modal">
                        <div className="preview-card-modal-title">
                          <div className="preview-card-modal-title-value">
                            {/* <pre> */}
                            {ReactHtmlParser(question.question)}
                            {/* </pre> */}
                          </div>
                        </div>
                        <hr />
                        <div className="preview-card-modal-body">
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-new-line">
                              <div className="preview-card-modal-body--items-new-line-title">Answer:</div>
                              <div className="preview-card-modal-body--items-new-line-value">
                                {/* <pre> */}
                                {ReactHtmlParser(question.answer)}
                                {/* </pre> */}
                              </div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Category:</div>
                              <div className="preview-card-modal-body--items-single-value">{question.category}</div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Difficulty:</div>
                              <div className="preview-card-modal-body--items-single-value">{question.difficulty}</div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Global:</div>
                              <div className="preview-card-modal-body--items-single-value">{question.global ? "Yes" : "No"}</div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-short">
                                <div className="preview-card-modal-body--items-single-short-title">Reviewed:</div>
                                <div className="preview-card-modal-body--items-single-short-value">{question.reviewed ? "Yes" : "No"}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-default" data-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          ))}
      </div>
    );
  } else {
    content = <div className="no-content">There are no questions set as global.</div>;
  }
  return <div>{content}</div>;
}
