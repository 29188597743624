import React from "react";
import { Link } from "react-router-dom";

export default function DifficultyCards({ difficulties }) {
  var content = "Loading...";
  if (difficulties && difficulties.length > 0) {
    content = (
      <div className='portrait-grid'>
        {difficulties &&
          difficulties.map((difficulty, index) => (
            <span key={index}>
              <Link to={difficulty.link} className='preview-card'>
                <div className='preview-card'>
                  <div className='preview-card-body'>
                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-title'>{difficulty.difficulty}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </span>
          ))}
      </div>
    );
  } else {
    content = (
      <div className='no-content'>
        There are no difficulties added. Click Add Difficulty in top right corner to add first.
      </div>
    );
  }
  return <div>{content}</div>;
}
