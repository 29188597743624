import { GET_CANDIDATE_REPORTS, GET_CANDIDATE_REPORT, REPORT_LOADING, REMOVE_LOADING } from "../actions/types";

const initialState = {
  reports: null,
  report: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REPORT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CANDIDATE_REPORTS:
      return {
        ...state,
        reports: action.payload,
        loading: false,
      };
    case GET_CANDIDATE_REPORT:
      return {
        ...state,
        report: action.payload,
        loading: false,
      };

    case REMOVE_LOADING:
      return {
        ...state,

        loading: false,
      };

    default:
      return state;
  }
}
