import {
  GET_SCENARIOS,
  GET_SCENARIO,
  SCENARIO_LOADING,
  GET_AVAILABLE_SCENARIOS,
  GET_ACTIVE_SCENARIO,
  REMOVE_LOADING,
} from "../actions/types";

const initialState = {
  scenarios: null,
  scenario: null,
  candidateActiveScenario: null,
  candidateAvailableScenarios: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SCENARIO_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SCENARIOS:
      return {
        ...state,
        scenarios: action.payload,
        loading: false,
      };
    case GET_SCENARIO:
      return {
        ...state,
        scenario: action.payload,
        loading: false,
      };
    case GET_ACTIVE_SCENARIO:
      return {
        ...state,
        candidateActiveScenario: action.payload,
        loading: false,
      };
    case GET_AVAILABLE_SCENARIOS:
      return {
        ...state,
        candidateAvailableScenarios: action.payload,
        loading: false,
      };
    case REMOVE_LOADING:
      return {
        ...state,

        loading: false,
      };
    default:
      return state;
  }
}
