import axios from "axios";

import { GET_TECHNOLOGIES, GET_TECHNOLOGY, TECHNOLOGY_LOADING, REMOVE_LOADING, GET_ERRORS } from "../types";

// Get All Technologies
export const getTechnologies = () => (dispatch) => {
  dispatch(technologyLoading());

  axios
    .get(`/api/candidate/technologies`)
    .then((res) => {
      dispatch({
        type: GET_TECHNOLOGIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Technology
export const getTechnology = (technologyId) => (dispatch) => {
  dispatch(technologyLoading());

  axios
    .get(`/api/candidate/technologies/${technologyId}`)
    .then((res) =>
      dispatch({
        type: GET_TECHNOLOGY,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    });
};

// Create Technology
export const createTechnology = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/technologies`, formData)
    .then((res) => {
      dispatch({
        type: GET_TECHNOLOGIES,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Update Technology
export const updateTechnology = (technologyId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidate/technologies/${technologyId}`, formData)
    .then((res) => {
      dispatch({
        type: GET_TECHNOLOGIES,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Remove Technology
export const removeTechnology = (technologyId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/technologies/${technologyId}`)
    .then((res) => {
      dispatch({
        type: GET_TECHNOLOGIES,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Difficulty loading
export const technologyLoading = () => {
  return {
    type: TECHNOLOGY_LOADING,
  };
};
