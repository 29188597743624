import axios from "axios";

import { GET_OFFICE_STATISTICS, GET_GLOBAL_STATISTICS, STATISTIC_LOADING, GET_ERRORS } from "./types";

// Get Office Statistics
export const getOfficeStatistics = (officeId, params) => (dispatch) => {
  dispatch(statisticLoading());

  var url = `/api/statistics/office/${officeId}`;
  if (params && typeof params === "number") {
    url = `/api/statistics/office/${officeId}?days=${params}`;
  } else if (params && typeof params === "string") {
    url = `/api/statistics/office/${officeId}?option=${params}`;
  }

  axios
    .post(url)
    .then((res) =>
      dispatch({
        type: GET_OFFICE_STATISTICS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get Global Statistics
export const getGlobalStatistics = (params) => (dispatch) => {
  dispatch(statisticLoading());

  var url = `/api/statistics/global`;
  if (params && typeof params === "number") {
    url = `/api/statistics/global?days=${params}`;
  } else if (params && typeof params === "string") {
    url = `/api/statistics/global?option=${params}`;
  }

  axios
    .post(url)
    .then((res) =>
      dispatch({
        type: GET_GLOBAL_STATISTICS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Statistics loading
export const statisticLoading = () => {
  return {
    type: STATISTIC_LOADING,
  };
};
