import React from "react";
import Input from "../../components/common/Input";

export default function SettingsItemList({
  value1,
  value,
  onClickRemove,
  onClickEdit,
  onChange,
  name,
  validationMsg,
  id,
  idToEdit,
  cancelEdit,
  onClickConfirmEdit,
  label,
}) {
  var listItemValue = "";
  var initialEditButtons = "";
  if (idToEdit && id === idToEdit) {
    listItemValue = (
      <div className='default-flex'>
        <Input
          value={value ? value : value1}
          name={name}
          onChange={onChange}
          label={label}
          validationMsg={validationMsg}
          noMargin
        />
        <div className='settings-list-buttons'>
          <div onClick={cancelEdit} className='settings-list-buttons-cancel'>
            <i className='fas fa-times'></i>
          </div>
          <div className='settings-list-buttons-confirm' onClick={onClickConfirmEdit}>
            <i className='fas fa-check'></i>
          </div>
        </div>
      </div>
    );
  } else {
    listItemValue = value1;
    initialEditButtons = (
      <div className='settings-list-buttons'>
        <div onClick={onClickEdit} className='settings-list-buttons-edit'>
          <i className='fas fa-pencil-alt'></i>
        </div>
        <div className='settings-list-buttons-remove' onClick={onClickRemove}>
          <i className='fas fa-trash'></i>
        </div>
      </div>
    );
  }

  return (
    <div className='settings'>
      <div className='settings-list'>
        {listItemValue}
        {initialEditButtons}
      </div>
    </div>
  );
}
