import React, { Component } from "react";
// import { clearErrors } from "../../actions/errorsActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import TestcaseCards from "../../components/Cards/TestcaseCards";
import { getCandidateTestcases } from "../../actions/candidate/testcaseActions";
import Spinner from "../../components/common/Spinner";
import { Link } from "react-router-dom";

class Testcases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      auth: {},
      testcasesMapped: [],
      testcases: [],
      errors: {},
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.testcases.testcases !== prevState.testcases) {
        update.testcases = nextProps.testcases.testcases;

        update.testcases = nextProps.testcases.testcases;
        update.testcasesMapped = nextProps.testcases.testcases;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getCandidateTestcases();
  }

  render() {
    const { loading } = this.props.testcases;
    var content = "";
    if (this.state.testcasesMapped === null || loading) {
      content = <Spinner />;
    } else {
      content = <TestcaseCards testcases={this.state.testcasesMapped} />;
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={"Test Cases"}
              link={"/dashboard"}
              buttons={[
                <Link to={"/new-testcase"} className="btn btn-primary">
                  New Test Case
                </Link>,
              ]}
            />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

Testcases.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  testcases: state.testcases,
  errors: state.errors,
});

export default connect(mapStateToProps, { getCandidateTestcases })(withRouter(Testcases));
