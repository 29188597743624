import isEmpty from "../validation/isEmpty";
import { examType } from "../enums/examType";
const ScenarioValidation = (data) => {
  var errors = {};
  var titleLimit = 200;
  var testcaseCountLimit = 20;
  var automatedCountLimit = 20;
  var reportCountLimit = 20;

  data.title = !isEmpty(data.title) ? data.title : "";
  data.scenario = !isEmpty(data.scenario) ? data.scenario : "";
  data.scenario_plain = !isEmpty(data.scenario_plain) ? data.scenario_plain : "";
  data.created_on = !isEmpty(data.created_on) ? data.created_on : "";
  data.office_id = !isEmpty(data.office_id) ? data.office_id : "";

  if (isEmpty(data.title)) {
    errors.title = "Title is required";
  } else {
    if (data.title.length > titleLimit) {
      errors.title = `Title can not have more than ${titleLimit} character (${data.title.length})`;
    }
  }
  if (isEmpty(data.scenario_plain)) {
    errors.scenario = "Scenario is required";
  }
  if (isEmpty(data.office_id)) {
    errors.office_id = "Office id is required";
  } else {
    if (isNaN(data.office_id)) {
      errors.office_id = "Office id is not a valid number";
    }
  }

  if (isEmpty(data.user_id)) {
    errors.user_id = "User id is required";
  } else {
    if (isNaN(data.user_id)) {
      errors.user_id = "User id is not a valid number";
    }
  }

  if (isEmpty(data.exam_type)) {
    errors.exam_type = "Exam type is required";
  }

  if (data.exam_type !== examType.MANUAL && data.exam_type !== examType.AUTOMATION) {
    errors.exam_type = "Exam type is not a valid number";
  }

  if (isEmpty(data.testcase_count) && data.show_testcase_count) {
    errors.testcase_count = "If switch is ON Test case count is required";
  } else if (data.show_testcase_count) {
    if (isNaN(data.testcase_count)) {
      errors.testcase_count = "Test case count is not a valid number";
    } else if (data.testcase_count > testcaseCountLimit || data.testcase_count <= 0) {
      errors.testcase_count = `Test case count can not be lower than 1 and higher than ${testcaseCountLimit}`;
    }
  }

  if (isEmpty(data.automated_count) && data.show_automated_count) {
    errors.automated_count = "If switch is ON Automated count is required";
  } else if (data.show_automated_count) {
    if (isNaN(data.automated_count)) {
      errors.automated_count = "Automated count is not a valid number";
    } else if (data.automated_count > automatedCountLimit || data.automated_count <= 0) {
      errors.automated_count = `Automated count can not be lower than 1 and higher than ${automatedCountLimit}`;
    }
  }

  if (isEmpty(data.report_count) && data.show_report_count) {
    errors.report_count = "If switch is ON Report count is required";
  } else if (data.show_report_count) {
    if (isNaN(data.report_count)) {
      errors.report_count = "Report count is not a valid number";
    } else if (data.report_count > reportCountLimit || data.report_count <= 0) {
      errors.report_count = `Report count can not be lower than 1 and higher than ${reportCountLimit}`;
    }
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default ScenarioValidation;
