import isEmpty from "../validation/isEmpty";
const ProfileValidation = (data) => {
  var errors = {};

  var biographyLength = 1000;

  data.biography = !isEmpty(data.biography) ? data.biography : "";
  data.career_status_id = !isEmpty(data.career_status_id) ? data.career_status_id : null;

  data.github_username = !isEmpty(data.github_username) ? data.github_username : "";

  if (!isEmpty(data.biography)) {
    if (data.biography.length > biographyLength) {
      errors.biography = `Biography can not have more than ${biographyLength} characters (${data.biography.length})`;
    }
  }

  if (!isEmpty(data.city_id)) {
    if (isNaN(data.city_id)) {
      errors.city_id = "Invalid value for city";
    }
  }

  function validationGithubUsername(githubUsername) {
    const gitHubRegex = /^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/i;
    return gitHubRegex.test(String(githubUsername));
  }
  if (!isEmpty(data.github_username)) {
    if (!validationGithubUsername(data.github_username)) {
      errors.github_username = "Invalid value for  github username";
    }
  }

  if (!isEmpty(data.career_status_id)) {
    if (isNaN(data.career_status_id)) {
      errors.career_status_id = "Invalid value for career status";
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default ProfileValidation;
