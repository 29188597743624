import React from "react";
// import ReactTooltip from "react-tooltip";

function Btn({
  type,
  className,
  label,
  onClick,
  disabled,
  tooltip,
  tooltipId,
  loading
}) {
  var classNameValue = className;
  if (disabled) {
    classNameValue = className + " disabled";
  }

  // var tooltipValue = "";
  // var tooltipIdValue = "";
  // if (tooltip) {
  //   tooltipValue = (
  //     <ReactTooltip
  //       id={tooltipId}
  //       aria-haspopup='true'
  //       className='custom-color-no-arrow'
  //       textColor='#000'
  //       backgroundColor='#eee'
  //       effect='solid'
  //     >
  //       <p>{tooltip}</p>
  //     </ReactTooltip>
  //   );
  //   tooltipIdValue = tooltipId;
  // }
  if (loading) {
    label = (
      <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
//       <button class="btn btn-primary" type="button" disabled>
//   <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//   Submitting...
// </button>
    );
    classNameValue = className + " disabled loading-btn";
  }
  return (
    <button
      type={type}
      className={classNameValue}
      onClick={onClick}
      disabled={disabled}
      // data-tip
      // data-for={tooltipIdValue}
    >
      {label}
      {/* {tooltipValue} */}
    </button>
  );
}

export default Btn;
