import React, { Component } from "react";
import { clearErrors } from "../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import SearchDropdown from "../../components/common/SearchDropdown";
import Input from "../../components/common/Input";
import loadingImg from "../../img/imgLoader.gif";
import Btn from "../../components/common/Btn";
import imgPlaceholder from "../../img/project.jpg";
import {
  getCandidateProject,
  updateProject,
  clearProjects,
  uploadProjectPhoto,
  removeProject,
} from "../../actions/candidate/projectActions";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import { getPeople } from "../../actions/candidate/peopleActions";
import Spinner from "../../components/common/Spinner";
import Technology from "../../components/Project/Technology";
import Seniority from "../../components/Project/Seniority";
import Team from "../../components/Project/Team";
import Person from "../../components/Project/Person";
import Confirm from "../../components/common/Confirm";
import ReactCrop from "react-image-crop";
import ProjectValidation from "../../validation/ProjectValidation";

class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      src: null,
      crop: {
        unit: "%",
        width: 50,
        aspect: 10 / 10,
      },
      uploading: false,
      user: {},
      mounted: false,
      project: this.props.projects,
      projectMapped: null,
      requestLoading: false,
      person: [],
      peopleMapped: [],
      auth: {},
      errors: {},
    };
    this.selectPerson = this.selectPerson.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangePhoto = this.onChangePhoto.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push("/login");
      }
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.projects && nextProps.projects.project) {
        if (nextProps.projects.project !== prevState.project) {
          var project = nextProps.projects.project;
          if (prevState.initialRender) {
            var selectedPeople = [];
            project &&
              project.teams &&
              project.teams.map((team) => {
                team.people.map((person) => {
                  selectedPeople.push({ id: person.id, title: person.name });
                  return null;
                });
                return null;
              });

            update.person = selectedPeople;
            update.title = project.title;
            update.initialRender = false;
          }
          update.img = nextProps.projects.project.image ? nextProps.projects.project.image : "";
          update.project = project;
          update.projectMapped = project;
        }
      }
      var peopleMapped = [];
      if (nextProps.people.people !== prevState.people) {
        update.people = nextProps.people.people;

        nextProps.people &&
          nextProps.people.people &&
          nextProps.people.people.map((person) => {
            peopleMapped.push({ id: person.id, title: person.name });
            return null;
          });
        update.peopleMapped = peopleMapped;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.IsMounted = true;
    this.props.getCandidateProject(this.props.match.params.projectId, () => {});
    this.props.getPeople();
  }
  componentWillUnmount() {
    this.IsMounted = false;
    this.props.clearProjects();
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }
  selectPerson(value) {
    this.setState({ person: value });
  }

  checkValidation() {
    this.setState({ errors: {} });
    this.props.clearErrors();
    var formData = {};
    formData.title = this.state.title && this.state.title.length > 0 ? this.state.title.trim() : "";

    const { errors, isValid } = ProjectValidation(formData);
    if (!isValid) {
      this.setState({ errors });
    }
  }

  onSubmit() {
    var formData = {};
    formData.title = this.state.title && this.state.title.length > 0 ? this.state.title.trim() : "";
    formData.people = this.state.person;
    formData.project_id =
      this.props.match.params.projectId && !isNaN(this.props.match.params.projectId) ? parseInt(this.props.match.params.projectId) : null;

    const { errors, isValid } = ProjectValidation(formData);
    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.updateProject(this.props.match.params.projectId, formData, (res) => {
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Project updated successfully");
        } else {
          this.setState({ requestLoading: false });
          failToast("Project updating failed");
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  onChangePhoto(e) {
    this.setState({ file: e.target.files[0] }, () => {
      this.onFormSubmit(e);
    });
  }

  onFormSubmit(e) {
    this.setState({ uploading: true });
    const formData = new FormData();
    formData.append("file", this.state.file);
    const config = {
      headers: {
        "content-type": "image/xyz",
      },
    };

    this.props.uploadProjectPhoto(this.props.match.params.projectId, formData, config, (res) => {
      if (res.status === 200) {
        successToast("Photo successfully uploaded");
        this.props.getCandidateProject(this.props.match.params.projectId, () => {
          if (this.IsMounted) {
            this.setState({
              uploading: false,
              src: null,
              croppedImageUrl: null,
              crop: {
                unit: "%",
                width: 100,
                aspect: 10 / 10,
              },
            });
          }
        });
      } else if (res.status === undefined) {
        failToast(`Photo upload failed! Try different format or smaller image`);
        if (this.IsMounted) {
          this.setState({ uploading: false });
        }
      }
      if (this.IsMounted) {
        this.setState({ uploading: false });
      }
    });
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
          crop: {
            unit: "%",
            width: 100,
            aspect: 10 / 10,
          },
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.setState({ croppedImageUrl }, () => {});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);

        function blobToFile(theBlob, fileName) {
          theBlob.lastModifiedDate = new Date();
          theBlob.name = fileName;
          return theBlob;
        }
        var newFile = blobToFile(blob, "name.png");

        var newfile = new File([newFile], "name.png", { type: "image/png" });
        this.setState({ file: newfile });
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  confirmRemoveProject = () => {
    this.props.removeProject(this.props.match.params.projectId, (res) => {
      if (res.status === 200) {
        this.props.history.push(`/projects`);
        successToast("Project removed successfully");
      } else {
        failToast("Project removing failed");
      }
    });
  };
  confirmRemoveProjectModal = () => {
    var mainText = "Remove this project?";
    var subText = "";
    var btnText = "Remove";

    Confirm(mainText, subText, "Cancel", btnText, () => this.confirmRemoveProject());
  };

  render() {
    const loadingProject = this.props.projects.loading;
    const { crop, src } = this.state;
    var content = "";

    var projectImage = "";
    if (this.state.img === null) {
      projectImage = imgPlaceholder;
    } else {
      projectImage = this.state.img;
    }

    var imgContent = "";
    if (this.state.uploading) {
      imgContent = (
        <div>
          <img src={loadingImg} style={{ width: "150px", margin: "auto", display: "block" }} alt="Loading..." />
        </div>
      );
    } else {
      imgContent = (
        <img
          className="card-img-top rounded-circle img-upload"
          src={this.state.croppedImageUrl ? this.state.croppedImageUrl : projectImage}
          alt=""
          onError={(e) => {
            e.preventDefault();
            e.target.onerror = null;
            e.target.src = imgPlaceholder;
          }}
        />
      );
    }

    if (this.state.projectMapped === null || this.state.peopleMapped === null || loadingProject) {
      content = <Spinner />;
    } else {
      var rightContent;
      if (this.state.src === null) {
        rightContent = (
          <div className="project-top-header-title-right--item">
            <Input
              placeholder={"Title"}
              type="text"
              onChange={(e) => this.onChange(e)}
              name={"title"}
              value={this.state.title}
              label="Title*"
              validationMsg={[this.state.errors.title, this.props.errors.title]}
            />
          </div>
        );
      } else {
        rightContent = (
          <div className="project-top-header-title-right--item">
            <div>
              {/* <div className='upload-image-container-img'> */}
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </div>
            <div className="upload-image-container-btns">
              <Btn
                label={"Cancel"}
                className="btn btn-secondary"
                onClick={() =>
                  this.setState({
                    src: null,
                    croppedImageUrl: null,
                    crop: {
                      unit: "%",
                      width: 100,
                      aspect: 10 / 10,
                    },
                  })
                }
              />
              <Btn label={"Upload"} className="btn btn-primary" onClick={this.onFormSubmit} />
            </div>
          </div>
          // </div>
        );
      }

      content = (
        <div className="project">
          <div className="project-top">
            <div className="project-top-header">
              <div className="project-top-header-image">
                <div className="fixed-ratio-div">
                  <label className="rounded-circle">
                    <input
                      className="centered-image rounded-circle"
                      type="file"
                      name="image"
                      accept=".gif,.jpg,.jpeg,.png,.tiff,.webp"
                      onChange={this.onSelectFile}
                      value={""}
                    />
                    {imgContent}
                  </label>
                </div>
              </div>
              <div className="project-top-header-title">{rightContent}</div>
            </div>
            <div className="project-top-people">
              Here you can add people on project.
              <SearchDropdown
                value={this.state.person}
                options={this.state.peopleMapped}
                onChange={this.selectPerson}
                placeholder={"People"}
                label={"People"}
                name={"people"}
                //   validationMsg={this.state.errors.new_user}
                multiple={true}
              />
              <div className="submit-button">
                <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.onSubmit(e)} loading={this.state.requestLoading} />
              </div>
              <React.Fragment key={1}>
                <div className="project-container-bottom">
                  {this.state.project &&
                    this.state.project.teams &&
                    this.state.project.teams.map((team, teamIndex) => (
                      <div className="project-container-bottom--teams" key={teamIndex}>
                        <React.Fragment key={teamIndex}>
                          <div className="project-container-bottom--teams-title">{team.title}</div>
                          {team.people &&
                            team.people.map((person, personIndex) => (
                              <React.Fragment key={personIndex}>
                                <div className="project-container-bottom--teams-people">
                                  <div className="project-container-bottom--teams-people--person">
                                    <div className="project-container-bottom--teams-people--person-name">
                                      {person && person.name ? person.name : ""}
                                    </div>
                                    <div className="project-container-bottom--teams-people--person-seniority">
                                      {person.seniority && person.seniority.title ? `Seniority: ${person.seniority.title}` : ""}
                                    </div>
                                    <div className="project-container-bottom--teams-people--person-technologies">
                                      {person.technologies && person.technologies.length > 0 ? `Technologies: ` : ""}
                                      {person.technologies &&
                                        person.technologies.map((technology, technologyIndex) => (
                                          <React.Fragment key={technologyIndex}>
                                            {technology.title}
                                            {person.technologies.length - 1 > technologyIndex ? `, ` : ``}
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))}
                        </React.Fragment>
                      </div>
                    ))}
                </div>
                {/* </div> */}
              </React.Fragment>
            </div>
          </div>
          <div className="project-bottom">
            <Technology />
            <Seniority />
            <Team />
            <Person />
          </div>
        </div>
      );
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={"Edit Project"}
              link={"/projects"}
              buttons={[<Btn className="btn btn-danger" label="Remove" onClick={(e) => this.confirmRemoveProjectModal()} />]}
            />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

EditProject.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  projects: state.projects,
  people: state.people,
});

export default connect(mapStateToProps, {
  getCandidateProject,
  getPeople,
  updateProject,
  clearProjects,
  uploadProjectPhoto,
  removeProject,
  clearErrors,
})(withRouter(EditProject));
