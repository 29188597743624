import React from "react";
import FormElementTitle from "../form/FormElementTitle";
import RemoveColumnBtn from "../common/RemoveColumnBtn";
// import FullBtn from "./FullBtn";

function InputGroup({
  className,
  type,
  label,
  validationMsg,
  validationMsg2,
  placeholder,
  values,
  onChange,
  addColumn,
  id,
  removeColumn,
  required,
  disabled,
  onKeyDown,
  orderUp,
  orderDown,
}) {


  var validationId = "";
  if(validationMsg2){
    validationId = "validation-msg"
  }

  return (
    <div className='form-element'>
      <div className='form-element-itemCol'>
        <FormElementTitle label={label} validationMsg={validationMsg}  />
      </div>
      {values.map((value, index) => (
        <React.Fragment key={index}>
          {validationMsg2 &&
            validationMsg2.map((error, index2) =>
              value.id === error.id ? (
                <span key={index2}>
                  <div></div>
                  <div className='float-right form-element--validation' id={validationId}>{error.error}</div>
                </span>
              ) : (
                ""
              )
            )}

          <div className='form-element-item' key={index}>
            <input
              type={type}
              placeholder={placeholder}
              value={value.value}
              className='form-element--input'
              onChange={onChange}
              disabled={disabled}
              name={`${id}-${index}`}
              onKeyDown={onKeyDown}
              id={`${id}-${index}`}
            />
            {required === false ? <RemoveColumnBtn index={`${id}-${index}`} onClick={removeColumn} /> : ""}
            {values.length > 1 ? <RemoveColumnBtn index={`${id}-${index}`} onClick={removeColumn} /> : ""}

            {values.length > 1 ? (
              <div className='form-element-reorder'>
                {index > 0 ? (
                  <i className='fas fa-angle-up form-element-reorder-up' id={index} onClick={orderUp}></i>
                ) : (
                  <div className='form-element-reorder-up'></div>
                )}
                {index < values.length - 1 ? (
                  <i className='fas fa-angle-down form-element-reorder-down' id={index} onClick={orderDown}></i>
                ) : (
                  <div className='form-element-reorder-down'></div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </React.Fragment>
      ))}
      {addColumn}
    </div>
  );
}

export default InputGroup;
