import React, { Component } from "react";
import { clearErrors } from "../actions/errorsActions";
import SideMenuOffices from "../components/layout/SideMenuOffices";
import SideMenu from "../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../components/Navigate/Navigate";
// import Spinner from "../components/common/Spinner";
// import ScenariosCard from "../components/Cards/ScenariosCard";
// import { Link } from "react-router-dom";
import { getScenarios } from "../actions/scenarioActions";
import { roles } from "../enums/roles";
import { pageType } from "../enums/pageType";
import CheckPermissions from "../validation/CheckPermissions";

import { withRouter } from "react-router-dom";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        var tokenUserOffices = nextProps.auth.user.offices;
        update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };
        if (prevState.initialRender) {
          update.reviewedFilter = false;
          update.globalFilter = false;
          update.initialRender = false;
        }
        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      //   if (nextProps.scenarios.scenarios !== prevState.scenarios) {
      //     var { scenarios } = nextProps.scenarios;
      //     var scenariosMapped = [];
      //     scenarios.map((scenario) => {
      //       return scenariosMapped.push({
      //         id: scenario.id,
      //         title: scenario.title,
      //         scenario: scenario.scenario,
      //         scenario_plain: scenario.scenario_plain,
      //         automated_count: scenario.automated_count,
      //         report_count: scenario.report_count,
      //         testcase_count: scenario.testcase_count,
      //         link: `/admin/${nextProps.match.params.officeId}/edit-scenario/${scenario.id}`,
      //         created_by: `${scenario.user.first_name} ${scenario.user.last_name}`,
      //       });
      //     });
      //     update.scenariosMapped = scenariosMapped;
      //     update.scenariosUpdated = true;
      //   }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    // this.props.getScenarios(this.props.match.params.officeId);
  }
  render() {
    // const { loading } = this.props.scenarios;
    var content = "Not found";

    return (
      <div className='grid-menu-container'>
        <div className='menu-grid'>
          <div className='menu'>
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className='main-grid'>
          <div className='main'>
            <Navigate title={"Scenarios"} link={`/admin/${this.props.match.params.officeId}/dashboard`} />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

NotFound.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  scenarios: state.scenarios,
});

export default connect(mapStateToProps, { getScenarios, clearErrors })(withRouter(NotFound));
