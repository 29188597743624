import axios from "axios";

import { GET_CANDIDATE_REPORTS, GET_CANDIDATE_REPORT, REPORT_LOADING, CLEAR_REPORTS, REMOVE_LOADING, GET_ERRORS } from "../types";

// Get All Office Candidate Reports
export const getCandidateReports = () => (dispatch) => {
  dispatch(reportLoading());

  axios
    .get("/api/candidate/reports")
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_REPORTS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Office Candidate report
export const getCandidateReport = (reportId) => (dispatch) => {
  axios
    .get(`/api/candidate/reports/${reportId}`)
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_REPORT,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Update Report
export const updateReport = (reportId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidate/reports/${reportId}`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Create Report
export const createReport = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/reports`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Remove Report
export const removeReport = (reportId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/reports/${reportId}`)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

export const uploadReportPhoto = (reportId, file, config, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/report/photo/${reportId}`, file, config)

    .then((res) => {
      dispatch({
        type: GET_CANDIDATE_REPORT,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

export const uploadReportPhotoCreate = (file, config, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/create-report/photo`, file, config)

    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
};

export const removeReportPhoto = (photoId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/report/photo/${photoId}`)

    .then((res) => {
      dispatch({
        type: GET_CANDIDATE_REPORT,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => callback(err));
};

export const removeReportPhotoCreate = (photoId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/create-report/photo/${photoId}`)

    .then((res) => {
      // dispatch({
      //   type: GET_CANDIDATE_REPORT,
      //   payload: res.data,
      // });
      callback(res);
    })
    .catch((err) => callback(err));
};
export const removeUnusedPhotos = (reportIds, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/remove-unused-photos`, reportIds)

    .then((res) => {
      // dispatch({
      //   type: GET_CANDIDATE_REPORT,
      //   payload: res.data,
      // });
      callback(res);
    })
    .catch((err) => callback(err));
};

// Clear Reports
export const clearReports = () => {
  return {
    type: CLEAR_REPORTS,
  };
};

// Report loading
export const reportLoading = () => {
  return {
    type: REPORT_LOADING,
  };
};
