import { GET_EXAM_EMAIL_RECIPIENTS, EXAM_LOADING, GET_EXAM_PARTICIPANTS, GET_EXAM_STATUSES, REMOVE_LOADING } from "../actions/types";

const initialState = {
  emailRecipients: null,
  examStatuses: null,
  examParticipants: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EXAM_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_EXAM_EMAIL_RECIPIENTS:
      return {
        ...state,
        emailRecipients: action.payload,
        loading: false,
      };
    case GET_EXAM_PARTICIPANTS:
      return {
        ...state,
        examParticipants: action.payload,
        loading: false,
      };
    case GET_EXAM_STATUSES:
      return {
        ...state,
        examStatuses: action.payload,
        loading: false,
      };
    case REMOVE_LOADING:
      return {
        ...state,

        loading: false,
      };
    default:
      return state;
  }
}
