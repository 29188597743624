import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { clearErrors } from "../../actions/errorsActions";
import Input from "../../components/common/Input";
import Btn from "../../components/common/Btn";
import Spinner from "../../components/common/Spinner";
import SearchDropdown from "../../components/common/SearchDropdown";
import { getSeniorites } from "../../actions/candidate/seniorityActions";
import { getTechnologies } from "../../actions/candidate/technologyActions";
import { getTeams } from "../../actions/candidate/teamActions";
import { getPeople, createPerson, updatePerson, removePerson } from "../../actions/candidate/peopleActions";
import { getCandidateProject } from "../../actions/candidate/projectActions";
import PersonItemList from "./PersonItemList";
import successToast from "../toast/successToast";
import failToast from "../toast/failToast";
import isEmpty from "../../validation/isEmpty";
import Confirm from "../../components/common/Confirm";
import PersonValidation from "../../validation/PersonValidation";

class Person extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      project: this.props.projects && this.props.project,
      seniorities: this.props.seniorities && this.props.seniorities.seniorities,
      seniority: [],
      teams: this.props.teams && this.props.teams.teams,
      team: [],
      technologies: this.props.technologies && this.props.technologies.technologies,
      technology: [],
      people: this.props.people && this.props.people.people,
      projectMapped: null,
      senioritiesMapped: null,
      technologiesMapped: null,
      peopleMapped: null,
      technologyEdit: null,
      requestLoading: false,
      seniorityEdit: null,
      teamEdit: null,
      personEdit: null,
      person: "",
      teamsMapped: null,
      updateSeniority: false,
      auth: {},
      errors: {},
    };
    this.selectSeniority = this.selectSeniority.bind(this);
    this.selectTeam = this.selectTeam.bind(this);
    this.selectTechnology = this.selectTechnology.bind(this);
    this.selectSeniorityEdit = this.selectSeniorityEdit.bind(this);
    this.selectTeamEdit = this.selectTeamEdit.bind(this);
    this.selectTechnologyEdit = this.selectTechnologyEdit.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.seniorities.seniorities !== prevState.seniorities) {
        update.seniorities = nextProps.seniorities.seniorities;
        update.senioritiesMapped = nextProps.seniorities.seniorities;
      }
      if (nextProps.teams.teams !== prevState.teams) {
        update.teams = nextProps.teams.teams;
        update.teamsMapped = nextProps.teams.teams;
      }
      if (nextProps.technologies.technologies !== prevState.technologies) {
        update.technologies = nextProps.technologies.technologies;
        update.technologiesMapped = nextProps.technologies.technologies;
      }
      if (nextProps.people.people !== prevState.people) {
        update.people = nextProps.people.people;
        update.peopleMapped = nextProps.people.people;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getPeople();
  }
  onChange(e) {
    var field = e.target.name;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (field === "person") {
        this.checkValidationCreate();
      } else if (field === "personEdit") {
        this.checkValidationEdit();
      }
    });
  }

  selectTechnology(value) {
    this.setState({ technology: value });
  }
  selectSeniority(value) {
    if (this.state.seniority === value) {
      this.setState({ seniority: [] });
    } else {
      this.setState({ seniority: value }, () => {});
    }
  }
  selectTeam(value) {
    if (this.state.team === value) {
      this.setState({ team: [] });
    } else {
      this.setState({ team: value }, () => {});
    }
  }

  selectTechnologyEdit(value) {
    this.setState({ technologyEdit: value });
  }
  selectSeniorityEdit(value) {
    if (this.state.seniorityEdit && this.state.seniorityEdit.id === value.id) {
      this.setState({ seniorityEdit: [] });
    } else {
      this.setState({ seniorityEdit: value });
    }
  }
  selectTeamEdit(value) {
    if (this.state.teamEdit && this.state.teamEdit.id === value.id) {
      this.setState({ teamEdit: [] });
    } else {
      this.setState({ teamEdit: value });
    }
  }

  checkValidationCreate() {
    this.props.clearErrors();
    this.setState({ errors: {} });
    var formData = {};
    formData.name = this.state.person;
    formData.technologies = this.state.technologyEdit;
    formData.seniority = this.state.seniority.title;
    formData.team = this.state.team.title;
    const { errors, isValid } = PersonValidation(formData);
    if (!isValid) {
      if (errors.person) {
        errors.personCreate = errors.person;
      }
      this.setState({ errors });
    }
  }

  checkValidationEdit() {
    this.props.clearErrors();
    this.setState({ errors: {} });
    var formData = {};
    formData.technologies = this.state.technologyEdit;
    formData.seniority = this.state.seniorityEdit;
    formData.team = this.state.teamEdit;
    formData.name = this.state.personEdit;
    const { errors, isValid } = PersonValidation(formData);
    if (!isValid) {
      if (errors.person) {
        errors.personEdit = errors.person;
      }
      this.setState({ errors });
    }
  }

  onSubmit() {
    var formData = {};
    formData.name = this.state.person;
    formData.technologies = this.state.technology;
    formData.seniority = this.state.seniority;
    formData.team = this.state.team;
    const { errors, isValid } = PersonValidation(formData);
    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.createPerson(formData, (res) => {
        if (res.status === 200) {
          successToast("Person created successfully");
          this.setState({
            requestLoading: false,
            person: "",
            technology: [],
            seniority: [],
            team: [],
          });
        } else {
          this.setState({ requestLoading: false });
          failToast("Person creating failed");
        }
      });
    } else {
      if (errors.person) {
        errors.personCreate = errors.person;
      }
      this.setState({ errors });
    }
  }
  editPerson(e, person) {
    var personToEdit = e.target.id && !isNaN(e.target.id) ? parseInt(e.target.id) : null;
    this.setState({
      personEdit: null,
      personToEdit,
      technologyEdit: null,
      seniorityEdit: person.seniority,
      teamEdit: person.team,
    });
  }
  confirmEditPerson(person) {
    var formData = {};
    formData.id = person.id;
    formData.technologies = this.state.technologyEdit === null ? person.technologies : this.state.technologyEdit;
    formData.seniority = this.state.seniorityEdit === null ? person.seniority : this.state.seniorityEdit;
    formData.team = this.state.teamEdit === null ? person.team : this.state.teamEdit;
    formData.name = this.state.personEdit === null ? person.name : this.state.personEdit;

    const { errors, isValid } = PersonValidation(formData);
    if (isValid) {
      this.props.updatePerson(formData.id, formData, (res) => {
        this.props.getCandidateProject(this.props.match.params.projectId, () => {});
        if (res.status === 200) {
          successToast("Person updated successfully");
          this.setState({
            personToEdit: null,
            technologyEdit: null,
            seniorityEdit: null,
            teamEdit: null,
            personEdit: null,
          });
        } else {
          failToast("Person update failed");
        }
      });
    } else {
      if (errors.person) {
        errors.personEdit = errors.person;
      }
      this.setState({ errors });
    }
  }

  confirmPersonRemove = (person) => {
    if (!isEmpty(person.id)) {
      this.props.removePerson(person.id, (res) => {
        this.props.getCandidateProject(this.props.match.params.projectId, () => {});
        if (res.status === 200) {
          successToast("Person removed successfully");
        } else {
          failToast("Person removing failed");
        }
      });
    }
  };
  confirmPersonRemoveModal = (person) => {
    var reject = "No";
    var title = "Remove this person?";
    var msg = ``;
    var confirm = "Remove";

    Confirm(title, msg, reject, confirm, (e) => this.confirmPersonRemove(person));
  };

  render() {
    const loadingSeniorities = this.props.seniorities.loading;
    const loadingTechnologies = this.props.technologies.loading;
    const loadingTeams = this.props.teams.loading;
    const loadingPeople = this.props.people.loading;
    var personContent = "";
    var peopleContent = "";
    if (
      this.state.senioritiesMapped === null ||
      this.state.technologiesMapped === null ||
      this.state.teamsMapped === null ||
      this.state.peopleMapped === null ||
      loadingSeniorities ||
      loadingTechnologies ||
      loadingTeams ||
      loadingPeople
    ) {
      personContent = <Spinner />;
    } else {
      if (this.state.people && this.state.people.length > 0) {
        peopleContent = this.state.people.map((person, index) => (
          <PersonItemList
            index={index}
            key={index}
            id={person.id}
            value={person}
            technologies={this.state.technologies}
            selectedTechnologies={this.state.technologyEdit ? this.state.technologyEdit : person.technologies}
            seniorities={this.state.seniorities}
            selectedSeniority={this.state.seniorityEdit ? this.state.seniorityEdit : person.seniority}
            teams={this.state.teams}
            selectedTeam={this.state.teamEdit ? this.state.teamEdit : person.team}
            title={this.state.personEdit !== null ? this.state.personEdit : person.name}
            onClickEdit={(e) => this.editPerson(e, person)}
            cancelEdit={(e) => this.setState({ personToEdit: null })}
            onClickConfirmEdit={(e) => this.confirmEditPerson(person)}
            onClickRemove={(e) => this.confirmPersonRemoveModal(person)}
            onChange={(e) => this.onChange(e, this.state.personToEdit)}
            validationMsg={[this.props.errors.personEdit, this.state.errors.personEdit]}
            idToEdit={this.state.personToEdit}
            onEditTechnology={this.selectTechnologyEdit}
            onEditSeniority={this.selectSeniorityEdit}
            onEditTeam={this.selectTeamEdit}
          />
        ));
      } else {
        peopleContent = <div className="no-content">No people created</div>;
      }

      personContent = (
        <div className="project-bottom-technologies">
          Here you can create people (John Doe, Petar Petrovic...) and assign them one team, one seniority and multuple tehnologies.
          <Input
            placeholder={"Person"}
            type="text"
            onChange={(e) => this.onChange(e)}
            name={"person"}
            label="Person"
            value={this.state.person}
            validationMsg={[this.state.errors.personCreate, this.props.errors.personCreate]}
          />
          <SearchDropdown
            value={this.state.technology}
            options={this.state.technologies}
            onChange={this.selectTechnology}
            placeholder={"Technology"}
            label={"Technologies"}
            name={"technology"}
            validationMsg={this.state.errors.technology}
            multiple={true}
          />
          <SearchDropdown
            value={this.state.seniority}
            options={this.state.seniorities}
            onChange={this.selectSeniority}
            placeholder={"Seniority"}
            label={"Seniorities"}
            name={"seniority"}
            validationMsg={this.state.errors.seniority}
            multiple={false}
          />
          <SearchDropdown
            value={this.state.team}
            options={this.state.teams}
            onChange={this.selectTeam}
            placeholder={"Team"}
            label={"Teams"}
            name={"team"}
            validationMsg={this.state.errors.team}
            multiple={false}
          />
          <div className="submit-button">
            <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.onSubmit(e)} loading={this.state.requestLoading} />
          </div>
          {peopleContent}
        </div>
      );
    }

    return personContent;
  }
}

Person.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  seniorities: state.seniorities,
  teams: state.teams,
  technologies: state.technologies,
  people: state.people,
});

export default connect(mapStateToProps, {
  getTeams,
  getSeniorites,
  getTechnologies,
  getPeople,
  createPerson,
  updatePerson,
  removePerson,
  getCandidateProject,
  clearErrors,
})(withRouter(Person));
