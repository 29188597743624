import {
  GET_OFFICE_CANDIDATE,
  GET_OFFICE_CANDIDATES,
  GET_CANDIDATE_DASHBOARD,
  CANDIDATE_LOADING,
  GET_CANDIDATE_REPOS,
  GET_CANDIDATE_SCENARIOS,
  GET_GLOBAL_CANDIDATES,
  GET_GLOBAL_CANDIDATE,
  INVITED_CANDIDATE,
  INVITE_CANDIDATE,
  CLEAR_REPOS,
  REMOVE_LOADING,
} from "../actions/types";

const initialState = {
  officeCandidate: null,
  officeCandidates: null,
  candidateRepos: null,
  candidateScenarios: null,
  dashboard: null,
  inviteCandidate: null,
  invitedCandidate: null,
  globalCandidates: null,
  globalCandidate: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CANDIDATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_OFFICE_CANDIDATES:
      return {
        ...state,
        officeCandidates: action.payload,
        loading: false,
      };
    case GET_OFFICE_CANDIDATE:
      return {
        ...state,
        officeCandidate: action.payload,
        loading: false,
      };
    case GET_CANDIDATE_REPOS:
      return {
        ...state,
        candidateRepos: action.payload,
        loading: false,
      };
    case GET_CANDIDATE_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
        loading: false,
      };
    case GET_CANDIDATE_SCENARIOS:
      return {
        ...state,
        candidateScenarios: action.payload,
        loading: false,
      };
    case CLEAR_REPOS:
      return {
        ...state,
        candidateRepos: null,
        loading: false,
      };
    case INVITE_CANDIDATE:
      return {
        ...state,
        inviteCandidate: action.payload,
        loading: false,
      };
    case INVITED_CANDIDATE:
      return {
        ...state,
        invitedCandidate: action.payload,
        loading: false,
      };
    case GET_GLOBAL_CANDIDATES:
      return {
        ...state,
        globalCandidates: action.payload,
        loading: false,
      };
    case GET_GLOBAL_CANDIDATE:
      return {
        ...state,
        globalCandidate: action.payload,
        loading: false,
      };

    case REMOVE_LOADING:
      return {
        ...state,

        loading: false,
      };
    default:
      return state;
  }
}
