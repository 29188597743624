import React from "react";
import { Link } from "react-router-dom";
import { roles } from "../../enums/roles";

export default function CandidateScenariosContainer({ props, activeScenariosCount, previousScenariosCount }) {
  var currentScenario;

  var officeId = props.match.params.officeId ? parseInt(props.match.params.officeId) : null;

  const result = props.auth.user.offices.filter((office) => office.user_role_office.office_id === officeId);
  var loggedUserRole = result[0].user_role_office.role.value;

  if (activeScenariosCount > 0) {
    currentScenario = (
      <Link
        className={`btn btn-light`}
        to={`/admin/${props.match.params.officeId}/candidate/${props.match.params.candidateId}/current-scenario`}
      >
        Current
      </Link>
    );
  } else {
    if (roles.QA !== loggedUserRole && roles.GLOBALVIEWER !== loggedUserRole) {
      currentScenario = (
        <Link
          className={`btn btn-light`}
          to={`/admin/${props.match.params.officeId}/candidate/${props.match.params.candidateId}/new-scenario`}
        >
          Set New Scenario
        </Link>
      );
    } else {
      currentScenario = "";
    }
  }

  var previousCount = "";
  var previousScenarioDisabled = "disabled";
  if (previousScenariosCount > 0) {
    previousCount = `(${previousScenariosCount})`;
    previousScenarioDisabled = "";
  }
  return (
    <div>
      <div className="candidate-bottom--item">
        <div className="candidate-bottom--item-title">Scenarios:</div>
        <div className="candidate-bottom--item-info">
          Current scenario assigned to candidate with options to change scenario or disable user. Previous scenarios from previous exams
          with statuses if any.
        </div>
        <div className="candidate-bottom--item-value">
          <div className="profile-buttons-grid">
            {currentScenario}
            <Link
              className={`btn btn-light ${previousScenarioDisabled}`}
              to={`/admin/${props.match.params.officeId}/candidate/${props.match.params.candidateId}/previous-scenarios`}
            >
              Previous {previousCount}
            </Link>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}
