import {
  GET_OFFICE_USERS,
  GET_OFFICE_USER,
  GET_AVAILABLE_USERS,
  GET_USER_GLOBAL_ROLE,
  GET_GLOBAL_USERS,
  GET_GLOBAL_USER,
  USER_LOADING,
  REMOVE_LOADING,
} from "../actions/types";

const initialState = {
  officeUsers: null,
  officeUser: null,
  globalRole: null,
  availableUsers: null,
  globalUsers: null,
  globalUser: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_OFFICE_USERS:
      return {
        ...state,
        officeUsers: action.payload,
        loading: false,
      };
    case GET_OFFICE_USER:
      return {
        ...state,
        officeUser: action.payload,
        loading: false,
      };
    case GET_AVAILABLE_USERS:
      return {
        ...state,
        availableUsers: action.payload,
        loading: false,
      };
    case GET_USER_GLOBAL_ROLE:
      return {
        ...state,
        globalRole: action.payload,
        loading: false,
      };
    case GET_GLOBAL_USERS:
      return {
        ...state,
        globalUsers: action.payload,
        loading: false,
      };
    case GET_GLOBAL_USER:
      return {
        ...state,
        globalUser: action.payload,
        loading: false,
      };
    case REMOVE_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
