import { GET_CANDIDATE_TESTCASES, GET_CANDIDATE_TESTCASE, TESTCASE_LOADING, REMOVE_LOADING } from "../actions/types";

const initialState = {
  testcases: null,
  testcase: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TESTCASE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CANDIDATE_TESTCASES:
      return {
        ...state,
        testcases: action.payload,
        loading: false,
      };
    case GET_CANDIDATE_TESTCASE:
      return {
        ...state,
        testcase: action.payload,
        loading: false,
      };

    case REMOVE_LOADING:
      return {
        ...state,

        loading: false,
      };

    default:
      return state;
  }
}
