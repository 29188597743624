import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import Input from "../../../components/common/Input";
import Btn from "../../../components/common/Btn";
import { createCandidate } from "../../../actions/candidateActions";
import AddCandidateValidation from "../../../validation/AddCandidateValidation";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";

class AddCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      submitPressed: false,
      mounted: false,
      first_name: "",
      last_name: "",
      email: "",
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR, roles.RECRUITER],
      auth: {},
      active: false,
      requestLoading: false,
      errors: {},
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        var tokenUserOffices = nextProps.auth.user.offices;
        update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/candidates`,
          roles_allowed_to_write: prevState.rolesAllowedToOpen,
          page_type: pageType.WRITE,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    // this.props.getAvailableUsers(this.props.match.params.officeId);
    // this.props.getOfficeRoles();
  }
  componentWillUnmount() {
    this.props.clearErrors();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }

  checkValidation() {
    this.setState({ errors: {} });
    this.props.clearErrors();
    var formData = {};
    formData.first_name = this.state.first_name ? this.state.first_name : null;
    formData.last_name = this.state.last_name ? this.state.last_name : null;
    formData.email = this.state.email ? this.state.email : null;
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }

    const { errors } = AddCandidateValidation(formData);
    this.setState({ errors });
  }

  submitForm(e) {
    this.setState({ errors: {}, submitPressed: true });
    e.preventDefault();
    var formData = {};

    formData.first_name = this.state.first_name ? this.state.first_name.trim() : null;
    formData.last_name = this.state.last_name ? this.state.last_name.trim() : null;
    formData.email = this.state.email ? this.state.email.trim() : null;
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }

    const { errors, isValid } = AddCandidateValidation(formData);
    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.createCandidate(formData, (res) => {
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Candidate added successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/candidates`);
        } else {
          this.setState({ requestLoading: false });
          failToast("Candidate adding failed");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/new-candidate`);
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ requestLoading: false });
      });
    }
  }
  render() {
    var content = (
      <div className="width-cointainer default-container default-padding">
        <div>
          <h2>Add new candidate</h2>
          <Input
            placeholder="First name"
            name={"first_name"}
            label={"First name"}
            onChange={(e) => this.onChange(e)}
            //   onKeyDown={this.handleKeyDown}
            value={this.state.new_first_name}
            validationMsg={[this.props.errors.first_name, this.state.errors.first_name]}
          />
          <Input
            placeholder="Last name"
            name={"last_name"}
            label={"Last name"}
            onChange={(e) => this.onChange(e)}
            //   onKeyDown={this.handleKeyDown}
            value={this.state.last_name}
            validationMsg={[this.props.errors.last_name, this.state.errors.last_name]}
          />
          <Input
            placeholder="Email"
            name={"email"}
            label={"Email"}
            onChange={(e) => this.onChange(e)}
            //   onKeyDown={this.handleKeyDown}
            value={this.state.email}
            validationMsg={[this.props.errors.email, this.state.errors.email]}
          />
          {/* <SearchDropdown
            label={"Roles"}
            placeholder={"Role"}
            value={this.state.roleNew}
            name={"new_role_id"}
            validationMsg={this.state.errors.new_role_id}
            onChange={this.selectRoleNew}
            multiple={false}
            options={this.state.officeRolesMapped}
            isRequired={true}
          /> */}
          <div className="submit-button">
            <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
          </div>
        </div>
      </div>
    );

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate title={"Add Candidate"} link={`/admin/${this.props.match.params.officeId}/candidates`} />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddCandidate.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  users: state.users,
  //   roles: state.roles,
});

export default connect(mapStateToProps, {
  //   getAvailableUsers,
  //   getOfficeRoles,
  //   addExistingUserToOffice,
  createCandidate,
  clearErrors,
})(withRouter(AddCandidate));
