import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "../../components/common/Input";
import Btn from "../../components/common/Btn";
import successToast from "../../components/toast/successToast";
import failToast from "../../components/toast/failToast";
import { forgotPasswordEmail } from "../../actions/candidate/profileActions";
import EmailValidation from "../../validation/EmailValidation";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      requestLoading: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    var data = {};
    data.email = this.state.email;

    var { errors, isValid } = EmailValidation(data);

    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.forgotPasswordEmail(data, (res) => {
        if (res.status === 200) {
          successToast("Email sent successfully");
          this.props.history.push(`/login`);
        } else {
          failToast("Email sending failed");
        }
        this.setState({ requestLoading: false });
      });
    } else {
      this.setState({ requestLoading: false });
      this.setState({ errors });
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="grid-container">
        <div className="main">
          <div className="landing">
            <div className="landing-title text-center">Forgot Password</div>
            <p className="landing-description text-center">Enter your email to receive instructions</p>
            <Input placeholder={"Email"} label="Email" onChange={(e) => this.onChange(e)} name={"email"} validationMsg={errors.email} />
            <br />
            <Btn className="full-width-btn" label="Send" onClick={(e) => this.onSubmit(e)} loading={this.state.requestLoading} />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  forgotPasswordEmail,
})(ForgotPassword);
