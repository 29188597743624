import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Navigate from "../../../components/Navigate/Navigate";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";

import {
  getOfficeCandidate,
  getCandidateCV,
  uploadCandidateCV,
  // getCandidateRepositories,
  clearRepos,
  removeCandidateCV,
} from "../../../actions/candidateActions";
import { getCandidateTestcases, clearTestcases } from "../../../actions/testcaseActions";

import { getCandidateReports, clearReports } from "../../../actions/reportActions";
import { getCandidateProjects, clearProjects } from "../../../actions/projectActions";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";

import Spinner from "../../../components/common/Spinner";
import Btn from "../../../components/common/Btn";
import CandidateTopContainer from "../../../components/Cards/CanddiateTopContainer";
import CandidateContentContainer from "../../../components/Cards/CandidateContentContainer";
import CandidateScenariosContainer from "../../../components/Cards/CandidateScenariosContainer";
import CandidateCvContainer from "../../../components/Cards/CandidateCvContainer";
import CandidateCommentContainer from "../../../components/Cards/CandidateCommentContainer";
import isEmpty from "../../../validation/isEmpty";

class Candidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      initialRender: true,
      mounted: false,
      auth: {},
      officeCandidate: this.props.candidates && this.props.candidates.officeCandidate,
      candidateRepos: this.props.candidates && this.props.candidates.candidateRepos,
      testcases: this.props.testcases && this.props.testcases.testcases,
      comments: this.props.comments && this.props.comments.comments,
      projects: this.props.projects && this.props.projects.projects,
      reports: this.props.reports && this.props.reports.reports,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR, roles.RECRUITER, roles.QA, roles.GLOBALVIEWER],
      latestScenario: {},
      github_username: "",
      repos: [],
      daysLeftToFinish: "",
      errors: {},
    };
    this.onFormSubmitCv = this.onFormSubmitCv.bind(this);
    this.removeCVClick = this.removeCVClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        const officeRoleObject =
          nextProps.auth &&
          nextProps.auth.user &&
          nextProps.auth.user.offices &&
          nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
        var userRoleOffice =
          officeRoleObject &&
          officeRoleObject[0] &&
          officeRoleObject[0].user_role_office &&
          officeRoleObject[0].user_role_office.role.value;
        update.userRoleOffice = userRoleOffice;
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: prevState.rolesAllowedToOpen,
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.testcases.testcases !== prevState.testcases) {
        update.testcases = nextProps.testcases.testcases;

        var testcases = nextProps.testcases.testcases;
        update.testcases = testcases;
      }
      if (nextProps.comments.comments !== prevState.comments) {
        update.comments = nextProps.comments.comments;

        var comments = nextProps.comments.comments;
        update.comments = comments;
      }
      if (nextProps.projects.projects !== prevState.projects) {
        update.projects = nextProps.projects.projects;

        var projects = nextProps.projects.projects;
        update.projects = projects;
      }
      if (nextProps.reports.reports !== prevState.reports) {
        update.reports = nextProps.reports.reports;

        var reports = nextProps.reports.reports;
        update.reports = reports;
      }
      if (nextProps.candidates.officeCandidate !== prevState.officeCandidate) {
        update.officeCandidate = nextProps.candidates.officeCandidate;
        var officeCandidate = nextProps.candidates.officeCandidate;
        update.github_username = officeCandidate.github_username;
        var activeScenarios = 0;
        officeCandidate &&
          officeCandidate.candidate_scenarios &&
          officeCandidate.candidate_scenarios.map((scenario) => {
            if (scenario.active) {
              activeScenarios++;
            }
            return null;
          });

        var previousScenarios = 0;
        officeCandidate &&
          officeCandidate.candidate_scenarios &&
          officeCandidate.candidate_scenarios.map((scenario) => {
            if (!scenario.active) {
              previousScenarios++;
            }
            return null;
          });

        var latestScenario =
          officeCandidate &&
          officeCandidate.candidate_scenarios &&
          officeCandidate.candidate_scenarios.sort((a, b) => new Date(b.assigned_at).getTime() - new Date(a.assigned_at).getTime())[0];
        update.cv = nextProps.candidates.officeCandidate.cv;
        update.latestScenario = latestScenario;
        update.officeCandidate = officeCandidate;
        update.officeCandidatesUpdated = true;
        update.activeScenariosCount = activeScenarios;
        update.previousScenariosCount = previousScenarios;
      }
      // if (officeCandidate && officeCandidate.github_username) {
      //   nextProps.getCandidateRepositories(officeCandidate.github_username);
      // }
      if (nextProps.candidates.candidateRepos !== prevState.candidateRepos) {
        var candidateRepos = nextProps.candidates.candidateRepos;
        update.candidateRepos = candidateRepos;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.IsMounted = true;
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/candidates`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {});
      this.props.getCandidateTestcases(this.props.match.params.candidateId);
      // this.props.getCandidateComments(this.props.match.params.candidateId);
      this.props.getCandidateProjects(this.props.match.params.candidateId);
      this.props.getCandidateReports(this.props.match.params.candidateId);
      this.props.getCandidateCV(this.props.match.params.candidateId, this.props.match.params.officeId, (res) => {
        if (res) {
          if (this.IsMounted) {
            this.setState({
              cvRoute: res,
            });
          }
        }
      });
    }
  }

  componentWillUnmount() {
    this.IsMounted = false;
    this.props.clearRepos();
    this.props.clearTestcases();
    this.props.clearReports();
    this.props.clearProjects();
  }

  onFormSubmitCv(e) {
    this.setState({ uploadingCv: true });
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.fileCv);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    this.props.uploadCandidateCV(this.props.match.params.candidateId, this.props.match.params.officeId, formData, config, (res) => {
      if (res.status === 200) {
        successToast("CV successfully uploaded");
        this.props.getCandidateCV(this.props.match.params.candidateId, this.props.match.params.officeId, (res) => {
          if (res) {
            if (this.IsMounted) {
              this.setState({
                cvRoute: res,
                uploadingCv: false,
              });
            }
          }
        });
        if (this.IsMounted) {
          this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {});
        }
      } else {
        failToast(`CV upload failed! Must be pdf and less than 1MB`);
      }
      if (this.IsMounted) {
        this.setState({
          fileCv: null,
          uploadingCv: false,
        });
      }
    });
  }
  removeCVClick() {
    this.props.removeCandidateCV(this.props.match.params.candidateId, this.props.match.params.officeId, (res) => {
      if (res.status === 200) {
        if (this.IsMounted) {
          this.setState({ cvRoute: null });
        }
        successToast("CV successfully removed");
        this.props.getOfficeCandidate(this.props.match.params.candidateId, this.props.match.params.officeId, () => {});
      } else {
        failToast("Something went wrong");
      }
    });
  }
  onChange(e) {
    this.setState({ fileCv: e.target.files[0] }, () => {});
  }

  render() {
    var candidate = this.state.officeCandidate;
    var latestScenario = this.state.latestScenario;
    const { loading } = this.props.candidates;
    var content;
    if (!this.state.officeCandidatesUpdated || loading) {
      content = <Spinner />;
    } else {
      if (isEmpty(candidate)) {
        content = <div>No candidate with this ID</div>;
      } else {
        var cvComponent = "";
        if (
          this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
          this.state.userRoleOffice === roles.ADMINISTRATOR ||
          this.state.userRoleOffice === roles.RECRUITER
        ) {
          cvComponent = (
            <CandidateCvContainer
              fileCv={this.state.fileCv}
              cv={this.state.cv}
              uploadingCv={this.state.uploadingCv}
              onFormSubmitCv={this.onFormSubmitCv}
              onChange={this.onChange}
              removeCv={this.removeCVClick}
            />
          );
        }

        content = (
          <div className="candidate width-container">
            <CandidateTopContainer candidate={candidate} latestScenario={latestScenario} cv={this.state.cv} cvRoute={this.state.cvRoute} />
            <div className="candidate-bottom">
              <CandidateContentContainer
                props={this.props}
                repoCount={this.state.candidateRepos ? this.state.candidateRepos.length : 0}
                testcaseCount={this.state.testcases ? this.state.testcases.length : 0}
                reportCount={this.state.reports ? this.state.reports.length : 0}
                projectCount={this.state.projects ? this.state.projects.length : 0}
              />
              <CandidateScenariosContainer
                props={this.props}
                activeScenariosCount={this.state.activeScenariosCount}
                previousScenariosCount={this.state.previousScenariosCount}
              />
              {cvComponent}
              {<CandidateCommentContainer comments={this.state.comments} author={this.state.auth.user} />}
            </div>
          </div>
        );
      }
      var editCandidateBtn = [];
      if (
        (this.state.userRoleOffice === roles.SUPERADMINISTRATOR || this.state.userRoleOffice === roles.ADMINISTRATOR) &&
        !isEmpty(candidate)
      ) {
        editCandidateBtn = [
          <Btn
            className="btn btn-primary"
            label={<i className="fas fa-user-edit"></i>}
            onClick={(e) =>
              this.props.history.push(`/admin/${this.props.match.params.officeId}/candidates/${this.props.match.params.candidateId}/edit`)
            }
          />,
        ];
      }
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate title={"Candidate"} buttons={editCandidateBtn} link={`/admin/${this.props.match.params.officeId}/candidates`} />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Candidate.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  testcases: state.testcases,
  reports: state.reports,
  candidates: state.candidates,
  projects: state.projects,
  comments: state.comments,
  exams: state.exams,
});

export default connect(mapStateToProps, {
  getOfficeCandidate,
  getCandidateCV,
  uploadCandidateCV,
  clearRepos,
  getCandidateTestcases,
  removeCandidateCV,
  clearTestcases,
  // getCandidateRepositories,
  getCandidateReports,
  // getCandidateComments,
  clearReports,
  getCandidateProjects,
  // clearComments,
  clearProjects,
  clearErrors,
})(withRouter(Candidate));
