import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import SideMenuOffices from "../../../components/layout/SideMenuOffices";
// import SideMenu from "../../../components/layout/SideMenu";

import Navigate from "../../../components/Navigate/Navigate";
// import { Link } from "react-router-dom";
import CandidateSettingsCard from "../../../components/Cards/CandidateSettingsCard";
import Input from "../../../components/common/Input";
import Dropdown from "../../../components/common/Dropdown";
import Btn from "../../../components/common/Btn";
import isEmpty from "../../../validation/isEmpty";
import Spinner from "../../../components/common/Spinner";

import { getGlobalCandidates } from "../../../actions/candidateActions";
import { getExamStatuses } from "../../../actions/examActions";
import { roles } from "../../../enums/roles";
import { accountType } from "../../../enums/accountType";
import moment from "moment";

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;
class Candidates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      globalCandidates: this.props.candidates && this.props.candidates.globalCandidates,
      examStatuses: this.props.exams && this.props.exams.examStatuses,
      auth: {},
      examStatusesMapped: [],
      candidates_search: "",
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        if (!nextProps.auth.isAuthenticated || !accountType.USER) {
          nextProps.history.push("/");
        }
        if (
          (nextProps.auth.user.offices &&
            nextProps.auth.user.offices[0] &&
            nextProps.auth.user.offices[0].user_role_office.role.value !== roles.SUPERADMINISTRATOR) ||
          isEmpty(nextProps.auth.user.offices)
        ) {
          nextProps.history.push("/");
        }

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.exams.examStatuses !== prevState.examStatuses) {
        update.examStatuses = nextProps.exams.examStatuses;
        var mappedExamStatuses = [];
        var examStatuses = nextProps.exams.examStatuses;
        examStatuses &&
          examStatuses.map((status) => {
            return mappedExamStatuses.push({ id: status.id, title: status.value });
          });
        mappedExamStatuses.push({ id: -1, title: "No Exam" });
        update.examStatusesMapped = mappedExamStatuses;
        update.examStatusesUpdated = true;
      }

      if (nextProps.candidates.globalCandidates !== prevState.globalCandidates) {
        update.globalCandidates = nextProps.candidates.globalCandidates;

        var globalCandidates = nextProps.candidates.globalCandidates;

        var mappedGlobalCandidates = [];
        globalCandidates &&
          globalCandidates.map((candidate) => {
            var latestScenario = candidate.candidate_scenarios.sort(
              (a, b) => new Date(b.assigned_at).getTime() - new Date(a.assigned_at).getTime()
            )[0];
            var daysLeftToFinish = "";
            if (latestScenario && latestScenario.days_to_finish && latestScenario.assigned_at && latestScenario.active) {
              var dateToFinish = moment(moment(latestScenario.assigned_at).add(latestScenario.days_to_finish, "days"));
              var date_to_finish = moment(dateToFinish);
              var current_date = moment();
              var daysLeft = date_to_finish.diff(current_date, "days");
              if (daysLeft > 0) {
                daysLeftToFinish = ` (${daysLeft} days left)`;
              } else if (daysLeft === 0) {
                if (current_date > date_to_finish) {
                  daysLeftToFinish = ` (expired today)`;
                } else {
                  daysLeftToFinish = ` (last day)`;
                }
              } else {
                if (Math.abs(daysLeft) === 1) {
                  daysLeftToFinish = ` (${Math.abs(daysLeft)} day over)`;
                } else {
                  daysLeftToFinish = ` (${Math.abs(daysLeft)} days over)`;
                }
              }
            }

            return mappedGlobalCandidates.push({
              id: candidate.id,
              email: candidate.email,
              first_name: candidate.first_name,
              last_name: candidate.last_name,
              img: candidate.img,
              office: candidate.offices.value,
              active: candidate.active,
              last_login: candidate.last_login,
              registered: candidate.registered,
              exams: candidate.candidate_scenarios && candidate.candidate_scenarios.length,
              status: latestScenario && latestScenario.status.value ? `${latestScenario.status.value} ${daysLeftToFinish}` : null,
            });
          });
        update.globalCandidatesMapped = mappedGlobalCandidates;
        update.globalCandidatesUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    this.props.getGlobalCandidates();
    this.props.getExamStatuses();
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.candidatesSearch();
    });
  }
  candidatesSearch() {
    var filters = {};
    if (!isEmpty(this.state.candidates_search)) {
      filters.candidates_search = this.state.candidates_search;
    }

    if (!isEmpty(this.state.exam_status) && !isNaN(this.state.exam_status) && parseInt(this.state.exam_status) !== 0) {
      filters.exam_status = parseInt(this.state.exam_status);
    }

    if (!isEmpty(this.state.active) && !isNaN(this.state.active) && parseInt(this.state.active) !== 0) {
      if (parseInt(this.state.active) === 2) {
        filters.active = parseInt(this.state.active);
      } else if (parseInt(this.state.active) === 3) {
        filters.active = parseInt(this.state.active);
      }
    }

    if (Object.keys(filters).length > 0) {
      this.setState({ filters }, () => {});
      this.props.getGlobalCandidates(filters);
    } else {
      this.props.getGlobalCandidates();
    }
  }

  timer = null;
  handleChange = (e) => {
    clearTimeout(this.timer);
    this.setState({ candidates_search: e.target.value }, () => {});
    this.timer = setTimeout((timeout) => {
      this.triggerChange(timeout);
    }, WAIT_INTERVAL);
  };

  handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.triggerChange(e);
    }
  };

  clearFilters() {
    this.setState(
      {
        candidates_search: "",
        active: "",
        exam_status: "",
      },
      () => {
        this.candidatesSearch();
      }
    );
  }
  triggerChange = (e) => {
    this.setState({ candidates_search: this.state.candidates_search }, () => {
      this.candidatesSearch();
    });
  };

  render() {
    const { loading } = this.props.candidates;
    var content;
    if (!this.state.globalCandidatesUpdated || loading) {
      content = <Spinner />;
    } else {
      content = <CandidateSettingsCard candidates={this.state.globalCandidatesMapped} props={this.props} filters={this.state.filters} />;
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Candidates"} link={`/admin/settings`} buttons={[]} />
            <div className="search-container">
              <div className="search-container-search">
                <Input
                  placeholder="Search candidates..."
                  noTitle={true}
                  onChange={(e) => this.handleChange(e)}
                  name={"candidates_search"}
                  value={this.state.candidates_search}
                />
              </div>
              <div className="search-container-filter">
                <Dropdown
                  placeholder="Active"
                  options={[
                    { id: 1, title: "All" },
                    { id: 2, title: "Activated" },
                    { id: 3, title: "Deactivated" },
                  ]}
                  onChange={(e) => this.onChange(e)}
                  name={"active"}
                  value={this.state.active}
                  noTitle={true}
                />
              </div>
              <div className="search-container-filter">
                <Dropdown
                  placeholder="Latest Exam Status"
                  options={this.state.examStatusesMapped}
                  onChange={(e) => this.onChange(e)}
                  name={"exam_status"}
                  value={this.state.exam_status}
                  noTitle={true}
                />
              </div>
              <div className="search-container-clear mb-1">
                <Btn label="Clear" className="btn btn-secondary" onClick={(e) => this.clearFilters()} />
              </div>
            </div>
            {content}
          </div>
        </div>
      </div>
    );
  }
}

Candidates.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  offices: state.offices,
  candidates: state.candidates,
  exams: state.exams,
});

export default connect(mapStateToProps, {
  getGlobalCandidates,
  getExamStatuses,
  clearErrors,
})(withRouter(Candidates));
