import React from "react";
import imgPlaceholder from "../../img/noImg.png";
import { Link } from "react-router-dom";

export default function Card({ img, imgTitle, title, subtitle, link, statusColor, statusMsg, disabled }) {
  var statusColorValue = "fail";
  if (statusColor === "danger") {
    statusColorValue = "danger";
  } else if (statusColor === "warning") {
    statusColorValue = "warning";
  } else if (statusColor === "success") {
    statusColorValue = "success";
  }
  var disabledDiv = "";
  if (disabled) {
    disabledDiv = "disabled";
  }
  return (
    <Link to={link} className={`card ${disabledDiv}`}>
      <div className="card-image">
        <div className="fixed-ratio-div">
          <img
            className="card-img-top rounded-circle "
            src={img}
            alt=""
            onError={(e) => {
              e.preventDefault();
              e.target.onerror = null;
              e.target.src = imgPlaceholder;
            }}
          />
        </div>
      </div>
      <div className="card-title">{title}</div>
      <div className="card-subtitle">{subtitle}</div>
      <div className={`card-status-${statusColorValue}`}>{statusMsg}</div>
    </Link>
  );
}
