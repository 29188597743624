import React from "react";
import moment from "moment";
export default function RepositoryCards({ repositories }) {
  return (
    <div className='portrait-grid'>
      {repositories.map((repo, index) => (
        <div className='preview-card preview-card-not-clickable' key={index}>
          <div className='preview-card-title'>
            <div className='preview-card-title-value'>{repo.title}</div>
          </div>
          <div className='preview-card-body'>
            <div className='preview-card-body--items'>
              <div className='preview-card-body--items-single'>
                <div className='preview-card-body--items-single-title'>Created:</div>
                <div className='preview-card-body--items-single-value'>
                  {moment(repo.created).format("DD.MM.YYYY HH:mm:ss")}
                </div>
              </div>
            </div>
            <div className='preview-card-body--items'>
              <div className='preview-card-body--items-single'>
                <div className='preview-card-body--items-single-title'>Updated:</div>
                <div className='preview-card-body--items-single-value'>
                  {moment(repo.updated).format("DD.MM.YYYY HH:mm:ss")}
                </div>
              </div>
            </div>
            <div className='preview-card-body--items'>
              <div className='preview-card-body--items-single'>
                <div className='preview-card-body--items-single-title'></div>
                <div className='preview-card-body--items-single-value'></div>
              </div>
            </div>{" "}
            <div className='preview-card-body--items'>
              <div className='preview-card-body--items-single'>
                <div className='preview-card-body--items-single-title'></div>
                <div className='preview-card-body--items-single-value'></div>
              </div>
            </div>
            <div className='preview-card-body--items'>
              <div className='preview-card-body--items-single'>
                <div className='preview-card-body--items-single-title'></div>
                <div className='preview-card-body--items-single-value'></div>
              </div>
            </div>
            <a href={repo.link} target='_blank' rel='noopener noreferrer' className='preview-card-body--items'>
              <div className='preview-card-body--items-single-preview'>
                <div className='preview-card-body--items-single-preview-title'>Open repo</div>
                <div className='preview-card-body--items-single-preview-value'>
                  {/* <a href={repo.link} target='_blank' rel='noopener noreferrer'> */}
                  <i className='fas fa-external-link-alt'></i>
                  {/* </a> */}
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
