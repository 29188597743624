import React from "react";
import { Link } from "react-router-dom";

export default function AdminCard({ cards }) {
  if (cards.length > 0) {
    var content = (
      <div className='admin-dashboard-grid'>
        {cards &&
          cards.map((card, index) => (
            <Link to={card.link} key={index}>
              <div className='admin-card'>
                <div className='admin-card-title'>{card.title}</div>
                <div className='admin-card-subtitle'>{card.subtitle}</div>
              </div>
            </Link>
          ))}
      </div>
    );
  } else {
    content = <div className='no-content'>It seems that you are not added to any office yet</div>;
  }
  return <div>{content}</div>;
}
