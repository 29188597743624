import axios from "axios";
import setAuthToken from "./setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import store from "../store";

// Login - Get User Token
export const loginUser = (userData, callback) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage
      const { token, refreshToken } = res.data;
      // Set token to ls
      localStorage.setItem("jwtSandboxToken", token);
      localStorage.setItem("jwtSandboxRefreshToken", refreshToken);
      // Set token to Auth header

      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Login - Get User Token
export const candidateLogin = (userData, callback) => (dispatch) => {
  axios
    .post("/api/candidate/login", userData)
    .then((res) => {
      // Save to localStorage
      const { token, refreshToken } = res.data;
      // Set token to ls
      localStorage.setItem("jwtSandboxToken", token);
      localStorage.setItem("jwtSandboxRefreshToken", refreshToken);
      // Set token to Auth header

      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtSandboxToken");
  localStorage.removeItem("jwtSandboxRefreshToken");

  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will also set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const refreshToken = () => (dispatch) => {
  var cryptedRefreshToken = localStorage.getItem("jwtSandboxRefreshToken");

  let refreshTokenObj = {
    refreshToken: cryptedRefreshToken,
  };

  axios
    .post("/api/token", refreshTokenObj)
    .then((res) => {
      // Save to localStorage
      const { token, refreshToken } = res.data;
      // Set token to ls
      localStorage.setItem("jwtSandboxToken", token);
      localStorage.setItem("jwtSandboxRefreshToken", refreshToken);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      store.dispatch(setCurrentUser(decoded));
      // request.headers.Authorization = "Bearer " + token;
    })
    .catch((err) => {
      store.dispatch(logoutUser());
    });
};

// Intercepting all requests and cheking if token is expired. If it is it will be refreshed
axios.interceptors.request.use(async (request) => {
  if (
    request.url !== "/api/candidate/login" &&
    request.url !== "/api/users/login" &&
    request.url !== "/api/token" &&
    request.url !== "/api/candidate/forgot-password" &&
    request.url !== "/api/users/forgot-password-admin" &&
    request.url !== "/api/candidate/reset-password" &&
    request.url !== "/api/users/reset-password" &&
    request.headers.typeinvite !== true &&
    request.headers.typeregister !== true
  ) {
    var token = localStorage.getItem("jwtSandboxToken");
    const currentTime = Date.now() / 1000;
    const decoded = jwt_decode(token);

    var cryptedRefreshToken = localStorage.getItem("jwtSandboxRefreshToken");

    let refreshTokenObj = {
      refreshToken: cryptedRefreshToken,
    };
    if (decoded.exp - currentTime < 1) {
      await axios
        .post("/api/token", refreshTokenObj)
        .then((res) => {
          // Save to localStorage
          const { token, refreshToken } = res.data;
          // Set token to ls
          localStorage.setItem("jwtSandboxToken", token);
          localStorage.setItem("jwtSandboxRefreshToken", refreshToken);
          // Set token to Auth header
          setAuthToken(token);
          // Decode token to get user data
          const decoded = jwt_decode(token);
          // Set current user
          store.dispatch(setCurrentUser(decoded));
          request.headers.Authorization = "Bearer " + token;
        })
        .catch((err) => {
          store.dispatch(logoutUser());
        });
    }
  }
  return request;
});

// Register Candidate
export const registerCandidate = (userData, history, callback) => (dispatch) => {
  axios
    .post("/api/candidates/register", userData, {
      headers: { typeregister: true },
    })
    .then((res) => callback(res))
    .then(() => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
