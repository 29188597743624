import React from "react";
import FormElementTitle from "../form/FormElementTitle";

function Input({
  className,
  lowerCase,
  type,
  placeholder,
  label,
  validationMsg,
  value,
  onChange,
  name,
  onKeyDown,
  id,
  noMargin,
  disabled,
  noTitle,
}) {
  var lowerCaseValue = "";

  var classNameInput = "form-element--input";
  if (disabled) {
    classNameInput = `${classNameInput} disabled ${lowerCaseValue}`;
  } else if (lowerCase) {
    lowerCaseValue = "lower-case";
    classNameInput = `${classNameInput}  ${lowerCaseValue}`;
  }
  var marginValue = "form-element";

  if (noMargin) {
    marginValue = "full-width";
  }
  // var disabled ="";
  // if(disabled){
  //   disabledValue = "disabled";
  // }
  var validationId = "";
  if (validationMsg) {
    validationId = "validation-msg";
  }
  var title = (
    <div className="form-element-itemCol">
      <FormElementTitle label={label} validationMsg={validationMsg} validationId={validationId} />
    </div>
  );
  if (noTitle) {
    title = "";
  }
  return (
    <div className={marginValue}>
      {title}
      <div className="form-element-item">
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          onKeyDown={onKeyDown}
          id={id}
          className={classNameInput}
        />
      </div>
    </div>
  );
}

export default Input;
