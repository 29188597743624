import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import RecipientCards from "../../../components/Cards/RecipientCards";
import Spinner from "../../../components/common/Spinner";
import { Link } from "react-router-dom";
import { getExamEmailRecipients } from "../../../actions/examActions";
import CheckPermissions from "../../../validation/CheckPermissions";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";

import { withRouter } from "react-router-dom";

class Recipients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      mounted: false,
      emailRecipients: this.props.exams && this.props.exams.emailRecipients,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: prevState.rolesAllowedToOpen,
          page_type: pageType.WRITE,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }

      if (nextProps.exams.emailRecipients !== prevState.emailRecipients) {
        update.emailRecipients = nextProps.exams.emailRecipients;

        var emailRecipients = nextProps.exams.emailRecipients;
        var mappedRecipients = [];
        emailRecipients &&
          emailRecipients.map((recipient) => {
            return mappedRecipients.push({
              title: recipient.user.email,
            });
          });
        update.emailRecipientsMapped = mappedRecipients;
        update.emailRecipientsUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/dashboard`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getExamEmailRecipients(this.props.match.params.officeId);
    }
  }

  render() {
    const { loading } = this.props.exams;
    var content;
    if (!this.state.emailRecipientsUpdated || loading) {
      content = <Spinner />;
    } else {
      content = <RecipientCards recipients={this.state.emailRecipientsMapped} />;
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={"Recipients"}
              link={`/admin/${this.props.match.params.officeId}/settings`}
              buttons={[
                <Link to={`/admin/${this.props.match.params.officeId}/edit-recipients`} className="btn btn-primary">
                  Edit Recipients
                </Link>,
              ]}
            />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

Recipients.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  exams: state.exams,
});

export default connect(mapStateToProps, { getExamEmailRecipients, clearErrors })(withRouter(Recipients));
