import React from "react";
import loadingImg from "../../img/imgLoader.gif";
import SmallButton from "../../components/common/SmallButton";

export default function CandidateCvContainer({ fileCv, cv, uploadingCv, onFormSubmitCv, onChange, removeCv }) {
  var removeCVButton = "";
  var uploadCvSubmit = "";
  if (fileCv) {
    uploadCvSubmit = (
      <button type="submit" className="btn btn-primary  confirm-cv mr-1">
        Confirm
      </button>
    );
  }
  if (cv) {
    var trimmedcvString = cv.substring(cv.lastIndexOf("/") + 1);
    removeCVButton = (
      <span>
        <SmallButton label={<i className="far fa-trash-alt"></i>} link={"#"} className={"btn btn-light-danger"} onClick={removeCv} />
      </span>
    );
  }
  var uploadBtnText = "Upload CV";
  if (cv) {
    uploadBtnText = "New CV";
  }
  var newCvName = "";
  if (fileCv) {
    newCvName = <span className="mr-1">{fileCv.name}</span>;
  }
  var uploadingCvSpinner;
  if (uploadingCv) {
    trimmedcvString = "";
    removeCVButton = "";
    uploadingCvSpinner = <img src={loadingImg} style={{ width: "100px", margin: "auto", display: "block" }} alt="Loading..." />;
  } else {
    uploadingCvSpinner = "";
  }

  return (
    <div>
      <div className="candidate-bottom--item">
        <div className="candidate-bottom--item-title">CV:</div>
        <div className="candidate-bottom--item-info">
          You can upload candidate's CV here. If there is one already it will be replaced with new one. Must be PDF and less then 1MB.
        </div>
        <div className="candidate-bottom--item-value">
          <div className="form-div">
            <form onSubmit={onFormSubmitCv}>
              <div className="profile-buttons-grid">
                <label className="btn btn-light mr-1">
                  <input type="file" onChange={onChange} name="cv" accept=".pdf" />
                  {uploadBtnText}
                </label>
              </div>
              <span className="text-secondary">{newCvName}</span>
              {uploadCvSubmit}
            </form>
          </div>

          <span className="text-primary">
            {trimmedcvString} <span className="text-danger">{removeCVButton}</span>
            {uploadingCvSpinner}
          </span>
        </div>
      </div>
      <hr />
    </div>
  );
}
