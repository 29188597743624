import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import Input from "../../../components/common/Input";
import Btn from "../../../components/common/Btn";
import OfficeValidation from "../../../validation/OfficeValidation";
import { createOffice } from "../../../actions/officeActions";
import { roles } from "../../../enums/roles";
import { accountType } from "../../../enums/accountType";

class CreateOffice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      submitPressed: false,
      mounted: false,
      value: "",
      short_text: "",
      email: "",
      auth: {},
      active: false,
      errors: {},
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        if (!nextProps.auth.isAuthenticated || !accountType.USER) {
          nextProps.history.push("/");
        }
        if (
          nextProps.auth.user.offices &&
          nextProps.auth.user.offices[0] &&
          nextProps.auth.user.offices[0].user_role_office.role.value !== roles.SUPERADMINISTRATOR
        ) {
          nextProps.history.push("/");
        }

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.submitPressed) {
        this.checkValidation();
      }
    });
  }

  checkValidation() {
    this.setState({ errors: {} });
    this.props.clearErrors();
    var formData = {};

    formData.value = this.state.value ? this.state.value : null;
    formData.short_text = this.state.short_text ? this.state.short_text.toUpperCase() : null;

    const { errors } = OfficeValidation(formData);
    this.setState({ errors });
  }

  submitForm(e) {
    this.setState({ errors: {}, submitPressed: true });
    e.preventDefault();
    var formData = {};

    formData.value = this.state.value ? this.state.value : null;
    formData.short_text = this.state.short_text ? this.state.short_text.toUpperCase() : null;

    const { errors, isValid } = OfficeValidation(formData);
    if (isValid) {
      this.props.createOffice(formData, (res) => {
        if (res.status === 200) {
          successToast("Office added successfully");
          this.props.history.push(`/admin/offices/settings`);
        } else {
          failToast("Office adding failed");
          this.props.history.push(`/admin/offices/settings/new-office`);
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  render() {
    var content = (
      <div className="width-cointainer default-container default-padding">
        <div>
          <h2>Add new office</h2>
          <Input
            placeholder="Office name"
            name={"value"}
            label={"Office name"}
            onChange={(e) => this.onChange(e)}
            value={this.state.value}
            validationMsg={[this.props.errors.value, this.state.errors.value]}
          />
          <Input
            placeholder="Short 2 char name"
            name={"short_text"}
            label={"Short 2 char name"}
            onChange={(e) => this.onChange(e)}
            value={this.state.short_text}
            validationMsg={[this.props.errors.short_text, this.state.errors.short_text]}
          />
          <div className="submit-button">
            <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitForm(e)} />
          </div>
        </div>
      </div>
    );
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate title={"Add Office"} link={`/admin/offices/global-settings`} />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateOffice.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createOffice,
  clearErrors,
})(withRouter(CreateOffice));
