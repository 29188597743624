import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
// import Input from "../../../components/common/Input";
import SearchDropdown from "../../../components/common/SearchDropdown";
import TextAreaEditor from "../../../components/common/TextAreaEditor";
import QuestionValidation from "../../../validation/QuestionValidation";
import Btn from "../../../components/common/Btn";
import Switch from "../../../components/common/SwitchComponent";
import Confirm from "../../../components/common/Confirm";
import Spinner from "../../../components/common/Spinner";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import CheckPermissions from "../../../validation/CheckPermissions";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import { getQuestion, updateQuestion, removeQuestion } from "../../../actions/questionActions";
import { getCategories } from "../../../actions/categoryActions";
import { getDifficulties } from "../../../actions/difficultyActions";
import isEmpty from "../../../validation/isEmpty";
import { EditorState, convertToRaw, ContentState } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import scrollToError from "../../../utility/scrollToError";
import { withRouter } from "react-router-dom";

class EditQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      editorStateQuestion: EditorState.createEmpty(),
      editorStateAnswer: EditorState.createEmpty(),
      questionDb: this.props.questions.question,
      categories: this.props.categories.categories,
      difficulties: this.props.difficulties.difficulties,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR, roles.QA],
      difficultiesMapped: [],
      requestLoading: false,
      reviewed: false,
      global: false,
      categoriesMapped: [],
      errors: {},
    };

    this.onEditorStateChangeQuestion = this.onEditorStateChangeQuestion.bind(this);
    this.onEditorStateChangeAnswer = this.onEditorStateChangeAnswer.bind(this);
    this.selectDifficulty = this.selectDifficulty.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.handleChangeRevewied = this.handleChangeRevewied.bind(this);
    this.handleChangeGlobal = this.handleChangeGlobal.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.questions && nextProps.questions.question) {
        if (nextProps.auth !== prevState.auth) {
          const officeRoleObject =
            nextProps.auth &&
            nextProps.auth.user &&
            nextProps.auth.user.offices &&
            nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
          var userRoleOffice = officeRoleObject && officeRoleObject[0].user_role_office.role.value;
          update.userRoleOffice = userRoleOffice;
          var dataToCheck = {
            isAuthenticated: nextProps.auth.isAuthenticated,
            user_type: nextProps.auth.user.type,
            root_link: `/admin/offices`,
            user_offices: nextProps.auth.user.offices,
            office_id: nextProps.match.params.officeId,
            previous_link: `/admin/${nextProps.match.params.officeId}/questions`,
            roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
            page_type: pageType.VIEW,
            history: nextProps.history,
          };

          CheckPermissions(dataToCheck);

          if (nextProps.errors) {
            update.errors = nextProps.errors;
          }
          update.auth = nextProps.auth;
        }
        if (prevState.initialRender) {
          if (nextProps.questions.question !== prevState.questionDb) {
            var { question } = nextProps.questions;

            if (!isEmpty(question)) {
              const htmlQuestion = question.question;
              const questionValue = htmlToDraft(htmlQuestion);
              const questionState = ContentState.createFromBlockArray(questionValue.contentBlocks);
              const editorStateQuestion = EditorState.createWithContent(questionState);
              update.editorStateQuestion = editorStateQuestion;

              const htmlAnswer = question.answer;
              const answerValue = htmlToDraft(htmlAnswer);
              const answerState = ContentState.createFromBlockArray(answerValue.contentBlocks);
              const editorStateAnswer = EditorState.createWithContent(answerState);

              update.editorStateAnswer = editorStateAnswer;
              update.global = question.global;
              update.reviewed = question.reviewed;
              update.user_id = question.user_id;

              if (question.category) {
                question.category.title = question.category.category;
              }
              update.category = question.category;

              if (question.difficulty) {
                question.difficulty.title = question.difficulty.difficulty;
              }
              update.difficulty = question.difficulty;
              update.initialRender = false;
            }
          }
        }
        if (nextProps.difficulties.difficulties !== prevState.difficulties) {
          var { difficulties } = nextProps.difficulties;
          var difficultiesMapped = [];
          difficulties.map((difficulty) => {
            return difficultiesMapped.push({ id: difficulty.id, title: difficulty.difficulty });
          });
          update.difficultiesMapped = difficultiesMapped;
        }
        if (nextProps.categories.categories !== prevState.categories) {
          var { categories } = nextProps.categories;
          var categoriesMapped = [];
          categories.map((category) => {
            return categoriesMapped.push({ id: category.id, title: category.category });
          });
          update.categoriesMapped = categoriesMapped;
        }
      }
    }
    return Object.keys(update).length ? update : null;
  }
  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/questions`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getQuestion(this.props.match.params.questionId);
      this.props.getCategories(this.props.match.params.officeId, () => {});
      this.props.getDifficulties(this.props.match.params.officeId, () => {});
    }
  }
  componentWillUnmount() {
    this.props.clearErrors();
  }

  checkValidation() {
    this.props.clearErrors();
    var formData = {};
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }
    formData.question = draftToHtml(convertToRaw(this.state.editorStateQuestion.getCurrentContent()));
    formData.question_plain = this.state.editorStateQuestion.getCurrentContent().getPlainText().trim();
    formData.answer = draftToHtml(convertToRaw(this.state.editorStateAnswer.getCurrentContent()));
    formData.answer_plain = this.state.editorStateAnswer.getCurrentContent().getPlainText().trim();
    formData.difficulty_id = this.state.difficulty && this.state.difficulty.id ? this.state.difficulty.id : null;
    formData.category_id = this.state.category && this.state.category.id ? this.state.category.id : null;
    formData.global = this.state.global ? this.state.global : false;
    formData.reviewed = this.state.reviewed ? this.state.reviewed : false;
    formData.user_id = this.props.auth.user.id;

    const { errors } = QuestionValidation(formData);
    this.setState({ errors });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }

  submitForm(e) {
    e.preventDefault();
    var formData = {};
    formData.question = draftToHtml(convertToRaw(this.state.editorStateQuestion.getCurrentContent()));
    formData.question_plain = this.state.editorStateQuestion.getCurrentContent().getPlainText().trim();
    formData.answer = draftToHtml(convertToRaw(this.state.editorStateAnswer.getCurrentContent()));
    formData.answer_plain = this.state.editorStateAnswer.getCurrentContent().getPlainText().trim();
    formData.difficulty_id = this.state.difficulty && this.state.difficulty.id ? this.state.difficulty.id : null;
    formData.category_id = this.state.category && this.state.category.id ? this.state.category.id : null;
    if (!isNaN(this.props.match.params.officeId)) {
      formData.office_id = parseInt(this.props.match.params.officeId);
    } else {
      formData.office_id = null;
    }

    formData.user_id = this.props.auth.user.id;
    formData.global = this.state.global ? this.state.global : false;
    formData.reviewed = this.state.reviewed ? this.state.reviewed : false;
    const { errors, isValid } = QuestionValidation(formData);
    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.updateQuestion(this.props.match.params.questionId, formData, (res) => {
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Question updated successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/questions`);
        } else {
          this.setState({ requestLoading: false });
          scrollToError();
          failToast("Question edit failed");
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ requestLoading: false });
        scrollToError();
      });
    }
  }

  confirmRemove = () => {
    this.props.removeQuestion(this.props.match.params.questionId, (res) => {
      if (res.status === 200) {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/questions`);
        successToast("Question removed successfully");
      } else {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/questions`);
        failToast(`Question removing failed`);
      }
    });
  };
  confirmModal = () => {
    Confirm("Remove this question?", "", "Cancel", "Remove", this.confirmRemove);
  };
  handleChangeRevewied(reviewed) {
    if (!reviewed) {
      this.setState({ reviewed, global: false });
    } else {
      this.setState({ reviewed });
    }
  }
  handleChangeGlobal(global) {
    this.setState({ global });
  }
  onEditorStateChangeQuestion = (editorStateQuestion) => {
    this.setState(
      {
        editorStateQuestion,
      },
      () => {
        this.checkValidation();
      }
    );
  };
  onEditorStateChangeAnswer = (editorStateAnswer) => {
    this.setState(
      {
        editorStateAnswer,
      },
      () => {
        this.checkValidation();
      }
    );
  };
  selectDifficulty(value) {
    if (value.id === (this.state.difficulty && this.state.difficulty.id)) {
      value = [];
    }
    this.setState({ difficulty: value }, () => {
      this.checkValidation();
    });
  }
  selectCategory(value) {
    if (value.id === (this.state.category && this.state.category.id)) {
      value = [];
    }
    this.setState({ category: value }, () => {
      this.checkValidation();
    });
  }
  render() {
    const { editorStateQuestion, editorStateAnswer } = this.state;
    const { question, loading } = this.props.questions;

    var content;
    var navBtns;
    if (
      // (question && question.id === null) ||
      loading
      // ||
      // this.state.reviewed === undefined ||
      // this.state.global === undefined
    ) {
      content = <Spinner />;
    } else {
      if (isEmpty(question)) {
        content = <div className="no-content">No question with this ID</div>;
      } else {
        navBtns = [<Btn className="btn btn-danger" label={<i className="far fa-trash-alt"></i>} onClick={(e) => this.confirmModal(e)} />];
        content = (
          <div className="question-container">
            <TextAreaEditor
              label={"Question*"}
              editorState={editorStateQuestion}
              placeholder={"Question"}
              onChange={this.onEditorStateChangeQuestion}
              validationMsg={[this.state.errors.question, this.props.errors.error]}
            />
            <TextAreaEditor
              label={"Answer*"}
              title={"Question"}
              placeholder={"Answer"}
              editorState={editorStateAnswer}
              onChange={this.onEditorStateChangeAnswer}
              validationMsg={[this.state.errors.answer]}
            />

            <SearchDropdown
              label={"Categories"}
              placeholder={"Category"}
              value={this.state.category}
              name={"category_id"}
              validationMsg={this.state.errors.category_id}
              onChange={this.selectCategory}
              multiple={false}
              options={this.state.categoriesMapped}
              isRequired={false}
            />
            <SearchDropdown
              label={"Difficulties"}
              placeholder={"Difficulty"}
              value={this.state.difficulty}
              name={"difficulty_id"}
              validationMsg={this.state.errors.difficulty_id}
              onChange={this.selectDifficulty}
              multiple={false}
              options={this.state.difficultiesMapped}
            />
            {this.state.userRoleOffice === roles.SUPERADMINISTRATOR || this.state.userRoleOffice === roles.ADMINISTRATOR ? (
              <div className="default-flex">
                <Switch
                  onChange={this.handleChangeRevewied}
                  value={this.state.reviewed}
                  id={"reviewed"}
                  name={"reviewed"}
                  label={"Reviewed"}
                />
                <Switch
                  onChange={this.handleChangeGlobal}
                  value={this.state.global}
                  id={"global"}
                  name={"global"}
                  label={"Global"}
                  disabled={!this.state.reviewed}
                />
              </div>
            ) : (
              ""
            )}

            <div className="submit-button">
              <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
            </div>
          </div>
        );
      }
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate title={"Edit Question"} link={`/admin/${this.props.match.params.officeId}/questions`} buttons={navBtns} />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditQuestion.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  questions: state.questions,
  difficulties: state.difficulties,
  categories: state.categories,
});

export default connect(mapStateToProps, {
  getQuestion,
  updateQuestion,
  removeQuestion,
  getCategories,
  getDifficulties,
  clearErrors,
})(withRouter(EditQuestion));
