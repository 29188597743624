import React from "react";
import { Link } from "react-router-dom";
import imgPlaceholder from "../../img/noImg.png";
import moment from "moment";

export default function UserCard({ users, props, filters }) {
  var content = "Loading...";

  var noContentText;
  if (filters && Object.keys(filters).length > 0) {
    noContentText = "There are no users for selected filters.";
  } else {
    noContentText = "There are no users invited. Click Add User in top right corner to add first.";
  }
  if (users && users.length > 0) {
    content = (
      <div className='portrait-grid-user'>
        {users &&
          users.map((user, index) => (
            <Link className='users' key={index} to={`/admin/${props.match.params.officeId}/users/${user.user.id}`}>
              <div className='users-image'>
                <div className='fixed-ratio-div'>
                  <img
                    className='card-img-top rounded-circle'
                    src={user.user.img ? user.user.img : imgPlaceholder}
                    alt=''
                    onError={(e) => {
                      e.preventDefault();
                      e.target.onerror = null;
                      e.target.src = imgPlaceholder;
                    }}
                  />
                </div>
              </div>
              <div className='users-name'>
                <div className='users-name-value'>{`${user.user.first_name} ${user.user.last_name}`}</div>
              </div>
              <div className='users-item'>
                <div className='users-item-title'> Role:</div>
                <div className='users-item-value'> {user.role.value}</div>
              </div>
              <div className='users-item'>
                <div className='users-item-title'>Active:</div>
                <div className='users-item-value'>{user.user.active ? "Yes" : "No"}</div>
              </div>
              <div className='users-item'>
                <div className='users-item-title'>CV:</div>
                <div className='users-item-value'>{user.user.cv ? "Yes" : "No"}</div>
              </div>
              <div className='users-item'>
                <div className='users-item-title'> Login:</div>
                <div className='users-item-value'>
                  {user.user.last_login
                    ? moment.utc(user.user.last_login).local().format("DD.MM.YYYY HH:mm:ss")
                    : "Never"}
                </div>
              </div>
              <div className='users-item'>
                <div className='users-item-title'> Joined:</div>
                <div className='users-item-value'>
                  {moment.utc(user.user.created_on).local().format("DD.MM.YYYY HH:mm:ss")}
                </div>
              </div>
            </Link>
          ))}
      </div>
    );
  } else {
    content = <div className='no-content'>{noContentText}</div>;
  }
  return <div>{content}</div>;
}
