import axios from "axios";

import { GET_CANDIDATE_REPORTS, REPORT_LOADING, CLEAR_REPORTS, REMOVE_LOADING, GET_ERRORS } from "./types";

// Get All Office Candidate Reports
export const getCandidateReports = (candidateId, scenarioId) => (dispatch) => {
  dispatch(reportLoading());

  var url = `/api/candidates/candidate/${candidateId}/reports`;
  if (scenarioId) {
    url = `/api/candidates/candidate/${candidateId}/reports?scenario_id=${scenarioId}`;
  }

  axios
    .get(url)
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_REPORTS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Clear Reports
export const clearReports = () => {
  return {
    type: CLEAR_REPORTS,
  };
};

// Report loading
export const reportLoading = () => {
  return {
    type: REPORT_LOADING,
  };
};
