import React from "react";
import { Link } from "react-router-dom";
import imgPlaceholder from "../../img/noImg.png";
import moment from "moment";

export default function CandidateSettingsCard({ candidates, props }) {
  var content = "Loading...";
  if (candidates && candidates.length > 0) {
    content = (
      <div className="portrait-grid-user">
        {candidates &&
          candidates.map((candidate, index) => (
            <Link className="candidates" key={index} to={`/admin/candidates/candidate/${candidate.id}/settings`}>
              <div className="candidates-image">
                <div className="fixed-ratio-div">
                  {/* <img className='card-img-top rounded-circle' src={candidate.img} alt='' title={"Candidate Image"} /> */}
                  <img
                    className="card-img-top rounded-circle"
                    src={candidate.img ? candidate.img : imgPlaceholder}
                    alt=""
                    onError={(e) => {
                      e.preventDefault();
                      e.target.onerror = null;
                      e.target.src = imgPlaceholder;
                    }}
                  />
                </div>
              </div>
              <div className="candidates-name">
                <div className="candidates-name-value">{`${candidate.first_name} ${candidate.last_name}`}</div>
              </div>
              <div className="candidates-item">
                <div className="candidates-item-title"> Email:</div>
                <div className="candidates-item-value"> {candidate.email}</div>
              </div>
              <div className="candidates-item">
                <div className="candidates-item-title"> Office:</div>
                <div className="candidates-item-value"> {candidate.office}</div>
              </div>

              <div className="candidates-item">
                <div className="candidates-item-title"> Joined:</div>
                <div className="candidates-item-value">
                  {candidate.registered ? moment.utc(candidate.registered).local().format("DD.MM.YYYY HH:mm:ss") : "No"}
                </div>
              </div>
              <div className="candidates-item">
                <div className="candidates-item-title"> Login:</div>
                <div className="candidates-item-value">
                  {candidate.last_login
                    ? moment.utc(candidate.last_login).local().format("DD.MM.YYYY HH:mm:ss")
                    : "Never"}
                </div>
              </div>
              <div className="candidates-item">
                <div className="candidates-item-title">Active:</div>
                <div className="candidates-item-value">{candidate.active ? "Yes" : "No"}</div>
              </div>
              <div className="candidates-item">
                <div className="candidates-item-title"> Nº Exams :</div>
                <div className="candidates-item-value"> {candidate.exams ? candidate.exams : "No Exams"}</div>
              </div>
              <div className="candidates-item">
                <div className="candidates-item-title"> Latest:</div>
                <div className="candidates-item-value"> {candidate.status ? candidate.status : "No Exams"}</div>
              </div>
            </Link>
          ))}
      </div>
    );
  } else {
    content = (
      <div className="no-content">
        There are no candidates invited. Click Add Candidate in top right corner to add first.
      </div>
    );
  }
  return <div>{content}</div>;
}
