import React, { Component } from "react";
import { clearErrors } from "../../../actions/errorsActions";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import Input from "../../../components/common/Input";
import Btn from "../../../components/common/Btn";
import Spinner from "../../../components/common/Spinner";
import DifficultyValidation from "../../../validation/DifficultyValidation";

import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import { updateDifficulty, getDifficulty, removeDifficulty } from "../../../actions/difficultyActions";
import CheckPermissions from "../../../validation/CheckPermissions";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import Confirm from "../../../components/common/Confirm";

import { withRouter } from "react-router-dom";
import isEmpty from "../../../validation/isEmpty";

class EditDifficulty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRender: true,
      user: {},
      difficultyDb: this.props.difficulties.difficulty,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
      mounted: false,
      auth: {},
      requestLoading: false,
      difficulty: "",
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.difficulties && nextProps.difficulties.difficulty) {
        if (nextProps.auth !== prevState.auth) {
          var dataToCheck = {
            isAuthenticated: nextProps.auth.isAuthenticated,
            user_type: nextProps.auth.user.type,
            root_link: `/admin/offices`,
            user_offices: nextProps.auth.user.offices,
            office_id: nextProps.match.params.officeId,
            previous_link: `/admin/${nextProps.match.params.officeId}/difficulties`,
            roles_allowed_to_write: prevState.rolesAllowedToOpen,
            page_type: pageType.WRITE,
            history: nextProps.history,
          };

          CheckPermissions(dataToCheck);

          if (nextProps.errors) {
            update.errors = nextProps.errors;
          }
          update.auth = nextProps.auth;
        }
        if (prevState.initialRender) {
          if (nextProps.difficulties.difficulty !== prevState.difficultyDb) {
            update.initialRender = false;
            var { difficulty } = nextProps.difficulties;
            update.difficulty = difficulty.difficulty;
            update.initialDifficulty = difficulty.difficulty;
          }
        }
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    var dataToCheck = {
      isAuthenticated: this.props.auth.isAuthenticated,
      user_type: this.props.auth.user.type,
      root_link: `/admin/offices`,
      user_offices: this.props.auth.user.offices,
      office_id: this.props.match.params.officeId,
      previous_link: `/admin/${this.props.match.params.officeId}/difficulties`,
      roles_allowed_to_write: this.state.rolesAllowedToOpen,
      page_type: pageType.WRITE,
      history: this.props.history,
    };
    if (CheckPermissions(dataToCheck)) {
      this.props.getDifficulty(this.props.match.params.difficultyId);
    }
  }
  componentWillUnmount() {
    this.props.clearErrors();
  }

  checkValidation() {
    this.props.clearErrors();
    var formData = {};
    formData.difficulty = this.state.difficulty;
    formData.office_id = this.props.match.params.officeId;

    const { errors } = DifficultyValidation(formData);

    this.setState({ errors });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkValidation();
    });
  }

  submitForm(e) {
    e.preventDefault();
    var formData = {};
    formData.difficulty = this.state.difficulty;
    formData.office_id = this.props.match.params.officeId;

    const { errors, isValid } = DifficultyValidation(formData);

    if (isValid) {
      this.setState({ requestLoading: true });
      this.props.updateDifficulty(this.props.match.params.difficultyId, formData, (res) => {
        if (res.status === 200) {
          this.setState({ requestLoading: false });
          successToast("Difficulty updated successfully");
          this.props.history.push(`/admin/${this.props.match.params.officeId}/difficulties`);
        } else {
          this.setState({ requestLoading: false });
          failToast("Difficulty edit failed");
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.setState({ requestLoading: false });
      });
    }
  }

  confirmRemove = () => {
    this.props.removeDifficulty(this.props.match.params.difficultyId, (res) => {
      if (res.status === 200) {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/difficulties`);
        successToast("Difficulty removed successfully");
      } else {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/difficulties`);
        failToast(`Difficulty removing failed`);
      }
    });
  };
  confirmModal = () => {
    Confirm(
      "Remove this difficulty?",
      "Questions assigned with this difficulty will lose this property",
      "Cancel",
      "Remove",
      this.confirmRemove
    );
  };

  render() {
    const {
      // difficulties,
      loading,
    } = this.props.difficulties;
    var content = "";
    var navDelBtn;
    if (loading) {
      content = <Spinner />;
    } else {
      if (isEmpty(this.state.initialDifficulty)) {
        content = <div className="no-content">No difficulty with this ID</div>;
      } else {
        navDelBtn = [<Btn className="btn btn-danger" label={<i className="far fa-trash-alt"></i>} onClick={(e) => this.confirmModal(e)} />];
        content = (
          <div>
            <Input
              placeholder={"Difficulty"}
              type="text"
              value={this.state.difficulty}
              onChange={(e) => this.onChange(e)}
              name={"difficulty"}
              label="Difficulty"
              validationMsg={[this.state.errors.difficulty, this.props.errors.error]}
            />
            <div className="submit-button">
              <Btn className="btn btn-primary " label="Submit" onClick={(e) => this.submitForm(e)} loading={this.state.requestLoading} />
            </div>
          </div>
        );
      }
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate title={"Edit Difficulty"} link={`/admin/${this.props.match.params.officeId}/difficulties`} buttons={navDelBtn} />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditDifficulty.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  difficulties: state.difficulties,
});

export default connect(mapStateToProps, { updateDifficulty, getDifficulty, removeDifficulty, clearErrors })(withRouter(EditDifficulty));
