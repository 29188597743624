import axios from "axios";

import { GET_SCENARIO, SCENARIO_LOADING, GET_ERRORS } from "../types";

// Get Scenario
export const getCandidateScenario = () => (dispatch) => {
  dispatch(scenarioLoading());

  axios
    .get(`/api/candidate/scenario`)
    .then((res) =>
      dispatch({
        type: GET_SCENARIO,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    });
};

// Finish exam
export const finishExam = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/scenario/finish`, formData)
    .then((res) => callback(res))
    .catch((err) => callback(err));
};

// Scenario loading
export const scenarioLoading = () => {
  return {
    type: SCENARIO_LOADING,
  };
};
