import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// import { clearErrors } from "../../../actions/errorsActions";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import Navigate from "../../../components/Navigate/Navigate";
import Spinner from "../../../components/common/Spinner";
import Dropdown from "../../../components/common/Dropdown";
import Chart from "../../../components/common/Chart";
import { getGlobalStatistics } from "../../../actions/statisticActions";
import capitalizeFirstLetter from "../../../utility/capitalizeFirstLetter";

import { getUserOffices } from "../../../actions/officeActions";
import { accountType } from "../../../enums/accountType";
import { roles } from "../../../enums/roles";
import ReactTooltip from "react-tooltip";

class GlobalStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      statistics: this.props.statistics.globalStatistics,
      rolesAllowedToOpen: [roles.SUPERADMINISTRATOR, roles.GLOBALVIEWER],
      // ALL TCs
      allTCOptions: {
        colors: ["#0548ff", "#25b7e8", "#ff2929", "#ff9100", "#50ff50"],
        legend: {
          show: false,
        },
        chart: {
          id: "bar",
          height: 900,

          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          tickPlacement: "between",

          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        },
        yaxis: {
          floating: false,
          decimalsInFloat: false,
          labels: {
            formatter: (val) => {
              return val;
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      allTCSeries: [
        {
          name: "In Progress",
          data: [],
        },
        {
          name: "Finished",
          data: [],
        },
        {
          name: "Quit",
          data: [],
        },
        {
          name: "Canceled",
          data: [],
        },
        {
          name: "Hired",
          data: [],
        },
      ],
      // MOST ACTIVE
      mostActiveOptions: {
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
        legend: {
          position: "bottom",
        },

        labels: ["In Progress", "Finished", "Quit", "Canceled", "Timed Out"],
      },

      mostActiveSeries: [1, 14, 4, 3, 7],

      // MOST FAILED TC
      mostProjectReportsFailedOptions: {
        colors: ["rgb(0, 227, 150)", "#503bbc", "rgb(255, 69, 96)"],
        legend: {
          show: false,
        },
        chart: {
          id: "bar",
          height: "600px",
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      mostProjectReportsFailedSeries: [
        {
          name: "Passed",
          data: [],
        },
        {
          name: "Reports",
          data: [],
        },
        {
          name: "Failed",
          data: [],
        },
      ],
      // MOST FAILED VERSION
      mostProjectTestcasesOptions: {
        colors: ["#a592ff"],
        legend: {
          show: false,
        },
        chart: {
          id: "bar",
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      mostProjectTestcasesSeries: [
        {
          name: "Test Cases",
          data: [],
        },
      ],
      // USER MOST TC
      usersWithMostTcOptions: {
        colors: ["#a592ff"],
        legend: {
          show: false,
        },
        chart: {
          id: "bar",
          height: "600px",
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      usersWithMostTcSeries: [
        {
          name: "Test Cases",
          data: [],
        },
      ],
      // USER MOST Reports
      usersWithMostReportsOptions: {
        colors: ["rgb(0, 227, 150)", "#503bbc", "rgb(255, 69, 96)"],
        legend: {
          show: false,
        },
        chart: {
          id: "bar",
          height: "600px",
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      usersWithMostReportsSeries: [
        {
          name: "Passed",
          data: [],
        },
        {
          name: "Reports",
          data: [],
        },
        {
          name: "Failed",
          data: [],
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth !== prevState.auth) {
      if (!nextProps.auth.isAuthenticated || accountType.USER !== nextProps.auth.user.type) {
        nextProps.history.push("/");
      }
      if (
        nextProps.auth.user.offices &&
        nextProps.auth.user.offices[0] &&
        nextProps.auth.user.offices[0].user_role_office.role.value !== roles.SUPERADMINISTRATOR &&
        nextProps.auth.user.offices[0].user_role_office.role.value !== roles.GLOBALVIEWER
      ) {
        nextProps.history.push("/");
      }

      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;

      if (nextProps.errors) {
        update.errors = nextProps.errors;
      }

      update.auth = nextProps.auth;
    }
    if (nextProps.statistics !== prevState.statistics) {
      if (nextProps.statistics.globalStatistics && nextProps.statistics.globalStatistics.annual_report) {
        var total_months = [];
        var total_in_progress = [];
        var total_finished = [];
        var total_quit = [];
        var total_canceled = [];
        var total_hired = [];
        Object.entries(nextProps.statistics.globalStatistics.annual_report).forEach(([key, value]) => {
          if (value && value.title) {
            total_months.push(capitalizeFirstLetter(value.title));
          }
          if (value && value.in_progress) {
            total_in_progress.push(value.in_progress);
          } else {
            total_in_progress.push(0);
          }
          if (value && value.finished) {
            total_finished.push(value.finished);
          } else {
            total_finished.push(0);
          }
          if (value && value.quit) {
            total_quit.push(value.quit);
          } else {
            total_quit.push(0);
          }
          if (value && value.canceled) {
            total_canceled.push(value.canceled);
          } else {
            total_canceled.push(0);
          }
          if (value && value.hired) {
            total_hired.push(value.hired);
          } else {
            total_hired.push(0);
          }
        });
        var newAnnual = prevState;
        newAnnual.allTCOptions.xaxis.categories = total_months;
        newAnnual.allTCSeries[0].data = total_in_progress;
        newAnnual.allTCSeries[1].data = total_finished;
        newAnnual.allTCSeries[2].data = total_quit;
        newAnnual.allTCSeries[3].data = total_canceled;
        newAnnual.allTCSeries[4].data = total_hired;
      }
      update.statistics = newAnnual;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated || accountType.USER !== this.props.auth.user.type) {
      this.props.history.push("/");
    } else {
      if (
        (this.props.auth.user.offices &&
          this.props.auth.user.offices[0] &&
          this.props.auth.user.offices[0].user_role_office.role.value === roles.SUPERADMINISTRATOR) ||
        this.props.auth.user.offices[0].user_role_office.role.value === roles.GLOBALVIEWER
      ) {
        this.props.getGlobalStatistics();
      }
    }
  }

  onChange(e) {
    this.setState({ days: +e.target.value }, () => {
      if (this.state.days === 0) {
        this.props.getGlobalStatistics();
      } else {
        var params;
        switch (this.state.days) {
          case 1:
            params = 3;
            break;
          case 2:
            params = 7;
            break;
          case 3:
            params = 30;
            break;
          case 4:
            params = 90;
            break;
          case 5:
            params = 180;
            break;
          case 6:
            params = 360;
            break;
          case 7:
            params = "current_week";
            break;
          case 8:
            params = "current_month";
            break;
          case 9:
            params = "current_year";
            break;
          default:
            params = 0;
        }
        this.props.getGlobalStatistics(params);
      }
    });
  }

  render() {
    const { loading, globalStatistics } = this.props.statistics;
    var content;
    if (globalStatistics === null || loading) {
      content = <Spinner />;
    } else {
      content = (
        <div className="stats">
          <div className="stats-top">
            <div className="stats-top-left">
              <div className="stats-top-left-title">
                Candidates statistics
                <div className="stats-top-left-title-dropdown">
                  <Dropdown
                    options={[
                      { id: 1, title: "3 Days" },
                      { id: 2, title: "Week" },
                      { id: 3, title: "Month" },
                      { id: 4, title: "3 Months" },
                      { id: 5, title: "6 Months" },
                      { id: 6, title: "Year" },
                      { id: 7, title: "Current Week" },
                      { id: 8, title: "Current Month" },
                      { id: 9, title: "Current Year" },
                    ]}
                    noTitle={true}
                    value={this.state.days}
                    placeholder={"All time"}
                    required={false}
                    onChange={(e) => this.onChange(e)}
                  />
                </div>
              </div>
              <div className="stats-top-left-body">
                <div className="stats-grid">
                  <div className="stats-grid--item">
                    <div className="stats-grid--item-1">
                      <div className="stats-grid--item-1-title">Exams</div>
                      <div className="stats-grid--item-1-count">
                        {globalStatistics.total_data.total_exams.value ? globalStatistics.total_data.total_exams.value : "No Exams"}
                      </div>
                      <div className="stats-grid--item-1-count-percentage">
                        <div className="stats-grid--item-1-count-percentage-title"> Candidates:</div>
                        <div className="stats-grid--item-1-count-percentage-value">
                          {globalStatistics.total_data.total_exams.percentage ? globalStatistics.total_data.total_exams.percentage : "None"}{" "}
                          {globalStatistics.total_data.total_exams.percentage ? (
                            <span>
                              <i className={`fas fa-info-circle ml-0 candidate-tooltip-text`} data-tip data-for={"total-candidates"}></i>
                              <ReactTooltip
                                id={"total-candidates"}
                                aria-haspopup="true"
                                className="custom-color-no-arrow"
                                textColor={"#000"}
                                backgroundColor={"#c7ca1d"}
                                effect="solid"
                              >
                                <p>{"Total candidates in selected time frame"}</p>
                              </ReactTooltip>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats-grid--item">
                    <div className="stats-grid--item-2">
                      <div className="stats-grid--item-1-title">In Progress</div>
                      <div className="stats-grid--item-1-count">
                        {globalStatistics.total_data.total_exams_in_progress.value
                          ? globalStatistics.total_data.total_exams_in_progress.value
                          : "None"}
                      </div>
                      <div className="stats-grid--item-1-count-percentage">
                        <div className="stats-grid--item-1-count-percentage-title">Percentage:</div>
                        <div className="stats-grid--item-1-count-percentage-value">
                          {globalStatistics.total_data.total_exams_in_progress.percentage
                            ? globalStatistics.total_data.total_exams_in_progress.percentage.toFixed(2)
                            : "None"}
                          {globalStatistics.total_data.total_exams_in_progress.percentage ? "%" : ""}{" "}
                          {globalStatistics.total_data.total_exams_in_progress.percentage ? (
                            <span>
                              <i className={`fas fa-info-circle ml-0 in-progress-tooltip-text`} data-tip data-for={"in-progress"}></i>
                              <ReactTooltip
                                id={"in-progress"}
                                aria-haspopup="true"
                                className="custom-color-no-arrow"
                                textColor={"#fff"}
                                backgroundColor={"#0548ff"}
                                effect="solid"
                              >
                                <p>{"Percentage of in progress exams based on total exams in this time frame"}</p>
                              </ReactTooltip>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats-grid--item">
                    <div className="stats-grid--item-3">
                      <div className="stats-grid--item-1-title">Finished</div>
                      <div className="stats-grid--item-1-count">
                        {globalStatistics.total_data.total_exams_finished.value
                          ? globalStatistics.total_data.total_exams_finished.value
                          : "None"}
                      </div>
                      <div className="stats-grid--item-1-count-percentage">
                        <div className="stats-grid--item-1-count-percentage-title">Percentage:</div>
                        <div className="stats-grid--item-1-count-percentage-value">
                          {globalStatistics.total_data.total_exams_finished.percentage
                            ? globalStatistics.total_data.total_exams_finished.percentage.toFixed(2)
                            : "None"}
                          {globalStatistics.total_data.total_exams_finished.percentage ? "%" : ""}{" "}
                          {globalStatistics.total_data.total_exams_finished.percentage ? (
                            <span>
                              <i className={`fas fa-info-circle ml-0 finished-tooltip-text`} data-tip data-for={"finished"}></i>
                              <ReactTooltip
                                id={"finished"}
                                aria-haspopup="true"
                                className="custom-color-no-arrow"
                                textColor={"#000"}
                                backgroundColor={"#25b7e8"}
                                effect="solid"
                              >
                                <p>{"Percentage of finished exams based on total exams in this time frame"}</p>
                              </ReactTooltip>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats-grid--item">
                    <div className="stats-grid--item-4">
                      <div className="stats-grid--item-1-title">Quit</div>
                      <div className="stats-grid--item-1-count">
                        {globalStatistics.total_data.total_exams_quit.value ? globalStatistics.total_data.total_exams_quit.value : "None"}
                      </div>
                      <div className="stats-grid--item-1-count-percentage">
                        <div className="stats-grid--item-1-count-percentage-title">Percentage:</div>
                        <div className="stats-grid--item-1-count-percentage-value">
                          {globalStatistics.total_data.total_exams_quit.percentage
                            ? globalStatistics.total_data.total_exams_quit.percentage.toFixed(2)
                            : "None"}
                          {globalStatistics.total_data.total_exams_quit.percentage ? "%" : ""}{" "}
                          {globalStatistics.total_data.total_exams_quit.percentage ? (
                            <span>
                              <i className={`fas fa-info-circle ml-0 quit-tooltip-text`} data-tip data-for={"quit"}></i>
                              <ReactTooltip
                                id={"quit"}
                                aria-haspopup="true"
                                className="custom-color-no-arrow"
                                textColor={"#fff"}
                                backgroundColor={"#ff2929"}
                                effect="solid"
                              >
                                <p>{"Percentage of quit exams based on total exams in this time frame"}</p>
                              </ReactTooltip>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats-grid--item">
                    <div className="stats-grid--item-5">
                      <div className="stats-grid--item-1-title">Canceled</div>
                      <div className="stats-grid--item-1-count">
                        {globalStatistics.total_data.total_exams_canceled.value
                          ? globalStatistics.total_data.total_exams_canceled.value
                          : "None"}
                      </div>
                      <div className="stats-grid--item-1-count-percentage">
                        <div className="stats-grid--item-1-count-percentage-title">Percentage:</div>
                        <div className="stats-grid--item-1-count-percentage-value">
                          {globalStatistics.total_data.total_exams_canceled.percentage
                            ? globalStatistics.total_data.total_exams_canceled.percentage.toFixed(2)
                            : "None"}
                          {globalStatistics.total_data.total_exams_canceled.percentage ? "%" : ""}{" "}
                          {globalStatistics.total_data.total_exams_canceled.percentage ? (
                            <span>
                              <i className={`fas fa-info-circle ml-0 canceled-tooltip-text`} data-tip data-for={"canceled"}></i>
                              <ReactTooltip
                                id={"canceled"}
                                aria-haspopup="true"
                                className="custom-color-no-arrow"
                                textColor={"#000"}
                                backgroundColor={"#ff9100"}
                                effect="solid"
                              >
                                <p>{"Percentage of canceled exams based on total exams in this time frame"}</p>
                              </ReactTooltip>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats-grid--item">
                    <div className="stats-grid--item-6">
                      <div className="stats-grid--item-1-title">Hired</div>
                      <div className="stats-grid--item-1-count">
                        {globalStatistics.total_data.total_hired.value ? globalStatistics.total_data.total_hired.value : "None"}
                      </div>
                      <div className="stats-grid--item-1-count-percentage">
                        <div className="stats-grid--item-1-count-percentage-title">Percentage:</div>
                        <div className="stats-grid--item-1-count-percentage-value">
                          {globalStatistics.total_data.total_hired.percentage
                            ? globalStatistics.total_data.total_hired.percentage.toFixed(2)
                            : 0}
                          %{/* {globalStatistics.total_data.total_hired.percentage ? "%" : ""}{" "} */}{" "}
                          {globalStatistics.total_data.total_hired.percentage ? (
                            <span>
                              <i className={`fas fa-info-circle ml-0 hired-tooltip-text`} data-tip data-for={"hired"}></i>
                              <ReactTooltip
                                id={"hired"}
                                aria-haspopup="true"
                                className="custom-color-no-arrow"
                                textColor={"#000"}
                                backgroundColor={"#50ff50"}
                                effect="solid"
                              >
                                <p>{"Percentage of hired candidates based on total exams in this time frame"}</p>
                              </ReactTooltip>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stats-bottom-body">
                  <Chart options={this.state.allTCOptions} series={this.state.allTCSeries} type="area" width="97%" />
                </div>
              </div>
            </div>
            {/* <div className="stats-top-right">
                  <div className="stats-top-right-title">Title</div>
                  <div className="stats-top-right-body">
                    <Chart
                      options={this.state.mostActiveOptions}
                      series={this.state.mostActiveSeries}
                      type="donut"
                      width="100%"
                      legend={this.state.legend1}
                    />
                  </div>
                </div> */}
          </div>
          {/* <div className="stats-bottom">
                <div className="stats-bottom-title">Title</div>
                <div className="stats-bottom-body">
                  <Chart options={this.state.allTCOptions} series={this.state.allTCSeries} type="area" width="100%" />
                </div>
              </div> */}
        </div>
      );
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate title={"Statistics"} link={`/admin/${this.props.match.params.officeId}/dashboard`} buttons={[]} />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

GlobalStatistics.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  statistics: state.statistics,
  offices: state.offices,
});

export default connect(mapStateToProps, { getUserOffices, getGlobalStatistics })(withRouter(GlobalStatistics));
