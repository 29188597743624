import React from "react";
import Input from "../common/Input";
import SearchDropdown from "../common/SearchDropdown";

export default function PersonItemList({
  value,
  onClickEdit,
  onClickRemove,
  idToEdit,
  index,
  id,
  onClickConfirmEdit,
  cancelEdit,
  technologies,
  selectedTechnologies,
  seniorities,
  selectedSeniority,
  teams,
  selectedTeam,
  title,
  onChange,
  onEditTechnology,
  onEditSeniority,
  onEditTeam,
  validationMsg,
}) {
  var listItemValue = "";
  var initialEditButtons = "";

  if (idToEdit && id === idToEdit) {
    listItemValue = (
      <div className=''>
        <Input
          placeholder={"Person"}
          type='text'
          value={title}
          onChange={onChange}
          name={"personEdit"}
          label='Person'
          validationMsg={validationMsg}
        />
        <SearchDropdown
          value={selectedTechnologies}
          options={technologies}
          onChange={onEditTechnology}
          placeholder={"Technology"}
          label={"Technologies"}
          name={"technology"}
          //   validationMsg={this.state.errors.technology}
          multiple={true}
        />
        <SearchDropdown
          value={selectedSeniority}
          options={seniorities}
          onChange={onEditSeniority}
          placeholder={"Seniority"}
          label={"Seniorities"}
          name={"seniority"}
          //   validationMsg={this.state.errors.seniority}
          multiple={false}
        />
        <SearchDropdown
          value={selectedTeam}
          options={teams}
          onChange={onEditTeam}
          placeholder={"Team"}
          label={"Teams"}
          name={"team"}
          //   validationMsg={this.state.errors.team}
          multiple={false}
        />
        <div className='default-flex'>
          <div></div>
          <div className='settings-list-btns'>
            <div onClick={cancelEdit} className='settings-list-btns-cancel'>
              <i className='fas fa-times'></i>
            </div>
            <div className='settings-list-btns-confirm' onClick={onClickConfirmEdit}>
              <i className='fas fa-check'></i>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    listItemValue = (
      <div className='settings-list'>
        <div className='person-container-bottom--teams-people'>
          <div className='person-container-bottom--teams-people--person'>
            <div className='person-container-bottom--teams-people--person-name'>
              {value && value.name ? value.name : ""}
            </div>
            <div className='person-container-bottom--teams-people--person-seniority'>
              {value.seniority && value.seniority.title ? `Seniority: ${value.seniority.title}` : ""}
            </div>
            <div className='person-container-bottom--teams-people--value-technologies'>
              {value.technologies && value.technologies.length > 0 ? `Technologies: ` : ""}
              {value.technologies &&
                value.technologies.map((technology, technologyIndex) => (
                  <React.Fragment key={technologyIndex}>
                    {technology.title}
                    {value.technologies.length - 1 > technologyIndex ? `, ` : ``}
                  </React.Fragment>
                ))}
              {value.team && value.team.title ? <div>Team: {value.team.title}</div> : ""}
            </div>
          </div>
        </div>
        {/* {initialEditButtons} */}
      </div>
    );
    initialEditButtons = (
      <div className='default-flex'>
        <div></div>
        <div className='settings-list-btns'>
          <div className='settings-list-btns-edit' onClick={onClickEdit} id={value.id} name={"edit-person"}>
            <i className='fas fa-pencil-alt' id={value.id} name={"edit-person"}></i>
          </div>
          <div className='settings-list-btns-remove' onClick={onClickRemove} id={value.id} name={"remove-person"}>
            <i className='fas fa-trash' id={value.id} name={"remove-person"}></i>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='settings' key={index}>
      {listItemValue}
      {initialEditButtons}
    </div>
  );
}
