import axios from "axios";

import {
  GET_EXAM_EMAIL_RECIPIENTS,
  GET_EXAM_STATUSES,
  GET_EXAM_PARTICIPANTS,
  EXAM_LOADING,
  REMOVE_LOADING,
  GET_ERRORS,
  GET_OFFICE_CANDIDATE,
} from "./types";

// Get All Exam Email Recipients
export const getExamEmailRecipients = (office_id) => (dispatch) => {
  dispatch(examLoading());

  axios
    .get(`/api/email-recipients/${office_id}`)
    .then((res) =>
      dispatch({
        type: GET_EXAM_EMAIL_RECIPIENTS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Exam Participants
export const getExamParticipants = (candidateId) => (dispatch) => {
  dispatch(examLoading());

  axios
    .get(`/api/exam/participants/${candidateId}`)
    .then((res) =>
      dispatch({
        type: GET_EXAM_PARTICIPANTS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Set Exam Participants
export const setExamParticipants = (candidateId, data) => (dispatch) => {
  dispatch(examLoading());

  axios
    .post(`/api/exam/participants/${candidateId}`, data)
    .then((res) =>
      dispatch({
        type: GET_EXAM_PARTICIPANTS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Update Exam Email Recipients
export const updateExamEmailRecipients = (officeId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/email-recipients/${officeId}`, formData)
    .then((res) => callback(res))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get All Exam Statuses
export const getExamStatuses = () => (dispatch) => {
  dispatch(examLoading());

  axios
    .get(`/api/exam/statuses`)
    .then((res) =>
      dispatch({
        type: GET_EXAM_STATUSES,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Update Exam Participant status
export const updateExamParticipantStatus = (data, callback) => (dispatch) => {
  dispatch(examLoading());

  axios
    .put(`/api/exam/participants`, data)
    .then((res) => {
      dispatch({
        type: GET_OFFICE_CANDIDATE,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Update Exam Participants
export const updateExamParticipants = (data, callback) => (dispatch) => {
  dispatch(examLoading());

  axios
    .post(`/api/exam/participants`, data)
    .then((res) => {
      dispatch({
        type: GET_EXAM_PARTICIPANTS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Exam loading
export const examLoading = () => {
  return {
    type: EXAM_LOADING,
  };
};
