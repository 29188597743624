import React from "react";
import { Link } from "react-router-dom";
import imgPlaceholder from "../../img/project.jpg";
// import moment from "moment";

export default function SettingsAdminCard({ offices, props, filters }) {
  var content = "Loading...";
  var noContentText;
  if (filters && Object.keys(filters).length > 0) {
    noContentText = "There are no offices for selected filters.";
  } else {
    noContentText = "There are no offices added. Click Add Office in top right corner to add first.";
  }
  if (offices && offices.length > 0) {
    content = (
      <div className='portrait-grid-user'>
        {offices &&
          offices.map((office, index) => (
            <Link className='users' key={index} to={`/admin/offices/office/${office.id}/settings`}>
              <div className='users-image'>
                <div className='fixed-ratio-div'>
                  <img
                    className='card-img-top rounded-circle'
                    src={office.image ? office.image : imgPlaceholder}
                    alt=''
                    onError={(e) => {
                      e.preventDefault();
                      e.target.onerror = null;
                      e.target.src = imgPlaceholder;
                    }}
                  />
                  <div className='fixed-ratio-div-project-short'>{office.short_text}</div>
                </div>
              </div>
              <div className='users-name'>
                <div className='users-name-value'>
                  {office.value} ({office.short_text})
                </div>
              </div>
            </Link>
          ))}
      </div>
    );
  } else {
    content = <div className='no-content'>{noContentText}</div>;
  }
  return <div>{content}</div>;
}
