import axios from "axios";

import { GET_CANDIDATE_COMMENTS, COMMENT_LOADING, CLEAR_COMMENTS, REMOVE_LOADING, GET_ERRORS } from "./types";

// Get All Office Candidate Comments
export const getCandidateComments = (candidateId) => (dispatch) => {
  dispatch(commentLoading());

  axios
    .get(`/api/candidates/candidate/${candidateId}/comments`)
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_COMMENTS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Create Comment
export const createCandidateComment = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidates/candidate/comment`, formData)
    .then((res) => callback(res))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Update Comment
export const updateCandidateComment = (commentId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidates/candidate/comment/${commentId}`, formData)
    .then((res) => callback(res))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Remove Comment
export const removeCandidateComment = (commentId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidates/candidate/comment/${commentId}`)
    .then((res) => callback(res))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Clear Comments
export const clearComments = () => {
  return {
    type: CLEAR_COMMENTS,
  };
};

// Comment loading
export const commentLoading = () => {
  return {
    type: COMMENT_LOADING,
  };
};
