import axios from "axios";

import {
  GET_QUESTIONS,
  GET_QUESTION,
  QUESTION_LOADING,
  REMOVE_LOADING,
  GET_GLOBAL_QUESTIONS,
  GET_GLOBAL_QUESTION,
  GET_ERRORS,
} from "./types";

// Get All Questions
export const getQuestions = (office_id, filterData) => (dispatch) => {
  dispatch(questionLoading());

  axios
    .post(`/api/questions/office/${office_id}`, filterData)
    .then((res) =>
      dispatch({
        type: GET_QUESTIONS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      //   callback(err.response);
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Question
export const getQuestion = (questionId) => (dispatch) => {
  dispatch(questionLoading());

  axios
    .get(`/api/questions/question/${questionId}`)
    .then((res) =>
      dispatch({
        type: GET_QUESTION,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Create Question
export const createQuestion = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/questions/question`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Update Question
export const updateQuestion = (questionId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/questions/question/${questionId}`, formData)
    .then((res) => callback(res))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Remove Question
export const removeQuestion = (questionId, callback) => (dispatch) => {
  axios
    .delete(`/api/questions/question/${questionId}`)
    .then((res) => callback(res))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get Global Questions
export const getGlobalQuestions = (officeId, filters) => (dispatch) => {
  dispatch(questionLoading());

  axios
    .post(`/api/global-questions/${officeId}`, filters)
    .then((res) =>
      dispatch({
        type: GET_GLOBAL_QUESTIONS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Global Question
export const getGlobalQuestion = (questionId, officeId) => (dispatch) => {
  dispatch(questionLoading());

  axios
    .get(`/api/global-questions/question/${questionId}/office/${officeId}`)
    .then((res) =>
      dispatch({
        type: GET_GLOBAL_QUESTION,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Question loading
export const questionLoading = () => {
  return {
    type: QUESTION_LOADING,
  };
};
