import React from "react";
import { Link } from "react-router-dom";
import imgPlaceholder from "../../img/noImg.png";
import moment from "moment";
import ReactTooltip from "react-tooltip";

export default function CandidateCard({ candidates, props }) {
  var content = "Loading...";

  if (candidates && candidates.length > 0) {
    content = (
      <div className="portrait-grid-user">
        {candidates &&
          candidates.map((candidate, index) => (
            <React.Fragment key={index}>
              <Link
                className={`candidates ${candidate.exam_participants
                  .map((participant) => {
                    var test1 = "";
                    if (participant.active) {
                      participant.candidate_exam_participants.map((exam_participant) => {
                        if (exam_participant) {
                          if (props.auth.user.id === exam_participant.user_id) {
                            test1 = "exam_participant";
                          }
                        }
                        return null;
                      });
                    }
                    return test1;
                  })
                  .join("")}
                  
                  ${candidate.exam_participants
                    .map((participant) => {
                      var test2 = "";
                      if (!participant.active) {
                        participant.candidate_exam_participants.map((exam_participant) => {
                          if (exam_participant) {
                            if (props.auth.user.id === exam_participant.user_id && exam_participant.reviewed === false) {
                              test2 = "exam_reviewer";
                            }
                          }
                          return null;
                        });
                      }
                      return test2;
                    })
                    .join("")
                    .substring(0, 13)}
                    `}
                key={index}
                to={`/admin/${props.match.params.officeId}/candidates/${candidate.id}`}
              >
                {candidate.exam_participants.map((participant) => {
                  var participantTooltip = "";
                  if (participant.active) {
                    participant.candidate_exam_participants.map((exam_participant, exam_participants_index2) => {
                      if (exam_participant) {
                        if (props.auth.user.id === exam_participant.user_id) {
                          participantTooltip = (
                            <div className="candidates-tooltip" key={`participant-${exam_participants_index2}-${index}`}>
                              <div className="candidates-tooltip-participant">
                                <ReactTooltip
                                  id="participant"
                                  aria-haspopup="true"
                                  className="custom-color-no-arrow"
                                  textColor="#000"
                                  backgroundColor="#0dc5ee"
                                  place="left"
                                  effect="solid"
                                >
                                  Assigned to you
                                </ReactTooltip>
                                <i className={`fas fa-info-circle`} data-tip data-for={"participant"}></i>
                              </div>
                            </div>
                          );
                        }
                      }
                      return null;
                    });
                  }
                  return participantTooltip;
                })}

                {candidate.exam_participants.map((participant) => {
                  var confirmationTooltip = "";
                  if (!participant.active) {
                    participant.candidate_exam_participants.map((exam_participant, exam_participants_index) => {
                      if (exam_participant) {
                        if (props.auth.user.id === exam_participant.user_id && exam_participant.reviewed === false) {
                          confirmationTooltip = (
                            <div className="candidates-tooltip" key={`confirm-${exam_participants_index}${index}`}>
                              <div className="candidates-tooltip-confirmation">
                                <ReactTooltip
                                  id="confirmation"
                                  aria-haspopup="true"
                                  className="custom-color-no-arrow"
                                  textColor="#fff"
                                  backgroundColor="#ff4141"
                                  place="left"
                                  effect="solid"
                                >
                                  Not reviewed
                                </ReactTooltip>
                                <i className={`fas fa-exclamation-circle`} data-tip data-for={"confirmation"}></i>
                              </div>
                            </div>
                          );
                        }
                      }
                      return null;
                    });
                  }
                  return confirmationTooltip;
                })}

                <div className="candidates-image">
                  <div className="fixed-ratio-div">
                    <img
                      className="card-img-top rounded-circle"
                      src={candidate.img ? candidate.img : imgPlaceholder}
                      alt=""
                      onError={(e) => {
                        e.preventDefault();
                        e.target.onerror = null;
                        e.target.src = imgPlaceholder;
                      }}
                    />
                  </div>
                </div>
                <div className="candidates-name">
                  <div className="candidates-name-value">{`${candidate.first_name} ${candidate.last_name}`}</div>
                </div>
                <div className="candidates-item">
                  <div className="candidates-item-title"> Email:</div>
                  <div className="candidates-item-value"> {candidate.email}</div>
                </div>
                <div className="candidates-item">
                  <div className="candidates-item-title"> Office:</div>
                  <div className="candidates-item-value"> {candidate.office}</div>
                </div>
                <div className="candidates-item">
                  <div className="candidates-item-title"> Joined:</div>
                  <div className="candidates-item-value">
                    {candidate.registered ? moment.utc(candidate.registered).local().format("DD.MM.YYYY HH:mm:ss") : "No"}
                  </div>
                </div>
                <div className="candidates-item">
                  <div className="candidates-item-title"> Login:</div>
                  <div className="candidates-item-value">
                    {candidate.last_login ? moment.utc(candidate.last_login).local().format("DD.MM.YYYY HH:mm:ss") : "Never"}
                  </div>
                </div>
                <div className="candidates-item">
                  <div className="candidates-item-title">Active:</div>
                  <div className="candidates-item-value">{candidate.active ? "Yes" : "No"}</div>
                </div>
                <div className="candidates-item">
                  <div className="candidates-item-title"> Nº Exams :</div>
                  <div className="candidates-item-value"> {candidate.exams ? candidate.exams : "No Exams"}</div>
                </div>
                <div className="candidates-item">
                  <div className="candidates-item-title"> Latest:</div>
                  <div className="candidates-item-value"> {candidate.status ? candidate.status : "No Exams"}</div>
                </div>
              </Link>
            </React.Fragment>
          ))}
      </div>
    );
  } else {
    content = <div className="no-content">There are no candidates invited. Click Add Candidate in top right corner to add first.</div>;
  }
  return <div>{content}</div>;
}
