import React from "react";
import ModalImage from "react-modal-image";
import { Link } from "react-router-dom";
import linkify from "../../utility/linkify"

export default function ReportCard({ reports }) {
  var content = "Loading...";
  if (reports && reports.length > 0) {
    content = (
      <div className='portrait-grid'>
        {reports &&
          reports.map((report, index) => (
            <span key={index}>
               <Link className='preview-card' key={index} to={`/edit-report/${report.id}`}>
              <div className='preview-card'>
               
                  <div className='preview-card-title'>
                    <div className='preview-card-title-value'>{report.summary}</div>
                  </div>
                  <div className='preview-card-body'>
                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-title'>Issue type:</div>
                        <div className='preview-card-body--items-single-value'>{report.type}</div>
                      </div>
                    </div>

                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-title'>Severity:</div>
                        <div className='preview-card-body--items-single-value'>{report.severity}</div>
                      </div>
                    </div>
                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-title'>Priority:</div>
                        <div className='preview-card-body--items-single-value'>{report.priority}</div>
                      </div>
                    </div>
                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-title'>Description:</div>
                        <div className='preview-card-body--items-single-value'>{report.description}</div>
                      </div>
                    </div>
                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-title'>Steps:</div>
                        <div className='preview-card-body--items-single-value'>
                          {report.reproduction_steps ? report.reproduction_steps.length : 0}
                        </div>
                      </div>
                    </div>
                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-title'>Expected result:</div>
                        <div className='preview-card-body--items-single-value'>{report.expected_result ? report.expected_result : "/"}</div>
                      </div>
                    </div>
                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-short'>
                          <div className='preview-card-body--items-single-short-title'>Images:</div>
                          <div className='preview-card-body--items-single-short-value'>
                            {report.report_images ? report.report_images.length : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                        className='preview-card-body--items'
                        onClick={(e) => e.preventDefault()}
                        data-toggle='modal'
                        data-target={`#myModal-${index}`}
                      >
                        <div className='preview-card-body--items-single-preview'>
                          <div className='preview-card-body--items-single-preview-title'>Preview</div>
                          <div className='preview-card-body--items-single-preview-value'>
                            <i className='fas fa-eye'></i>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              </Link>
              <div id={`myModal-${index}`} className='modal fade' role='dialog'>
                <div className='modal-dialog'>
                  {/* <!-- Modal content--> */}
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h4 className='modal-titles'>Report Preview</h4>
                      <button type='button' className='close' data-dismiss='modal'>
                        &times;
                      </button>
                    </div>
                    <div className='modal-body'>
                      <div className='preview-card-modal'>
                        <div className='preview-card-modal-title'>
                          <div className='preview-card-modal-title-value'>{report.summary}</div>
                        </div>
                        <hr />
                        <div className='preview-card-modal-body'>
                          <div className='preview-card-modal-body--items'>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-title'>Issue type:</div>
                              <div className='preview-card-modal-body--items-single-value'>{report.type}</div>
                            </div>
                          </div>

                          <div className='preview-card-modal-body--items'>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-title'>Severity:</div>
                              <div className='preview-card-modal-body--items-single-value'>{report.severity}</div>
                            </div>
                          </div>
                          <div className='preview-card-modal-body--items'>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-title'>Priority:</div>
                              <div className='preview-card-modal-body--items-single-value'>{report.priority}</div>
                            </div>
                          </div>
                          <div className='preview-card-modal-body--items'>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-title'>Description:</div>
                              <div className='preview-card-modal-body--items-single-value'>{linkify(report.description)}</div>
                            </div>
                          </div>
                          <div className='preview-card-modal-body--items'>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-short'>
                                <div className='preview-card-modal-body--items-single-short-title'>Images:</div>
                                <div className='preview-card-modal-body--items-single-short-value'>
                                  {report.report_images.length > 0 ? (
                                    <div className='uploaded'>
                                      <div className='uploaded-images'>
                                        {report.report_images &&
                                          report.report_images.map((image, index) => (
                                            <div className='uploaded-images-wrapper' key={index}>
                                              <div className={"uploaded-images-wrapper--img"}>
                                                <ModalImage small={image.image} large={image.image} key={index} />
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  ) : (
                                    "None"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='preview-card-modal-body--items'>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-title'>Steps:</div>
                              <div className='preview-card-modal-body--items-single-value'>
                                {report.reproduction_steps &&
                                  report.reproduction_steps.map((step, index) => (
                                    <div className='report-modal-steps-value' key={index}>
                                      {index + 1}. {linkify(step.value)}
                                      <br />
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className='preview-card-modal-body--items'>
                            <div className='preview-card-modal-body--items-single'>
                              <div className='preview-card-modal-body--items-single-title'>Expected result:</div>
                              <div className='preview-card-modal-body--items-single-value'>{linkify(report.expected_result)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-default' data-dismiss='modal'>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          ))}
      </div>
    );
  } else {
    content = (
      <div className='no-content'>
        There are no reports created. Click New Report in top right corner to get started.
      </div>
    );
  }
  return <div>{content}</div>;
}
