import React, { Component } from "react";
// import { clearErrors } from "../../actions/errorsActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navigate from "../../components/Navigate/Navigate";
import SideMenu from "../../components/layout/SideMenu";
import ProjectCard from "../../components/Cards/ProjectCard";
import Spinner from "../../components/common/Spinner";
import { Link } from "react-router-dom";
import { getCandidateProjects } from "../../actions/candidate/projectActions";

class Playground extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      projects: [],
      mounted: false,
      projectsMapped: [],
      auth: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      if (nextProps.auth !== prevState.auth) {
        if (!nextProps.auth.isAuthenticated) {
          nextProps.history.push("/login");
        }
        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.projects.projects !== prevState.projects) {
        update.projects = nextProps.projects.projects;

        update.projects = nextProps.projects.projects;
        update.projectsMapped = nextProps.projects.projects;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.props.getCandidateProjects();
  }

  render() {
    const { loading } = this.props.projects;
    var content = "";
    if (this.state.projectsMapped === [] || loading) {
      content = <Spinner />;
    } else {
      content = <ProjectCard projects={this.state.projectsMapped} />;
    }

    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <Navigate
              title={"Projects"}
              link={"/dashboard"}
              buttons={[
                <Link to={"/new-project"} className="btn btn-primary">
                  New Project
                </Link>,
              ]}
            />
            {content}
          </div>
        </div>
      </div>
    );
  }
}

Playground.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  projects: state.projects,
});

export default connect(mapStateToProps, { getCandidateProjects })(withRouter(Playground));
