import React from "react";
import { accountType } from "../../enums/accountType";
import { roles } from "../../enums/roles";
import { Link } from "react-router-dom";

export default function SideMenu({ props }) {
  var items = [];

  const officeRoleObject =
    props.auth &&
    props.auth.user &&
    props.auth.user.offices &&
    props.auth.user.offices.filter((office) => office.id === parseInt(props.match.params.officeId));
  var userRoleOffice =
    officeRoleObject && officeRoleObject[0] && officeRoleObject[0].user_role_office && officeRoleObject[0].user_role_office.role.value;

  if (accountType.USER === props.auth.user.type) {
    // USER
    if (userRoleOffice === roles.SUPERADMINISTRATOR || userRoleOffice === roles.ADMINISTRATOR) {
      items = [
        {
          title: "Dashboard",
          link: `/admin/${props.match.params.officeId}/dashboard`,
          icon: <i className="fas fa-columns"></i>,
          active: props.match.path === "/admin/:officeId/dashboard" ? true : false,
        },
        {
          title: "Candidates",
          link: `/admin/${props.match.params.officeId}/candidates`,
          icon: <i className="fas fa-users"></i>,
          active:
            props.match.path === "/admin/:officeId/candidates" ||
            props.match.path === "/admin/:officeId/candidates/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/testcases/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/projects/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/reports/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/repositories/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/:candidateId/current-scenario" ||
            props.match.path === "/admin/:officeId/candidate/:candidateId/previous-scenarios" ||
            props.match.path === "/admin/:officeId/candidate/:candidateId/new-scenario"
              ? true
              : false,
        },
        {
          title: "Users",
          link: `/admin/${props.match.params.officeId}/users`,
          icon: <i className="fas fa-users-cog"></i>,
          active: props.match.path === "/admin/:officeId/users" || props.match.path === "/admin/:officeId/users/:userId" ? true : false,
        },
        {
          title: "Questions",
          link: `/admin/${props.match.params.officeId}/questions`,
          icon: <i className="fas fa-question"></i>,
          active:
            props.match.path === "/admin/:officeId/questions" ||
            props.match.path === "/admin/:officeId/edit-question/:questionId" ||
            props.match.path === "/admin/:officeId/new-question"
              ? true
              : false,
        },
        {
          title: "Global Questions",
          link: `/admin/${props.match.params.officeId}/global-questions`,
          icon: <i className="fas fa-question-circle"></i>,
          active:
            props.match.path === "/admin/:officeId/global-questions" || props.match.path === "/admin/:officeId/copy-question/:questionId"
              ? true
              : false,
        },
        {
          title: "Scenarios",
          link: `/admin/${props.match.params.officeId}/scenarios`,
          icon: <i className="fas fa-book-open"></i>,
          active:
            props.match.path === "/admin/:officeId/scenarios" ||
            props.match.path === "/admin/:officeId/edit-scenario/:scenarioId" ||
            props.match.path === "/admin/:officeId/new-scenario"
              ? true
              : false,
        },
        // {
        //   title: "Recipients",
        //   link: `/admin/${props.match.params.officeId}/recipients`,
        //   icon: <i className="fas fa-envelope"></i>,
        //   active:
        //     props.match.path === "/admin/:officeId/recipients" || props.match.path === "/admin/:officeId/edit-recipients" ? true : false,
        // },
        // {
        //   title: "Difficulties",
        //   link: `/admin/${props.match.params.officeId}/difficulties`,
        //   icon: <i className="fas fa-tachometer-alt"></i>,
        //   active:
        //     props.match.path === "/admin/:officeId/difficulties" ||
        //     props.match.path === "/admin/:officeId/edit-difficulty/:difficultyId" ||
        //     props.match.path === "/admin/:officeId/new-difficulty"
        //       ? true
        //       : false,
        // },
        // {
        //   title: "Categories",
        //   link: `/admin/${props.match.params.officeId}/categories`,
        //   icon: <i className="fas fa-pencil-ruler"></i>,
        //   active:
        //     props.match.path === "/admin/:officeId/categories" ||
        //     props.match.path === "/admin/:officeId/edit-category/:categoryId" ||
        //     props.match.path === "/admin/:officeId/new-category"
        //       ? true
        //       : false,
        // },

        {
          title: "Settings",
          link: `/admin/${props.match.params.officeId}/settings`,
          icon: <i className="fas fa-cog"></i>,
          active:
            props.match.path === "/admin/:officeId/settings" ||
            props.match.path === "/admin/:officeId/categories" ||
            props.match.path === "/admin/:officeId/edit-category/:categoryId" ||
            props.match.path === "/admin/:officeId/new-category" ||
            props.match.path === "/admin/:officeId/difficulties" ||
            props.match.path === "/admin/:officeId/edit-difficulty/:difficultyId" ||
            props.match.path === "/admin/:officeId/new-difficulty" ||
            props.match.path === "/admin/:officeId/recipients" ||
            props.match.path === "/admin/:officeId/edit-recipients"
              ? true
              : false,
        },
        {
          title: "Statistics",
          link: `/admin/${props.match.params.officeId}/statistics`,
          icon: <i className="fas fa-chart-bar"></i>,
          active: props.match.path === "/admin/:officeId/statistics" ? true : false,
        },
      ];
    } else if (userRoleOffice === roles.GLOBALVIEWER) {
      items = [
        {
          title: "Dashboard",
          link: `/admin/${props.match.params.officeId}/dashboard`,
          icon: <i className="fas fa-columns"></i>,
          active: props.match.path === "/admin/:officeId/dashboard" ? true : false,
        },
        {
          title: "Candidates",
          link: `/admin/${props.match.params.officeId}/candidates`,
          icon: <i className="fas fa-users"></i>,
          active:
            props.match.path === "/admin/:officeId/candidates" ||
            props.match.path === "/admin/:officeId/candidates/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/testcases/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/projects/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/reports/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/repositories/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/:candidateId/current-scenario" ||
            props.match.path === "/admin/:officeId/candidate/:candidateId/previous-scenarios"
              ? true
              : false,
        },
        {
          title: "Users",
          link: `/admin/${props.match.params.officeId}/users`,
          icon: <i className="fas fa-users-cog"></i>,
          active: props.match.path === "/admin/:officeId/users" || props.match.path === "/admin/:officeId/users/:userId" ? true : false,
        },
        {
          title: "Questions",
          link: `/admin/${props.match.params.officeId}/questions`,
          icon: <i className="fas fa-question"></i>,
          active:
            props.match.path === "/admin/:officeId/questions" ||
            props.match.path === "/admin/:officeId/edit-question/:questionId" ||
            props.match.path === "/admin/:officeId/new-question"
              ? true
              : false,
        },
        {
          title: "Global Questions",
          link: `/admin/${props.match.params.officeId}/global-questions`,
          icon: <i className="fas fa-question-circle"></i>,
          active:
            props.match.path === "/admin/:officeId/global-questions" || props.match.path === "/admin/:officeId/copy-question/:questionId"
              ? true
              : false,
        },
        {
          title: "Scenarios",
          link: `/admin/${props.match.params.officeId}/scenarios`,
          icon: <i className="fas fa-book-open"></i>,
          active:
            props.match.path === "/admin/:officeId/scenarios" ||
            props.match.path === "/admin/:officeId/edit-scenario/:scenarioId" ||
            props.match.path === "/admin/:officeId/new-scenario"
              ? true
              : false,
        },
        {
          title: "Statistics",
          link: `/admin/${props.match.params.officeId}/statistics`,
          icon: <i className="fas fa-chart-bar"></i>,
          active: props.match.path === "/admin/:officeId/statistics" ? true : false,
        },
      ];
    } else if (userRoleOffice === roles.QA) {
      items = [
        {
          title: "Dashboard",
          link: `/admin/${props.match.params.officeId}/dashboard`,
          icon: <i className="fas fa-columns"></i>,
          active: props.match.path === "/admin/:officeId/dashboard" ? true : false,
        },
        {
          title: "Candidates",
          link: `/admin/${props.match.params.officeId}/candidates`,
          icon: <i className="fas fa-users"></i>,
          active:
            props.match.path === "/admin/:officeId/candidates" ||
            props.match.path === "/admin/:officeId/candidates/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/testcases/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/projects/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/reports/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/repositories/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/:candidateId/current-scenario" ||
            props.match.path === "/admin/:officeId/candidate/:candidateId/previous-scenarios"
              ? true
              : false,
        },
        {
          title: "Users",
          link: `/admin/${props.match.params.officeId}/users`,
          icon: <i className="fas fa-users-cog"></i>,
          active: props.match.path === "/admin/:officeId/users" || props.match.path === "/admin/:officeId/users/:userId" ? true : false,
        },
        {
          title: "Questions",
          link: `/admin/${props.match.params.officeId}/questions`,
          icon: <i className="fas fa-question"></i>,
          active:
            props.match.path === "/admin/:officeId/questions" ||
            props.match.path === "/admin/:officeId/edit-question/:questionId" ||
            props.match.path === "/admin/:officeId/new-question"
              ? true
              : false,
        },
      ];
    } else if (userRoleOffice === roles.RECRUITER) {
      items = [
        {
          title: "Dashboard",
          link: `/admin/${props.match.params.officeId}/dashboard`,
          icon: <i className="fas fa-columns"></i>,
          active: props.match.path === "/admin/:officeId/dashboard" ? true : false,
        },
        {
          title: "Candidates",
          link: `/admin/${props.match.params.officeId}/candidates`,
          icon: <i className="fas fa-users"></i>,
          active:
            props.match.path === "/admin/:officeId/candidates" ||
            props.match.path === "/admin/:officeId/candidates/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/testcases/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/projects/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/reports/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/repositories/:candidateId" ||
            props.match.path === "/admin/:officeId/candidate/:candidateId/current-scenario" ||
            props.match.path === "/admin/:officeId/candidate/:candidateId/previous-scenarios"
              ? true
              : false,
        },
        {
          title: "Users",
          link: `/admin/${props.match.params.officeId}/users`,
          icon: <i className="fas fa-users-cog"></i>,
          active: props.match.path === "/admin/:officeId/users" || props.match.path === "/admin/:officeId/users/:userId" ? true : false,
        },

        {
          title: "Scenarios",
          link: `/admin/${props.match.params.officeId}/scenarios`,
          icon: <i className="fas fa-book-open"></i>,
          active:
            props.match.path === "/admin/:officeId/scenarios" ||
            props.match.path === "/admin/:officeId/edit-scenario/:scenarioId" ||
            props.match.path === "/admin/:officeId/new-scenario"
              ? true
              : false,
        },

        {
          title: "Statistics",
          link: `/admin/${props.match.params.officeId}/statistics`,
          icon: <i className="fas fa-chart-bar"></i>,
          active: props.match.path === "/admin/:officeId/statistics" ? true : false,
        },
      ];
    }
  } else if (accountType.CANDIDATE === props.auth.user.type) {
    // CANDIDATE
    items = [
      {
        title: "Dashboard",
        link: "/dashboard",
        icon: <i className="fas fa-columns"></i>,
        active: props.match.path === "/dashboard" ? true : false,
      },
      {
        title: "Profile",
        link: "/profile",
        icon: <i className="fas fa-user-alt"></i>,
        active:
          props.match.path === "/profile" || props.match.path === "/edit-profile" || props.match.path === "/change-password" ? true : false,
      },
      {
        title: "Test Cases",
        link: "/testcases",
        icon: <i className="fas fa-file-alt"></i>,
        active:
          props.match.path === "/testcases" || props.match.path === "/edit-testcase/:testcaseId" || props.match.path === "/new-testcase"
            ? true
            : false,
      },
      {
        title: "Playground",
        link: "/projects",
        icon: <i className="fas fa-project-diagram"></i>,
        active:
          props.match.path === "/projects" || props.match.path === "/edit-project/:projectId" || props.match.path === "/new-project"
            ? true
            : false,
      },
      {
        title: "Reports",
        link: "/reports",
        icon: <i className="fas fa-bug"></i>,
        active:
          props.match.path === "/reports" || props.match.path === "/edit-report/:reportId" || props.match.path === "/new-report"
            ? true
            : false,
      },
      {
        title: "Exam",
        link: "/exam",
        icon: <i className="fas fa-stream"></i>,
        active: props.match.path === "/exam" ? true : false,
      },
      {
        title: "Introduction",
        link: "/intro",
        icon: <i className="fas fa-handshake"></i>,
        active: props.match.path === "/intro" ? true : false,
      },
    ];
  }

  return (
    <div className="menu-items">
      {items.map((item, index) => (
        <Link to={item.link} className={`menu-items--item${item.active ? "-active" : ""}  ${item.disabled ? "disabled" : ""}`} key={index}>
          <div className={`menu-items--item${item.active ? "-active" : ""}-value`}> {item.title}</div>
          <div className={`menu-items--item${item.active ? "-active" : ""}-icon`}>
            <i className="fas fa-arrow-right"></i>
          </div>
          <div className={`menu-items--item${item.active ? "-active" : ""}-icon2`}>{item.icon}</div>
        </Link>
      ))}
    </div>
  );
}
