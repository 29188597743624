import React from "react";
import Switch from "react-switch";
import isEmpty from "../../validation/isEmpty";
function SwitchComponent({ label, onChange, name, value, id, className, disabled }) {
  // var isChecked = false;
  // if (value) {
  //   isChecked = true;
  // }
  var customClass = "";
  if (!isEmpty(className)) {
    customClass = className;
  }
  var disabledClass = "";
  if (disabled) {
    disabledClass = "disabled";
  }

  return (
    <div className={`switch-element ${customClass} ${disabledClass}`}>
      <label className="switch-element--label">{label}</label>
      <label className="switch">
        <Switch onChange={onChange} checked={value} onColor={"#25b7e8"} offColor={"#a7a7a7"} height={20} width={45} />
        {/* <span className='slider round'></span> */}
      </label>
    </div>
  );
}

export default SwitchComponent;
