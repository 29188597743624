import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navigate from "../../../components/Navigate/Navigate";
import SideMenuOffices from "../../../components/layout/SideMenuOffices";
import SideMenu from "../../../components/layout/SideMenu";
import { Link } from "react-router-dom";
import Dropdown from "../../../components/common/Dropdown";
import loadingImg from "../../../img/imgLoader.gif";
import Spinner from "../../../components/common/Spinner";
import Btn from "../../../components/common/Btn";
import Confirm from "../../../components/common/Confirm";
import isEmpty from "../../../validation/isEmpty";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import {
  getOfficeUser,
  updateOfficeUserRole,
  removeOfficeUser,
  uploadUserCV,
  removeUserCV,
  getUserCV,
  // setUserGlobalRole,
  // getUserGlobalRole,
  uploadProfilePhoto,
} from "../../../actions/userActions";
import { clearErrors } from "../../../actions/errorsActions";
import { getOfficeRoles } from "../../../actions/roleActions";
import { refreshToken } from "../../../actions/authActions";
import successToast from "../../../components/toast/successToast";
import failToast from "../../../components/toast/failToast";
import { pageType } from "../../../enums/pageType";
import { roles } from "../../../enums/roles";
import CheckPermissions from "../../../validation/CheckPermissions";
import moment from "moment";
import imgPlaceholder from "../../../img/noImg.png";
import SmallButtonExternal from "../../../components/common/SmallBtnExternal";
import SmallButton from "../../../components/common/SmallButton";
// import Switch from "../../../components/common/SwitchComponent";

import openSocket from "socket.io-client";
import webSocket from "../../../configSocket/keys";
var socket = openSocket(webSocket.webSocket);
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      crop: {
        unit: "%",
        width: 50,
        aspect: 10 / 10,
      },
      super_admin_switch: false,
      global_viewer_switch: false,
      user: {},
      officeUser: this.props.users && this.props.users.officeUser,
      globalRole: this.props.users && this.props.users.globalRole,
      mounted: false,
      auth: {},
      uplading: false,
      file: null,
      fileCv: null,
      uploadingCv: false,
      active: false,
      errors: {},
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormSubmitCv = this.onFormSubmitCv.bind(this);
    this.onChangePhoto = this.onChangePhoto.bind(this);
    this.onChange = this.onChange.bind(this);
    // this.handleSuperAdminSwitch = this.handleSuperAdminSwitch.bind(this);
    // this.handleGlobalViewerSwitch = this.handleGlobalViewerSwitch.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.auth) {
      var tokenUserOffices = nextProps.auth.user.offices;
      update.singleOffice = tokenUserOffices && tokenUserOffices.length === 1 ? true : false;
      if (nextProps.auth !== prevState.auth) {
        const officeRoleObject =
          nextProps.auth &&
          nextProps.auth.user &&
          nextProps.auth.user.offices &&
          nextProps.auth.user.offices.filter((office) => office.id === parseInt(nextProps.match.params.officeId));
        var userRoleOffice =
          officeRoleObject &&
          officeRoleObject[0] &&
          officeRoleObject[0].user_role_office &&
          officeRoleObject[0].user_role_office.role.value;
        update.userRoleOffice = userRoleOffice;
        const userId = nextProps.auth && nextProps.auth.user && nextProps.auth.user.id;
        update.userId = userId;

        var dataToCheck = {
          isAuthenticated: nextProps.auth.isAuthenticated,
          user_type: nextProps.auth.user.type,
          root_link: `/admin/offices`,
          user_offices: nextProps.auth.user.offices,
          office_id: nextProps.match.params.officeId,
          previous_link: `/admin/${nextProps.match.params.officeId}/dashboard`,
          roles_allowed_to_write: [roles.SUPERADMINISTRATOR, roles.ADMINISTRATOR],
          page_type: pageType.VIEW,
          history: nextProps.history,
        };

        CheckPermissions(dataToCheck);

        if (nextProps.errors) {
          update.errors = nextProps.errors;
        }
        update.auth = nextProps.auth;
      }
      if (nextProps.users.globalRole !== prevState.globalRole) {
        update.globalRole = nextProps.users.globalRole;

        if (nextProps.users.globalRole[0]) {
          if (nextProps.users.globalRole[0].role.value === roles.SUPERADMINISTRATOR) {
            update.super_admin_switch = true;
          }
          if (nextProps.users.globalRole[0].role.value === roles.GLOBALVIEWER) {
            update.global_viewer_switch = true;
          }
        }
      }

      if (nextProps.users.officeUser !== prevState.officeUser) {
        update.first_name = nextProps.users.officeUser && nextProps.users.officeUser.user && nextProps.users.officeUser.user.first_name;
        update.last_name = nextProps.users.officeUser && nextProps.users.officeUser.user && nextProps.users.officeUser.user.last_name;
        update.email = nextProps.users.officeUser && nextProps.users.officeUser.user && nextProps.users.officeUser.user.email;
        update.role = nextProps.users.officeUser && nextProps.users.officeUser.user && nextProps.users.officeUser.role.value;
        update.office = nextProps.users.officeUser && nextProps.users.officeUser.office && nextProps.users.officeUser.office.value;
        update.cv = nextProps.users.officeUser && nextProps.users.officeUser.user && nextProps.users.officeUser.user.cv;
        update.img = nextProps.users.officeUser && nextProps.users.officeUser.user && nextProps.users.officeUser.user.img;
        update.role_id = nextProps.users.officeUser && nextProps.users.officeUser.role && nextProps.users.officeUser.role.id;
        update.active = nextProps.users.officeUser && nextProps.users.officeUser.user && nextProps.users.officeUser.user.active;
        update.last_login =
          nextProps.users.officeUser && nextProps.users.officeUser.user && nextProps.users.officeUser.user.last_login
            ? moment.utc(nextProps.users.officeUser.user.last_login).local().format("DD.MM.YYYY HH:mm:ss")
            : null;
        update.created_on =
          nextProps.users.officeUser && nextProps.users.officeUser.user && nextProps.users.officeUser.user.created_on
            ? moment.utc(nextProps.users.officeUser.user.created_on).local().format("DD.MM.YYYY HH:mm:ss")
            : null;
        update.officeUserUpdated = true;
      }
      if (nextProps.roles.officeRoles !== prevState.officeRoles) {
        update.officeRoles = nextProps.roles.officeRoles;

        var officeRoles = nextProps.roles.officeRoles;
        var mappedOfficeRoles = [];
        officeRoles &&
          officeRoles.map((role) => {
            return mappedOfficeRoles.push({ id: role.id, title: role.value });
          });
        update.officeRolesMapped = mappedOfficeRoles;
        update.officeRolesUpdated = true;
      }
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {
    this.IsMounted = true;
    this.props.getOfficeUser(this.props.match.params.userId, this.props.match.params.officeId, () => {});
    // this.props.getUserGlobalRole(this.props.match.params.userId);
    this.props.getOfficeRoles();
    this.props.getUserCV(this.props.match.params.userId, (res) => {
      if (res) {
        this.setState({
          cvRoute: res,
        });
      }
    });
  }
  componentWillUnmount() {
    this.IsMounted = false;
  }
  confirmRoleChange = (newRoleId) => {
    var formData = {};
    formData.role_id = newRoleId;
    this.props.updateOfficeUserRole(this.props.match.params.userId, this.props.match.params.officeId, formData, (res) => {
      if (res.status === 200) {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/users/${this.props.match.params.userId}`);
        socket.emit("refreshUserToken", this.props.match.params.userId);
        successToast("User role changed successfully");
      } else {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/users/${this.props.match.params.userId}`);
        failToast(`User role changing failed`);
      }
    });
  };
  confirmRoleChangeModal = (e) => {
    var newRoleId = !isNaN(e.target.value) ? parseInt(e.target.value) : null;
    Confirm("Change user role?", "User will be able to see and do different thing depending on role", "Cancel", "Change", () =>
      this.confirmRoleChange(newRoleId)
    );
  };
  confirmRemove = () => {
    this.props.removeOfficeUser(this.props.match.params.userId, this.props.match.params.officeId, (res) => {
      if (res.status === 200) {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/users`);
        socket.emit("refreshUserToken", this.props.match.params.userId);
        successToast("User removed from office successfully");
      } else {
        this.props.history.push(`/admin/${this.props.match.params.officeId}/users`);
        failToast(`User removing failed`);
      }
    });
  };
  confirmRemoveModal = () => {
    Confirm("Remove from office?", "User will not be able to see anything office related", "Cancel", "Remove", () => this.confirmRemove());
  };

  onChangePhoto(e) {
    this.setState({ file: e.target.files[0] }, () => {
      this.onFormSubmit(e);
    });
  }

  onFormSubmit(e) {
    this.setState({ uploading: true });
    const formData = new FormData();
    formData.append("file", this.state.file);
    const config = {
      headers: {
        "content-type": "image/xyz",
      },
    };

    this.props.uploadProfilePhoto(this.props.match.params.userId, formData, config, (res) => {
      if (this.props.auth.user.id === parseInt(this.props.match.params.userId)) {
        this.props.refreshToken();
      }
      if (res.status === 200) {
        successToast("Photo successfully uploaded");
        this.props.getOfficeUser(this.props.match.params.userId, this.props.match.params.officeId, () => {
          if (this.IsMounted) {
            this.setState({
              uploading: false,
              src: null,
              croppedImageUrl: null,
              crop: {
                unit: "%",
                width: 100,
                aspect: 10 / 10,
              },
            });
          }
        });
      } else if (res.status === undefined) {
        failToast(`Photo upload failed! Try different format or smaller image`);
        if (this.IsMounted) {
          this.setState({ uploading: false });
        }
      }
      if (this.IsMounted) {
        this.setState({ uploading: false });
      }
    });
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
          crop: {
            unit: "%",
            width: 100,
            aspect: 10 / 10,
          },
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.setState({ croppedImageUrl }, () => {});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);

        function blobToFile(theBlob, fileName) {
          theBlob.lastModifiedDate = new Date();
          theBlob.name = fileName;
          return theBlob;
        }
        var newFile = blobToFile(blob, "name.png");

        var newfile = new File([newFile], "name.png", { type: "image/png" });
        this.setState({ file: newfile });
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  onChange(e) {
    this.setState({ fileCv: e.target.files[0] }, () => {});
  }

  onFormSubmitCv(e) {
    this.setState({ uploadingCv: true });
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.fileCv);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    this.props.uploadUserCV(this.props.match.params.userId, formData, config, (res) => {
      if (res.status === 200) {
        successToast("CV successfully uploaded");
        this.props.getUserCV(this.props.match.params.userId, (res) => {
          if (res) {
            if (this.IsMounted) {
              this.setState({
                cvRoute: res,
                uploadingCv: false,
              });
            }
          }
        });
        if (this.IsMounted) {
          this.props.getOfficeUser(this.props.match.params.userId, this.props.match.params.officeId, () => {});
        }
      } else {
        failToast(`CV upload failed! Must be pdf and less than 1MB`);
      }
      if (this.IsMounted) {
        this.setState({
          fileCv: null,
          uploadingCv: false,
        });
      }
    });
  }
  removeCVClick() {
    this.props.removeUserCV(this.props.match.params.userId, (res) => {
      if (res.status === 200) {
        if (this.IsMounted) {
          this.setState({ cvRoute: null });
        }
        successToast("CV successfully removed");
        this.props.getOfficeUser(this.props.match.params.userId, this.props.match.params.officeId, () => {});
      } else {
        failToast("Something went wrong");
      }
    });
  }

  render() {
    const { loading } = this.props.users;
    const { crop, src } = this.state;

    var content;
    var userImage = "";
    if (this.state.img === null) {
      userImage = imgPlaceholder;
    } else {
      userImage = this.state.img;
    }

    var rightContent;
    if (this.state.src === null) {
      rightContent = (
        <div className="candidate-top-right">
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">First Name:</div>
            <div className="candidate-top-right--item-value">{this.state.first_name}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Last Name:</div>
            <div className="candidate-top-right--item-value">{this.state.last_name}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Email:</div>
            <div className="candidate-top-right--item-value">{this.state.email}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Role:</div>
            <div className="candidate-top-right--item-value">{this.state.role}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Office:</div>
            <div className="candidate-top-right--item-value">{this.state.office}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Last Login:</div>
            <div className="candidate-top-right--item-value">{this.state.last_login ? this.state.last_login : "Never"}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">Created:</div>
            <div className="candidate-top-right--item-value">{this.state.created_on}</div>
          </div>
          <div className="candidate-top-right--item">
            <div className="candidate-top-right--item-title">CV:</div>
            <div className="candidate-top-right--item-value">
              {this.state.cv ? (
                <SmallButtonExternal
                  label={<i className="fas fa-external-link-alt"></i>}
                  link={this.state.cvRoute}
                  className={"btn-primary"}
                />
              ) : (
                "Not uploaded"
              )}
            </div>
          </div>
        </div>
      );
    } else {
      rightContent = (
        <div className="candidate-top-right">
          <div className="upload-image-container">
            <div className="upload-image-container-img">
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </div>
            <div className="upload-image-container-btns">
              <Btn
                label={"Cancel"}
                className="btn btn-secondary"
                onClick={() =>
                  this.setState({
                    src: null,
                    croppedImageUrl: null,
                    crop: {
                      unit: "%",
                      width: 100,
                      aspect: 10 / 10,
                    },
                  })
                }
              />
              <Btn label={"Upload"} className="btn btn-primary" onClick={this.onFormSubmit} />
            </div>
          </div>
        </div>
      );
    }

    if (!this.state.officeUserUpdated || !this.state.officeRolesUpdated || loading) {
      content = <Spinner />;
    } else {
      if (isEmpty(this.state.first_name)) {
        content = "No user with this ID";
      } else {
        var imgContent = "";
        if (this.state.uploading) {
          imgContent = (
            <div>
              <img src={loadingImg} style={{ width: "250px", margin: "auto", display: "block" }} alt="Loading..." />
            </div>
          );
        } else {
          imgContent = (
            <img
              className="card-img-top rounded-circle img-upload"
              src={this.state.croppedImageUrl ? this.state.croppedImageUrl : userImage}
              alt=""
              onError={(e) => {
                e.preventDefault();
                e.target.onerror = null;
                e.target.src = imgPlaceholder;
              }}
            />
          );
        }

        var removeCVButton = "";
        var uploadCvSubmit = "";
        if (this.state.fileCv) {
          uploadCvSubmit = (
            <button type="submit" className="btn btn-primary  confirm-cv mr-1">
              Confirm
            </button>
          );
        }
        if (this.state.cv) {
          var cvString = this.state.cv;
          var trimmedcvString = cvString.substring(13);
          removeCVButton = (
            <span>
              <SmallButton
                label={<i className="far fa-trash-alt"></i>}
                link={"#"}
                className={"btn btn-light-danger"}
                onClick={(e) => this.removeCVClick(e)}
              />
            </span>
          );
        }
        var uploadBtnText = "Upload CV";
        if (this.state.cv) {
          uploadBtnText = "New CV";
        }
        var newCvName = "";
        if (this.state.fileCv) {
          newCvName = <span className="mr-1">{this.state.fileCv.name}</span>;
        }
        var uploadingCvSpinner;
        if (this.state.uploadingCv) {
          trimmedcvString = "";
          removeCVButton = "";
          uploadingCvSpinner = <img src={loadingImg} style={{ width: "100px", margin: "auto", display: "block" }} alt="Loading..." />;
        } else {
          uploadingCvSpinner = "";
        }
        content = (
          <div className="candidate width-container">
            <div className="candidate-top">
              <div className="candidate-top-left">
                <div className="candidate-top-left-img">
                  {this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
                  (this.props.match.params.userId &&
                    !isNaN(this.props.match.params.userId) &&
                    parseInt(this.props.match.params.userId) === this.state.userId) ? (
                    <form onSubmit={this.onFormSubmit}>
                      <div className="fixed-ratio-div">
                        <label className="rounded-circle">
                          <input
                            className="centered-image rounded-circle"
                            type="file"
                            name="image"
                            accept=".gif,.jpg,.jpeg,.png,.tiff,.webp"
                            onChange={this.onSelectFile}
                            value={""}
                          />
                          {imgContent}
                        </label>
                      </div>
                    </form>
                  ) : (
                    <img
                      className="card-img-top rounded-circle"
                      src={userImage}
                      alt=""
                      onError={(e) => {
                        e.preventDefault();
                        e.target.onerror = null;
                        e.target.src = imgPlaceholder;
                      }}
                    />
                  )}
                </div>
              </div>
              {rightContent}
            </div>
            <div className="candidate-bottom">
              {this.state.userRoleOffice === roles.SUPERADMINISTRATOR || this.state.userRoleOffice === roles.ADMINISTRATOR ? (
                <div className="candidate-bottom--item">
                  <div className="candidate-bottom--item-title">User role:</div>
                  <div className="candidate-bottom--item-info">Depending on roles user can see and do different things in QASandbox.</div>
                  <div className="candidate-bottom--item-value">
                    <div className="profile-buttons-grid">
                      <div className="">
                        <Dropdown
                          options={this.state.officeRolesMapped}
                          value={this.state.role_id}
                          name={"role"}
                          onChange={(e) => this.confirmRoleChangeModal(e)}
                          placeholder="Roles"
                          required={true}
                          noTitle
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.userRoleOffice === roles.SUPERADMINISTRATOR ||
              (this.props.match.params.userId &&
                !isNaN(this.props.match.params.userId) &&
                parseInt(this.props.match.params.userId) === this.state.userId) ? (
                <div className="candidate-bottom--item">
                  <div className="candidate-bottom--item-title">CV:</div>
                  <div className="candidate-bottom--item-info">
                    You can upload user's CV here. If there is one already it will be replaced with new one. Must be PDF and less then 1MB.
                  </div>
                  <div className="candidate-bottom--item-value">
                    <div className="form-div">
                      <form onSubmit={this.onFormSubmitCv}>
                        <div className="profile-buttons-grid">
                          <label className="btn btn-light mr-1">
                            <input type="file" onChange={this.onChange} name="cv" accept=".pdf" />
                            {uploadBtnText}
                          </label>
                        </div>
                        <span className="text-secondary">{newCvName}</span>
                        {uploadCvSubmit}
                      </form>
                    </div>
                    <span className="text-primary">
                      {trimmedcvString} <span className="text-danger">{removeCVButton}</span>
                      {uploadingCvSpinner}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.userRoleOffice === roles.SUPERADMINISTRATOR || this.state.userRoleOffice === roles.ADMINISTRATOR ? (
                <div className="candidate-bottom--item">
                  <div className="candidate-bottom--item-title">Remove from office</div>
                  <div className="candidate-bottom--item-info">
                    User removed from office will not be able to see anything office related.
                  </div>
                  <div className="candidate-bottom--item-value">
                    <div className="profile-buttons-grid">
                      <div className="">
                        <Link className="btn btn-light-danger" to={"#"} onClick={(e) => this.confirmRemoveModal(e)}>
                          Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      }
    }
    return (
      <div className="grid-menu-container">
        <div className="menu-grid">
          <div className="menu">
            <SideMenuOffices props={this.props} />
            <hr />
            <SideMenu props={this.props} />
          </div>
        </div>
        <div className="main-grid">
          <div className="main">
            <div className="width-container">
              <Navigate
                title={"User"}
                link={`/admin/${this.props.match.params.officeId}/users`}
                // buttons={[
                //   <Link to={"/edit-profile"} className='btn btn-danger'>
                //     Remove
                //   </Link>,
                // ]}
              />
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

User.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  users: state.users,
  roles: state.roles,
});

export default connect(mapStateToProps, {
  getOfficeUser,
  getOfficeRoles,
  updateOfficeUserRole,
  removeOfficeUser,
  uploadProfilePhoto,
  uploadUserCV,
  removeUserCV,
  refreshToken,
  // getUserGlobalRole,
  // setUserGlobalRole,
  getUserCV,
  clearErrors,
})(withRouter(User));
