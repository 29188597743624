import React from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import SmallButton from "../common/SmallButton";
import SmallButtonExternal from "../../components/common/SmallBtnExternal";

export default function ScenariosCardPrevious({ scenarios, props }) {
  var content = "Loading...";
  var reviewer = "";
  var reviewer2 = "";
  var notreviewed = "";

  if (scenarios && scenarios.length > 0) {
    content = (
      <div className="portrait-grid">
        {scenarios &&
          scenarios.map((scenario, index) => (
            <span key={index}>
              <div className="preview-card preview-card-not-clickable">
                <div className="preview-card-title">
                  <div className="preview-card-title-value">{scenario.title}</div>
                  {scenario &&
                    scenario.candidate_exam_participants &&
                    scenario.candidate_exam_participants.map((candidate_exam_participant, index) => {
                      reviewer2 =
                        candidate_exam_participant.reviewed && candidate_exam_participant.user_id === props.auth.user.id ? (
                          <div className="candidates-tooltip-review-confirmed2" key={index}>
                            <ReactTooltip
                              id={`review-confirmed2-${index}`}
                              aria-haspopup="true"
                              className="custom-color-no-arrow"
                              textColor="#fff"
                              backgroundColor="#25c025"
                              place="left"
                              effect="solid"
                            >
                              Review confirmed
                            </ReactTooltip>

                            <i className={`fas fa-check`} data-tip data-for={`review-confirmed2-${index}`}></i>
                          </div>
                        ) : (
                          ""
                        );
                      return reviewer2;
                    })}
                  {scenario &&
                    scenario.candidate_exam_participants &&
                    scenario.candidate_exam_participants.map((candidate_exam_participant, index2) => {
                      notreviewed =
                        !candidate_exam_participant.reviewed && candidate_exam_participant.user_id === props.auth.user.id ? (
                          <div className="candidates-tooltip-not-reviewed2" key={index2}>
                            <ReactTooltip
                              id={`not-reviewed2-${index2}`}
                              aria-haspopup="true"
                              className="custom-color-no-arrow"
                              textColor="#fff"
                              backgroundColor="#ff4141"
                              place="left"
                              effect="solid"
                            >
                              Not reviewed
                            </ReactTooltip>
                            <i className={`fas fa-times`} data-tip data-for={`not-reviewed2-${index2}`}></i>
                          </div>
                        ) : (
                          ""
                        );
                      return notreviewed;
                    })}
                </div>
                <div className="preview-card-body">
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-new-line">
                      <div className="preview-card-body--items-new-line-title">Scenario:</div>
                      <div className="preview-card-body--items-new-line-value">{scenario.scenario_plain}</div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-title">Assigned:</div>
                      <div className="preview-card-body--items-single-value">
                        {moment(scenario.assigned_at).format("DD.MM.YYYY HH:mm:ss")}
                      </div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-title">Deadline:</div>
                      <div className="preview-card-body--items-single-value">
                        {scenario.days_to_finish ? `${scenario.days_to_finish} days` : "Not set"}
                      </div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-title">Status:</div>
                      <div className="preview-card-body--items-single-value">{scenario.status ? scenario.status.value : "Not set"}</div>
                    </div>
                  </div>

                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-title">Test Cases Count:</div>
                      <div className="preview-card-body--items-single-value">
                        {scenario.testcase_count ? scenario.testcase_count : "Not set"}
                      </div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-title">Automate Count:</div>
                      <div className="preview-card-body--items-single-value">
                        {scenario.automated_count ? scenario.automated_count : "Not set"}
                      </div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-short">
                        <div className="preview-card-body--items-single-short-title">Reports Count:</div>
                        <div className="preview-card-body--items-single-short-value">
                          {scenario.report_count ? scenario.report_count : "Not set"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-short">
                        <div className="preview-card-body--items-single-short-title">Repository:</div>
                        <div className="preview-card-body--items-single-short-value">
                          {scenario.repository ? scenario.repository : "Not set"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="preview-card-body--items">
                    <div className="preview-card-body--items-single">
                      <div className="preview-card-body--items-single-short">
                        <div className="preview-card-body--items-single-short-title">Comment:</div>
                        <div className="preview-card-body--items-single-short-value">
                          {scenario.comment ? scenario.comment : "Not entered"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="preview-card-body--items"
                    onClick={(e) => e.preventDefault()}
                    data-toggle="modal"
                    data-target={`#myModal-${index}`}
                  >
                    <div className="preview-card-body--items-single-preview">
                      <div className="preview-card-body--items-single-preview-title">Preview</div>
                      <div className="preview-card-body--items-single-preview-value">
                        <i className="fas fa-eye"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id={`myModal-${index}`} className="modal fade" role="dialog">
                <div className="modal-dialog">
                  {/* <!-- Modal content--> */}
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-titles">Scenario Preview</h4>
                      <button type="button" className="close" data-dismiss="modal">
                        &times;
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="preview-card-modal">
                        <div className="preview-card-modal-title">
                          <div className="preview-card-modal-title-value">{scenario.title}</div>
                        </div>
                        <hr />
                        <div className="preview-card-modal-body">
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Assigned:</div>
                              <div className="preview-card-modal-body--items-single-value">
                                {moment(scenario.assigned_at).format("DD.MM.YYYY HH:mm:ss")}
                              </div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Deadline:</div>
                              <div className="preview-card-modal-body--items-single-value">
                                {scenario.days_to_finish ? `${scenario.days_to_finish} days` : "Not set"}
                              </div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Status:</div>
                              <div className="preview-card-modal-body--items-single-value">
                                {scenario.status ? scenario.status.value : "Not set"}
                              </div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Test Cases:</div>
                              <div className="preview-card-modal-body--items-single-value">
                                {scenario.testcase_count ? scenario.testcase_count : "Not set"}
                              </div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Automate Count:</div>
                              <div className="preview-card-modal-body--items-single-value">
                                {scenario.automated_count ? scenario.automated_count : "Not set"}
                              </div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Reports:</div>
                              <div className="preview-card-modal-body--items-single-value">
                                {scenario.report_count ? scenario.report_count : "Not set"}
                              </div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Repository:</div>
                              <div className="preview-card-modal-body--items-single-value">
                                {scenario.repository ? scenario.repository : "Not set"}{" "}
                                {scenario.repository ? (
                                  <SmallButtonExternal
                                    label={<i className="fas fa-external-link-alt"></i>}
                                    link={`https://github.com/HTEC-interview/${scenario.repository}`}
                                    className={"btn-primary"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-single">
                              <div className="preview-card-modal-body--items-single-title">Comment:</div>
                              <div className="preview-card-modal-body--items-single-value">
                                {scenario.comment ? scenario.comment : "Not set"}
                              </div>
                            </div>
                          </div>
                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-new-line">
                              <div className="preview-card-modal-body--items-new-line-title">Scenario:</div>
                              <div className="preview-card-modal-body--items-new-line-value">{ReactHtmlParser(scenario.scenario)}</div>
                            </div>
                          </div>

                          <div className="preview-card-modal-body--items">
                            <div className="preview-card-modal-body--items-new-line">
                              <div className="preview-card-modal-body--items-new-line-title">Reviewers:</div>
                              {scenario && scenario.candidate_exam_participants ? <hr /> : ""}
                              {scenario &&
                                scenario.candidate_exam_participants &&
                                scenario.candidate_exam_participants.map((candidate_exam_participant, index) => {
                                  reviewer = (
                                    <div className="preview-card-modal-body--items-new-line-value" key={index}>
                                      <ReactTooltip
                                        id={`review-confirmed-${index}`}
                                        aria-haspopup="true"
                                        className="custom-color-no-arrow"
                                        textColor="#fff"
                                        backgroundColor="#25c025"
                                        effect="solid"
                                      >
                                        Review confirmed
                                      </ReactTooltip>
                                      <ReactTooltip
                                        id={`not-reviewed-${index}`}
                                        aria-haspopup="true"
                                        className="custom-color-no-arrow"
                                        textColor="#fff"
                                        backgroundColor="#ff4141"
                                        effect="solid"
                                      >
                                        Not reviewed
                                      </ReactTooltip>
                                      <div className="candidate-review-row">
                                        <div className="candidate-review-row-left">
                                          <div className="candidate-review-row-left--item">
                                            {candidate_exam_participant.user.first_name +
                                              " " +
                                              candidate_exam_participant.user.last_name +
                                              " - (" +
                                              candidate_exam_participant.user.email +
                                              ")"}
                                          </div>
                                          <div className="candidate-review-row-left--item">
                                            {candidate_exam_participant.reviewed ? (
                                              <div className="candidates-tooltip-review-confirmed">
                                                <i className={`fas fa-check`} data-tip data-for={`review-confirmed-${index}`}></i>
                                              </div>
                                            ) : (
                                              <div className="candidates-tooltip-not-reviewed">
                                                <i className={`fas fa-times`} data-tip data-for={`not-reviewed-${index}`}></i>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="candidate-review-row-right">
                                          {candidate_exam_participant.user_id === props.auth.user.id &&
                                          !candidate_exam_participant.reviewed ? (
                                            <SmallButton
                                              label="Reviewed"
                                              className="btn-primary"
                                              link="#"
                                              onClick={() =>
                                                props.updateExamParticipantStatus(
                                                  {
                                                    candidate_scenario_id: scenario.id,
                                                    office_id: props.match.params.officeId,
                                                    candidate_id: props.match.params.candidateId,
                                                    reviewed: true,
                                                  },
                                                  () => {}
                                                )
                                              }
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      <hr />
                                    </div>
                                  );
                                  return reviewer;
                                })}

                              {/* <div className="preview-card-modal-body--items-new-line-value">test1</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          ))}
      </div>
    );
  } else {
    content = <div className="no-content">There are no previous scenarios assigned.</div>;
  }
  return <div>{content}</div>;
}
