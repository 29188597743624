import isEmpty from "../validation/isEmpty";
const ChangePasswordValidation = (data) => {
  const minPassLength = 6;
  const maxPassLength = 30;

  let errors = {};

  data.current_password = !isEmpty(data.current_password) ? data.current_password : "";
  data.new_password = !isEmpty(data.new_password) ? data.new_password : "";
  data.confirm_password = !isEmpty(data.confirm_password) ? data.confirm_password : "";

  if (data.new_password.length < minPassLength) {
    errors.new_password = `New password must be at least ${minPassLength} characters long`;
  }else if(data.new_password.length > maxPassLength){
    errors.new_password = `New password must have ${maxPassLength} characters maximum`;
  }


  if (isEmpty(data.current_password)) {
    errors.current_password = "Current password is required";
  }

  if (data.current_password === data.new_password) {
    errors.new_password = "New password is same as current password";
  }

  if (isEmpty(data.new_password)) {
    errors.new_password = "New password is required";
  }

  if (data.new_password !== data.confirm_password) {
    errors.confirm_password = "Confirm password must match password";
  }

  if (isEmpty(data.confirm_password)) {
    errors.confirm_password = "Confirm password is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default ChangePasswordValidation;
