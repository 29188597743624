import axios from "axios";

import { GET_SENIORITIES, GET_SENIORITY, SENIORITY_LOADING, REMOVE_LOADING, GET_ERRORS } from "../types";

// Get All Seniorities
export const getSeniorites = () => (dispatch) => {
  dispatch(seniorityLoading());

  axios
    .get(`/api/candidate/seniorities`)
    .then((res) => {
      dispatch({
        type: GET_SENIORITIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Seniority
export const getSeniority = (seniorityId) => (dispatch) => {
  dispatch(seniorityLoading());

  axios
    .get(`/api/candidate/seniorities/${seniorityId}`)
    .then((res) =>
      dispatch({
        type: GET_SENIORITY,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
    });
};

// Create Seniority
export const createSeniority = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/seniorities`, formData)
    .then((res) => {
      dispatch({
        type: GET_SENIORITIES,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Update Seniority
export const updateSeniority = (seniorityId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidate/seniorities/${seniorityId}`, formData)
    .then((res) => {
      dispatch({
        type: GET_SENIORITIES,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Remove Seniority
export const removeSeniority = (seniorityId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/seniorities/${seniorityId}`)
    .then((res) => {
      dispatch({
        type: GET_SENIORITIES,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : { email: "Unauthorized" },
      });
      callback(err);
    });
};

// Seniority loading
export const seniorityLoading = () => {
  return {
    type: SENIORITY_LOADING,
  };
};
