import isEmpty from "../validation/isEmpty";
const CategoryValidation = (data) => {
  var errors = {};

  var titleLimit = 30;

  data.category = !isEmpty(data.category) ? data.category : "";

  if (isEmpty(data.category)) {
    errors.category = "Title is required";
  } else {
    if (data.category.length > titleLimit) {
      errors.category = `Title can not have more than ${titleLimit} character (${data.category.length})`;
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default CategoryValidation;
