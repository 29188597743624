import React from "react";
import { Link } from "react-router-dom";

export default function CategoryCards({ categories }) {
  var content = "Loading...";
  if (categories && categories.length > 0) {
    content = (
      <div className='portrait-grid'>
        {categories &&
          categories.map((category, index) => (
            <span key={index}>
              <Link to={category.link} className='preview-card'>
                <div className='preview-card'>
                  <div className='preview-card-body'>
                    <div className='preview-card-body--items'>
                      <div className='preview-card-body--items-single'>
                        <div className='preview-card-body--items-single-title'>{category.category}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </span>
          ))}
      </div>
    );
  } else {
    content = (
      <div className='no-content'>
        There are no categories added. Click Add Category in top right corner to add first.
      </div>
    );
  }
  return <div>{content}</div>;
}
