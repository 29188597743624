import axios from "axios";

import {
  GET_OFFICE_CANDIDATES,
  GET_OFFICE_CANDIDATE,
  GET_CANDIDATE_REPOS,
  GET_CANDIDATE_SCENARIOS,
  GET_GLOBAL_CANDIDATES,
  GET_GLOBAL_CANDIDATE,
  CANDIDATE_LOADING,
  REMOVE_LOADING,
  CLEAR_REPOS,
  GET_ERRORS,
} from "./types";
require("dotenv").config();

// Get All Office Candidates
export const getOfficeCandidates = (office_id, filters) => (dispatch) => {
  dispatch(candidateLoading());

  axios
    .post(`/api/candidates/office/${office_id}`, filters)
    .then((res) =>
      dispatch({
        type: GET_OFFICE_CANDIDATES,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Office Candidate
export const getOfficeCandidate = (candidateId, officeId, callback) => (dispatch) => {
  dispatch(candidateLoading());

  axios
    .get(`/api/candidates/candidate/${candidateId}/office/${officeId}`)
    .then((res) => {
      dispatch({
        type: GET_OFFICE_CANDIDATE,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

export const uploadCandidateProfilePhoto = (candidateId, officeId, file, config, callback) => (dispatch) => {
  axios
    .post(`/api//candidate/${candidateId}/photo/office/${officeId}`, file, config)

    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
};

export const uploadCandidateCV = (candidateId, officeId, cv, config, callback) => (dispatch) => {
  axios
    .post(`/api/candidate/${candidateId}/cv/office/${officeId}`, cv, config)

    .then((res) => {
      callback(res);
    })
    .catch((err) => callback(err));
};

export const removeCandidateCV = (candidateId, officeId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidate/${candidateId}/cv/office/${officeId}`)

    .then((res) => {
      callback(res);
    })
    .catch((err) => callback(err));
};

export const getCandidateCV = (candidateId, officeId, callback) => (dispatch) => {
  axios
    .get(`/api/candidate/${candidateId}/cv/office/${officeId}`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    })
    .then((res) => {
      if (!res.headers.etag) {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        callback(fileURL);
      }
    })

    .catch((error) => {
      callback(400);
    });
};

// var clientId = "81023a698e3b0c4c69cc";
// var clientSecret = "5215f281b5821187ac758e180c43f32ec531975d";

// export const getCandidateRepositories = (github_username) => (dispatch) => {
//   let headers = new Headers();
//   headers.set("Authorization", "Basic " + Buffer.from(clientId + ":" + clientSecret).toString("base64"));
//   fetch(`https://api.github.com/users/${github_username}/repos`, {
//     method: "GET",
//     headers,
//   })
//     .then((res) => res.json())
//     .then((data) => {
//       dispatch({
//         type: GET_CANDIDATE_REPOS,
//         payload: data,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err && err.response ? err.response.data : {},
//       });
//     })
//     .then(() =>
//       dispatch({
//         type: REMOVE_LOADING,
//       })
//     );
// };

export const getCandidateRepository = (repo) => (dispatch) => {
  let headers = new Headers();
  headers.set(
    "Authorization",
    "Basic " + Buffer.from(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_ACCESS_TOKEN}`).toString("base64")
  );
  fetch(`https://api.github.com/repos/HTEC-interview/${repo}`, {
    method: "GET",
    headers,
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({
        type: GET_CANDIDATE_REPOS,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : {},
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

export const updateCandidateRepository = (candidateId, formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidates/candidate/${candidateId}/repository`, formData)
    .then((res) => {
      dispatch({
        type: GET_CANDIDATE_REPOS,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Get Office Candidate
export const inviteCandidate = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidates/candidate/invite`, formData)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Create Office Candidate
export const createCandidate = (formData, callback) => (dispatch) => {
  axios
    .post(`/api/candidates/candidate/create`, formData)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Update Office Candidate
export const updateCandidate = (formData, callback) => (dispatch) => {
  axios
    .put(`/api/candidates/candidate/${formData.candidate_id}/update`, formData)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      callback(err);
    });
};

// Get All Office Candidates
export const getCandidateScenarios = (candidateId) => (dispatch) => {
  dispatch(candidateLoading());

  axios
    .get(`/api/candidates/candidate/${candidateId}/scenarios`)
    .then((res) =>
      dispatch({
        type: GET_CANDIDATE_SCENARIOS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Global Candidates
export const getGlobalCandidates = (filters) => (dispatch) => {
  dispatch(candidateLoading());

  axios
    .post(`/api/candidates`, filters)
    .then((res) =>
      dispatch({
        type: GET_GLOBAL_CANDIDATES,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Get Global Candidate
export const getGlobalCandidate = (candidateId, callback) => (dispatch) => {
  dispatch(candidateLoading());

  axios
    .get(`/api/candidates/${candidateId}`)
    .then((res) => {
      dispatch({
        type: GET_GLOBAL_CANDIDATE,
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    })
    .then(() =>
      dispatch({
        type: REMOVE_LOADING,
      })
    );
};

// Remove Candidate
export const removeGlobalCandidate = (candidateId, callback) => (dispatch) => {
  axios
    .delete(`/api/candidates/${candidateId}`)
    .then((res) => callback(res))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Deactivate User
export const examStatusChangeEmail = (emailData) => (dispatch) => {
  axios.post(`/api/candidate/scenario/status-email`, emailData);
};

// Clear Repos
export const clearRepos = () => {
  return {
    type: CLEAR_REPOS,
  };
};

// Candidate loading
export const candidateLoading = () => {
  return {
    type: CANDIDATE_LOADING,
  };
};
